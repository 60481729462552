<template>
  <div class="left_content" :style="this.getLeftContentHeight" v-if="exerciseRecord.log_no">
    <div class="section">
      <div class="info_feed text">나의 운동 성향에 따른 해당 코스 예상 소요시간은 <span class="text_org">{{this.$convertTimeStampFormatCustom(courseInfo.follow_time)}}</span> 입니다.</div>
    </div>
    <div class="section" v-if="courseInfo.track.wheather">
      <div class="info_weather">
        <span class="weather1">
          그날의 날씨는?
        </span>
        <span class="weather2">
          <span class="weather_img">
            <img src="../../../assets/images/weather/weather_01.png" v-if="courseInfo.track.wheather.rain === 0"/>
            <img src="../../../assets/images/weather/weather_05.png" v-if="courseInfo.track.wheather.rain === 1"/>
            <img src="../../../assets/images/weather/weather_08.png" v-if="courseInfo.track.wheather.rain === 2"/>
            <img src="../../../assets/images/weather/weather_08.png" v-if="courseInfo.track.wheather.rain === 3"/>
            <img src="../../../assets/images/weather/weather_06.png" v-if="courseInfo.track.wheather.rain === 4"/>
          </span>
          <span class="weather_c"><span class="address">{{courseInfo.track.log_region2}}</span>
          {{courseInfo.track.wheather.low === '' ? '-' : courseInfo.track.wheather.low}} ℃ / {{courseInfo.track.wheather.high === '' ? '-' : courseInfo.track.wheather.high}} ℃</span>
        </span>
      </div>
    </div>
    <div class="section">
      <div class="info_photo">
        <h3>사진</h3>
        <template  v-if="exerciseRecord.images.length > 0">
          <div class="gallery">
            <ul>
              <li v-for="(image, i) in exerciseRecord.images" :key="i"><img :src="image.image_thumb" @click="viewImage(i)" /></li>
            </ul>
            <!--<a href="#" class="photo_btn_left"></a>-->
            <!-- <a href="#" class="photo_btn_right"></a> -->
          </div>
        </template>
        <template v-else>
          <div class="bin">
            해당 코스 관련 사진이 없습니다.
          </div>
        </template>
      </div>
    </div>
    <div class="section" v-if="Object.keys(this.exerciseRecord).length > 0">
      <div class="info_record">
        <h3>운동 일시</h3>
          <ul class="column">
            <li>시작<span class="text">{{exerciseRecord.log_time_start}}</span></li>
            <li>종료<span class="text">{{exerciseRecord.log_time_end}}</span></li>
          </ul>
        <h3>시간</h3>
        <ul>
          <li>전체 시간<span class="text">{{this.$convertTimeStampFormat(exerciseRecord.log_total_time)}}</span></li>
          <li>운동 시간<span class="text">{{this.$convertTimeStampFormat(exerciseRecord.log_pass_time)}}</span></li>
          <li>휴식 시간<span class="text">{{this.$convertTimeStampFormat(exerciseRecord.log_total_time - exerciseRecord.log_pass_time)}}</span></li>
        </ul>
        <h3>거리</h3>
        <ul>
          <li>전체 거리<span class="text">{{this.$convertKilometerFormatCustom(exerciseRecord.log_distance)}}km</span></li>
          <li>운동 거리<span class="text">{{this.$convertKilometerFormatCustom(exerciseRecord.log_real_distance)}}km</span></li>
        </ul>
        <h3>속도</h3>
        <ul>
          <li>최고 속도<span class="text">{{this.$convertDistanceFormatCustom(exerciseRecord.log_max_speed)}}km/h</span></li>
          <li>평균 속도<span class="text">{{this.$convertDistanceFormatCustom(exerciseRecord.log_aver_speed)}}km/h</span></li>
          <li>구간 속도<span class="text">{{this.$convertDistanceFormatCustom(exerciseRecord.log_speed_section_top)}}km/h</span></li>
        </ul>
        <h3>경사도</h3>
        <ul>
          <li>오르막<span class="text">{{this.$convertDistanceFormatCustom(exerciseRecord.log_speed_up_hill)}}km</span></li>
          <li>내리막<span class="text">{{this.$convertDistanceFormatCustom(exerciseRecord.log_speed_down_hill)}}km</span></li>
          <li>평지<span class="text">{{this.$convertDistanceFormatCustom(exerciseRecord.log_speed_flat)}}km</span></li>
        </ul>
        <h3>고도</h3>
        <ul>
          <li>시작 고도<span class="text">{{this.$convertAltitudeFormatCustom(exerciseRecord.log_elevate_start)}}m</span></li>
          <li>최고 고도<span class="text">{{this.$convertAltitudeFormatCustom(exerciseRecord.log_max_elevate)}}m</span></li>
          <li>누적 고도<span class="text">{{this.$convertAltitudeFormatCustom(exerciseRecord.log_accum_elevate)}}m</span></li>
        </ul>
        <h3>소모 열량</h3>
        <ul>
          <li>총 소모 열량<span class="text">{{this.$convertKcalFormat(exerciseRecord.log_kcal)}}kcal</span></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'courseDetailRecord',
  data () {
    return {
      exerciseRecord: {},
      trackImageList: this.exerciseRecord && this.exerciseRecord.images.map((v) => v.image_origin),
      slickOptions: {
        slidesToShow: 1,
        dots: true
      }
    }
  },
  props: {
    courseInfo: Object,
    trackKey: String
  },
  components: {
  },
  async mounted () {
    await this.getExerciseRecord()
  },
  computed: {
    getLeftContentHeight () {
      if (this.$store.state.Common.courseMenuType === 'my' &&
        this.$store.state.Member.memberId === this.courseInfo.member.member_id) {
        return 'height: calc(100vh - 500px);'
      } else {
        return 'height: calc(100vh - 418px);'
      }
    }
  },
  methods: {
    viewImage (index) {
      const imageArr = this.exerciseRecord && this.exerciseRecord.images.map((v) => v.image_origin)
      this.$store.commit('Popup/setImageList', imageArr)
      this.$store.commit('Popup/setImageListIndex', index)
      this.$store.commit('Popup/controlImagePopup')
    },
    async getExerciseRecord () {
      this.axios.get('/course/exercise_record.json', {
        params: {
          track_key: this.trackKey
        }
      })
        .then(data => {
          if (data.data.response.code === '00') {
            this.exerciseRecord = data.data.response.content.response.content.list[0]
          }
        })
    }
  }
}
</script>

<style>

</style>
