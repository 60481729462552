import axios from '../../util/axios.js'
import store from '../../store'

export default {
  namespaced: true,
  state: {
    popBg: false,
    popArea: false,
    MyTrangConfPopUpStep1: false,
    MyTrangConfPopUpStep2: false,
    MyTrangConfPopUpStep3: false,
    MyTrangConfPopUpStep4: false,
    MyTrangConfPopUpStep5: false,
    MyTrangConfPopUpStep6: false,
    MyTrangBadgeTag: false,
    MyTrangBadgeSearchPopUp: false,
    MyTrangFriendTag: false,
    MyTrangFriendSearchPopUp: false,
    MyTrangBadgeSearchTag: false,
    MyTrangBadgeSearchPagePopup: false,
    MessageSendEnd: false,
    msgstat: false,
    msgBoxViewNickname: '',
    msgContent: '',
    selectFriend: [],
    friendIdOne: '',
    Exercise: false,
    addrSearch: false,
    mapSearch: false,
    addVlog: false,
    badgeSearchTap: false,

    // info
    userName: '',
    // userImg: '',
    // userLevel: 10,
    // userNextLevel: 1,
    categoryPrefer: '',
    categoryPreferArray: [],
    categoryPreferText: '',

    // trang
    trangTerms: false,
    paymentPage: false,

    searchFriendId: '',
    badgedetailId: '',
    badgename: '',

    // badge 좌표
    badgelat: 35, // 37.94036
    badgelon: 127, // 127.61253
    badgeInfo: [],

    // badge map
    badgeMapArray: [],
    badgeMapIndex: 0,
    mapInstance: null,
    BadgeList: [],
    BadgeInfoWindowList: [],
    badgeIndex: 0,

    // config map
    marker: null,
    configLat: null,
    configLon: null,

    // info
    categoryImageArray: [],
    categoryImageCnt: 0,
    address1: '',
    gender: '',
    age: '',
    createYear: 2022,

    // configArea
    locationBlock: [],
    locationAddrKo: [],

    // popup change getter 용도
    popupCommit: 0,
    changePasswordBool: false
  },
  mutations: {
    // my 트랭글 사용 메소드
    confChgComp (state) {
      state.MyTrangConfPopUpStep4 = !state.MyTrangConfPopUpStep4
      state.MyTrangConfPopUpStep5 = !state.MyTrangConfPopUpStep5
    },
    confChgIt (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.MyTrangConfPopUpStep4 = !state.MyTrangConfPopUpStep4
    },
    confClosePopChg (state) {
      state.popBg = false
      state.popArea = false
      state.MyTrangConfPopUpStep1 = false
      state.MyTrangConfPopUpStep2 = false
      state.MyTrangConfPopUpStep3 = false
      state.MyTrangConfPopUpStep4 = false
      state.MyTrangConfPopUpStep5 = false
      state.MyTrangConfPopUpStep6 = false
    },
    confMemberWith (state) {
      state.popBg = !state.MyTrangConfPopUpStep1
      state.popArea = !state.MyTrangConfPopUpStep1
      state.MyTrangConfPopUpStep1 = !state.MyTrangConfPopUpStep1
      state.MyTrangConfPopUpStep2 = false
    },
    confMemberWithNext (state) {
      state.popBg = !state.MyTrangConfPopUpStep2
      state.popArea = !state.MyTrangConfPopUpStep2
      state.MyTrangConfPopUpStep1 = false
      state.MyTrangConfPopUpStep2 = !state.MyTrangConfPopUpStep2
    },
    confMemberwithdrawLastChk (state) {
      state.popBg = !state.MyTrangConfPopUpStep6
      state.popArea = !state.MyTrangConfPopUpStep6
      state.MyTrangConfPopUpStep2 = false
      state.MyTrangConfPopUpStep6 = !state.MyTrangConfPopUpStep6
    },
    badgeSearch (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.MyTrangBadgePopupStep1 = !state.MyTrangBadgePopupStep1
      state.MyTrangBadgeTag = false
    },
    badgeSelect (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.MyTrangBadgeTag = true
      state.MyTrangBadgePopupStep1 = !state.MyTrangBadgePopupStep1
    },
    friendSearch (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.MyTrangFriendSearchPopUp = !state.MyTrangFriendSearchPopUp
      state.MyTrangFriendTag = false
    },
    friendSelect (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.MyTrangFriendTag = true
      state.MyTrangFriendSearchPopUp = !state.MyTrangFriendSearchPopUp
    },
    badgeSearchPage (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.MyTrangBadgeSearchPagePopup = !state.MyTrangBadgeSearchPagePopup
      state.MyTrangBadgeSearchTag = false
    },
    badgeSearchSelect (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.MyTrangBadgeSearchTag = true
      state.MyTrangBadgeSearchPagePopup = !state.MyTrangBadgeSearchPagePopup
    },
    interestExercise (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.Exercise = !state.Exercise
      // console.log(state.categoryPreferArray)
    },
    trangTermsPage (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.trangTerms = !state.trangTerms
    },
    trangAdd (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.paymentPage = !state.paymentPage
    },
    msgSendComp (state) {
      state.msgstat = !state.msgstat
      state.MessageSendEnd = !state.MessageSendEnd
    },
    msgPopView (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.msgstat = !state.msgstat
    },
    closeMsgEndPop (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.MessageSendEnd = !state.MessageSendEnd
    },
    addrSearchView (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.addrSearch = !state.addrSearch
    },
    mapSearchView (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.mapSearch = !state.mapSearch
    },
    blockConfigCommit (state, Block) {
      state.locationBlock = Block
    },
    addrClear (state) {
      state.locationAddrKo = []
    },
    addrKoConfigCommit (state, addrKo) {
      state.locationAddrKo.push(addrKo)
    },
    vlogAddPage (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.addVlog = !state.addVlog
    },
    photoEmpty (state, src) {
      if (src === '' || src === null || src === undefined) {
        // return 'https://www.tranggle.com/resource/img/main/thum_no_image.jpg'
        return require('@/assets/images/common/img_profile.png')
      } else if (String(src).substring(0, 4) === 'blob') {
        return src
      } else if (String(src).substring(0, 4) !== 'http') {
        return 'http://' + src
      } else {
        return src
      }
    },
    setChangePassword (state, bool) {
      state.changePasswordBool = bool
    }
  },
  actions: {
    locationBlockConfig ({ state, commit }) {
      var Block = []
      var addrKo = []
      axios.get('/Member/member_location_block_config.json', {
        params: {
          token: this.state.Member.token
        }
      })
        .then(data => {
          state.locationBlock = data.data.response.content
          Block = data.data.response.content
          // state.locationAddrKo = []
          commit('blockConfigCommit', Block)
          commit('addrClear')
          for (const i in state.locationBlock) {
            window.naver.maps.Service.reverseGeocode({
              location: new window.naver.maps.LatLng(state.locationBlock[i].location_latitude, state.locationBlock[i].location_longitude)
            }, (status, response) => {
              if (status !== window.naver.maps.Service.Status.OK) {
                // return alert('Something wrong!')
              }
              var result = response.result // 검색 결과의 컨테이너
              var items = result.items // 검색 결과의 배열

              addrKo.push(items[0].address)
              commit('addrKoConfigCommit', items[0].address)
            })
          }
        })
    },
    // 회원설정 > 비밀번호 변경
    changePassword ({ state, commit }, data) {
      const fd = new FormData()
      if (data) {
        Object.keys(data).forEach(el => {
          fd.append(el, data[el])
        })
      }
      axios.post('/Member/change_password.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setChangePassword', true)
          } else {
            const errorPopObj = {
              popupBodyMessage: memberResponseData.message
            }
            store.commit('Popup/setMessagePopup', errorPopObj)
            store.commit('Popup/controlMessagePopup')
          }
        })
    }
  },
  modules: {
  },
  getters: {
    popupChange (state) {
      state.popupCommit = state.popupCommit + 1
      console.log(state.popupCommit)
    },
    createBadges (state, commit) {
      var badge = state.badgeMapArray
      for (const i in badge) {
        // if (state.badgeMapArray.length !== 0) {
        //   state.badgeMapArray.forEach((badge, index, array) => {
        console.log(badge[i])
        const badgeMarker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(Number(badge[i].info_org_lat), Number(badge[i].info_org_lon)),
          map: state.mapInstance,
          icon: {
            // url: require('@/assets/images/sample/badge.png'),
            url: 'http://' + badge[i].badge_image,
            size: new window.naver.maps.Size(72, 72),
            origin: new window.naver.maps.Size(0, 0),
            anchor: new window.naver.maps.Point(20, 40),
            scaledSize: new window.naver.maps.Size(40, 40)
          },
          zIndex: 100
        })

        state.BadgeList.push(badgeMarker)

        // console.log(state.BadgeList)

        const contentString = [
          '    <div class="info_window">',
          '        <div class="course_info">',
          '            <ul class="only">',
          '                <li class="on">',
          '                    <span class="info_td2 h1">',
          '                        <span class="poi_info">',
          '                            <span class="subject">',
          // '                                <span class="icon_badge"><img src="' + require('@/assets/images/sample/badge.png') + '"/></span>',
          '                                <span class="icon_badge"><img src= http://' + badge[i].badge_image + '></span>',
          '                                <span class="text">' + badge[i].info_badge_name,
          '                                    <span class="stext">' + badge[i].info_org_alt + ' Km</span>',
          '                                </span>',
          '                            </span>',
          '                        </span>',
          '                        <a href="/mytranggle/badge/' + badge[i].info_badge_id + '" class="btn_detail">상세보기</a>',
          '                    </span>',
          '                </li>',
          '            </ul>',
          '        </div>',
          '    </div>'].join('')

        const badgeInfoWindow = new window.naver.maps.InfoWindow({
          content: contentString,
          borderWidth: 0,
          backgroundColor: null
        })

        state.BadgeInfoWindowList.push(badgeInfoWindow)
      }
      // })

      for (let i = 0; i < state.BadgeList.length; i++) {
        window.naver.maps.Event.addListener(state.BadgeList[i], 'click', () => {
          state.badgeIndex = i
          // this.selectedBadge()
          // console.log(i)
          state.commit('selectedBadge')
          // i.commit('selectedBadge')
        })
      }
    },
    selectedBadge (state) {
      // if (this.badgeIdx !== index) {
      // this.clearBadge()
      // console.log(state.badgeIndex)

      if (state.BadgeList.length !== 0) {
        state.BadgeList[state.badgeIndex].setZIndex(101)
        state.BadgeInfoWindowList[state.badgeIndex].open(state.mapInstance, state.BadgeList[state.badgeIndex])

        state.mapInstance.setCenter(state.BadgeList[state.badgeIndex].getPosition())

        // this.$store.commit('Course/setSelectedBadgeIndex', index)
        // this.badgeIdx = state.badgeIndex

        state.mapInstance.setZoom(14)
      }
      // }
    },
    clearBadge (state) {
      state.BadgeInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })

      // this.$store.commit('Course/setSelectedBadgeIndex', '')
      this.badgeIdx = ''
    }
  }
}
