import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import MyTranggle from './modules/MyTranggle.js'
import Activity from './modules/Activity.js'
import Course from './modules/Course.js'
import Connect from './modules/Connect.js'
import Mission from './modules/Mission.js'
import Club from './modules/Club.js'
import UserSet from './modules/UserSet.js'
import Member from './modules/Member.js'
import Common from './modules/Common.js'
import Popup from './modules/Popup.js'
import Story from './modules/Story.js'
import Now from './modules/Now.js'
import Support from './modules/Support.js'
import Control from './modules/Control.js'

export default createStore({
  modules: {
    Member,
    Connect,
    Activity,
    Course,
    Mission,
    Club,
    UserSet,
    Common,
    Popup,
    Story,
    MyTranggle,
    Now,
    Support,
    Control
  },
  plugins: [
    createPersistedState({
      paths: ['UserSet']
    })
  ]
})
