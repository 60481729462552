<template>
  <div>
    <ul :class="uiType ? 'tab_menu' : 'sub_tab_menu'">
      <li v-for="(item, idx) in subTabInfo" :key="idx" :class="item.active ? 'active': ''" @click="isActive(item)">{{ item.name }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'SubTab',
  props: {
    subTabInfo: {
      type: Array,
      default: () => ([])
    },
    uiType: {
      type: String,
      default: ''
    }
  },
  mounted () {
  },
  methods: {
    isActive (data) {
      this.subTabInfo.map((v) => {
        v.active = false
        return false
      })
      data.active = true
      this.$emit('child', this.subTabInfo)
    }
  }
}
</script>
