<template>
  <div class="left_content type1">
    <div class="list_badge">
      <ul>
        <li v-for="(badge, i) in badgeList" :key="i" :class="{on: i === this.$store.state.Course.selectedBadgeIndex}" @click="this.selectedBadge(i)">
          <span class="td1"><span class="poi_badge"><img :src="this.$photoEmpty(badge.badge_image_url)" /></span></span>
          <span class="td2">
            <span class="poi_content">
              <span class="subject"><span class="text elp">{{badge.info_badge_name}}</span></span>
              <span class="sub_text">{{badge.info_badge_message}}</span>
              <span class="data">
                <span class="text"><span class="t1">획득 경험치</span>{{badge.info_reward}}</span>
                <span class="text"><span class="t1">배지 획득 방법</span>{{badge.how_to_get}}</span>
              </span>
            </span>
          </span>
        </li>
      </ul>
    </div><!--/course_badge-->
  </div>
  <!-- <div class="list_page">
    <a href="#" class="i_left"></a>
    <a href="#" class="on">1</a><a href="#">2</a><a href="#">3</a><a href="#">4</a><a href="#">5</a>
    <a href="#" class="i_right"></a>
  </div> -->
</template>

<script>

export default {
  name: 'courseDetailBadge',
  data () {
    return {
      selectedBadgeIndex: -1,
      badgeList: this.courseInfo.badge_list
    }
  },
  props: {
    courseInfo: Object,
    trackYear: String
  },
  methods: {
    selectedBadge (index) {
      this.$store.commit('Course/setSelectedBadgeIndex', index)
    }
  },
  mounted () {
    this.$store.commit('Course/setBadgeList', this.badgeList)
  }
}
</script>

<style>

</style>
