<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <div class="popup_box popup_box_l">
        <div class="popup_top popup_top_line"><h2>코스북 이용약관 및 방법</h2>
          <span class="top_right">
            <a href="#" @click.prevent="this.$store.commit('Course/controlCourseBookAgreePopup')" class="btn_default">확인</a>
          </span>
        </div>
        <div class="popup_body">
          <p class="title">코스북 이용약관</p>
          <div class="text_block">
            코스북 이용약관 (시행일자 2022. 03. 01)<br><br>

            1. 코스북 이용 및 구매에 따른 권리<br>
            1.1 2018년 6월 1일부터 코스북을 이용하여 배지를 모으거나 조회하는 기능은 트랭글 회원이면 누구나 사용 가능합니다. 단, 콘텐츠의 편집 및 공유 기능은 구매 코스북에서 만 지원 됩니다.<br>

            1.2 구매회원에게는 구매시점에 구매자의 정보가 포함된 코스북에 한하여 트랭글 서비스의 종료시점까지 이용권한이 부여됩니다.<br>

            1.3 완성된 코스북은 트랭글 서비스가 종료되는 시점까지기간 제한없이 코스북에 포함된 텍스트와 콘텍스트 정보의 편집권한이 부여됩니다.<br>

            1.4 이미 구매한 코스북이더라도 새로운 인증정보와 콘텐츠를 별도 저장하고자 할 경우에는 재구매를 해야 합니다.<br>

            1.5 코스북을 구매하였더라도 배지가 하나도 없는 경우, 코스북의 보관기간은 2년이며, 코스북에 배지를 더 이상 추가하지 않으면 코스북은 자동 소멸될 수도 있습니다.<br>

            1.6 코스북은 양도할 수 없으며, 개인의 계정정보 관리에 따른 이용권한의 책임은 이용자 본인에게 있습니다.<br><br>

            2. 구매한 코스북 확인방법<br>
            2.1 코스북 구매 및 이용 현황은 트랭글 앱의 코스북에서 확인할 수 있습니다.<br>

            2.2 구매 완료한 코스북은 [구매]마크가 표시됩니다.<br>

            2.3 구매한 코스북 중 성공 조건에 미치지 못한 모든 코스북은 [도전 중] 탭에서 이용할 수 있으며, 성공조건을 충족한 코스북은 [성공]탭에서 조회 및 이용가능합니다.<br>

            2.4 구매 완료한 코스북은 내정보의 [내코스북]에서도 운동종목별로 확인할 수 있습니다.<br><br>

            3. 코스북 사용방법<br>
            3.1 코스북을 구성하는 위치인증배지, 기록인증배지, 이벤트배지 등을 확보한 경우, 코스북에 배지 가 자동으로 표시됩니다.<br>

            3.2 운동기록 또는 GPX파일, 인증을 위한 승인정보를 트랭글 서버에 전송하여 최종적으로 인증배지를 발급받으면 구매여부와 관계없이 코스북의 인증이 자동으로 진행되어 스탬프 페이지에 인증배지가 표시됩니다.<br>

            3.3 인증배지를 허위로 발급받거나 시스템의 허점을 이용하여 인증배지를 발급받은 경우, 코스북 성공이 취소, 코스북에서 배지 제거 또는 도전중인 코스북 전체가 초기화될 수 있습니다.<br>

            3.4 코스 매칭율(코스따라가기 비율)에 따라 인증배지가 발급되는 코스북의 경우, 각 코스의 80% 이상 정확성이 확보된 경우 만 인정됩니다. 단, 필요에 의하여 매칭율은 변경될 수 있습니다.<br>

            3.5 정상적으로 발급받은 인증배지는 코스북에 자동 적용되며, 인증배지가 하나이상 적용된 코스북은 [도전중]에 서 확인할 수 있습니다.<br>

            3.6 코스북에서 제공하는 코스, 소개자료 등의 정보는 구매여부, 인증배지 적용여부와 관계없이 누구나 이용할 수 있습니다.<br><br>

            4. 코스북의 구매 전제조건<br>
            4.1 코스북에 표시된 배지 위치, 운동기록, 코스정보는 실제와 다를 수 있으므로 개인의 책임으로 현지의 안내 푯말, 주의사항에 따라 안전을 최우선으로 이용합니다.<br>

            4.2 코스북은 트랭글에서 사용하는 사이버머니 “트랭”을 이용합니다.<br>

            4.3 환불조건은 온라인 거래의 일반규정을 따르며 성공 보상을 받은 코스북은 구매시점과 관계없이 환불되지 않습니다.<br>

            4.4 인증 배지는 동일 조건을 요구하는 모든 코스북에 중복 적용되나 트랙 매칭율에 따라 발급된 배지(예 :둘레길 배지)는 선택한 코스 외 다른 코스에 중복 적용되지 않습니다. 만일 코스 따라가기를 하지 않은 상태에서 유사한 코스가 중복으로 존재할 경우, 중복 코스 중 임의의 코스 1개에 배지가 자동 발급됩니다.<br>

            4.5 코스북에 적용된 정보는 지자체의 정책, 코스 관리상의 안전 등을 이유로 예고 없이 변경될 수 있으며, 현지 코스의 환경 변화로 인해 정보의 신뢰성이 저하될 수 있습니다.<br>

            4.6 코스북의 정보 중에는 계곡, 외나무 다리, 고지대를 통과하거나 사람들의 통행이 없거나 적은 지역이 포함할 수 있으며, 계절에 따른 위험성이 상존하므로 코스의 안정성에 대해서 (주)비글은 책임을 지지 않습니다.<br>

            4.7. 코스북 이용 관련 문의, 오류 제보는 이메일(trangglecs@tranggle.com)로 접수 받고 있습니다.<br>

            코스북을 구매한다는 것은 상기 약관에 완전하게 동의함을 의미합니다.
          </div>
          <br><br>
          <p class="title">코스북 이용방법</p>
          <div class="text_block">
            코스북은 완주, 종주 배지를 획득하게 도와주는 도전 컨텐츠 입니다. 코스북에 제시된 모든 배지를 획득하면 코스북이 완성되고 목표로 하는 완주 또는 종주 배지가 배지함에 발급됩니다. (완주, 종주 배지 획득에는 반드시 코스북이 필요 합니다.)<br><br>

            코스북을 이용한 도전은 코스북 내에 포함된 배지를 하나라도 획득시 자동으로 시작됩니다. 그러나 도전을 원치 않는 다면 코스북은 스스로 삭제 할 수 있습니다. 이 경우, 삭제된 코스북은 복구되지 않으며 트랭글에서도 도와드릴 수 없습니다. 그러니 코스북 삭제는 신중하게 결정하세요.<br><br>

            코스북을 이용한 새로운 완주, 종주 도전은 추천 코스북에서 스스로 시작할 수 있습니다. 코스북은 도전 중에도, 완료한 후에도 구매가 가능합니다. 구매한 코스북은 코스북에 포함된 사진과 글을 내 사진과 글로 멋지게 편집할 수 있습니다.<br><br>

            멋진 나만의 코스북으로 종주와 완주의 기록을 추억의 앨범처럼 남겨보세요. 후에 소장이 가능한 실재 앨범 제작 서비스와 연계될 예정입니다. 트랭글은 당신의 도전 기록을 소중하게 생각합니다.<br>
            도전하라, 트랭글!<br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  name: 'CourseBookAgreePopup'
}
</script>

<style>

</style>
