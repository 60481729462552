<template>
  <div class="popup login_bg m_pop join_complete" v-if="!this.$store.state.Popup.isOpenUpdateMemberInfo">
    <div class="login_page" v-if="$store.state.UserSet.isLogin === 'Y'">
      <div class="popup_area">
        <div class="popup_box popup_box_lm">
          <div class="popup_top"><h2 class="head1">회원가입완료</h2></div>
          <div class="popup_body login_body">
            <p>회원가입을 축하합니다.<br>
            {{memberInfo.name}}님의 아이디는 <span class="text_org">{{memberInfo.id}}</span> 입니다.</p>

            <div class="box_org">
              <p><span class="t_bold">100경험치, 가입축하배지, 50트랭</span>이<br>
              지급되었습니다.</p>
              <p class="mt"><span class="stext"><span class="t_bold">마이트랭글 〉마이페이지</span>에서 확인하실 수 있습니다.</span></p>

              <span class="login_benefits"></span>
            </div>
            <p>트랭글은 운동기록을 공유하고 랭킹 서비스를 제공하는<br>
            회원제(무료)서비스 입니다.</p><br>
            <p>트랭글의 <span class="text_org">다양한 맞춤 서비스를 위하여 회원정보 설정</span>을 완료해 주세요. 또는 우측 트랭글 아이콘을 클릭하여 홈 이동하세요.
            <br><br><br><br><br><br><br>
            </p>
            <div class="footer_bottom">
              <div class="btn_area btn_bottom2">
                <button type="button" class="btn_f on" @click="$store.commit('Popup/controlUpdateMemberInfo')">설정하기</button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="login_main">
          <router-link to="/" class="btn_main"></router-link>
        </div> -->
      </div>
    </div>
  </div>
  <UpdateMemberInfo v-if="$store.state.Popup.isOpenUpdateMemberInfo"/>
</template>

<script>
import UpdateMemberInfo from './UpdateMemberInfo.vue'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'JoinComplete',
  data () {
    return {
      isOpenUpdateMemberInfo: false
    }
  },
  components: {
    UpdateMemberInfo: UpdateMemberInfo
  },
  computed: {
    ...mapState('Member', ['memberInfo'])
  },
  mounted () {
    if (this.$store.state.UserSet.isLogin === 'Y') {
      // 데이터 조회
      this.getMemberInfo()
    } else {
      this.$showMessagePopup('', '잘못된 접근입니다.', '/')
    }
  },
  methods: {
    ...mapActions('Member', [
      'getMemberInfo'
    ])
  }
}
</script>
