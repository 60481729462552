
<template>
  <div class="sub_content terms_contents">
    <h3 v-if="from !== 'join'" class="line_bottom">광고성 정보 수신동의</h3>
    <!--<div class="top_link_area"><a href="#">최신 이용약관 보기</a></div>-->
    <div class="text wrapper">
      <div>
        <strong>이용 목적 </strong><br />
        이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션, 신규 컨텐츠 알림이나 이벤트 정보안내 등 고객 지향적인 마케팅<br /><br />
        <strong>활용 항목 </strong><br />
        이메일, 휴대폰번호, APP.PUSH<br /><br />
        <strong>보유기간</strong><br />
        동의 철회 또는 회원 탈퇴 시까지<br /><br />
        광고성 정보를 APP.PUSH로 전송하고 있습니다. 회사는 광고성 정보 전송에 대한 고객의 사전 동의를 얻고 APP.PUSH의 본문란에 아래와 같이 고객이 광고성 정보임을 쉽게 알아볼 수 있도록 조치합니다.<br />
        - APP.PUSH 본문란 : ‘(광고)’ 라는 문구를 본문 맨 처음에 빈칸 없이 한글로 표시합니다.<br /><br />
        * 위의 개인정보 수집・이용에 대한 동의를 거부할 권리가 있습니다. 그러나, 동의하지 않으셔도 회원가입 및 일부 서비스 이용이 가능합니다.
      </div>
    </div>
    <!-- <div class="terms_history">
      <select name="" id="" class="style1 select_lg">
        <option value="">최신 개인 위치정보 사용 약관 보기</option>
        <option value="">이전약관 보기 (시행일자 2022.03.01)</option>
      </select>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    from: {
      type: String,
      defualt: ''
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
    if (this.from !== 'join') {
      window.scrollTo(0, 0)
    } else {
      document.getElementsByClassName('popup_terms')[0].scrollTo(0, 0)
    }
  }
}
</script>
