<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <input type='file' id='inputGpxFile' @change="inputFile" accept=".gpx" multiple>
      <div class="popup_box popup_box_l" v-if="this.gpxFiles.length === 0">
        <div class="popup_top popup_top_line"><h2>GPX 업로드<span class="sub_title">한 번에 GPX 파일 최대 50개, 50MB 까지 업로드 가능합니다.</span></h2>
          <a href="#" class="btn_close" @click="this.$store.commit('Course/controlUploadGPXFilePopup')"></a>
        </div>
        <div class="popup_body">
          <div class="upload_file" @drop="dropFiles" @dragenter.prevent @dragover.prevent>
            <div class="upload_box">
              <div class="txt">
                외부 GPX 파일은 트랭글 <b>배지 및 경험치 지급이 되지 않고, 기타 기록</b>으로 저장됩니다.<br />
                트랭글 GPX가 아닌 외부 GPX파일은 따라가기 등 트랭글에서 지원하는 다양한 기능들이 동작하지 않을 수 있으며,<br />이는 오류가 아니오니 이용에 참고 부탁드립니다.
              </div>
              GPX 파일을 여기에 드래그하세요.<br>또는<br>
              <button type="button" class="on" @click="clickInputFile">기기에서 파일 선택</button>
            </div>
          </div>
          <div class="btn_area">
            <button type="button" class="dis btn_m">업로드</button>
            <button type="button" class="btn_m" @click="this.$store.commit('Course/controlUploadGPXFilePopup')">취소</button>
          </div>
        </div>
      </div>
      <div class="popup_box popup_box_l" v-if="this.gpxFiles.length > 0">
        <div class="popup_top popup_top_line"><h2>GPX 업로드<span class="sub_title">한 번에 GPX 파일 최대 50개, 50MB 까지 업로드 가능합니다.</span></h2>
        </div>
        <div class="popup_body">
          <div class="upload_area_text">전체 파일 : {{this.gpxFiles.length}} / {{this.maxGpxFileCount}}개(업로드 가능/불가능 : {{getUploadFileCount(true)}} / {{getUploadFileCount(false)}})   ㅣ 용량 : {{getGpxFilesSize(true)}} / 50 MB</div>
          <div class="upload_area_text ft_red" v-if="resultMsg !== ''">{{resultMsg}}</div>
          <div class="upload_area">
            <div class="upload_file">
              <div class="file_table">
                <table>
                  <colgroup>
                    <col width="45" />
                    <col width="200" />
                    <col width="67" />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>파일명</th>
                      <th>용량</th>
                      <th>업로드</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(gpxFile, i) in this.gpxFiles" :key="i"
                     @click="this.$store.state.Course.selectedGpxFile = gpxFile"
                     :class="{'on': this.$store.state.Course.selectedGpxFile.file.name === gpxFile.file.name}"
                     >
                      <td>{{i + 1}}</td>
                      <td>{{gpxFile.file.name}}</td>
                      <td>{{this.$addComma(Math.floor(gpxFile.file.size / 1024))}}KB</td>
                      <td class="yes" v-if="gpxFile.possible">가능<a href="#" class="btn_delete" @click.prevent="deleteGpxFile(i)"></a></td>
                      <td class="no" v-else>불가능<a href="#" class="btn_delete" @click.prevent="deleteGpxFile(i)"></a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="upload_box" @drop="dropFiles" @dragenter.prevent @dragover.prevent>
                GPX 파일을 여기에 드래그하세요.<br>또는<br>
                <button type="button" class="upload" @click="clickInputFile">기기에서 파일 선택</button>
              </div>
            </div>
            <div class="map_view" style="background-color: antiquewhite;">
              <UploadGpxFileMap :menu="'popup'" ref="popupMap"/>
            </div>
          </div>
          <div class="info_text">파일을 클릭하시면 우측 지도에 경로가 보이며 업로드 이후 나의 코스 우측 메뉴 > 외부 기록 에서 확인할 수 있습니다.</div>
          <div class="btn_area">
            <button type="button" class="btn_m" @click="this.uploadFile()">업로드</button>
            <button type="button" class="btn_m" @click="this.$store.commit('Course/controlUploadGPXFilePopup')">취소</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadGpxFileMap from '../map/UploadGpxFileMap.vue'

export default {
  name: 'UploadGPXFilePopup',
  data () {
    return {
      gpxFiles: [],
      maxGpxFilesSize: 50 * 1024 * 1024,
      maxGpxFileCount: 50,
      totalDistance: 0,
      totalTime: '00:00:00',
      averageSpeed: 0,
      resultMsg: ''
    }
  },
  components: {
    UploadGpxFileMap: UploadGpxFileMap
  },
  methods: {
    checkDuplicateFile (files) {
      let checkDuplicate = false
      for (let i = 0; i < this.gpxFiles.length; i++) {
        for (let j = 0; j < files.length; j++) {
          if (this.gpxFiles[i].file.name === files[j].name) {
            checkDuplicate = true
          }
        }
        if (checkDuplicate) {
          this.$showMessagePopup('GPX 업로드', '중복된 파일이 존재합니다.<br/>' + this.gpxFiles[i].file.name)
          return false
        }
      }

      return !checkDuplicate
    },
    clickInputFile () {
      if (this.checkFilesCount && this.checkFilesSize('popup')) {
        document.getElementById('inputGpxFile').value = ''
        document.getElementById('inputGpxFile').click()
      }
    },
    dragOverFiles (e) {
      e.stopPropagation()
      e.preventDefault()
    },
    dropFiles (e) {
      e.preventDefault()
      const files = e.dataTransfer.files
      this.addGpxFile(files)
    },
    addGpxFile (files) {
      console.log(files.length)
      if (this.checkDuplicateFile(files) && this.checkFilesCount(files) && this.checkFilesExt(files) && this.checkFilesSize('popup', files)) {
        Array.from(files).forEach((file, index) => {
          this.loadFile(file, (xml) => {
            console.log(file)
            this.gpxFiles.push({ file: file })

            const trackData = this.parseXml(xml, index)
            if (trackData.segments.length > 0) {
              this.gpxFiles[this.gpxFiles.length - 1].possible = true
              this.convertGpxData(trackData.segments, this.gpxFiles.length - 1)
            } else {
              this.gpxFiles[this.gpxFiles.length - 1].possible = false
            }
            if (this.gpxFiles.length === 1) {
              this.$store.state.Course.selectedGpxFile = this.gpxFiles[0]
            }
          })
        })
      }
      return false
    },
    deleteGpxFile (index) {
      if (this.gpxFiles[index].file.name === this.$store.state.Course.selectedGpxFile.file.name) {
        this.gpxFiles.splice(index, 1)

        if (this.gpxFiles.length > 0) {
          this.$store.state.Course.selectedGpxFile = this.gpxFiles[0]
        }
      } else {
        this.gpxFiles.splice(index, 1)
      }
      if (this.checkFilesSize() && this.getUploadFileCount(false) === 0) {
        this.resultMsg = ''
      }
    },
    convertGpxData (data, index) {
      const startTime = new Date(data[0][0].time)
      const endTime = new Date(data[0][data[0].length - 1].time)
      const diffTime = (endTime.getTime() - startTime.getTime()) / (1000)
      let seconds = diffTime % 60
      seconds = seconds.toString().length === 1 ? '0' + seconds : seconds
      const minutes = ((diffTime - seconds) / 60) % 60
      const hours = (((diffTime - seconds) / 60) - minutes) / 60
      let totalDistance = 0

      this.gpxFiles[index].time = ((diffTime - seconds) / 60) + '.' + seconds
      this.gpxFiles[index].coords = []
      data[0].forEach((value, dataIndex) => {
        if (dataIndex < data[0].length - 1) {
          const lat1 = value.loc[0]
          const lng1 = value.loc[1]
          const lat2 = data[0][dataIndex + 1].loc[0]
          const lng2 = data[0][dataIndex + 1].loc[1]

          this.gpxFiles[index].coords.push({ lat: lat1, lon: lng1 })

          var r = 6371
          var dLat = this.deg2rad(lat2 - lat1)
          var dLon = this.deg2rad(lng2 - lng1)

          var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) *
           Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
          var d = r * c

          totalDistance += Math.round(d * 1000)
        }
      })

      const speed = (totalDistance / (parseFloat(hours + '.' + minutes.toString() + seconds.toString()) * 1000)).toFixed(1)

      this.gpxFiles[index].distance = totalDistance
      this.gpxFiles[index].speed = speed
    },
    deg2rad (deg) {
      return deg * (Math.PI / 180)
    },
    checkFilesExt (files) {
      let checkFile = true
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileNameLength = file.name.length
        const fileDot = file.name.lastIndexOf('.')
        const fileExt = file.name.substring(fileDot, fileNameLength).toLowerCase()

        if (fileExt !== '.gpx') {
          this.$showMessagePopup('GPX 업로드', 'GPX파일만 업로드 가능합니다.')
          checkFile = false
          return
        }
      }
      return checkFile
    },
    checkFilesCount (files) {
      const checkFilesCount = files == null ? this.gpxFiles.length >= this.maxGpxFileCount : this.gpxFiles.length + files.length > this.maxGpxFileCount
      if (checkFilesCount) {
        this.$showMessagePopup('GPX 업로드', '최대 ' + this.maxGpxFileCount + '개 까지 업로드 가능합니다.')
        return false
      } else {
        return true
      }
    },
    checkFilesSize (type, files) {
      const checkFiles = []
      Object.assign(checkFiles, this.gpxFiles.map((gpxFile) => { return gpxFile.file }))
      if (files != null) {
        Object.assign(checkFiles, files)
      }

      let filesSize = 0
      checkFiles.forEach((file) => {
        filesSize += file.size
      }, 0)

      const checkFilesSize = files == null ? filesSize >= this.maxGpxFilesSize : filesSize > this.maxGpxFilesSize
      if (checkFilesSize) {
        if (type === 'popup') {
          this.$showMessagePopup('GPX 업로드', '최대 50Mb 까지 업로드 가능합니다.')
        }
        return false
      } else {
        return true
      }
    },
    inputFile (e) {
      this.addGpxFile(e.target.files)
    },
    parseXml (xmlstr, index) {
      var doc = new DOMParser().parseFromString(xmlstr, 'text/xml')
      return this.get_gpx_data(doc.documentElement)
    },
    get_gpx_data (node, result) {
      if (!result) {
        result = { segments: [] }
      }

      switch (node.nodeName) {
        case 'trkseg':
          var segment = []
          result.segments.push(segment)
          for (var i = 0; i < node.childNodes.length; i++) {
            var snode = node.childNodes[i]
            if (snode.nodeName === 'trkpt') {
              var trkpt = { loc: [parseFloat(snode.attributes.lat.value), parseFloat(snode.attributes.lon.value)] }
              for (var j = 0; j < snode.childNodes.length; j++) {
                var ssnode = snode.childNodes[j]
                switch (ssnode.nodeName) {
                  case 'time':
                    trkpt.time = new Date(ssnode.childNodes[0].data)
                    break
                  case 'ele':
                    trkpt.ele = parseFloat(ssnode.childNodes[0].data)
                    break
                }
              }
              segment.push(trkpt)
            }
          }
          break
      }
      for (var k = 0; k < node.childNodes.length; k++) {
        this.get_gpx_data(node.childNodes[k], result)
      }
      return result
    },
    loadFile (inputFile, onloadFunc) {
      if (typeof window.FileReader !== 'function') {
        this.$showMessagePopup('GPX 업로드', '이 기능을 사용할 수 없는 브라우저입니다.')
        return
      }

      const fr = new FileReader()
      fr.onload = receivedText
      fr.readAsText(inputFile)

      function receivedText () {
        onloadFunc(fr.result)
      }
    },
    getUploadFileCount (type) {
      let count = 0
      this.gpxFiles.forEach(gpxFile => {
        if (gpxFile.possible === type) {
          count++
        }
      })
      return count
    },
    getGpxFilesSize () {
      let filesize = 0
      this.gpxFiles.forEach(gpxFile => {
        filesize += gpxFile.file.size
      })
      return (filesize / (1024 * 1024)).toFixed(1)
    },
    uploadFile () {
      if (!this.checkFilesSize()) {
        this.resultMsg = '업로드 파일의 용량이 50MB를 넘어 업로드 할 수 없습니다.'
      } else if (this.getUploadFileCount(false) > 0) {
        this.resultMsg = '업로드 불가능한 GPX 파일이 포함되어 있습니다.'
      } else {
        this.gpxFiles.forEach(gpxFile => {
          const formData = new FormData()
          formData.append('token', this.$store.state.Member.token)
          formData.append('log_distance', gpxFile.distance)
          formData.append('log_total_time', gpxFile.time)
          formData.append('log_aver_speed', gpxFile.speed)
          formData.append('gpxFile_01', gpxFile.file)

          this.axios.post('/course/gpxUpload.json', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then(data => {
              const responseCode = data.data.response.code

              if (responseCode === '00') {
                this.$store.commit('Course/controlUploadGPXFilePopup')
                this.$showMessagePopup('GPX 업로드', data.data.response.message, '/course/my/gpx')
                this.$store.commit('Course/setUploadGPXSuccess')
              } else {
                this.$showMessagePopup('GPX 업로드', data.data.response.message)
              }
            })
        })
      }
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
  #inputGpxFile {display: none}
  .ft_red {color: red}
</style>
