<template>
    <div id="naver_map" style="width: 100%;" :style="{height: domestic ? '100%' : '0%'}"></div>
    <CourseMapOSM v-if="!domestic" />
    <template v-if="this.menu !== 'story'">
      <div class="map_lnb">
        <template v-if="trackNo != null && domestic">
          <span class="map_btn_badge"><a href="#" :class="{'on': isVisibleBadge}" @click.prevent="controlPanel('badge')">배지</a></span>
          <span class="map_btn_waypoint"><a href="#" :class="{'on': isVisibleWaypoint}" @click.prevent="controlPanel('waypoint')">웨이포인트</a></span>
          <span class="map_btn_speed"><a href="#" :class="{'on': isVisibleSpeed}" @click.prevent="controlLineType('speed')">속도</a></span>
          <span class="map_btn_speed"><a href="#" :class="{'on': isVisibleSlope}" @click.prevent="controlLineType('slope')">고도</a></span>
        </template>
        <div class="map_course_compare" v-if="this.$store.state.Course.isOpenCompareTrackPopup">
            <span class="sub_text">
              2개의 코스를 '코스 비교'로 등록해보세요.
              <a href="#" class="btn_close" @click.prevent="closeCouseComp"></a>
            </span>
            <span class="course_compare_g">
              <span :class="{'compare def elp': this.$store.state.Common.compareTrack1.name === '' || this.$store.state.Common.compareTrack1.name === null, 'compare fin elp': this.$store.state.Common.compareTrack1.name !== '' }">{{this.$store.state.Common.compareTrack1.name}}
                <a href="#" class="i_del" v-if="this.$store.state.Common.compareTrack1.name !== ''" @click="this.$store.commit('Common/deleteCompareTrack', '1')"></a>
              </span>
              <span class="text_vs">VS</span>
              <span :class="{'compare def elp': this.$store.state.Common.compareTrack2.name === '' || this.$store.state.Common.compareTrack2.name === null, 'compare fin elp': this.$store.state.Common.compareTrack2.name !== '' }">{{this.$store.state.Common.compareTrack2.name}}
                <a href="#" class="i_del" v-if="this.$store.state.Common.compareTrack2.name !== ''" @click="this.$store.commit('Common/deleteCompareTrack', '2')"></a>
              </span>
              <a href="#" class="btn_compare" :class="{on:this.$store.state.Common.isPossibleCourseCompare}"
                @click="this.$store.commit('Common/compareTrack')">비교하기</a>
            </span>
          </div>
      </div>
      <div class="map_rnb" v-if="this.lineType.length === 1">
        <div class="map_btn_speed_info">
          <ul v-show="lineType[0] === 'speed'">
            <li><span class="speed_square c1"></span>측정 불가</li>
            <li><span class="speed_square c2"></span>1~3km/h</li>
            <li><span class="speed_square c3"></span>3~5km/h</li>
            <li><span class="speed_square c4"></span>5~7km/h</li>
            <li><span class="speed_square c5"></span>7~12km/h</li>
            <li><span class="speed_square c6"></span>12~20km/h</li>
            <li><span class="speed_square c7"></span>20~40km/h</li>
            <li><span class="speed_square c8"></span>40~60km/h</li>
            <li><span class="speed_square c9"></span>60~70km/h</li>
            <li><span class="speed_square c10"></span>70~80km/h</li>
            <li><span class="speed_square c11"></span>80~100km/h</li>
            <li><span class="speed_square c12"></span>100km/h초과</li>
          </ul>
          <ul v-show="lineType[0] === 'slope'">
            <li><span class="square1"></span>기타(측정불가)</li>
            <li><span class="square2"></span>내리막 심함</li>
            <li><span class="square3"></span>내리막 보통</li>
            <li><span class="square4"></span>평지</li>
            <li><span class="square5"></span>오르막 보통</li>
            <li><span class="square6"></span>오르막 심함</li>
          </ul>
        </div>
      </div>
    </template>
    <div class="map_fnb" v-if="this.trackNo != null && this.menu !== 'story'" ref="map_fnb">
      <!-- <a href="#"><span class="map_btn_refresh" v-if="this.$store.state.Course.searchKeyword !== '' && checkMoved">현 지도에서 재검색1</span></a> -->
      <div :class="[{'fnb_area': true}, {'fold': !this.$store.state.Course.isOpenLaps}]">
        <div class="fnb_area_btn">
          <a href="#" @click.prevent="controlPanel('laps')"><span class="i_height"></span><span class="i_timelapse2"></span></a>
          <div class="fnb_area_top"></div>
        </div>
        <Laps v-if="this.$store.state.Course.isOpenLaps"/>
      </div>
    </div>
    <div class="map_fnb" v-else-if="checkMoved">
      <a href="#" @click.prevent="selectListByCoords"><span class="map_btn_refresh">현위치 코스보기</span></a>
    </div>
</template>

<script>
import Laps from '../map/Laps.vue'
import CourseMapOSM from '../map/CourseMapOSM.vue'
import { mapState } from 'vuex'

let mapInstance = null
let coursePinMarkerList = []
let courseInfoWindowList = []
let coursePolyLinePointList = []
let listCourseBadgeList = []
let courseBadgeList = []
let courseBadgeInfoWindowList = []
let courseWaypointList = []
let courseWaypointInfoWindowList = []
let coursePolyLine = null
let courseOutPolyLine = null
let lineTypePolyLine = {
  speed: [],
  slope: []
}
let startPointMarker = null
let endPointMarker = null
let lapsMarker = null

const speedColorArray = ['#FFFFFF', '#C6D9F1', '#00B0F0', '#0070C0', '#A7DC53', '#23BF1B', '#FFFF99', '#FFFF00', '#FFC000', '#E46C0A', '#984807', '#FF4141']
const slopeColorArray = ['#9A9A9A', '#3271F1', '#4DAE39', '#FFB619', '#FF730D', '#DA0303']
const defaultPolyLineStyle = {
  strokeColor: '#000000',
  strokeWeight: 6,
  strokeOpacity: 0.8,
  strokeStyle: 'solid',
  strokeLineCap: 'round',
  strokeLineJoin: 'round'
}
const outPolyLineStyle = {
  strokeColor: '#FF7920 ',
  strokeWeight: 4,
  strokeOpacity: 1,
  strokeStyle: 'solid',
  strokeLineCap: 'round',
  strokeLineJoin: 'round'
}
const polyLineStyle = {
  fillOpacity: 0.3,
  strokeWeight: 6,
  strokeOpacity: 1,
  strokeStyle: 'solid',
  strokeLineCap: 'round',
  strokeLineJoin: 'bevel'
}

export default {
  data () {
    return {
      courseList: this.$store.state.Course.courseList,
      badgeIdx: '',
      badge: {},
      isVisibleBadge: false,
      isVisibleWaypoint: false,
      isVisibleLineType: false,
      isVisibleSpeed: false,
      isVisibleSlope: false,
      lineType: [],
      trackPointArray: [],
      checkMoved: false,
      checkClicked: false,
      maxLat: 0,
      minLat: 0,
      maxLng: 0,
      minLng: 0,
      mapHeight: 0
    }
  },
  props: {
    trackNo: String,
    menu: String,
    searchStatus: Boolean,
    searchKeyword: String,
    cSearch: String
  },
  name: 'CourseMap',
  components: {
    Laps: Laps,
    CourseMapOSM: CourseMapOSM
  },
  computed: {
    ...mapState('Course', ['isOpenLaps', 'currentLapsTab', 'courseInfo', 'domestic']),
    checkCourseList () {
      return this.$store.state.Course.courseList
    },
    checkBadgeList () {
      return this.$store.state.Course.badgeList
    },
    checkIsVisibleCourseBadge () {
      return this.$store.state.Course.isVisibleCourseBadge
    },
    checkSelectedBadgeIndex () {
      return this.$store.state.Course.selectedBadgeIndex
    },
    checkCurrentLapsTab () {
      return this.$store.state.Course.currentLapsTab
    },
    checkCurrentTrackPoint () {
      return this.$store.state.Course.currentTrackPoint
    },
    checkCourseBadgeList () {
      return this.$store.state.Course.courseBadgeList
    }
  },
  watch: {
    checkCourseList (courseList) {
      this.courseList = courseList
      this.createCourseMarker()
    },
    checkBadgeList (badgeList) {
      this.badgeList = badgeList
      this.createCourseBadges()
    },
    checkIsVisibleCourseBadge (isVisibleCourseBadge) {
      if (isVisibleCourseBadge) {
        this.showCourseBadgeList()
      } else {
        this.hideCourseBadgeList()
      }
    },
    checkSelectedBadgeIndex (selectedBadgeIndex) {
      if (selectedBadgeIndex !== '') {
        this.selectedCourseBadge(selectedBadgeIndex)
      }
    },
    checkCurrentLapsTab (currentLapsTab) {
      if (currentLapsTab === 'detail') {
        this.showLapsMarker()
      } else {
        this.deleteLapsMarker()
      }
    },
    checkCurrentTrackPoint (currentTrackPoint) {
      this.showLapsMarker()
    },
    checkCourseBadgeList (courseBadgeList) {
      this.createListCourseBadges()
    },
    currentLapsTab () {
      this.$nextTick(() => {
        // 하단 탭 이동시 지도 size 변경
        const mapSize = mapInstance.getSize()
        const fnbHeight = this.mapHeight - this.$refs.map_fnb.clientHeight + 50
        mapInstance.setSize(new window.naver.maps.Size(mapSize.width, fnbHeight))
        this.setFitBounds(coursePolyLine.getBounds())
      })
    }
  },
  emits: ['selectListByCoords'],
  methods: {
    closeCouseComp () {
      this.$store.state.Common.compareTrack1.name = ''
      this.$store.state.Common.compareTrack1.year = ''
      this.$store.state.Common.compareTrack1.no = ''
      this.$store.state.Common.compareTrack2.name = ''
      this.$store.state.Common.compareTrack2.year = ''
      this.$store.state.Common.compareTrack2.no = ''
      this.$store.state.Course.isOpenCompareTrackPopup = false
    },
    initMap () {
      mapInstance = new window.naver.maps.Map('naver_map', {
        useStyleMap: true,
        center: new window.naver.maps.LatLng(37.4012318, 127.108642),
        zoom: 10,
        enableWheelZoom: true,
        enableDragPan: true,
        enableDblClickZoom: false,
        mapMode: 0,
        activateTrafficMap: false,
        activateBicycleMap: false,
        minZoom: 7,
        maxZoom: 21,
        zoomControl: true,
        zoomControlOptions: {
          position: window.naver.maps.Position.TOP_RIGHT,
          style: window.naver.maps.ZoomControlStyle.SMALL
        },
        mapTypeControl: true
      })

      // window.naver.maps.Event.addListener(mapInstance, 'click', (e) => {
      //   if (this.trackNo == null) {
      //     this.clearCourseMarker()
      //   }
      //   this.clearCourseBadge()
      //   this.clearCourseWaypoint()
      // })

      window.naver.maps.Event.addListener(mapInstance, 'dragstart', (e) => {
        this.checkMoved = false
      })

      window.naver.maps.Event.addListener(mapInstance, 'dragend', (e) => {
        this.checkMoved = true
      })

      window.naver.maps.Event.addListener(mapInstance, 'zoom_changed', (e) => {
        if (this.checkClicked) {
          this.checkClicked = false
        } else {
          this.checkMoved = true
        }
      })

      window.naver.maps.Event.addListener(mapInstance, 'click', (e) => {
        this.closeInfoWindow()
      })
    },
    showLapsMarker () {
      this.deleteLapsMarker()
      const point = new window.naver.maps.LatLng(
        this.$store.state.Course.trackPoints[0][this.$store.state.Course.currentTrackPoint].lat
        , this.$store.state.Course.trackPoints[0][this.$store.state.Course.currentTrackPoint].lon)

      lapsMarker = new window.naver.maps.Marker({
        position: point,
        map: mapInstance,
        icon: this.$getMapPinMarkerIcon('map_pin_play', ''),
        zIndex: 100
      })

      // mapInstance.setCenter(point)
    },
    deleteLapsMarker () {
      if (lapsMarker != null) {
        lapsMarker.setMap(null)
        lapsMarker = null
      }
    },
    createCourseMarker () {
      this.deleteCourseMarker()
      coursePinMarkerList = []
      courseInfoWindowList = []
      const courseArray = []
      this.courseList.forEach((course, index, array) => {
        const pinClassName = 'map_pin'
        if (index === 0) {
          mapInstance.setCenter(new window.naver.maps.LatLng(course.log_position_start_y, course.log_position_start_x))
        }
        const marker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(course.log_position_start_y, course.log_position_start_x),
          map: mapInstance,
          icon: this.$getMapPinMarkerIcon(pinClassName, (index + 1)),
          zIndex: 100
        })

        // fitbounds용 좌표 배열
        courseArray.push(new window.naver.maps.LatLng(course.log_position_start_y, course.log_position_start_x))

        coursePinMarkerList.push(marker)

        // let detailLink = '/course/' + course.log_track_no
        // 코스상세 주석처리 추후 오류 수정
        // let detailLink = `/course/${course.log_track_no}?search_val=${this.searchKeyword || ''}`

        // if (this.menu === 'theme') {
        //   detailLink = '/course/' + course.log_track_no + '?type=theme&category=' + this.$route.params.id
        // }

        const contentString = [
          '<div class="info_window">',
          '  <div class="course_info">',
          '    <ul class="only">',
          '      <li class="on">',
          '        <span class="info_td2">',
          '          <span class="poi_info">',
          '            <span class="subject"><span class="icon_exc ' + this.$categoryImage(course.log_category) + '"></span><span class="text elp">' + course.log_track_name + '</span></span>',
          '            <span class="data">',
          '              <span class="info_distance">' + this.$convertKilometerFormatCustom(course.log_distance) + ' km</span>',
          '              <span class="info_time">' + this.$convertTimeStampFormat(course.log_total_time) + '</span>',
          '            </span>',
          '          </span>',
          // `          <a href="#" class="btn_detail" onclick="moveCourse('${detailLink}')">코스상세</a>`,
          '        </span>',
          '      </li>',
          '    </ul>',
          '  </div>',
          '</div>'].join('')
        const infoWindow = new window.naver.maps.InfoWindow({
          content: contentString,
          borderWidth: 0,
          backgroundColor: null
        })
        courseInfoWindowList.push(infoWindow)
      })

      // 검색시 좌표들 fitbounds
      if (this.searchStatus && !this.cSearch) {
        this.setFitBounds(courseArray)
      }

      for (let i = 0; i < coursePinMarkerList.length; i++) {
        window.naver.maps.Event.addListener(coursePinMarkerList[i], 'click', () => {
          this.selectedCourseMarker(i)
        })
      }
    },
    selectedCourseMarker (index) {
      // 선택한 트랙으로 스크롤 이동
      document.querySelector(`#list_${index}`) && document.querySelector(`#list_${index}`).scrollIntoView({
        behavior: 'smooth'
      })
      if (this.$store.state.Course.selectedCourseIndex !== index) {
        this.clearCourseMarker()
        coursePinMarkerList[index].setZIndex(101)
        coursePinMarkerList[index].setIcon(this.$getMapPinMarkerIcon('map_pin on', (index + 1)))
        courseInfoWindowList[index].open(mapInstance, coursePinMarkerList[index])

        // mapInstance.setCenter(coursePinMarkerList[index].getPosition())
        this.createCourseLine(this.courseList[index].log_track_no)

        this.$store.commit('Course/setSelectedCourseIndex', index)

        this.checkClicked = true
        // mapInstance.setZoom(14)
      } else {
        this.closeInfoWindow()
        courseInfoWindowList[index].open(mapInstance, coursePinMarkerList[index])
      }
    },
    hoverCourseMarker (index) {
      coursePinMarkerList[index].setZIndex(101)
    },
    grazeCourseMarker (tagetindex) {
      if (this.$store.state.Course.selectedCourseIndex !== tagetindex) {
        coursePinMarkerList[tagetindex].setIcon(this.$getMapPinMarkerIcon('map_pin on', (tagetindex + 1)))
      }
    },
    EraseCourseOnMarker (tagetindex) {
      if (this.$store.state.Course.selectedCourseIndex !== tagetindex) {
        coursePinMarkerList[tagetindex].setIcon(this.$getMapPinMarkerIcon('map_pin', (tagetindex + 1)))
      }
    },
    clearCourseMarker () {
      coursePinMarkerList.forEach((value, index, array) => {
        value.setZIndex(100)
        value.setIcon(this.$getMapPinMarkerIcon('map_pin', (index + 1)))
      })
      courseInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })
      if (coursePolyLine != null) {
        coursePolyLine.setMap(null)
      }
      if (courseOutPolyLine != null) {
        courseOutPolyLine.setMap(null)
      }

      this.$store.commit('Course/setSelectedCourseIndex', '')
    },
    deleteCourseMarker () {
      coursePinMarkerList.forEach((marker, index, array) => {
        marker.setMap(null)
      })
      courseInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })
      if (coursePolyLine != null) {
        coursePolyLine.setMap(null)
        coursePolyLine = null
      }
      if (courseOutPolyLine != null) {
        courseOutPolyLine.setMap(null)
        courseOutPolyLine = null
      }
      if (startPointMarker != null) {
        startPointMarker.setMap(null)
      }
      if (endPointMarker != null) {
        endPointMarker.setMap(null)
      }
    },
    createCourseLine (trackNo) {
      this.axios.get('/map2/get_track_point_v2.json', {
        params: {
          track_key: trackNo
        }
      })
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode === '00') {
            this.trackPointArray = data.data.response.content.point
            if (this.trackPointArray.length === 1) {
              if (this.trackNo != null) {
                // 하단 laps 정보
                this.$store.commit('Course/setCourseTrackData', data.data.response.content)
              }
              const pointArray = this.trackPointArray[0]
              // 국내, 해외 판단
              this.$store.commit('Course/setNationCheck', pointArray)
              if (this.domestic) {
                coursePolyLinePointList = pointArray.map((trackPoint, index) => {
                  return window.naver.maps.LatLng(trackPoint.lat, trackPoint.lon)
                })
                this.createLineTypePolyLine('speed')
                this.createLineTypePolyLine('slope')
                coursePolyLine = new window.naver.maps.Polyline(defaultPolyLineStyle)
                coursePolyLine.setPath(coursePolyLinePointList)
                coursePolyLine.setMap(mapInstance)
                coursePolyLine.setVisible(true)
                courseOutPolyLine = new window.naver.maps.Polyline(outPolyLineStyle)
                courseOutPolyLine.setPath(coursePolyLinePointList)
                courseOutPolyLine.setMap(mapInstance)
                courseOutPolyLine.setVisible(true)
                const polyBounds = coursePolyLine.getBounds()
                this.maxLat = polyBounds._max._lat
                this.maxLng = polyBounds._max._lng
                this.minLat = polyBounds._min._lat
                this.minLng = polyBounds._min._lng
                // this.setMapCenter()
                this.setFitBounds(polyBounds)
                // mapInstance.setCenter(coursePolyLinePointList[0])
                if (this.trackNo != null) {
                  // 웨이포인트, 배지 디폴트 실행
                  this.showCourseWaypointList()
                  this.showCourseBadgeList()
                  this.controlLineType('slope')
                  startPointMarker = new window.naver.maps.Marker({
                    position: coursePolyLinePointList[0],
                    map: mapInstance,
                    icon: this.$getMapPinMarkerIcon('map_pin_start', ''),
                    zIndex: 100
                  })
                  endPointMarker = new window.naver.maps.Marker({
                    position: coursePolyLinePointList[coursePolyLinePointList.length - 1],
                    map: mapInstance,
                    icon: this.$getMapPinMarkerIcon('map_pin_goal', ''),
                    zIndex: 100
                  })
                }
              }
            }
          }
        })
    },
    createCourseBadges () {
      courseBadgeInfoWindowList = []
      this.$store.state.Course.badgeList.forEach((badge, index, array) => {
        const badgeMarker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(Number(badge.info_org_lat), Number(badge.info_org_lon)),
          icon: {
            url: this.$photoEmpty(badge.badge_image_url),
            size: new window.naver.maps.Size(30, 30),
            scaledSize: new window.naver.maps.Size(30, 30),
            origin: new window.naver.maps.Size(0, 0),
            anchor: new window.naver.maps.Point(15, 30)
          },
          zIndex: 100
        })

        courseBadgeList.push(badgeMarker)

        const contentString = [
          '<div class="info_window">',
          '  <div class="badge_info">',
          '    <span class="badge_img"><img src="' + this.$photoEmpty(badge.badge_image_url) + '"></span>',
          '    <div class="poi_content">',
          '      <span class="subject"><span class="text elp">' + badge.info_badge_name + '</span></span>',
          // '      <span class="sub_text">' + badge.info_badge_message + '</span>',
          '    </div>',
          // '    <div class="info_box line_bottom">',
          // '      <ul>',
          // '        <li><span class="title">배지 주인</span><span class="text">' + badge.info_register_id + '</span></li>',
          // '        <li><span class="title">보유자수</span><span class="text">' + badge.info_count + '명</span></li>',
          // '      </ul>',
          // '    </div>',
          // '    <div class="info_box">',
          // '      <h4>나의 배지 획득 기록</h4>',
          // '      <ul>',
          // '        <li><span class="title">배지순위</span><span class="text">-</span></li>',
          // '        <li><span class="title">최초획득</span><span class="text">-</span></li>',
          // '        <li><span class="title">방문횟수</span><span class="text">-</span></li>',
          // '      </ul>',
          // '    </div>',
          // '    <div class="info_btn">',
          // `      <a href="#" onclick="moveCourse('/mytranggle/badge/${badge.info_badge_id}')">배지 상세 정보 바로가기</a>`,
          // '    </div>',
          '  </div>',
          '</div>'].join('')

        const badgeInfoWindow = new window.naver.maps.InfoWindow({
          content: contentString,
          borderWidth: 0,
          backgroundColor: null
        })

        courseBadgeInfoWindowList.push(badgeInfoWindow)
      })

      for (let i = 0; i < courseBadgeList.length; i++) {
        window.naver.maps.Event.addListener(courseBadgeList[i], 'click', () => {
          this.selectedCourseBadge(i)
        })
      }
    },
    createCourseWaypoints () {
      courseWaypointInfoWindowList = []
      this.courseInfo.waypoint.forEach((waypoint, index, array) => {
        const latitude = waypoint.lat
        const longitude = waypoint.lon
        if (parseInt(latitude) === 0 || parseInt(longitude) === 0) {
          return
        }
        const waypointMarker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(latitude, longitude),
          icon: this.$getMapPinMarkerIcon('map_pin_waypoint', ''),
          zIndex: 100
        })
        courseWaypointList.push(waypointMarker)
        const contentString = [
          '<div class="info_window">',
          '  <div class="waypoint_info">',
          `    <div class="info_title" ${!waypoint.wp_img_name ? 'style="display: none;"' : ''}>${waypoint.wp_img_name}</div>`,
          `    <span class="waypoint_img" ${!waypoint.wp_img_web_url ? 'style="display: none;"' : ''}><img src="${waypoint.wp_img_web_url}"></span>`,
          '    <div class="info_box">',
          '      <ul>',
          `        <li><span class="title">위치</span><span class="text">${latitude} / ${longitude}</span></li>`,
          '      </ul>',
          '    </div>',
          '  </div>',
          '</div>'].join('')
        const waypointInfoWindow = new window.naver.maps.InfoWindow({
          content: contentString,
          borderWidth: 0,
          backgroundColor: null
        })
        courseWaypointInfoWindowList.push(waypointInfoWindow)
      })
      for (let i = 0; i < courseWaypointList.length; i++) {
        window.naver.maps.Event.addListener(courseWaypointList[i], 'click', () => {
          courseWaypointList[i].setZIndex(101)
          mapInstance.setCenter(courseWaypointList[i].getPosition())
          courseWaypointInfoWindowList[i].open(mapInstance, courseWaypointList[i])
        })
      }
    },
    selectedCourseBadge (index) {
      if (this.badgeIdx !== index) {
        this.clearCourseBadge()

        // mapInstance.setZoom(14)
        courseBadgeList[index].setZIndex(101)
        // mapInstance.setCenter(courseBadgeList[index].getPosition())
        courseBadgeInfoWindowList[index].open(mapInstance, courseBadgeList[index])

        this.$store.commit('Course/setSelectedBadgeIndex', index)
        this.badgeIdx = index
      } else {
        this.closeInfoWindow()
        courseBadgeInfoWindowList[index].open(mapInstance, courseBadgeList[index])
      }
    },
    selectedListCourseBadge (index) {
      listCourseBadgeList[index].setZIndex(101)
      mapInstance.setZoom(14)
      this.checkClicked = true
      mapInstance.setCenter(listCourseBadgeList[index].getPosition())
      courseBadgeInfoWindowList[index].open(mapInstance, listCourseBadgeList[index])
    },
    clearCourseBadge () {
      courseBadgeInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })

      this.$store.commit('Course/setSelectedBadgeIndex', '')
      this.badgeIdx = ''
    },
    clearCourseWaypoint () {
      courseWaypointInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })
    },
    showCourseBadgeList () {
      courseBadgeList = []
      if (courseBadgeList.length === 0 && this.courseInfo.badge_list.length > 0) {
        this.createCourseBadges()
        this.isVisibleBadge = true
      }
      courseBadgeList.forEach((badge, index, array) => {
        badge.setMap(mapInstance)
      })
    },
    hideCourseBadgeList () {
      this.$store.commit('Course/setSelectedBadgeIndex', '')
      courseBadgeList.forEach((badge, index, array) => {
        badge.setMap(null)
      })
      courseBadgeInfoWindowList.forEach((bdageInfoWindow, index, array) => {
        bdageInfoWindow.close()
      })
    },
    showCourseWaypointList () {
      if (courseWaypointList.length === 0 && this.courseInfo.waypoint.length > 0) {
        this.createCourseWaypoints()
        this.isVisibleWaypoint = true
      }
      courseWaypointList.forEach((waypoint, index, array) => {
        waypoint.setMap(mapInstance)
      })
    },
    hideCourseWaypointList () {
      courseWaypointList.forEach((waypoint, index, array) => {
        waypoint.setMap(null)
      })
      courseWaypointInfoWindowList.forEach((waypointInfoWindow, index, array) => {
        waypointInfoWindow.close()
      })
    },
    showListCourseBadgeList () {
      listCourseBadgeList.forEach((badge, index, array) => {
        badge.setMap(mapInstance)
      })
    },
    hideListCourseBadgeList () {
      listCourseBadgeList.forEach((badge, index, array) => {
        badge.setMap(null)
      })
      courseBadgeInfoWindowList.forEach((bdageInfoWindow, index, array) => {
        bdageInfoWindow.close()
      })
    },
    controlPanel (type) {
      if (type === 'laps') {
        this.$store.commit('Course/controlIsOpenLaps')
        this.fitMapSize()
      } else if (type === 'badge') {
        this.isVisibleBadge = !this.isVisibleBadge
        if (this.trackNo == null) {
          if (this.isVisibleBadge) {
            this.showListCourseBadgeList()
          } else {
            this.hideListCourseBadgeList()
          }
        } else {
          if (this.isVisibleBadge) {
            this.showCourseBadgeList()
          } else {
            this.hideCourseBadgeList()
          }
        }
      } else if (type === 'waypoint') {
        this.isVisibleWaypoint = !this.isVisibleWaypoint
        if (this.isVisibleWaypoint) {
          this.showCourseWaypointList()
        } else {
          this.hideCourseWaypointList()
        }
      } else if (type === 'lineType') {
        this.isVisibleLineType = !this.isVisibleLineType
        if (!this.isVisibleLineType) {
          // this.controlLineType()
          this.lineType = []
          coursePolyLine.setMap(mapInstance)
          coursePolyLine.setVisible(true)
          courseOutPolyLine.setMap(mapInstance)
          courseOutPolyLine.setVisible(true)
        }
      }
    },
    clearPolyLine (type) {
      for (const polyLine in lineTypePolyLine) {
        lineTypePolyLine[polyLine].forEach(value => {
          value.setMap(null)
          value.setVisible(false)
        })
      }
      if (type) {
        coursePolyLine.setStyles({
          strokeColor: '#ffffff',
          strokeOpacity: 0.3,
          strokeWeight: 8
        })
      }
      // coursePolyLine.setMap(null)
      // coursePolyLine.setVisible(false)
      courseOutPolyLine.setMap(null)
      courseOutPolyLine.setVisible(false)
    },
    controlLineType (type) {
      const target = type
      this.clearPolyLine(target)
      if (target === 'speed') {
        this.isVisibleSpeed = !this.isVisibleSpeed
        this.isVisibleSlope = false
      } else {
        this.isVisibleSlope = !this.isVisibleSlope
        this.isVisibleSpeed = false
      }

      if (this.lineType.length > 0 && !this.lineType.includes(target || this.lineType[0])) {
        this.lineType = []
        this.showLineTypePolyLine(target || this.lineType[0])
      } else if (this.lineType.length > 0 && this.lineType.includes(target || this.lineType[0])) {
        this.lineType = []
        coursePolyLine.setMap(mapInstance)
        coursePolyLine.setVisible(true)
        courseOutPolyLine.setMap(mapInstance)
        courseOutPolyLine.setVisible(true)
        coursePolyLine.setStyles({
          strokeColor: '#000000',
          strokeOpacity: 0.8,
          strokeWeight: 6
        })
      } else {
        this.showLineTypePolyLine(target || this.lineType[0])
      }
    },
    showLineTypePolyLine (lineType) {
      if (lineType) {
        this.lineType.push(lineType)
        lineTypePolyLine[lineType].forEach(value => {
          value.setMap(mapInstance)
          value.setVisible(true)
        })
      }
    },
    createLineTypePolyLine (lineType) {
      const path = []
      path.push([])
      let pathIndex = 0
      let tempColor = ''
      let pathColor = ''
      let eleFirst = '' // 구간의 첫 높이
      let eleLast = '' // 구간의 마지막 높이
      let disSum = 0 // 구간의 거리 합
      let speedArr = []
      // 50m(3포인트) 마다 속도/경사도 평균값 적용
      this.trackPointArray[0].forEach((trackPoint, j) => {
        if (trackPoint.lat != null && trackPoint.lon != null) {
          if (!eleFirst) {
            eleFirst = String(trackPoint.ele)
          }
          disSum = disSum + Number(trackPoint.distance)
          speedArr.push(Number(trackPoint.speed))
          // 3포인트 약 50m 마다 측정
          if (j % 3 === 0 && j !== 0) {
            eleLast = String(trackPoint.ele)
            // 경사도(%) 계산 (마지막 높이 - 첫 높이) / 거리합 * 100
            const pathSlope = (Number(eleLast) - Number(eleFirst)) / disSum * 100 || 0
            // 속도는 50m 속도의 평균값
            const reducer = (accumulator, curr) => accumulator + curr
            const pathSpeed = speedArr.reduce(reducer) / speedArr.length
            pathColor = (lineType === 'speed') ? this.getSpeedLineColor(pathSpeed) : this.getSlopeLineColor(pathSlope)
            eleFirst = eleLast
            disSum = 0
            speedArr = []
          }
          const latlng1 = window.naver.maps.LatLng(trackPoint.lat, trackPoint.lon)
          if (tempColor === '' || tempColor === pathColor) {
            path[pathIndex].push(latlng1)
          } else {
            path[pathIndex].push(latlng1)
            this.setLineTypePolyLine(lineType, tempColor, path[pathIndex])

            path[++pathIndex] = []
            path[pathIndex].push(latlng1)
          }
          tempColor = pathColor
        }
      })

      if (path[pathIndex].length > 1) {
        path[pathIndex].push(window.naver.maps.LatLng(this.trackPointArray[0][this.trackPointArray[0].length - 1].lat,
          this.trackPointArray[0][this.trackPointArray[0].length - 1].lon))
      } else {
        path[pathIndex].push(window.naver.maps.LatLng(this.trackPointArray[0][this.trackPointArray[0].length - 1].lat,
          this.trackPointArray[0][this.trackPointArray[0].length - 1].lon))

        if (lineType === 'speed') {
          const speed = parseFloat(this.trackPointArray[this.trackPointArray.length - 1].speed)
          tempColor = this.getSpeedLineColor(speed)
        } else if (lineType === 'slope') {
          const slope = parseFloat(this.trackPointArray[this.trackPointArray.length - 1].slope)
          tempColor = this.getSlopeLineColor(slope)
        }
      }

      this.setLineTypePolyLine(lineType, tempColor, path[pathIndex])
    },
    setLineTypePolyLine (lineType, color, path) {
      polyLineStyle.strokeColor = color
      polyLineStyle.fillColor = color
      const polyLine = new window.naver.maps.Polyline(polyLineStyle)
      polyLine.setPath(path)
      lineTypePolyLine[lineType].push(polyLine)
    },
    getSpeedLineColor (speed) {
      if (speed > 1 && speed <= 3) {
        return speedColorArray[1]
      } else if (speed > 3 && speed <= 5) {
        return speedColorArray[2]
      } else if (speed > 5 && speed <= 7) {
        return speedColorArray[3]
      } else if (speed > 7 && speed <= 12) {
        return speedColorArray[4]
      } else if (speed > 12 && speed <= 20) {
        return speedColorArray[5]
      } else if (speed > 20 && speed <= 40) {
        return speedColorArray[6]
      } else if (speed > 40 && speed <= 60) {
        return speedColorArray[7]
      } else if (speed > 60 && speed <= 70) {
        return speedColorArray[8]
      } else if (speed > 70 && speed <= 80) {
        return speedColorArray[9]
      } else if (speed > 80 && speed <= 100) {
        return speedColorArray[10]
      } else if (speed > 100) {
        return speedColorArray[11]
      } else {
        return speedColorArray[0]
      }
    },
    getSlopeLineColor (slope) {
      if (slope <= -20) {
        return slopeColorArray[1]
      } else if (slope > -20 && slope < -5) {
        return slopeColorArray[2]
      } else if (slope >= -5 && slope < 5) {
        return slopeColorArray[3]
      } else if (slope >= 5 && slope < 20) {
        return slopeColorArray[4]
      } else if (slope >= 20) {
        return slopeColorArray[5]
      } else {
        return slopeColorArray[0]
      }
    },
    createListCourseBadges () {
      listCourseBadgeList = []
      this.$store.state.Course.courseBadgeList.forEach((badge, index, array) => {
        const badgeMarker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(Number(badge.info_org_lat), Number(badge.info_org_lon)),
          icon: {
            url: this.$photoEmpty(badge.badge_image_url),
            size: new window.naver.maps.Size(30, 30),
            scaledSize: new window.naver.maps.Size(30, 30),
            origin: new window.naver.maps.Size(0, 0),
            anchor: new window.naver.maps.Point(15, 30)
          },
          zIndex: 100
        })

        listCourseBadgeList.push(badgeMarker)

        const contentString = [
          '<div class="info_window">',
          '  <div class="badge_info">',
          '    <span class="badge_img"><img src="' + this.$photoEmpty(badge.badge_image_url) + '"></span>',
          '    <div class="poi_content">',
          '      <span class="subject"><span class="text elp">' + badge.info_badge_name + ')</span></span>',
          // '      <span class="sub_text">' + badge.info_badge_message + '</span>',
          '    </div>',
          // '    <div class="info_box line_bottom">',
          // '      <ul>',
          // '        <li><span class="title">배지 주인</span><span class="text">' + badge.info_register_id + '</span></li>',
          // '        <li><span class="title">보유자수</span><span class="text">' + badge.info_count + '명</span></li>',
          // '      </ul>',
          // '    </div>',
          // '    <div class="info_box">',
          // '      <h4>나의 배지 획득 기록</h4>',
          // '      <ul>',
          // '        <li><span class="title">배지순위</span><span class="text">-</span></li>',
          // '        <li><span class="title">최초획득</span><span class="text">-</span></li>',
          // '        <li><span class="title">방문횟수</span><span class="text">-</span></li>',
          // '      </ul>',
          // '    </div>',
          // '    <div class="info_btn">',
          // `      <a href="#" onclick="moveCourse('/mytranggle/badge/${badge.info_badge_id}')">배지 상세 정보 바로가기</a>`,
          // '    </div>',
          '  </div>',
          '</div>'].join('')

        const badgeInfoWindow = new window.naver.maps.InfoWindow({
          content: contentString,
          borderWidth: 0,
          backgroundColor: null
        })

        courseBadgeInfoWindowList.push(badgeInfoWindow)
      })
      for (let i = 0; i < listCourseBadgeList.length; i++) {
        window.naver.maps.Event.addListener(listCourseBadgeList[i], 'click', () => {
          this.selectedListCourseBadge(i)
        })
      }
    },
    selectListByCoords () {
      this.checkMoved = false
      this.deleteCourseMarker()
      this.$emit('select-list-by-coords', mapInstance.getCenter().y, mapInstance.getCenter().x)
    },
    closeInfoWindow () {
      courseBadgeInfoWindowList.forEach((bdageInfoWindow, index, array) => {
        bdageInfoWindow.close()
      })
      courseInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })
      courseWaypointInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })
      return false
    },
    convertDmsToDegree (degree) {
      const dmsDegree = parseInt(degree)
      const dmsMinutes = parseInt((degree - dmsDegree) * 60)
      const dmsSeconds = Math.round((parseInt((degree - dmsDegree) * 60) - dmsMinutes) * 60, 2)

      return dmsDegree + ' ' + dmsMinutes + '\'' + dmsSeconds + '"'
    },
    setMapCenter () {
      const averlat = (this.minLat + this.maxLat) / 2
      const averlon = (this.minLng + this.maxLng) / 2
      mapInstance.setCenter(averlat, averlon)
    },
    setFitBounds (bounds) {
      const pad = this.isOpenLaps ? 10 : 100
      mapInstance.fitBounds(bounds, { top: pad, right: pad, bottom: pad, left: pad })
    },
    moveCourse (link) {
      this.$router.push(link)
    },
    fitMapSize () {
      this.$nextTick(() => {
        // 하단 fnb on/off에 따라 지도 size 변경
        const mapSize = mapInstance.getSize()
        const fnbHeight = mapSize.height - this.$refs.map_fnb.clientHeight + 50
        mapInstance.setSize(new window.naver.maps.Size(mapSize.width, this.isOpenLaps ? fnbHeight : this.mapHeight))
        this.setFitBounds(coursePolyLine.getBounds())
      })
    }
  },
  mounted () {
    this.initMap()
    if (this.trackNo != null) {
      this.createCourseLine(this.trackNo)
    }
    if ((this.$store.state.Common.compareTrack1.year !== null && this.$store.state.Common.compareTrack1.no !== null) ||
            (this.$store.state.Common.compareTrack2.year !== null && this.$store.state.Common.compareTrack2.no !== null)) {
      if ((this.$store.state.Common.compareTrack1.year !== '' && this.$store.state.Common.compareTrack1.no !== '') ||
            (this.$store.state.Common.compareTrack2.year !== '' && this.$store.state.Common.compareTrack2.no !== '')) {
        this.$store.state.Course.isOpenCompareTrackPopup = true
      }
    }
    this.mapHeight = mapInstance.getSize().height
  },
  unmounted () {
    lineTypePolyLine = {
      speed: [],
      slope: []
    }
    this.deleteLapsMarker()
    courseWaypointList = []
  },
  created () {
    window.closeInfoWindow = this.closeInfoWindow
    window.moveCourse = this.moveCourse
  }
}
</script>

<style>

</style>
