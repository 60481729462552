<template>
  <div class="popup_in popup_bg no_country">
    <div class="popup_area">
      <div class="popup_box popup_box_ms">
        <div class="popup_top popup_top_line"><h2>인증 미지원</h2></div>
        <div class="popup_body">
          <p>목록에 없는 국가는 SMS 인증 미지원 국가입니다.<br>
          이메일 인증을 진행하여, 회원가입을 완료 해 주세요.
          </p><br>
          <div>
            <ul>
              <li>
                <span class="set_text">이메일</span>
                <span class="gc">
                  <span class="set_input1 input_l mb input_flex">
                    <div class="request_input">
                      <input
                        v-model="email"
                        type="text"
                        class="input_l"
                        placeholder="이메일 주소"
                        :disabled="validateEmailCode === 'Y' || certifyEmailTime > 0"
                        @change="valEamil()"
                        @blur="valEamil()"
                        @keydown.enter.prevent="sendEmail"
                      />
                    </div>
                    <div class="request_wrap">
                      <button class="request" :class="{'line': isSendEmail}" @click.prevent="sendEmail" :disabled="!emailCheck">
                        {{ isSendEmail ? '재요청' : '인증요청' }}
                      </button>
                    </div>
                  </span>
                  <span v-if="vals.email === 'N'" class="stext text_red">{{ vals.emailMsg }}</span>
                  <span v-if="isSendEmail" class="set_input1 input_l mb">
                    <div class="input_flex">
                      <div class="request_input">
                        <input
                          v-model="emailCode"
                          type="text"
                          class="input_l"
                          maxlength="6"
                          placeholder="인증번호 6자리"
                          :disabled="vals.auth === 'Y'"
                          @input="checkMaxLength"
                        />
                        <span v-if="isSendEmail" class="confirm_time">{{ timeText }}</span>
                         <span v-if="vals.auth !== ''" :class="[{'check_ok': vals.auth === 'Y'}]"></span>
                        <span v-if="vals.auth === 'N'" ckass="stext text_red"> {{ vals.authMsg }}</span>
                      </div>
                      <div class="request_wrap">
                        <button :disabled="vals.auth === 'Y'" class="request on" @click.prevent="certifyEmail">확인</button>
                      </div>
                    </div>
                    <!-- <span v-if="validateEmailCode !== ''" :class="[{'check_ok': validateEmailCode === 'Y'}, {'check_no': validateEmailCode !== 'Y' && certifyEmailTime === 0}]"></span>
                    <span v-if="validateEmailCode === 'Y'" class="stext">이메일 인증되었습니다.</span>
                    <span v-if="validateEmailCode === 'N' && certifyEmailTime > 0" class="stext text_red">이메일 인증 입력정보 오류로 본인인증에 실패하였습니다.<br/>다시 시도해 주세요.</span>
                    <span v-if="validateEmailCode !== 'Y' && certifyEmailTime === 0" class="stext text_red">요청 시간이 만료되었습니다. 다시 요청해 주세요.</span> -->
                  </span>
                </span>
              </li>
            </ul>
          </div><br><br>
          <p class="t_bold">이메일 인증을 받지 못한 경우</p>
          <div class="box_gray mt mbs">
            <span class="section">
              · 정확한 주소를 입력하였는지 확인 해 주세요.<br>
              · 스팸메일함에 있는지 확인 해 주세요.<br>
              · 이메일 주소를 변경하여 재인증 해주세요.
            </span>
          </div><br>
          <div class="btn_area">
            <button type="button" class="line" @click="cancelEmailAuth()">취소</button>
            <button type="button" :class="{'disabled': vals.auth !== 'Y'}" @click="completeEmailAuth()">인증완료</button>
          </div>
          <!-- <div v-if="isOpenNoticeSendEmailPopup" class="popup_notice"><span class="notice_text">입력하신 이메일 주소로 인증번호가 전송 되었습니다.</span></div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'EmailAuthPopup',
  data () {
    return {
      email: '',
      emailCode: '',
      validateEmail: '',
      validateEmailCode: '',
      isSendEmail: false,
      timer: null,
      timeText: '',
      vals: {
        email: '',
        emailMsg: '',
        auth: '',
        authMsg: ''
      },
      certifyEmailTime: 0,
      emailCheck: false
    }
  },
  computed: {
    ...mapState('Member', [
      'emailAuthBool',
      'emailAuthMessage',
      'emailCertifyBool',
      'emailCertifyMessage'
    ])
  },
  watch: {
    emailAuthBool () {
      if (this.emailAuthBool === 'Y') {
        // this.isOpenNoticeSendEmailPopup = true
        // setTimeout(() => { this.isOpenNoticeSendEmailPopup = false }, 1000)
        this.$showMessagePopup('이메일 인증', '입력하신 이메일 주소로 인증번호가 전송 되었습니다.')
        this.isSendEmail = true
        this.emailCode = ''
        // this.certifyEmailTime = 3600
        // this.controlEmailTimer()
        this.validateEmailCode = ''
        this.authTimer(60 * 60)
      }
      if (this.emailAuthBool === 'N') {
        this.vals.email = 'N'
        this.vals.emailMsg = this.emailAuthMessage
        // this.$showMessagePopup('이메일 인증', this.emailAuthMessage)
      }
      this.$store.state.Member.emailAuthBool = ''
    },
    emailCertifyBool () {
      if (this.emailCertifyBool === 'Y') {
        this.vals.auth = 'Y'
        clearInterval(this.timer)
        this.timeText = ''
      }
      if (this.emailCertifyBool === 'N') {
        // this.$showMessagePopup('인증번호 확인', this.emailCertifyMessage)
        this.vals.auth = 'N'
        this.vals.authMsg = this.emailCertifyMessage
      }
      this.$store.state.Member.emailCertifyBool = ''
    },
    email () {
      if (this.email.length) {
        this.emailCheck = true
      } else {
        this.emailCheck = false
      }
    }
  },
  methods: {
    ...mapActions('Member', [
      'loadEmailAuth',
      'loadEmailCertify'
    ]),
    authTimer (duration) {
      let timer = duration
      let minutes
      let seconds
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10)
        minutes = minutes < 10 ? `0${minutes}` : minutes
        seconds = seconds < 10 ? `0${seconds}` : seconds
        this.timeText = `${minutes}:${seconds}`
        if (--timer < 0) {
          clearInterval(this.timer)
          // this.isSendEmail = false
          this.vals.auth = 'N'
          this.vals.authMsg = '인증 시간이 만료되었습니다. 인증번호 재요청을 시도해주세요.'
        }
      }, 1000)
    },
    valEamil () {
      const regEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/gi
      if (!this.email) {
        this.vals.email = 'N'
        this.vals.emailMsg = '이메일 주소를 입력하세요.'
        return false
      }
      if (!regEmail.test(this.email)) {
        this.vals.email = 'N'
        this.vals.emailMsg = '이메일 주소 형식이 아닙니다.'
        return false
      }
      this.vals.email = 'Y'
    },
    sendEmail () {
      this.valEamil()
      if (this.vals.email === 'Y') {
        this.loadEmailAuth({
          email: this.email
        })
      }
    },
    certifyEmail () {
      const regType = /\d/
      if (this.emailCode === '' || !regType.test(this.emailCode)) {
        this.vals.auth = 'N'
        this.vals.authMsg = '인증번호를 정확하게 입력해주세요.'
        return false
      }
      this.loadEmailCertify({
        email: this.email,
        code: this.emailCode
      })
    },
    completeEmailAuth () {
      if (this.vals.auth === 'Y') {
        this.$emit('completeEmailAuth', this.email)
      }
    },
    cancelEmailAuth () {
      this.$emit('closeEmailAuth', false)
    }
  }
}
</script>
