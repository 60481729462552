<template>
  <div v-if="!newPwdPop" class="popup login_bg find_box find m_pop">
    <div class="popup_area">
      <div class="popup_box popup_box_m">
        <div class="popup_top"><h2 class="head1">비밀번호 재설정</h2></div>
        <div class="popup_body login_body">
          <SubTab ref="subtab" :sub-tab-info="subTabInfo" />
          <div class="login_area">
            <ul>
              <li>
                <span class="set_text">아이디</span>
                <span class="set_input1 input_l">
                  <input type="text" v-model="memberId" @keypress.enter="changePassword" placeholder="아이디를 입력해 주세요" />
                </span>
              </li>
              <SelfAuthentication v-if="subTabInfo[0].active" @completeAuth="completeAuth" @chkDomestic="chkDomestic" />
              <EmailAuthentication v-else @completeEmailAuth="completeEmailAuth" />
            </ul>
          </div>
          <div class="footer_bottom">
            <MobileHint :type="subTabInfo[0].active ? 'mobile' : 'email'" />
            <div class="btn_area btn_bottom2">
              <button type="button" class="btn_f" :class="{'disabled': !validationChk()}" @click="changePassword">비밀번호 재설정</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ResetPasword v-if="newPwdPop" />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SelfAuthentication from '@/components/member/SelfAuthentication.vue'
import EmailAuthentication from '@/components/member/EmailAuthentication.vue'
import SubTab from '@/components/common/SubTab.vue'
import ResetPasword from '@/components/member/popup/ResetPasword.vue'
import MobileHint from '@/components/common/MobileHint.vue'
export default {
  name: 'ChangePassword',
  data () {
    return {
      subTabInfo: [{
        name: '휴대전화로 찾기',
        active: true
      },
      {
        name: '이메일로 찾기',
        active: false
      }],
      memberId: '',
      phone: '',
      email: '',
      // showResult: false,
      validateAuth: ''
    }
  },
  components: {
    SelfAuthentication,
    EmailAuthentication,
    SubTab,
    ResetPasword,
    MobileHint
  },
  computed: {
    ...mapState('Member', [
      'findPwBool',
      'validateSmsCode',
      'memberNo',
      'mobileDomestic',
      'newPwdPop'
    ])
  },
  watch: {
    findPwBool () {
      if (this.findPwBool) {
        this.$store.state.Member.findPwBool = false
      }
    },
    memberNo () {
      if (this.memberNo) {
        this.$store.state.Member.newPwdPop = true
      }
    },
    subTabInfo: {
      handler () {
        // 탭 변경 감지
        this.memberId = ''
      },
      deep: true
    }
  },
  unmounted () {
    this.$store.state.Member.newPwdPop = false
  },
  methods: {
    ...mapActions('Member', [
      'loadMemberNo'
    ]),
    changePassword () {
      const typeKr = this.subTabInfo[0].active ? '휴대전화를' : '이메일을'
      if (this.memberId === '') {
        this.$showMessagePopup('비밀번호 재설정', '아이디를 입력하세요')
        return false
      }
      if (this.validateAuth !== 'Y') {
        this.$showMessagePopup('비밀번호 재설정', `${typeKr} 인증하세요`)
        return false
      }
      const data = {
        member_id: this.memberId
      }
      if (this.email) {
        Object.assign(data, {
          email: this.email
        })
      } else {
        Object.assign(data, {
          phone: this.phone
        })
      }
      this.loadMemberNo(data)
    },
    completeAuth (phone, email) {
      this.phone = phone
      this.email = email
      this.validateAuth = 'Y'
    },
    completeEmailAuth (email) {
      this.email = email
      this.validateAuth = 'Y'
    },
    chkDomestic (val) {
      this.isDomestic = val
    },
    validationChk () {
      return this.memberId && this.validateAuth === 'Y'
    }
  },
  mounted () {
  }
}
</script>
