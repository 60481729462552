<template>
  <div class="chart_area">
    <div :style="`position: absolute; height: 100%; margin-left: 40px; width: ${chartWidth}px;`">
      <span class="chart_pin" :style="`margin-left:${lineBarMarginLeft}%`"></span>
      <template v-if="this.waypoint">
        <div class="info_window" v-for="(waypoint, i) in this.waypointList" :key="i" :style="`left: ${waypoint.marginLeft}%; position: absolute;`">
          <div class="waypoint_info">
            <div class="info_title" :title="waypoint.image_title">{{waypoint.image_title}}</div>
          </div>
          <span class="info_window_pin"></span>
        </div>
      </template>
    </div>
    <canvas id="myChart"></canvas>
  </div>
  <div class="altitude_check">
    <span class="graph_text">
        <span class="text_box"><span class="square square_c1"></span>고도(m)</span>
        <span class="text_box"><span class="square square_c2"></span>속도(km/h)</span>
    </span>
    <span>
        <input type="checkbox" v-model="speed" id="c1" @change="this.createLineChart"/><label for="c1">속도</label>
    </span>
    <span>
        <input type="checkbox" v-model="altitude" id="c2" @change="this.createLineChart"/><label for="c2">고도</label>
    </span>
    <span v-if="this.type === 'course'">
        <input type="checkbox" v-model="waypoint" id="c3" @change="this.createWaypoint"/><label for="c3">웨이포인트</label>
    </span>
  </div>
</template>

<script>
import Chart from 'chart.js'
export default {
  data () {
    return {
      myChart: null,
      speed: true,
      altitude: true,
      waypoint: false,
      chartWidth: 0
    }
  },
  name: 'CourseLineChart',
  props: {
    type: String
  },
  computed: {
    lineBarMarginLeft () {
      let marginLeft = 0
      marginLeft = this.$store.state.Course.currentTrackPoint === 0 ? 0
        : ((this.$store.state.Course.currentTrackPoint) / (this.$store.state.Course.trackPoints[0].length - 1) * 100).toFixed(2)
      return marginLeft
    },
    waypointList () {
      const list = []

      this.$store.state.Course.courseInfo.waypoints.forEach((waypoint, index, array) => {
        let minTrackIndex = 0
        let minDiffDistance = 0

        this.$store.state.Course.trackPoints[0].forEach((trackPoint, trackIndex) => {
          const diffDistance = Math.abs(waypoint.image_lat - trackPoint.lat) + Math.abs(waypoint.image_lon - trackPoint.lon)
          console.log('minDiffDistance ' + trackIndex + ' : ', minDiffDistance)
          console.log('diffDistance', diffDistance)
          if (trackIndex === 0 || minDiffDistance > diffDistance) {
            minDiffDistance = diffDistance
            minTrackIndex = trackIndex
          }
        })
        console.log(minTrackIndex)

        waypoint.marginLeft = minTrackIndex === 0 ? 0 : ((minTrackIndex) / (this.$store.state.Course.trackPoints[0].length - 1) * 100).toFixed(2)

        list.push(waypoint)
        return false
      })

      return list
    }
  },
  methods: {
    createLineChart () {
      var ctx = document.getElementById('myChart').getContext('2d')
      const datasets = []
      const yAxes = []
      const labels = this.$store.state.Course.trackDetailData.time
      let speedData = []
      let altitudeData = []
      if (this.altitude) {
        altitudeData = this.$store.state.Course.trackDetailData.altitude

        datasets.push({
          data: altitudeData,
          borderColor: 'green',
          lineTension: 0,
          backgroundColor: 'rgb(255, 255, 255, 0)',
          yAxisID: 'A',
          borderWidth: 1,
          pointRadius: 0
        })
      }

      yAxes.push({
        id: 'A',
        type: 'linear',
        display: this.altitude,
        position: 'left',
        afterFit: function (scaleInstance) {
          scaleInstance.width = 40
        }
      })

      if (this.speed) {
        speedData = this.$store.state.Course.trackDetailData.speed

        datasets.push({
          data: speedData,
          borderColor: 'red',
          lineTension: 0,
          backgroundColor: 'rgb(255, 255, 255, 0)',
          yAxisID: 'B',
          borderWidth: 1,
          pointRadius: 0
        })
      }
      yAxes.push({
        id: 'B',
        display: this.speed,
        type: 'linear',
        position: 'right',
        afterFit: function (scaleInstance) {
          scaleInstance.width = 40
        }
      })

      const data = {
        labels: labels,
        datasets: datasets
      }

      this.myChart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: yAxes,
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                maxTicksLimit: 8,
                maxRotation: 0,
                minRotation: 0
              },
              afterTickToLabelConversion: (data) => {
                var xLabels = data.ticks
                xLabels.forEach((labels, i) => {
                  xLabels[i] = this.$convertTimeStampFormat(Number(labels) / 60).substring(0, 5)
                  if (i % 10 === 1) {
                    xLabels[i] = ''
                  }
                })
              }
            }]
          }
        }
      })
    }
  },
  mounted () {
    this.createLineChart()
    this.chartWidth = Number(document.querySelector('.chart_area').clientWidth) - 80
  }
}

</script>
<style scoped>
  #myChart {height: 200px}
  .info_window {top: -23px; margin-left: -23px;}
  .info_window_pin {top: 29px;}
</style>
