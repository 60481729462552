<template>
  <div v-if="findIdPop" class="popup popup_bg join_findid">
    <div class="popup_area1">
      <div class="popup_box1 popup_box_s">
        <div class="popup_top1 popup_top_line">
          <h2>안내</h2>
        </div>
        <div class="popup_body1">
          <p>
            이미 등록된 ID가 있습니다.<br />
            <span>설정 > 아이디찾기</span>에서 이미 가입되어 있는 아이디를 찾아 사용해주세요.
          </p>
          <div class="btn_area1">
            <button type="button" @click="findIdPop = false">확인</button>
            <button type="button" class="on1" @click="findIdLink">아이디 찾기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'DuplicateNumber',
  data () {
    return {
      findIdPop: false
    }
  },
  computed: {
    ...mapState('Member', ['smsCertifyCode'])
  },
  watch: {
    smsCertifyCode () {
      if (this.smsCertifyCode === '07') {
        this.findIdPop = true
      }
    }
  },
  methods: {
    findIdLink () {
      this.findIdPop = false
      this.$router.push('/member/find/id')
    }
  }
}
</script>
<style scoped>
.popup_body1 p {
  margin-top: 25px;
}
.popup_body1 p span {
  color: #ff7920
}
.popup_box1 .btn_area1 button.on1 {
  background-color: #ff7920;
  color: #fff;
}
</style>
