<template>
  <div class="main_div">
    <div class="wrap">
      <div class="header_wrap" :class="{'m_black' : checkTheme}">
        <TopMenu main="Y" :black-theme="checkTheme" />
      </div>
    </div>
    <div class="container">
      <div class="home">
        <div ref="banner" class="home_top">
          <div class="home_top_area">
            <Banner />
          </div>
        </div>
        <div class="home_menu">
          <div class="section">
            <div class="home_title">
              <span class="stitle">
                <img src="@/assets/images/logo_text_white.png" alt="Tranggle" />
              </span>트랭글러들과 함께
            </div>
            <div class="home_menu_area">
              <router-link to="/activity/main" class="block_click">
                <span class="menu">
                  <span class="menu_icon m1"></span>
                  <span class="menu_text">
                    <span class="t1">나의 활동</span>
                    <span class="t2">
                      걸음, 운동 기록 등 나의 활동<br />
                      데이터를 확인하고<br />
                      원하는 대상과 비교 가능
                    </span>
                  </span>
                </span>
              </router-link>
              <router-link to="/course" class="block_click">
                <span class="menu">
                  <span class="menu_icon m2"></span>
                  <span class="menu_text">
                    <span class="t1">코스</span>
                    <span class="t2">
                      나의 코스를 비롯하여<br />
                      코스비교, 제안, 테마별 코스,<br />
                      코스북 등 다양한 코스 확인
                    </span>
                  </span>
                </span>
              </router-link>
              <router-link to="/mission" class="block_click">
                <span class="menu">
                  <span class="menu_icon m3"></span>
                  <span class="menu_text">
                    <span class="t1">미션</span>
                    <span class="t2">
                      트랭글 전체 미션 검색,<br />
                      현재 내가 개설한 미션 및<br />
                      참가중인 미션 확인
                    </span>
                  </span>
                </span>
              </router-link>
              <!-- <router-link to="/club" class="block_click">
                <span class="menu">
                  <span class="menu_icon m4"></span>
                  <span class="menu_text">
                    <span class="t1">클럽</span>
                    <span class="t2">
                      종목·지역별 클럽 검색,<br />
                      클럽에서 진행 중인 미션 및<br />
                      내가 가입한 클럽 정보 확인
                    </span>
                  </span>
                </span>
              </router-link> -->
              <!-- <router-link to="/story" class="block_click">
                <span class="menu">
                  <span class="menu_icon m5"></span>
                  <span class="menu_text">
                    <span class="t1">스토리</span>
                    <span class="t2">
                      수많은 트랭글러들이 올린<br>
                      스토리를 볼 수 있으며<br>
                      내가 작성한 스토리 손쉽게 확인
                    </span>
                  </span>
                </span>
              </router-link> -->
            </div>
          </div>
        </div>
        <div class="home_app">
          <span class="home_app_g">
            <div class="app_text">
              <span class="text_tranggle"></span>
              <strong>게임 같은 운동앱</strong>
              <p>
                맞춤 서비스로 더욱 새로워진 트랭글,<br />
                다양한 운동과 코스 공유를 통해서<br class="m-br" />
                운동을 더욱 즐겁게 해보세요<br />
              </p>
              <div class="app_btn">
                <a href="https://play.google.com/store/apps/details?id=com.beaglemap.tranggle"  target="_blank" class="store_google">
                  <span>구글 플레이</span>
                </a>
                <a href="https://itunes.apple.com/app/id505902704" target="_blank" class="store_apple">
                  <span>앱스토어</span>
                </a>
              </div>
            </div>
            <div class="app_img">
              <img src="../assets/images/home/watch+phone_mockup.png" alt="">
              <!-- <div class="img_tranggle"></div> -->
            </div>
          </span>
        </div>
      </div>
      <footers ref="footer"/>
    </div><!--/container-->
  </div>
</template>

<script>

import TopMenu from '../components/common/TopMenu.vue'
import Banner from '@/components/home/Banner.vue'
import footers from '../components/support/footer.vue'
import { mapState } from 'vuex'
export default {
  name: 'Home',
  components: {
    TopMenu,
    Banner,
    footers: footers
  },
  data () {
    return {
      currentTime: '',
      checkTheme: false,
      thirdDateOver: ''
    }
  },
  computed: {
    ...mapState('UserSet', [
      'isOpenthirdPopup'
    ])
  },
  mounted () {
    // this.getCurrentTime()
    setInterval(this.getCurrentTime.bind(this), 1000)
    const fullH = document.getElementsByTagName('html')[0].clientHeight
    document.getElementsByClassName('home_top_area')[0].style.height = `${fullH}px`
    document.addEventListener('scroll', this.checkScroll)
    // console.log(new Date())
    // 제 3자 제공동의 변경 팝업 2월 26일까지만 노출 2월 27일 이후 삭제
    if (new Date(2023, 1, 27) <= new Date()) {
      this.thirdDateOver = 'Y'
    }
  },
  unmounted () {
    document.removeEventListener('scroll', this.checkScroll)
  },
  beforeCreate () {
    // if (this.$route.query.token != null) {
    //   this.axios.get('/member/info.json', {
    //     params: {
    //       token: this.$route.query.token
    //     }
    //   })
    //     .then(data => {
    //       const memberResponseData = data.data.response
    //       const memberResponseCode = memberResponseData.code

    //       if (memberResponseCode === '00') {
    //         const memberInfo = memberResponseData.content

    //         this.$store.state.Member.token = this.$route.query.token
    //         this.$store.state.Member.memberId = memberInfo.id
    //         this.$store.commit('Member/setMemberInfo', memberInfo)
    //         this.$router.push('/')
    //       }
    //     })
    // }
  },
  methods: {
    setDate () {
      const year = new Date().getFullYear()
      const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
      const date = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
      const hh = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()
      const mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      return {
        year: year,
        month: month,
        date: date,
        hh: hh,
        mm: mm,
        ss: ss
      }
    },
    getCurrentTime () {
      this.currentTime = this.setDate().hh + '<span>：</span>' + this.setDate().mm + '<span>：</span>' + this.setDate().ss
    },
    checkScroll (e) {
      const cScroll = e.srcElement.scrollingElement.scrollTop
      const containOffsetY = document.getElementsByClassName('home_menu')[0].offsetTop
      const homeAppOffsetY = document.getElementsByClassName('home_app')[0].offsetTop
      if (cScroll > containOffsetY) {
        this.checkTheme = true
      } else {
        this.checkTheme = false
      }
      if (cScroll > homeAppOffsetY) {
        this.checkTheme = false
      }
    }
  }
}
</script>
