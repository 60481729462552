<template>
  <div class="popup popup_bg">
    <a href="#" class="btn_popup_left" v-if="imageListIndex != 0" @click="this.imageListIndex--"></a>
    <a href="#" class="btn_popup_right" v-if="imageListIndex != this.imageList.length - 1" @click="this.imageListIndex++"></a>
    <div class="popup_area popup_img">
      <div class="popup_box">
        <span class="photo_box_big">
          <template v-for="(image, i) in imageList" :key="i">
            <img :src="this.$addHtml(image)" v-if="this.imageListIndex === i">
          </template>
        </span>
      </div>
    </div>
    <a href="#" class="btn_popup_close" @click="this.$store.commit('Popup/controlImagePopup')"></a>
  </div>
</template>

<script>

export default {
  data () {
    return {
      imageList: this.$store.state.Popup.imageList,
      imageListIndex: this.$store.state.Popup.imageListIndex
    }
  },
  props: {
  },
  methods: {
  },
  mounted () {
  },
  name: 'ViewImagePopup'
}
</script>

<style>

</style>
