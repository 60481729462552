<template>
  <JoinTerms v-if="stepAgree" @termBool="termBool" />
  <div class="popup login_bg m_pop" v-if="stepJoin">
    <div class="popup_area">
      <div class="popup_box popup_box_lm">
        <div class="popup_top"><h2 class="head1">TRANGGLE 회원가입</h2></div>
        <div class="popup_body login_body">
          <p>
            트랭글에서는 성별, 연령, 거주지별 랭킹 정보 및 운동분석,<br />
            통계 데이터 분석을 위해 아래 정보들을 수집합니다.
          </p>
          <br /><br />
          <div class="login_area">
            <form>
              <ul>
                <li>
                  <span class="set_text">아이디</span>
                  <span class="set_input1 input_l">
                    <input
                      type="text"
                      v-model="member_id"
                      @change="checkMemberId"
                      maxlength="20"
                      placeholder="영문소문자, 숫자로 4~20자리"
                      autocomplete="off"
                    />
                    <span
                      class="check_ok"
                      v-if="validateMemberId === 'Y' && isDuplicate === 'N'"
                    ></span>
                    <button
                      class="on"
                      v-if="isDuplicate !== 'N'"
                      @click.prevent="checkDuplicate"
                    >
                      중복확인
                    </button>
                    <span class="stext" v-if="isDuplicate === 'N'"
                      >사용가능한 아이디입니다.</span
                    >
                    <span class="stext text_red" v-if="isDuplicate === 'Y'"
                      >이미 사용중인 아이디로 사용하실 수 없습니다.</span
                    >
                    <span
                      class="stext text_red"
                      v-if="this.member_id !== '' && validateMemberId === 'C'"
                      >영문 소문자, 숫자로만 입력하세요.</span
                    >
                    <span
                      class="stext text_red"
                      v-if="this.member_id !== '' && validateMemberId === 'N'"
                      >사용 불가능한 아이디입니다.</span
                    >
                  </span>
                </li>
                <li>
                  <span class="set_text">비밀번호</span>
                  <span class="set_input1 input_l">
                    <input
                      type="password"
                      name="password"
                      v-model="password"
                      @blur="checkPassword"
                      autocomplete="off"
                      maxlength="20"
                      placeholder="8자 이상 영문 대소문자, 숫자, 특수문자 조합"
                    />
                    <span
                      :class="[
                        { check_ok: this.validatePassword === 'Y' },
                        { check_no: this.validatePassword === 'N' },
                      ]"
                      v-if="this.validatePassword !== ''"
                    ></span>
                    <span
                      class="stext text_red"
                      v-if="this.validatePassword === 'N'"
                      >8자 이상 영문 대소문자, 숫자, 특수문자를
                      조합해주세요.</span
                    >
                  </span>
                </li>
                <li>
                  <span class="set_text">비밀번호 확인</span>
                  <span class="set_input1 input_l">
                    <input
                      type="password"
                      name="confirm_password"
                      v-model="confirmPassword"
                      @blur="checkConfirmPassword"
                      autocomplete="off"
                      maxlength="20"
                      placeholder="다시한번 입력하세요"
                    />
                    <span
                      :class="[
                        { check_ok: this.comparePassword },
                        { check_no: !this.comparePassword },
                      ]"
                      v-if="this.confirmPassword !== ''"
                    ></span>
                    <span
                      class="stext text_red"
                      v-if="!comparePassword && this.confirmPassword !== ''"
                      >일치하지 않습니다.</span
                    >
                  </span>
                </li>
                <li>
                  <span class="set_text">이름</span>
                  <span class="set_input">
                    <input
                      type="text"
                      v-model="name"
                      maxlength="15"
                      placeholder="이름(실명)을 입력하세요"
                    />
                  </span>
                </li>
                <li>
                  <span class="set_text">생년월일</span>
                  <span class="set_input1 input_l">
                    <input
                      type="text"
                      v-model="birthday"
                      maxlength="10"
                      @keydown="birthDayAutoDash($event, birthday)"
                      @blur="checkBirthday"
                      placeholder="YYYY-MM-DD 숫자만 입력해주세요."
                    />
                    <span
                      :class="[
                        { check_ok: this.validateBirthday === 'Y' },
                        { check_no: this.validateBirthday === 'N' },
                      ]"
                      v-if="this.validateBirthday !== ''"
                    ></span>
                    <span
                      class="stext text_red"
                      v-if="validateBirthday !== ''"
                      >{{ errorBirthdayMessage }}</span
                    >
                  </span>
                </li>
                <li>
                  <span class="set_text">성별</span>
                  <span class="set_input">
                    <span class="tab1">
                      <span
                        v-bind:class="{ on: gender == 'MALE' }"
                        @click="gender = 'MALE'"
                        >남성</span
                      >
                      <span
                        v-bind:class="{ on: gender == 'FEMALE' }"
                        @click="gender = 'FEMALE'"
                        >여성</span
                      >
                    </span>
                  </span>
                </li>
                <li class="stext">
                  본인 명의 휴대폰 번호로 본인확인을 진행해 주세요.
                </li>
                <SelfAuthentication
                  useType="join"
                  @completeAuth="completeAuth"
                />
              </ul>
            </form>
          </div>
          <div class="btn_area btn_bottom2">
            <button type="button" class="btn_f" @click.prevent="signUp">
              회원 가입
            </button>
          </div>
        </div>
      </div>
    </div>
    <DuplicateNumber />
  </div>
  <!--회원가입-->
</template>

<script>
import SelfAuthentication from "./SelfAuthentication.vue";
// import dayjs from 'dayjs'
import JoinTerms from "@/components/member/popup/JoinTerms.vue";
import DuplicateNumber from "@/components/member/popup/DuplicateNumber.vue";
export default {
  name: "Join",
  components: {
    SelfAuthentication,
    JoinTerms,
    DuplicateNumber,
  },
  data() {
    return {
      stepAgree: true,
      stepJoin: false,
      gender: "",
      ctyCd: "",
      isDuplicate: "",
      isDomesctic: true,
      member_id: "",
      password: "",
      confirmPassword: "",
      name: "",
      birthday: "",
      phone: "",
      email: "",
      smsCode: "",
      validateMemberId: "",
      validatePassword: "",
      validateAuth: "",
      validateBirthday: "",
      errorBirthdayMessage: "",
      comparePassword: null,
      isSendSms: false,
      certifySmsTime: 0,
      // 유효성 체크
      vals: {
        birth: "",
        birthMsg: "",
      },
      selected: [],
      // agreeBool: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    signUp() {
      if (this.validateMemberId !== "Y") {
        this.$showMessagePopup("회원 가입", "올바른 아이디를 입력해주세요.");
      } else if (this.isDuplicate !== "N") {
        this.$showMessagePopup("회원 가입", "아이디 중복확인을 해주세요.");
      } else if (this.validatePassword !== "Y") {
        this.$showMessagePopup("회원 가입", "올바른 비밀번호를 해주세요.");
      } else if (!this.comparePassword) {
        this.$showMessagePopup(
          "회원 가입",
          "비밀번호와 비밀번호 확인은 일치해야 합니다."
        );
      } else if (this.name === "") {
        this.$showMessagePopup("회원 가입", "이름을 입력해주세요.");
      } else if (this.validateBirthday !== "Y") {
        this.$showMessagePopup("회원 가입", "올바른 생년월일을 입력하세요.");
      } else if (this.gender === "") {
        this.$showMessagePopup("회원 가입", "성별을 선택하세요.");
      } else if (this.validateAuth !== "Y") {
        this.$showMessagePopup("회원 가입", "휴대폰 인증을 해주세요.");
      } else {
        const obj = {
          member_id: this.member_id,
          password: this.password,
          name: this.name,
          birthday: this.birthday,
          gender: this.gender,
          mobile: this.phone,
          email: this.email,
          advertise_agreement: this.selected.includes("termAd")
            ? "TRUE"
            : "FALSE", // 광고수신 체크
          // custom_ad: this.selected.includes('termAd') ? 'Y' : 'N', // 맞춤형광고 동의
          external: this.selected.includes("termProvision") ? "Y" : "N", // 제 3자 제공 동의
        };
        const formData = new FormData();
        Object.keys(obj).forEach((el) => {
          formData.append(el, obj[el]);
        });
        this.axios.post("/Member/regist.json", formData).then((data) => {
          const responseCode = data.data.response.code;
          if (responseCode === "00") {
            this.axios.get("/login/getToken.json", {}).then((res) => {
              if (location.href.includes("dev")) {
                localStorage.setItem(
                  "sessionID",
                  res.data.response.content.session_id
                );
                const sessionId = localStorage.getItem("sessionID")
                  ? localStorage.getItem("sessionID")
                  : "devsession_id";
                // this.axios.defaults.baseURL = `//stage.tranggle.com/dev.html/${sessionId}/v2/`
                this.axios.defaults.baseURL = `//dev-new.tranggle.com/v2/`;
              }
              if (res.data.response.code === "00") {
                this.$showMessagePopup(
                  "회원 가입",
                  "회원가입이 완료되었습니다.",
                  "/member/joinComplete"
                );
                this.$store.state.UserSet.isLogin = "Y";
              } else if (res.data.response.code === "1004") {
                this.axios.get("/login/getToken.json", {}).then((response) => {
                  if (response.data.response.code === "00") {
                    this.$showMessagePopup(
                      "회원 가입",
                      "회원가입이 완료되었습니다.",
                      "/member/joinComplete"
                    );
                    this.$store.state.UserSet.isLogin = "Y";
                  }
                });
              }
            });
          } else {
            this.$showMessagePopup("회원 가입", data.data.response.message);
          }
        });
      }
    },
    checkMemberId() {
      const regType = /^[a-z0-9+]{4,20}$/;
      const regExp = /^[A-Z]*$/;
      if (regExp.test(this.member_id)) {
        this.validateMemberId = "C";
      } else {
        this.validateMemberId = regType.test(this.member_id) ? "Y" : "N";
      }

      this.isDuplicate = "";
    },
    checkDuplicate() {
      if (this.member_id !== "" && this.validateMemberId === "Y") {
        const formData = new FormData();
        formData.append("item", "ID");
        formData.append("string", this.member_id);
        this.axios
          .post("/Member/validation_check.json", formData)
          .then((data) => {
            const responseCode = data.data.response.code;

            if (responseCode === "00") {
              this.isDuplicate = "N";
            } else {
              this.isDuplicate = "Y";
            }
          });
      } else {
        this.$showMessagePopup("중복 확인", "회원 ID를 정확하게 입력해주세요.");
      }
    },
    checkPassword() {
      const numRegType = this.password.search(/[0-9]/g);
      const engRegType = this.password.search(/[A-Za-z]/gi);
      const spcRegType = this.password.search(
        /[!?@#$%^():;-=~{}<>₩₩₩'₩;:₩/?]/gi
      );
      // console.log(this.password.search(/[!?@#$%^():;-=~{}<>₩₩₩'₩;:₩/?]/gi))
      if (
        !(this.password.length >= 8 && this.password.length <= 20) ||
        !(
          numRegType > -1 &&
          engRegType > -1 &&
          engRegType > -1 &&
          spcRegType > -1 &&
          spcRegType > -1 &&
          numRegType > -1
        )
      ) {
        this.validatePassword = "N";
      } else {
        this.validatePassword = "Y";
      }
    },
    checkConfirmPassword() {
      this.comparePassword = this.password === this.confirmPassword;
    },
    checkBirthday() {
      const reg =
        /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
      const birthStr = this.birthday.replaceAll("-", "");
      if (!this.birthday || birthStr.length !== 8) {
        this.validateBirthday = "N";
        this.errorBirthdayMessage = "생년월일을 입력하세요.";
        return false;
      }
      if (!reg.test(this.birthday)) {
        this.validateBirthday = "N";
        this.errorBirthdayMessage = "생년월일을 바르게 입력하세요.";
        return false;
      }
      // 만 14세 체크
      const userBirth = this.birthday.replaceAll("-", "");
      const current = new Date();
      const y = current.getFullYear();
      const m =
        current.getMonth() + 1 < 10
          ? `0${current.getMonth() + 1}`
          : current.getMonth() + 1;
      const d =
        current.getDate() < 10 ? `0${current.getDate()}` : current.getDate();
      if (parseInt(`${y}${m}${d}`) - parseInt(userBirth) < 140000) {
        this.validateBirthday = "N";
        this.errorBirthdayMessage = "만 14세 미만은 가입할 수 없습니다.";
        return false;
      }
      this.validateBirthday = "Y";
      this.errorBirthdayMessage = "";
    },
    checkMaxLength(e) {
      if (e.target.value.length > e.target.maxLength) {
        e.target.value = e.target.value.slice(0, e.target.maxLength);
      }
    },
    completeAuth(phone, email) {
      this.phone = phone;
      this.email = email;
      this.validateAuth = "Y";
    },
    birthDayAutoDash($event, str) {
      if ($event.keyCode !== 8) {
        if (str.length === 4 || str.length === 7) {
          this.birthday += "-";
        }
      }
      if (str.length >= 10) {
        this.checkBirthday();
      }
    },
    termBool(val) {
      if (val) {
        this.stepAgree = false;
        this.stepJoin = true;
        this.selected = val;
      }
    },
  },
  beforeCreate() {
    if (this.$store.state.UserSet.isLogin === "Y") {
      this.$router.go(-1);
    }
  },
  mounted() {
    document.body.classList.remove("m_menu_show");
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
