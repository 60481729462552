<template>
  <div class="popup popup_bg change">
    <div class="popup_area">
      <div class="popup_box popup_box_lm">
        <div class="popup_top"><h2 class="head1">비밀번호 변경</h2></div>
        <div class="popup_body">
          <div class="title_img"><img src="@/assets/images/common/img_password.png" /></div>
            <p class="t_bold">회원님의 비밀번호를 변경해주세요</p><br>
            <p>개인정보보호를 위해 6개월 마다 비밀번호 변경을 권장하고 있사오니<br>
            비밀번호를 변경해 주시기 바랍니다.</p>
            <br>
            <div class="login_area">
              <ul>
                <li>
                  <span class="set_text">현재 비밀번호</span>
                  <span class="set_input"><input type="password" v-model="currentPassword" placeholder="현재 비밀번호"/></span>
                </li>
                <li>
                  <span class="set_text">새 비밀번호</span>
                  <span class="set_input1 input_l">
                    <input type="password" name="password" v-model="password"
                    @blur="checkPassword" autocomplete="off" maxlength="20"
                    placeholder="8자 이상 영문 대소문자, 숫자, 특수문자 조합" />
                    <span :class="[{'check_ok': this.validatePassword === 'Y'}, {'check_no': this.validatePassword !== 'Y'}]" v-if="this.validatePassword !== ''"></span>
                    <span class="stext text_red" v-if="this.validatePassword === 'D'">현재 비밀번호와 동일합니다.</span>
                    <span class="stext text_red" v-if="this.validatePassword === 'N'">8자 이상 영문 대소문자, 숫자, 특수문자 조합</span>
                  </span>
                </li>
                <li>
                  <span class="set_text">새 비밀번호 확인</span>
                  <span class="set_input1 input_l">
                    <input type="password" name="confirm_password" v-model="confirmPassword"
                    @blur="checkConfirmPassword" autocomplete="off" maxlength="20"
                    placeholder="다시한번 입력하세요" />
                    <span :class="[{'check_ok': this.comparePassword}, {'check_no': !this.comparePassword}]" v-if="this.confirmPassword !== ''"></span>
                    <span class="stext text_red" v-if="!comparePassword && this.confirmPassword !== ''">일치하지 않습니다.</span>
                  </span>
                </li>
              </ul>
            </div>
            <br><br>
            <p class="t_bold">비밀번호 안전규칙</p>
            <div class="box_gray mt"><span class="section">
              · 반드시 8자 이상 영문 대소문자, 숫자, 특수문자 3개의 조합 필수<br>
              · 특수문자는 ~!@#$%^()_-={[}]’:; &lt; &gt;,.?/ 사용가능<br>
              · 생년월일, 휴대전화와 동일한 번호는 사용 자제</span>
            </div>
            <div class="btn_area btn_bottom2">
              <button type="button" class="btn_f" @click="extendPwPeriod()">6개월 이후에 변경</button>
              <button type="button" class="btn_f" @click="changePasswordType()" :disabled="!(validatePassword === 'Y' && comparePassword)">변경하기</button>
            </div>
          </div>
      </div>
    </div>
  </div><!--/popup-->
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'ChangePasswordPopup',
  data () {
    return {
      password: '',
      confirmPassword: '',
      currentPassword: '',
      validatePassword: '',
      comparePassword: null
    }
  },
  props: {
    originalPassword: String,
    token: String,
    memberId: String
  },
  computed: {
    ...mapState('MyTranggle', ['changePasswordBool']),
    ...mapState('Member', ['pwdDateBool'])
  },
  watch: {
    changePasswordBool () {
      if (this.changePasswordBool) {
        this.$store.state.MyTranggle.changePasswordBool = false
        this.$emit('setMemberInfo', 'ChangePasswordPopup')
        this.$showMessagePopup('로그아웃 안내', '비밀번호가 변경되어 로그아웃 되었습니다. 안전한 서비스 이용을 위해 새 비밀번호로 다시 로그인해주시기 바랍니다.', '/login', '로그인하기')
      }
    },
    pwdDateBool () {
      if (this.pwdDateBool) {
        this.$showMessagePopup('비밀번호 변경', '6개월 이후에 변경을 선택하셨습니다.', '/')
      }
    }
  },
  mounted () {
    document.body.classList.add('overhidden')
  },
  unmounted () {
    document.body.classList.remove('overhidden')
  },
  methods: {
    ...mapActions('MyTranggle', [
      'changePassword'
    ]),
    ...mapActions('Member', [
      'setPasswordDate'
    ]),
    changePasswordType () {
      const pwdObj = {
        password: this.currentPassword,
        new_password: this.password,
        new_password_re: this.confirmPassword
      }
      this.changePassword(pwdObj)
    },
    checkPassword () {
      const regPw = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[(){}<>[\]';:.,_/=~#?!@$%^&-]).{8,20}$/gi
      if (!regPw.test(this.password)) {
        this.validatePassword = 'N'
        return false
      }
      if (this.password === this.currentPassword) {
        this.validatePassword = 'D'
        return false
      }
      this.validatePassword = 'Y'
    },
    checkConfirmPassword () {
      const regPw = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[(){}<>[\]';:.,_/=~#?!@$%^&-]).{8,20}$/gi
      if (regPw.test(this.password) && this.password === this.confirmPassword) {
        this.comparePassword = true
      } else {
        this.comparePassword = false
      }
    },
    extendPwPeriod () {
      this.setPasswordDate()
    }
  }
}

</script>

<style>
</style>
