<template>
  <div class="popup popup_bg" v-if="Object.keys(this.firstTrack).length > 0">
    <div class="popup_area">
      <div class="popup_box popup_box_l">
        <div class="popup_top"><h2>코스 비교일
          <span class="sub_title">{{this.$store.state.Course.compareDate}}</span></h2>
          <a href="#" class="btn_close" @click="this.$store.commit('Course/closeCompareTrackLayerPopup')"></a>
        </div>
        <div class="popup_body1">
          <div class="compare_area">
            <span class="poi_content">
              <span class="compare_title">비교코스 1</span>
              <span class="g">
                <span class="icon_exc"><img :src="require(`@/assets/images/sports/icon_sports_` + firstTrack.track.log_category.toLowerCase() + `.png`)" /><span class="date">{{firstTrack.track.log_track_date}}</span>
                </span>
                <span class="gc">
                  <span class="subject">{{firstTrack.track.log_track_name}}</span>
                  <span class="g">
                      <span class="data">
                      <span class="text"><span class="t1">전체 거리</span>{{this.$convertKilometerFormat(firstTrack.track.log_distance)}} km</span>
                      <span class="text"><span class="t1">전체 시간</span>{{this.$convertTimeStampFormat(firstTrack.track.log_total_time)}}</span>
                      <span class="text"><span class="t1">평균 속도</span>{{this.$convertSpeedFormat(firstTrack.track.log_aver_speed)}} km/h</span>
                    </span>
                    <span class="data">
                      <span class="text"><span class="t1">추천 수</span>{{firstTrack.track.log_recommend_count}}</span>
                      <span class="text"><span class="t1">뷰어 수</span>{{firstTrack.track.log_hit}}</span>
                      <span class="text"><span class="t1">담기 수</span>{{firstTrack.track.log_download_count}}</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span class="list_vs"></span>
            <span class="poi_content">
              <span class="compare_title">비교코스 2</span>
              <span class="g">
                <span class="icon_exc"><img :src="require(`@/assets/images/sports/icon_sports_` + secondTrack.track.log_category.toLowerCase() + `.png`)" /><span class="date">{{secondTrack.track.log_track_date}}</span>
                </span>
                <span class="gc">
                  <span class="subject">{{secondTrack.track.log_track_name}}</span>
                  <span class="g">
                      <span class="data">
                      <span class="text"><span class="t1">전체 거리</span>{{this.$convertKilometerFormat(secondTrack.track.log_distance)}} km</span>
                      <span class="text"><span class="t1">전체 시간</span>{{this.$convertTimeStampFormat(secondTrack.track.log_total_time)}}</span>
                      <span class="text"><span class="t1">평균 속도</span>{{this.$convertSpeedFormat(secondTrack.track.log_aver_speed)}} km/h</span>
                    </span>
                    <span class="data">
                      <span class="text"><span class="t1">추천 수</span>{{secondTrack.track.log_recommend_count}}</span>
                      <span class="text"><span class="t1">뷰어 수</span>{{secondTrack.track.log_hit}}</span>
                      <span class="text"><span class="t1">담기 수</span>{{secondTrack.track.log_download_count}}</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div class="compare_area1">
            <div class="compare_info">
              <div class="tab_menu2">
                <ul>
                  <li :class="{on : listTab === 'RECORD'}"><a href="#" @click="changeTab('RECORD')">운동기록</a></li>
                  <li :class="{on : listTab === 'BADGE'}"><a href="#" @click="changeTab('BADGE')">획득 배지</a></li>
                </ul>
              </div>
              <div class="compare_img1"></div>
              <div class="compare_graph" v-show="listTab === 'RECORD'">
                <ul>
                  <li>
                    <div class="g">
                      <div class="graph_g">
                        <span class="graph_bar"><span class="bar_r on1" style="width: 0%;"></span></span>
                        <span class="graph_percent">00:00:00</span>
                      </div>
                      <div class="graph_name">예상 시간</div>
                      <div class="graph_g">
                        <span class="graph_percent">00:00:00</span>
                        <span class="graph_bar"><span class="bar on2" style="width: 0%;"></span></span>
                      </div>
                    </div>
                  </li>
                  <li v-for="(value, key) in compareTrackResult" :key="key">
                    <div class="g">
                      <div class="graph_g">
                        <span class="graph_bar"><span class="bar_r on1" :style="`width: ` + value[1].percent + `%;`"></span></span>
                        <span class="graph_percent">{{value[1].data}}</span>
                      </div>
                      <div class="graph_name">{{key}}</div>
                      <div class="graph_g">
                        <span class="graph_percent">{{value[2].data}}</span>
                        <span class="graph_bar"><span class="bar on2" :style="`width: ` + value[2].percent + `%;`"></span></span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="compare_badge" v-show="listTab === 'BADGE'">
                <span class="t_l t_bold">{{this.firstBadgeList.length}}</span>
                <span class="title">획득 배지 수</span>
                <span class="t_l t_bold">{{this.secondBadgeList.length}}</span>
              </div>
              <div class="compare_badge_list" v-show="listTab === 'BADGE'">
                <div class="list_badge">
                  <ul>
                    <li v-for="(badge, i) in firstBadgeList" :key="i" @click="selectedBadge(badge)"
                     :class="{on: badge.info_badge_id === this.$store.state.Course.compareTrackBadge.info_badge_id}">
                      <span class="td1"><span class="poi_badge"><img :src="this.$photoEmpty(badge.badge_image_url)" /></span></span>
                      <span class="td2">
                        <span class="poi_content">
                          <span class="subject"><span class="text">{{badge.info_badge_name}} ({{badge.info_badge_code}})</span></span>
                          <span class="sub_text elp2">{{badge.info_tag_basic}}</span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="list_badge">
                  <ul>
                    <li v-for="(badge, i) in secondBadgeList" :key="i" @click="selectedBadge(badge)"
                     :class="{on: badge.info_badge_id === this.$store.state.Course.compareTrackBadge.info_badge_id}">
                      <span class="td1"><span class="poi_badge"><img :src="this.$photoEmpty(badge.badge_image_url)" /></span></span>
                      <span class="td2">
                        <span class="poi_content">
                          <span class="subject"><span class="text">{{badge.info_badge_name}} ({{badge.info_badge_code}})</span></span>
                          <span class="sub_text elp2">{{badge.info_badge_message}}</span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="compare_map" style="background-color: antiquewhite;">
              <CompareTrackMap ref="compareTrackMap" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompareTrackMap from '../map/CompareTrackMap.vue'
import dayjs from 'dayjs'

export default {
  data () {
    return {
      listTab: 'RECORD',
      firstTrack: {},
      secondTrack: {},
      compareTrackResult: {},
      firstBadgeList: [],
      secondBadgeList: []
    }
  },
  methods: {
    getCompareTrack () {
      this.axios.get('/course/compareCourse.json', {
        params: {
          token: this.$store.state.Member.token,
          track_1_year: this.$store.state.Course.compareTrack1.year,
          track_1_no: this.$store.state.Course.compareTrack1.no,
          track_2_year: this.$store.state.Course.compareTrack2.year,
          track_2_no: this.$store.state.Course.compareTrack2.no
          // track_1_year: 2013,
          // track_1_no: 158,
          // track_2_year: 2021,
          // track_2_no: 529
        }
      })
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode === '00') {
            this.firstTrack = data.data.response.content.track_1_info
            this.secondTrack = data.data.response.content.track_2_info
            this.firstBadgeList = data.data.response.content.track_1_info.badge_list
            this.secondBadgeList = data.data.response.content.track_2_info.badge_list

            if (this.$store.state.Course.compareDate === '') {
              this.$store.state.Course.compareDate = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
            }

            this.compareTrack()
          } else {
            this.$showMessagePopup('코스비교', data.data.response.message)
            this.$store.state.Course.isOpenCompareTrackLayerPopup = false
          }
        })
    },
    selectedBadge (badge) {
      this.$store.state.Course.compareTrackBadge = badge
    },
    changeTab (type) {
      this.listTab = type
      this.$store.state.Course.compareTrackBadge = {}
    },
    compareTrack () {
      let firstTrackData = this.firstTrack.track.log_total_time
      let secondTrackData = this.secondTrack.track.log_total_time
      Object.assign(this.compareTrackResult, {
        '전체 시간': {
          1: {
            data: this.$convertTimeStampFormat(firstTrackData),
            percent: parseFloat(firstTrackData) >= parseFloat(secondTrackData)
              ? 100 : Math.round(firstTrackData / secondTrackData * 100)
          },
          2: {
            data: this.$convertTimeStampFormat(secondTrackData),
            percent: parseFloat(firstTrackData) <= parseFloat(secondTrackData)
              ? 100 : Math.round(secondTrackData / firstTrackData * 100)
          }
        }
      })

      firstTrackData = this.firstTrack.track.log_pass_time
      secondTrackData = this.secondTrack.track.log_pass_time
      Object.assign(this.compareTrackResult, {
        '운동 시간': {
          1: {
            data: this.$convertTimeStampFormat(firstTrackData),
            percent: parseFloat(firstTrackData) >= parseFloat(secondTrackData)
              ? 100 : Math.round(firstTrackData / secondTrackData * 100)
          },
          2: {
            data: this.$convertTimeStampFormat(secondTrackData),
            percent: parseFloat(firstTrackData) <= parseFloat(secondTrackData)
              ? 100 : Math.round(secondTrackData / firstTrackData * 100)
          }
        }
      })

      firstTrackData = this.firstTrack.track.log_distance
      secondTrackData = this.secondTrack.track.log_distance
      Object.assign(this.compareTrackResult, {
        '전체 거리': {
          1: {
            data: this.$convertKilometerFormat(firstTrackData) + ' km',
            percent: parseFloat(firstTrackData) >= parseFloat(secondTrackData)
              ? 100 : Math.round(firstTrackData / secondTrackData * 100)
          },
          2: {
            data: this.$convertKilometerFormat(secondTrackData) + ' km',
            percent: parseFloat(firstTrackData) <= parseFloat(secondTrackData)
              ? 100 : Math.round(secondTrackData / firstTrackData * 100)
          }
        }
      })

      firstTrackData = this.firstTrack.track.log_real_distance
      secondTrackData = this.secondTrack.track.log_real_distance
      Object.assign(this.compareTrackResult, {
        '운동 거리': {
          1: {
            data: this.$convertKilometerFormat(firstTrackData) + ' km',
            percent: parseFloat(firstTrackData) >= parseFloat(secondTrackData)
              ? 100 : Math.round(firstTrackData / secondTrackData * 100)
          },
          2: {
            data: this.$convertKilometerFormat(secondTrackData) + ' km',
            percent: parseFloat(firstTrackData) <= parseFloat(secondTrackData)
              ? 100 : Math.round(secondTrackData / firstTrackData * 100)
          }
        }
      })

      let firstBasePercent = 0
      let secondBasePercent = 0

      const firstTrackTotalDistance = parseFloat(this.firstTrack.track.log_speed_up_hill) +
       parseFloat(this.firstTrack.track.log_speed_flat) + parseFloat(this.firstTrack.track.log_speed_down_hill)
      const secondTrackTotalDistance = parseFloat(this.secondTrack.track.log_speed_up_hill) +
       parseFloat(this.secondTrack.track.log_speed_flat) + parseFloat(this.secondTrack.track.log_speed_down_hill)

      if (firstTrackTotalDistance > 0 || secondTrackTotalDistance > 0) {
        if (firstTrackTotalDistance >= secondTrackTotalDistance) {
          firstBasePercent = 100
          secondBasePercent = Math.round((secondTrackTotalDistance / firstTrackTotalDistance) * 100)
        } else {
          firstBasePercent = Math.round((firstTrackTotalDistance / secondTrackTotalDistance) * 100)
          secondBasePercent = 100
        }
      } else {
        firstBasePercent = 0
        secondBasePercent = 0
      }

      firstTrackData = this.firstTrack.track.log_speed_up_hill
      secondTrackData = this.secondTrack.track.log_speed_up_hill
      Object.assign(this.compareTrackResult, {
        오르막: {
          1: {
            data: this.$convertDistanceFormat(firstTrackData) + ' km',
            percent: parseFloat(firstTrackData) === 0
              ? 0 : firstBasePercent - Math.round(firstTrackData / firstTrackTotalDistance * 100)
          },
          2: {
            data: this.$convertDistanceFormat(secondTrackData) + ' km',
            percent: parseFloat(secondTrackData) === 0
              ? 0 : secondBasePercent - Math.round(secondTrackData / secondTrackTotalDistance * 100)
          }
        }
      })

      firstTrackData = this.firstTrack.track.log_speed_down_hill
      secondTrackData = this.secondTrack.track.log_speed_down_hill
      Object.assign(this.compareTrackResult, {
        내리막: {
          1: {
            data: this.$convertDistanceFormat(firstTrackData) + ' km',
            percent: parseFloat(firstTrackData) === 0
              ? 0 : firstBasePercent - Math.round(firstTrackData / firstTrackTotalDistance * 100)
          },
          2: {
            data: this.$convertDistanceFormat(secondTrackData) + ' km',
            percent: parseFloat(secondTrackData) === 0
              ? 0 : secondBasePercent - Math.round(secondTrackData / secondTrackTotalDistance * 100)
          }
        }
      })

      firstTrackData = this.firstTrack.track.log_speed_flat
      secondTrackData = this.secondTrack.track.log_speed_flat
      Object.assign(this.compareTrackResult, {
        평지: {
          1: {
            data: this.$convertDistanceFormat(firstTrackData) + ' km',
            percent: parseFloat(firstTrackData) === 0
              ? 0 : firstBasePercent - Math.round(firstTrackData / firstTrackTotalDistance * 100)
          },
          2: {
            data: this.$convertDistanceFormat(secondTrackData) + ' km',
            percent: parseFloat(secondTrackData) === 0
              ? 0 : secondBasePercent - Math.round(secondTrackData / secondTrackTotalDistance * 100)
          }
        }
      })

      firstTrackData = this.firstTrack.track.log_max_speed
      secondTrackData = this.secondTrack.track.log_max_speed
      Object.assign(this.compareTrackResult, {
        '최고 속도': {
          1: {
            data: this.$convertDistanceFormat(firstTrackData) + ' km/h',
            percent: parseFloat(firstTrackData) >= parseFloat(secondTrackData)
              ? 100 : Math.round(firstTrackData / secondTrackData * 100)
          },
          2: {
            data: this.$convertDistanceFormat(secondTrackData) + ' km/h',
            percent: parseFloat(firstTrackData) <= parseFloat(secondTrackData)
              ? 100 : Math.round(secondTrackData / firstTrackData * 100)
          }
        }
      })

      firstTrackData = this.firstTrack.track.log_aver_speed
      secondTrackData = this.secondTrack.track.log_aver_speed
      Object.assign(this.compareTrackResult, {
        '평균 속도': {
          1: {
            data: this.$convertDistanceFormat(firstTrackData) + ' km/h',
            percent: parseFloat(firstTrackData) >= parseFloat(secondTrackData)
              ? 100 : Math.round(firstTrackData / secondTrackData * 100)
          },
          2: {
            data: this.$convertDistanceFormat(secondTrackData) + ' km/h',
            percent: parseFloat(firstTrackData) <= parseFloat(secondTrackData)
              ? 100 : Math.round(secondTrackData / firstTrackData * 100)
          }
        }
      })

      firstTrackData = this.firstTrack.track.log_kcal
      secondTrackData = this.secondTrack.track.log_kcal
      Object.assign(this.compareTrackResult, {
        소모열량: {
          1: {
            data: this.$convertKcalFormat(firstTrackData) + ' kcal',
            percent: parseFloat(firstTrackData) >= parseFloat(secondTrackData)
              ? 100 : Math.round(firstTrackData / secondTrackData * 100)
          },
          2: {
            data: this.$convertKcalFormat(secondTrackData) + ' kcal',
            percent: parseFloat(firstTrackData) <= parseFloat(secondTrackData)
              ? 100 : Math.round(secondTrackData / firstTrackData * 100)
          }
        }
      })
    }
  },
  mounted () {
    this.getCompareTrack()
  },
  components: {
    CompareTrackMap: CompareTrackMap
  },
  name: 'CompareTrackPopup'
}
</script>

<style>

</style>
