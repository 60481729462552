<template>
  <div class="popup popup_bg change pw">
    <div class="popup_area">
      <div class="popup_box popup_box_m sports_pop">
        <div class="popup_body">
          <div class="title_img">
            <img src="@/assets/images/common/img_password.png" />
          </div>
          <p class="t_bold">회원님의 비밀번호를 변경해주세요</p><br />
          <p>
            개인정보보호를 위해 6개월 마다 비밀번호 변경을 권장하고 있사오니<br />비밀번호를 변경해 주시기 바랍니다.
          </p>
          <br />
          <div class="login_area">
            <ul>
              <li>
                <span class="set_text">비밀번호</span>
                <span class="set_input">
                  <input v-model="oldPass" type="password" placeholder="현재 비밀번호를 입력해주세요" />
                </span>
              </li>
              <li>
                <span class="set_text">새 비밀번호</span>
                <span class="set_input">
                  <input v-model="newPass" type="password" placeholder="새 비밀번호를 입력해주세요" @blur="checkPassword"/>
                  <span v-if="validatePassword === 'Y'" class="check_ok"></span>
                  <span v-if="validatePassword === 'N'" class="check_no"></span>
                  <span v-if="validatePassword === 'N'" class="stext text_red">8자 이상 영문 대소문자, 숫자, 특수문자 조합</span>
                </span>
              </li>
              <li>
                <span class="set_text">새 비밀번호 확인</span>
                <span class="set_input">
                  <input v-model="reNewPass" type="password" placeholder="새 비밀번호를 다시 한번 입력해 주세요" @blur="checkConfirmPassword"/>
                  <span v-if="comparePassword && reNewPass !== ''" class="check_ok"></span>
                  <span v-if="!comparePassword && reNewPass !== ''" class="check_no"></span>
                  <span v-if="!comparePassword && reNewPass !== ''" class="stext text_red">일치하지 않습니다.<br/></span>
                  <span v-if="oldPass === reNewPass && reNewPass !== ''" class="stext text_red">현재 비밀번호와 동일합니다</span>
                </span>
              </li>
            </ul>
          </div>
          <br />
          <span>{{ passErrorText }}</span>
          <br />
          <p class="t_bold">비밀번호 안전규칙</p>
          <div class="box_gray mt">
            <span class="section">· 반드시 8자 이상 영문 대소문자, 숫자, 특수문자 3개의 조합 필수<br />
            · 특수문자는 ~!@#$%^()_-={[}]’:; <!--<--> >,.?/ 사용가능<br />
            · 생년월일, 휴대전화와 동일한 번호는 사용 자제</span>
          </div>
          <br />
          <div class="btn_area">
            <button type="button" class="btn_m on" @click="passChgAPI">변경하기</button>
            <button type="button" class="btn_m" @click="close">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'MyTranggleChangePassword',
  data () {
    return {
      oldPass: '',
      newPass: '',
      reNewPass: '',
      validatePassword: '',
      comparePassword: null,
      passErrorText: '',
      isChanged: false
    }
  },
  computed: {
    ...mapState('MyTranggle', [
      'MyTrangConfPopUpStep4',
      'changePasswordBool'
    ])
  },
  watch: {
    changePasswordBool () {
      if (this.changePasswordBool) {
        this.isChanged = true
        this.$store.state.MyTranggle.changePasswordBool = false
        this.loadLogout()
        this.$showMessagePopup('로그아웃 안내', '비밀번호가 변경되어 로그아웃 되었습니다. 안전한 서비스 이용을 위해 새 비밀번호로 다시 로그인해주시기 바랍니다.', '/login', '로그인하기')
      }
    }
  },
  unmounted () {
    if (this.isChanged) {
      this.loadLogout()
    }
    this.$store.state.MyTranggle.MyTrangConfPopUpStep4 = false
    this.$store.state.MyTranggle.popBg = false
    this.$store.state.MyTranggle.popArea = false
  },
  methods: {
    ...mapMutations('MyTranggle', ['confClosePopChg']),
    ...mapActions('MyTranggle', [
      'changePassword'
    ]),
    ...mapActions('UserSet', [
      'loadLogout'
    ]),
    checkPassword () {
      const regPw = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[(){}<>[\]';:.,_/=~#?!@$%^&-]).{8,20}$/gi
      if (!regPw.test(this.newPass)) {
        this.validatePassword = 'N'
        return false
      }
      this.validatePassword = 'Y'
    },
    checkConfirmPassword () {
      const regPw = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[(){}<>[\]';:.,_/=~#?!@$%^&-]).{8,20}$/gi
      if (regPw.test(this.newPass) && this.newPass === this.reNewPass) {
        this.comparePassword = true
      } else {
        this.comparePassword = false
      }
    },
    passChgAPI () {
      this.changePassword({
        password: this.oldPass,
        new_password: this.newPass,
        new_password_re: this.reNewPass
      })
      // // console.log(this.$store.state.token)
      // this.axios.get('/Member/change_password.json', {
      //   params: {
      //     token: this.$store.state.Member.token,
      //     password: this.oldPass,
      //     new_password: this.newPass,
      //     new_password_re: this.reNewPass
      //   }
      // })
      //   .then(data => {
      //     // if구성 00이면 페이지 이동 아니면 텍스트 표출
      //     if (data.data.response.code === '00') {
      //       this.confChgComp()
      //     } else {
      //       // this.passErrorText = data.data.response.message
      //       this.$showMessagePopup('', data.data.response.message)
      //     }
      //   })
    },
    close () {
      this.oldPass = ''
      this.newPass = ''
      this.reNewPass = ''
      this.validatePassword = ''
      this.comparePassword = null
      this.confClosePopChg()
    }
  }
}
</script>
