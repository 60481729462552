<template>
  <!-- 공개 설정 -->
  <div class="right_body mt">
    <div class="setting_area">
      <ul>
        <li>
          <span class="set_text">내 정보 공개</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="revelationALL" v-model="revelation" value="TRUE" name="revelation" @click="revelationChange('TRUE')" />
              <label for="revelationALL">공개</label>
              <input type="radio" id="revelationFRIENDS" v-model="revelation" value="FRIENDS" name="revelation" @click="revelationChange('FRIENDS')" />
              <label for="revelationFRIENDS">친구만</label>
              <input type="radio" id="revelationCLOSE" v-model="revelation" value="FALSE" name="revelation" @click="revelationChange('FALSE')" />
              <label for="revelationCLOSE">비공개</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">메시지 수신</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="messageSendALL" v-model="messageSend" value="ALL" name="messageSend" @click="messageSendChange('ALL')" />
              <label for ="messageSendALL">모두</label>
              <input type="radio" id="messageSendFRIENDS" v-model="messageSend" value="FRIENDS" name="messageSend" @click="messageSendChange('FRIENDS')" />
              <label for ="messageSendFRIENDS">친구만</label>
              <input type="radio" id="messageSendCLOSE" v-model="messageSend" value="CLOSE" name="messageSend" @click="messageSendChange('CLOSE')" />
              <label for ="messageSendCLOSE">메시지 받지 않음</label>
            </span>
            <span class="stext">메시지를 보낼 수 있는 회원의 범위를 설정</span>
          </span>
        </li>
        <li>
          <span class="set_text">친구 신청</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="friendRequestALL" v-model="friendRequest" value="ALL" name="friendRequest" @click="friendRequestChange('ALL')" />
              <label for ="friendRequestALL">모두</label>
              <input type="radio" id="friendRequestMALE" v-model="friendRequest" value="MALE" name="friendRequest" @click="friendRequestChange('MALE')" />
              <label for ="friendRequestMALE">남성만</label>
              <input type="radio" id="friendRequestFEMALE" v-model="friendRequest" value="FEMALE" name="friendRequest" @click="friendRequestChange('FEMALE')" />
              <label for ="friendRequestFEMALE">여성만</label>
              <input type="radio" id="friendRequestCLOSE" v-model="friendRequest" value="CLOSE" name="friendRequest" @click="friendRequestChange('CLOSE')" />
              <label for ="friendRequestCLOSE">친구신청 받지 않음</label>
            </span>
            <span class="stext">친구 신청할 수 있는 회원의 범위를 설정</span>
          </span>
        </li>
      </ul>
      <div class="stitle">내 위치</div>
      <ul>
        <li>
          <span class="set_text">위치 공유 제한 지역</span>
          <span class="set_input">
            <span class="add_text_area">
              <a href="#" class="add_text" @click="geofind()">현 위치</a>
              <a href="#" class="add_text" @click="mapSearchView">지도에서 선택</a>
              <a href="#" class="add_text" @click="showApi()">주소 직접 입력</a>
            </span>
            <div class="add_list">
              <span class="add_box" v-for="(list,i) in this.$store.state.MyTranggle.locationBlock" :key="i">
                {{this.$store.state.MyTranggle.locationAddrKo[i]}}
                <span class="text_blue">으로부터 2km 반경</span>
                <a href="#" class="btn_trash" @click="locationBlockConfigDel(list.location_longitude, list.location_latitude, list.location_around)"></a>
              </span>
            </div>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      revelation: '',
      messageSend: '',
      friendRequest: ''
    }
  },
  computed: {
    ...mapState('Member', [
      'memberConfig'
    ])
  },
  watch: {
    memberConfig () {
      console.log(this.memberConfig)
      // this.memberConfig
      this.revelation = !this.memberConfig.config_is_record_open ? 'FRIENDS' : this.memberConfig.config_is_record_open
      this.messageSend = !this.memberConfig.config_message_receive_type ? 'FRIENDS' : this.memberConfig.config_message_receive_type
      this.friendRequest = this.memberConfig.config_friends_receive_type
    }
  },
  mounted () {
    this.getMemberConfig()
  },
  methods: {
    ...mapMutations('MyTranggle', [
      'mapSearchView'
    ]),
    ...mapActions('Member', [
      'updateMemberConfig',
      'getMemberConfig'
    ]),
    ...mapActions('MyTranggle', [
      'locationBlockConfig'
    ]),
    revelationChange (val) {
      this.revelation = val
      this.memberConfigDataUpt()
    },
    messageSendChange (val) {
      this.messageSend = val
      this.memberConfigDataUpt()
    },
    friendRequestChange (val) {
      this.friendRequest = val
      this.memberConfigDataUpt()
    },
    memberConfigDataUpt () {
      const data = {
        record_open: this.revelation,
        message_receive_type: this.messageSend,
        friends_receive_type: this.friendRequest
      }
      this.updateMemberConfig(data)
    },
    geofind () {
      if (!('geolocation' in navigator)) {
        // this.textContent = 'Geolocation is not available.'
        this.$showMessagePopup('', '현위치 저장에 실패하였습니다.')
        return
      }
      // this.textContent = 'Locating...'

      // get position
      navigator.geolocation.getCurrentPosition(pos => {
        this.locationBlockConfigUpt(pos.coords.longitude, pos.coords.latitude, '2000')
      }, err => {
        // textContent = err.message
        console.log(err.message)
        this.$showMessagePopup('', '해당 기능은 HTTPS 환경에서만 작동합니다. \n 접속 환경을 확인해 주십시요.')
      })
    },
    locationBlockConfigUpt (lon, lat, around) {
      const formData = new FormData()
      formData.append('token', this.$store.state.Member.token)
      formData.append('location_longitude', lon)
      formData.append('location_latitude', lat)
      formData.append('location_around', around)
      this.axios.post('/Member/member_location_block_config_upt.json', formData)
        .then(data => {
          // console.log(data.data.response.message)
          this.$showMessagePopup('', data.data.response.message)
          this.locationBlockConfig()
        })
    },
    showApi (step) {
      new window.daum.Postcode({
        oncomplete: (data) => {
          console.log(data)
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
          // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let fullRoadAddr = data.roadAddress // 도로명 주소 변수
          let extraRoadAddr = '' // 도로명 조합형 주소 변수

          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
            extraRoadAddr += data.bname
          } // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== '' && data.apartment === 'Y') {
            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName)
          }
          // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraRoadAddr !== '') {
            extraRoadAddr = ' (' + extraRoadAddr + ')'
          }
          // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
          if (fullRoadAddr !== '') {
            fullRoadAddr += extraRoadAddr
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          this.zip = data.zonecode // 5자리 새우편번호 사용
          this.addr1 = fullRoadAddr
          this.addrToXY(fullRoadAddr)
        }
      }).open(this.$refs.embed)
    },
    addrToXY (addr1) {
      // console.log(this.addr1)
      var x = ''
      var y = ''
      if (this.addr1 !== '') {
        window.naver.maps.Service.geocode({
          address: addr1
        }, (status, response) => {
          if (status !== window.naver.maps.Service.Status.OK) {
            return alert('Something wrong!')
          }

          var result = response.result // 검색 결과의 컨테이너
          var items = result.items // 검색 결과의 배열

          if (items.length !== '0') {
            x = result.items[0].point.x
            y = result.items[0].point.y
            // 체크
            this.locationBlockConfigUpt(x, y, '2000')
          }
        // do Something
        })
      }
    },
    locationBlockConfigDel (lon, lat, around) {
      const formData = new FormData()
      formData.append('token', this.$store.state.Member.token)
      formData.append('location_longitude', lon)
      formData.append('location_latitude', lat)
      formData.append('location_around', around)
      this.axios.post('/Member/member_location_block_config_del.json', formData)
        .then(data => {
          // console.log(data.data.response.message)
          this.$showMessagePopup('', data.data.response.message)
          this.locationBlockConfig()
        })
    }
  }
}
</script>
