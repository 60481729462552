<template>
  <div v-if="!isOpenDormantPopup" class="popup login_bg login_pop">
    <div class="login_page">
      <div class="popup_area">
        <div class="login_box" v-if="isFacebook !== true">
          <span class="title">게임 같은 운동앱 트랭글</span>
          <span class="title_logo"></span>
          <span class="stitle"
            >트랭글 회원이 되어 재미있게 운동하고 운동에 따른<br />다양한 리워드
            혜택을 누려보세요.<br />
            <span class="text_org">만 14세 이상 가입 가능합니다.</span></span
          >

          <div class="login_input">
            <input
              type="text"
              name="id"
              v-model="id"
              placeholder="아이디 또는 이메일주소"
            />
            <input
              type="password"
              name="password"
              v-model="password"
              placeholder="비밀번호"
              @keydown.enter="login"
            />
            <button type="button" class="btn_login" @click="login">
              로그인
            </button>
          </div>
          <div class="login_menu">
            <router-link to="/member/find/id">아이디 찾기</router-link>
            <router-link to="/member/change/pw">비밀번호 재설정</router-link>
            <!-- <router-link to="/now">손님으로 입장</router-link> -->
          </div>
          <div class="login_input">
            <button
              type="button"
              class="btn_join"
              @click="$router.push('/member/join')"
            >
              회원가입
            </button>
          </div>
          <div class="login_sns">
            <span class="title">간편 로그인</span>
            <div class="login_sns_box">
              <a href="/v2/Naver_login/login" class="i_login_naver"></a>
              <a href="/v2/kakao/set_login" class="i_login_kakao"></a>
              <!-- <router-link to="/Loding" class="i_login_facebook"></router-link> -->
              <a href="/v2/Facebook/login" class="i_login_facebook"></a>
            </div>
          </div>
        </div>
        <!-- <div class="login_main">
          <router-link to="/" class="btn_main"></router-link>
        </div> -->
      </div>
    </div>
  </div>
  <!--메인화면-->

  <ChangePasswordPopup
    v-if="isOpenChangePasswordPopup"
    @setMemberInfo="setMemberInfo"
    :originalPassword="this.password"
    :token="token"
    :memberId="memberId"
  />
  <!-- <DormantPopup v-if="isOpenDormantPopup" :dormantDate="dormantDate" :lastLoginDate="lastLoginDate" /> -->
  <ExpireTrangPopup
    v-if="isOpenExpireTrangPopup"
    @setMemberInfo="setMemberInfo"
    :trangExpireDate="trangExpireDate"
    :lastLoginDate="lastLoginDate"
  />
  <MobileAuthenticationPopup
    v-if="isOpenMobileAuthenticationPopup"
    @setMemberInfo="setMemberInfo"
  />
</template>

<script>
import ChangePasswordPopup from "@/components/member/popup/ChangePasswordPopup.vue";
// import DormantPopup from '@/components/member/popup/DormantPopup.vue'
import ExpireTrangPopup from "@/components/member/popup/ExpireTrangPopup.vue";
import MobileAuthenticationPopup from "@/components/member/popup/MobileAuthenticationPopup.vue";
import dayjs from "dayjs";
import { mapState, mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      id: "",
      password: "",
      resultMessage: "",
      isOpenChangePasswordPopup: false,
      // isOpenDormantPopup: false,
      isOpenExpireTrangPopup: false,
      isOpenMobileAuthenticationPopup: false,
      token: "",
      memberId: "",
      dormantDate: "",
      trangExpireDate: "",
      lastLoginDate: "",
      isFacebook: false,
      fbAsyncInit: () => {
        window.FB.init({
          appId: "1465698267204838",
          status: true,
          cookie: true,
          xfbml: false,
          version: "v14.0",
        });
      },
      from: "",
    };
  },
  components: {
    ChangePasswordPopup,
    // DormantPopup,
    ExpireTrangPopup,
    MobileAuthenticationPopup,
  },
  //   watch: {
  //     window.fbAsyncInit = ()=> {
  // FB.init({
  // appId      : '1465698267204838',
  // cookie     : true,
  // xfbml      : true,
  // version    : 'v14.0'
  // });
  // FB.AppEvents.logPageView();
  // fnFbCustomLogin();
  // }
  //   },
  computed: {
    ...mapState("UserSet", ["isOpenDormantPopup", "from"]),
  },
  created() {
    this.storeFromParam();
  },
  mounted() {
    document.body.classList.remove("m_menu_show");
  },
  methods: {
    storeFromParam() {
      const fromParam = this.$route.query.from;

      if (fromParam) {
        this.$store.commit("UserSet/setFrom", fromParam);
      }
    },
    ...mapActions("UserSet", ["loadGetToken"]),
    fnFbCustomLogin() {
      window.FB.AppEvents.logPageView();
      // console.log('dd')
      // this.isFacebook = true
      window.FB.login(
        (response) => {
          if (response.status === "connected") {
            window.FB.api("/me", "get", { fields: "name,email" }, function (r) {
              var form = document.createElement("form");
              form.setAttribute("method", "post");
              form.setAttribute(
                "action",
                "https://www.tranggle.com/v2/Facebook/login"
              );
              document.charset = "UTF-8";

              var hiddenField = document.createElement("input");
              hiddenField.setAttribute("type", "hidden");
              hiddenField.setAttribute("name", "app_ver");
              hiddenField.setAttribute("value", "");
              form.appendChild(hiddenField);

              hiddenField = document.createElement("input");
              hiddenField.setAttribute("type", "hidden");
              hiddenField.setAttribute("name", "name");
              hiddenField.setAttribute("value", r.name);
              form.appendChild(hiddenField);

              hiddenField = document.createElement("input");
              hiddenField.setAttribute("type", "hidden");
              hiddenField.setAttribute("name", "id");
              hiddenField.setAttribute("value", r.id);
              form.appendChild(hiddenField);

              hiddenField = document.createElement("input");
              hiddenField.setAttribute("type", "hidden");
              hiddenField.setAttribute("name", "email");
              hiddenField.setAttribute("value", r.email);
              form.appendChild(hiddenField);

              document.body.appendChild(form);
              form.submit();
              // this.isFacebook = false
            });
          } else if (response.status === "not_authorized") {
            alert("앱에 로그인해야 이용가능한 기능입니다.");
            history.back();
          } else {
            alert("페이스북에 로그인해야 이용가능한 기능입니다.");
            history.back();
          }
          // this.isFacebook = false
        },
        { scope: "public_profile,email" }
      );
    },
    login() {
      if (!this.id) {
        this.$showMessagePopup("", "아이디 또는 이메일을 입력해주세요.");
      } else if (!this.password) {
        this.$showMessagePopup("", "비밀번호를 입력해주세요.");
      } else {
        const formData = new FormData();
        formData.append("id", this.id);
        formData.append("password", this.password);
        formData.append("by", "ID");

        this.axios.post("/Login/login.json", formData).then((data) => {
          const loginResponseData = data.data.response;
          const loginResponseCode = loginResponseData.code;

          if (loginResponseCode === "00") {
            const memberPasswordDate = loginResponseData.content.password_date; // 6개월마다 비밀번호 갱신
            const mobileAuth = loginResponseData.content.mobile_auth; // 휴대전화 인증
            const memberDormant = loginResponseData.content.dormant_yn; // 휴면계정
            const today = dayjs().format("YYYY-MM-DD");
            if (new Date(memberPasswordDate) < new Date(today)) {
              // 비밀번호 만료일 체크
              this.isOpenChangePasswordPopup = true;
            } else if (mobileAuth === "FALSE") {
              // 휴대전화 인증
              this.isOpenMobileAuthenticationPopup = true;
            } else if (memberDormant === "TRUE") {
              // 휴면계정
              this.$store.state.UserSet.isOpenDormantPopup = true;
              this.$store.state.UserSet.dormanInfo = {
                loginDate: loginResponseData.content.last_login_date,
                dormantDate: loginResponseData.content.dormant_date,
              };
              // this.isOpenDormantPopup = true
              // this.dormantDate = loginResponseData.content.dormant_date
              // this.lastLoginDate = loginResponseData.content.last_login_date
            } else {
              // 로그인 성공
              const url = sessionStorage.getItem("loginUrl")
                ? sessionStorage.getItem("loginUrl")
                : "/";
              this.loadGetToken(url);
            }
          } else {
            if (loginResponseCode === "02") {
              this.$showMessagePopup(
                "",
                "가입하지 않은 아이디이거나,<br>잘못된 비밀번호 입니다."
              );
            } else {
              this.$showMessagePopup("", loginResponseData.message);
            }
          }
        });
      }
    },
    setMemberInfo(val) {
      this.id = "";
      this.password = "";
      if (val === "ChangePasswordPopup") {
        this.isOpenChangePasswordPopup = false;
      }
      // await this.axios.get('/member/info.json', {
      //   params: {
      //     token: this.token,
      //     member_id: this.memberId
      //   }
      // })
      //   .then(data => {
      //     const memberResponseData = data.data.response
      //     const memberResponseCode = memberResponseData.code

      //     if (memberResponseCode === '00') {
      //       const memberInfo = memberResponseData.content
      //       this.$store.state.Member.token = this.token
      //       this.$store.state.Member.memberId = this.memberId
      //       this.$store.commit('Member/setMemberInfo', memberInfo)
      //     } else {
      //       this.$showMessagePopup('', '오류가 발생했습니다.<br />다시 로그인 부탁드립니다.')
      //     }
      //   })
    },
  },
};
</script>
