<template>
  <div class="popup login_bg find_box reset_pwd">
    <div class="popup_area">
      <div class="popup_box popup_box_m sports_pop">
        <div class="popup_top popup_top_line">
          <h2>비밀번호 재설정</h2>
          <!-- <a href="#" class="btn_close" @click="close"></a> -->
        </div>
        <div class="popup_body login_body">
          <div class="title_img">
            <img src="@/assets/images/common/img_password.png" />
          </div>
          <p class="t_bold">안전한 비밀번호로 변경해주세요.</p>
          <div class="login_area">
            <ul>
              <li>
                <span class="set_text">새 비밀번호</span>
                <span class="set_input1 input_l">
                  <input type="password" v-model="newPass" placeholder="새 비밀번호를 입력해주세요." maxlength="20" @blur="checkPassword" />
                  <span class="check_ok" v-if="validatePassword === 'Y'"></span>
                  <span class="check_no" v-if="validatePassword === 'N'"></span>
                  <span class="stext text_red" v-if="validatePassword === 'N'">8자 이상 영문 대소문자, 숫자, 특수문자 조합</span>
                </span>
              </li>
              <li>
                <span class="set_text">새 비밀번호 확인</span>
                <span class="set_input1 input_l">
                  <input type="password" v-model="reNewPass" placeholder="새 비밀번호를 다시 한번 입력해주세요." maxlength="20" @blur="checkConfirmPassword" />
                  <span class="check_ok" v-if="comparePassword && reNewPass !== ''"></span>
                  <span class="check_no" v-if="!comparePassword && reNewPass"></span>
                  <span class="stext text_red" v-if="(!comparePassword && reNewPass)">일치하지 않습니다.</span>
                </span>
              </li>
            </ul>
          </div>
          <div class="footer_bottom change">
            <MobileHint type="resetPwd" />
            <div class="btn_area">
              <button type="button" class="line" @click="close()">닫기</button>
              <button type="button" :class="{'disabled': !validationChk()}" @click="passChgAPI()">변경하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import MobileHint from '@/components/common/MobileHint.vue'
export default {
  data () {
    return {
      newPass: '',
      validatePassword: '',
      reNewPass: '',
      comparePassword: false,
      chkMount: false
    }
  },
  components: {
    MobileHint
  },
  computed: {
    ...mapState('Member', [
      'newPwdBool',
      'memberNo'
    ])
  },
  watch: {
    newPwdBool () {
      if (this.newPwdBool) {
        // 비밀번호 설정완료
        this.$store.state.Member.newPwdBool = false
        this.$showMessagePopup('비밀번호 변경 완료', '비밀번호 변경이 완료되었습니다. 재로그인 해주세요.', '/login')
      }
    },
    newPass () {
      this.checkPassword()
      this.checkConfirmPassword()
    },
    reNewPass () {
      this.checkPassword()
      this.checkConfirmPassword()
    }
  },
  mounted () {
    document.body.classList.add('overhidden')
  },
  unmounted () {
    document.body.classList.remove('overhidden')
    this.$store.state.Member.newPwdBool = false
    this.$store.state.Member.memberNo = ''
  },
  methods: {
    ...mapActions('Member', [
      'updateNewPassword'
    ]),
    close () {
      this.$store.state.Member.newPwdPop = false
    },
    checkPassword () {
      const regPw = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[(){}<>[\]';:.,_/=~#?!@$%^&-]).{8,20}$/gi
      if (!regPw.test(this.newPass)) {
        this.validatePassword = 'N'
        return false
      }
      this.validatePassword = 'Y'
    },
    checkConfirmPassword () {
      const regPw = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[(){}<>[\]';:.,_/=~#?!@$%^&-]).{8,20}$/gi
      if (regPw.test(this.newPass) && this.newPass === this.reNewPass) {
        this.comparePassword = true
      } else {
        this.comparePassword = false
      }
    },
    validationChk () {
      return this.validatePassword === 'Y' && this.comparePassword
    },
    passChgAPI () {
      if (this.validatePassword !== 'Y') {
        this.$showMessagePopup('비밀번호 변경', '비밀번호는 8자 이상 영문 대소문자, 숫자, 특수문자 조합으로 설정해주세요.')
        return false
      }
      if (!this.comparePassword) {
        this.$showMessagePopup('비밀번호 변경', '새 비밀번호 확인이 일치하지 않습니다.')
        return false
      }
      this.updateNewPassword({
        pwd: this.newPass,
        pwd_re: this.reNewPass,
        member_no: this.memberNo
      })
    }
  }
}
</script>
