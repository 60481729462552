import { createWebHistory, createRouter } from 'vue-router'

// 홈
import Home from '../views/Home.vue'

// 코스
import Course from '../views/Course.vue'
import CourseMain from '../components/course/Course.vue'
import CourseList from '../components/course/course/List.vue'
import CourseDetail from '../components/course/course/Detail.vue'
import CourseDetailRecord from '../components/course/course/DetailRecord.vue'
import CourseDetailInfo from '../components/course/course/DetailInfo.vue'
import CourseDetailComment from '../components/course/course/DetailComment.vue'
import CourseDetailBadge from '../components/course/course/DetailBadge.vue'
// import CourseBook from '../components/course/Book.vue'
// import CourseBookList from '../components/course/book/List.vue'
// import CourseBookDetail from '../components/course/book/Detail.vue'
// import CourseBookDetailInfo from '../components/course/book/DetailInfo.vue'
// import CourseBookDetailChallenge from '../components/course/book/DetailChallenge.vue'
// import CourseBookDetailComment from '../components/course/book/DetailComment.vue'
// import CourseBookDetailBadge from '../components/course/book/DetailBadge.vue'
// import UserCourseBookDetail from '../components/course/book/user/Detail.vue'
// import UserCourseBookDetailInfo from '../components/course/book/user/DetailInfo.vue'
// import UserCourseBookDetailChallenge from '../components/course/book/user/DetailChallenge.vue'
// import UserCourseBookDetailComment from '../components/course/book/user/DetailComment.vue'
// import UserCourseBookDetailBadge from '../components/course/book/user/DetailBadge.vue'
// import CourseAnalyze from '../components/course/Analyze.vue'
import CourseMy from '../components/course/My.vue'
import CourseMyGpx from '../components/course/MyGpx.vue'
// import CourseHistory from '../components/course/History.vue'
// import CourseTheme from '../components/course/Theme.vue'
// import CourseThemeMain from '../components/course/theme/Main.vue'
// import CourseThemeList from '../components/course/theme/List.vue'

// 마이트랭글
import MyTranggle from '../views/MyTranggle.vue'
// import MyTranggleMyInfo from '../components/mytranggle/MyInfo.vue'
// import MyTranggleMyFriend from '../components/mytranggle/MyFriend.vue'
// import MyTranggleMyFriendDetails from '../components/mytranggle/MyfriendDetails.vue'
// import MyTranggleAnotherRecord from '../components/mytranggle/AnotherRecord.vue'
// import MyTranggleBadgeSearch from '../components/mytranggle/BadgeSearch.vue'
// import MyTranggleBadgeDetails from '../components/mytranggle/BadgeDetails.vue'
// import MyTranggleBadgeDetails360 from '../components/mytranggle/BadgeDetails360.vue'
// import MyTranggleMyAcceptFriend from '../components/mytranggle/MyAcceptFriend.vue'
// import MyTranggleMyAcceptFriendInvited from '../components/mytranggle/MyAcceptFriendInvited.vue'
// import MyTranggleMyAcceptFriendInvite from '../components/mytranggle/MyAcceptFriendInvite.vue'
// import MyTranggleMyMessage from '../components/mytranggle/MyMessage.vue'
// import MyTranggleMessageChat from '../components/mytranggle/MyMessageChat.vue'
// import MyTranggleMyMessageSend from '../components/mytranggle/MyMessageSend.vue'
// import MyTranggleMyBadgeList from '../components/mytranggle/MyBadgeList.vue'
// import MyTranWallet from '../components/mytranggle/MyTranWallet.vue'
import MyTrangConf from '../components/mytranggle/MyTrangConf.vue'

// 나의 활동
// import Activity from '../views/Activity.vue'
// import ActivityMain from '../components/activity/ActivityMain.vue'
// import ActivityComp from '../components/activity/ActivityComp.vue'
// import ActivityCompDetail from '../components/activity/ActivityCompDetail.vue'

// 미션
// import Mission from '../views/Mission.vue'
// import MissionMain from '../components/mission/MissionMain.vue'
// // import MyMission from '../components/mission/MyMission.vue'
// // import MyMissionSearch from '../components/mission/MyMissionSearch.vue'
// // import MissionOpened from '../components/mission/MissionOpened.vue'
// // import MissionConfig from '../components/mission/MissionConfig.vue'
// // import MyMission from '../components/mission/MyMission.vue'
// // import MyMissionSearch from '../components/mission/MyMissionSearch.vue'
// import MissionCreate from '../components/mission/MissionCreate.vue'
// // import MissionModify from '../components/mission/MissionModify.vue'
// import MissionSearch from '../components/mission/MissionSearch.vue'
// import MissionView from '../components/mission/MissionView.vue'
// import MissionJoin from '../components/mission/MissionJoin.vue'
// // import MissionConfig from '../components/mission/MissionConfig.vue'
// import MissionAdmin from '../components/mission/manager/MissionAdmin.vue'
// import MissionManagerStatus from '../components/mission/manager/MissionManagerStatus.vue'
// import MissionManagerParticipant from '../components/mission/manager/MissionManagerParticipant.vue'
// import MissionManagerRecord from '../components/mission/manager/MissionManagerRecord.vue'
// import MissionManagerSurvey from '../components/mission/manager/MissionManagerSurvey.vue'
// import MissionManagerPopup from '../components/mission/manager/MissionManagerPopup.vue'
// import MissionManagerGroup from '../components/mission/manager/MissionManagerGroup.vue'
// import MissionManagerIntroduction from '../components/mission/manager/MissionManagerIntroduction.vue'
// import MissionManagerAdmin from '../components/mission/manager/MissionManagerAdmin.vue'

// 클럽
// import Club from '../views/Club.vue'
// import ClubMain from '../components/club/ClubMain.vue'
// import ClubSearch from '../components/club/ClubSearch.vue'
// import ClubRecruitment from '../components/club/ClubRecruitment.vue'
// import ClubNew from '../components/club/ClubNew.vue'
// import ClubCreate from '../components/club/ClubCreate.vue'
// import MyClub from '../components/club/ClubMyClub.vue'
// import ClubDetail from '../components/club/details/main/Main.vue'
// import ClubDetailRecirect from '../components/club/ClubDetailRecirect.vue'
// import ClubDetailCalendar from '../components/club/details/calendar/Main.vue' // 클럽 - 일정
// import ClubDetailMember from '../components/club/details/member/Main.vue' // 클럽 - 멤버
// import ClubDetailRank from '../components/club/details/rank/Main.vue' // 클럽 - 랭크
// import ClubDetailNotice from '../components/club/details/notice/Main.vue' // 클럽 - 공지 메인
// import ClubDetailNoticeWrite from '../components/club/details/notice/Write.vue' // 클럽 - 공지 작성
// import ClubDetailNoticeRead from '../components/club/details/notice/Read.vue' // 클럽 - 공지 읽기
// import ClubDetailNoticeModify from '../components/club/details/notice/Modify.vue' // 클럽 - 공지 수정
// import ClubDetailMeet from '../components/club/details/meet/Main.vue' // 클럽 - 모임
// import ClubDetailMeetCreate from '../components/club/details/meet/Create.vue' // 클럽 - 모임 개설
// import ClubDetailMeetRead from '../components/club/details/meet/Read.vue' // 클럽 - 모임 개설
// import ClubDetailMeetModify from '../components/club/details/meet/Modify.vue' // 클럽 - 모임 개설
// import ClubDetailBoard from '../components/club/details/board/Main.vue' // 클럽 - 자유 게시판
// import ClubDetailBoardWrite from '../components/club/details/board/Write.vue' // 클럽 - 게시판 작성
// import ClubDetailBoardRead from '../components/club/details/board/Read.vue' // 클럽 - 게시판 읽기
// import ClubDetailBoardModify from '../components/club/details/board/Modify.vue' // 클럽 - 게시판 수정
// import ClubManage from '../components/club/manage/Intro.vue' // 클럽 - 클럽관리
// import ClubManageMember from '../components/club/manage/Member.vue' // 클럽 - 클럽관리 멤버
// import ClubManageBoard from '../components/club/manage/Board.vue' // 클럽 - 클럽관리 게시판

// 스토리
// import Story from '../views/Story.vue'
// import StoryMain from '../components/story/Main.vue'
// import StoryList from '../components/story/List.vue'
// import StoryDetail from '../components/story/Detail.vue'
// import MyStory from '../components/story/My.vue'
// import WriteStory from '../components/story/Write.vue'

// 회원
import Login from '../views/Login.vue'
import Join from '../components/member/Join.vue'
import JoinComplete from '../components/member/JoinComplete.vue'
import FindId from '../components/member/FindId.vue'
import ChangePassword from '../components/member/ChangePassword.vue'
import Authentication from '../components/member/Authentication.vue'

// 트랭글 NOW
// import TranggleNow from '../views/TranggleNow.vue'

// 페이스북 로그인 이동
import Loding from '../views/Loding.vue'

// 고객지원
// import Support from '../views/Support.vue'
// import Notice from '../components/support/Notice.vue'
// import NoticeDetail from '../components/support/NoticeDetail.vue'
// import Information from '../components/support/Information.vue'
// import Inquire from '../components/support/Inquire.vue'
// import InquireRegist from '../components/support/InquireRegist.vue'
// import InquireDetail from '../components/support/InquireDetail.vue'
// import Recommended from '../components/support/Recommended.vue'
// import Instruction from '../components/support/Instruction.vue'
// import InstructionDetail from '../components/support/InstructionDetail.vue'

// 정책 및 약관
import Policy from '../views/Policy.vue'
import Terms from '../components/policy/Terms.vue'
import PersonalInformation from '../components/policy/PersonalInformation.vue'
import TranggleTalk from '../components/policy/TranggleTalk.vue'
import GeoData from '../components/policy/GeoData.vue'
import TrangInfo from '../components/policy/TrangInfo.vue'
import TermProvision from '../components/policy/TermProvision.vue'
import TermMission from '../components/policy/TermMission.vue'

// import store from '../store/index'

// import Control from '../views/Control.vue'
// import MissonList from '../components/admin/MissonList.vue'
// import MissonSearch from '../components/admin/MissonSearch.vue'
// import ControlCourseList from '../components/admin/CourseList.vue'
// import CourseSearch from '../components/admin/CourseSearch.vue'
// import ThemaList from '../components/admin/ThemaList.vue'
// import ThemaSearch from '../components/admin/ThemaSearch.vue'
// import ThemaDetail from '../components/admin/ThemaDetail.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/control',
  //   component: Control,
  //   children: [
  //     {
  //       path: 'missonlist',
  //       component: MissonList
  //     },
  //     {
  //       path: 'missonsearch',
  //       component: MissonSearch
  //     },
  //     {
  //       path: 'courselist',
  //       component: ControlCourseList
  //     },
  //     {
  //       path: 'coursesearch',
  //       component: CourseSearch
  //     },
  //     {
  //       path: 'themalist',
  //       component: ThemaList
  //     },
  //     {
  //       path: 'themasearch',
  //       component: ThemaSearch
  //     },
  //     {
  //       path: 'themadetail/:no',
  //       component: ThemaDetail
  //     }
  //   ]
  // },
  // {
  //   path: '/support',
  //   component: Support,
  //   children: [
  //     {
  //       path: 'notice',
  //       component: Notice
  //     },
  //     {
  //       path: 'notice/:no',
  //       component: NoticeDetail
  //     },
  //     {
  //       path: 'information',
  //       component: Information
  //     },
  //     {
  //       path: 'inquire',
  //       component: Inquire
  //     },
  //     {
  //       path: 'regist',
  //       component: InquireRegist
  //     },
  //     {
  //       path: 'inquire/:no',
  //       component: InquireDetail
  //     },
  //     {
  //       path: 'recommended',
  //       component: Recommended
  //     },
  //     {
  //       path: 'instruction',
  //       component: Instruction
  //     },
  //     {
  //       path: 'instruction/:page/:no',
  //       component: InstructionDetail
  //     }
  //   ]
  // },
  {
    path: '/policy',
    component: Policy,
    children: [
      {
        path: 'terms',
        component: Terms
      },
      {
        path: 'personalinformation',
        component: PersonalInformation
      },
      {
        path: 'tranggletalk',
        component: TranggleTalk
      },
      {
        path: 'geodata',
        component: GeoData
      },
      {
        path: 'tranginfo',
        component: TrangInfo
      },
      {
        path: 'termprovision',
        component: TermProvision
      },
      {
        path: 'termmission',
        component: TermMission
      }
    ]
  },
  {
    path: '/course',
    component: Course,
    children: [
      {
        path: '',
        component: CourseMain,
        children: [
          {
            path: '',
            component: CourseList
          },
          {
            path: ':id',
            component: CourseDetail,
            children: [
              {
                path: '',
                component: CourseDetailRecord
              },
              {
                path: 'info',
                component: CourseDetailInfo
              },
              {
                path: 'badge',
                component: CourseDetailBadge
              },
              {
                path: 'comment',
                component: CourseDetailComment
              }
            ]
          }
        ]
      },
      // {
      //   path: 'book',
      //   component: CourseBook,
      //   children: [
      //     {
      //       path: '',
      //       component: CourseBookList
      //     },
      //     {
      //       path: ':id',
      //       component: CourseBookDetail,
      //       children: [
      //         {
      //           path: '',
      //           component: CourseBookDetailInfo
      //         },
      //         {
      //           path: 'challenge',
      //           component: CourseBookDetailChallenge
      //         },
      //         {
      //           path: 'badge',
      //           component: CourseBookDetailBadge
      //         },
      //         {
      //           path: 'comment',
      //           component: CourseBookDetailComment
      //         }
      //       ]
      //     },
      //     {
      //       path: 'user/:id',
      //       component: UserCourseBookDetail,
      //       children: [
      //         {
      //           path: '',
      //           component: UserCourseBookDetailInfo
      //         },
      //         {
      //           path: 'challenge',
      //           component: UserCourseBookDetailChallenge
      //         },
      //         {
      //           path: 'badge',
      //           component: UserCourseBookDetailBadge
      //         },
      //         {
      //           path: 'comment',
      //           component: UserCourseBookDetailComment
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   path: 'analyze',
      //   component: CourseAnalyze
      // },
      {
        path: 'my',
        component: CourseMy
      },
      {
        path: 'my/gpx',
        component: CourseMyGpx
      }
      // {
      //   path: 'history',
      //   component: CourseHistory
      // },
      // {
      //   path: 'theme',
      //   component: CourseTheme,
      //   children: [
      //     {
      //       path: '',
      //       component: CourseThemeMain
      //     },
      //     {
      //       path: ':id',
      //       component: CourseThemeList
      //     }
      //   ]
      // }
    ]
  },
  {
    path: '/mytranggle',
    component: MyTranggle,
    children: [
      // {
      //   path: 'myinfo',
      //   component: MyTranggleMyInfo
      // },
      // {
      //   path: 'myfriend',
      //   component: MyTranggleMyFriend
      // },
      // {
      //   path: 'friendInfo/:id',
      //   component: MyTranggleMyFriendDetails
      // },
      // {
      //   path: 'anotherrecord',
      //   component: MyTranggleAnotherRecord
      // },
      // {
      //   path: 'badgesearch',
      //   component: MyTranggleBadgeSearch
      // },
      // {
      //   path: 'badge/:badgeCode',
      //   component: MyTranggleBadgeDetails
      // },
      // {
      //   path: 'badgevideo/:badgeCode',
      //   component: MyTranggleBadgeDetails360
      // },
      // {
      //   path: 'myacceptfriend',
      //   component: MyTranggleMyAcceptFriend
      // },
      // {
      //   path: 'myacceptfriendinvited',
      //   component: MyTranggleMyAcceptFriendInvited
      // },
      // {
      //   path: 'myacceptfriendinvite',
      //   component: MyTranggleMyAcceptFriendInvite
      // },
      // {
      //   path: 'mymessage',
      //   component: MyTranggleMyMessage
      // },
      // {
      //   path: 'mymessage/:no',
      //   component: MyTranggleMessageChat
      // },
      // {
      //   path: 'messagesend',
      //   component: MyTranggleMyMessageSend
      // },
      // {
      //   path: 'mybadgelist',
      //   component: MyTranggleMyBadgeList
      // },
      // {
      //   path: 'mytranwallet',
      //   component: MyTranWallet
      // },
      {
        path: 'mytrangconf',
        component: MyTrangConf
      }
    ]
  },
  // {
  //   path: '/activity',
  //   component: Activity,
  //   children: [
  //     {
  //       path: 'main',
  //       component: ActivityMain
  //     },
  //     {
  //       path: 'comp',
  //       component: ActivityComp
  //     },
  //     {
  //       path: 'ActivityCompDetail/:no',
  //       component: ActivityCompDetail
  //     }
  //   ]
  // },
  // {
  //   path: '/mission',
  //   component: Mission,
  //   children: [
  //     {
  //       path: '',
  //       component: MissionMain
  //     },
  //     {
  //       path: 'search',
  //       component: MissionSearch,
  //       beforeEnter: (to, from, next) => {
  //         if (store.state.UserSet.isLogin === 'Y') {
  //           return next()
  //         }
  //         store.state.Popup.isLoginPopupBackEvent = 1
  //         store.state.Popup.isOpenLoginPopup = true
  //       }
  //     },
  //     {
  //       path: 'create',
  //       component: MissionCreate
  //     },
  //     {
  //       path: ':id',
  //       component: MissionView,
  //       beforeEnter: (to, from, next) => {
  //         if (store.state.UserSet.isLogin === 'Y') {
  //           return next()
  //         }
  //         store.state.Popup.isLoginPopupBackEvent = 1
  //         store.state.Popup.isOpenLoginPopup = true
  //       }
  //     },
  //     {
  //       path: ':id(\\d+)/notice',
  //       component: MissionView,
  //       props: {
  //         isNotice: true,
  //         isBoard: false
  //       }
  //     },
  //     {
  //       path: ':id(\\d+)/notice/:boardNo(\\d+)',
  //       component: MissionView,
  //       props: {
  //         isNotice: true,
  //         isBoard: false
  //       }
  //     },
  //     {
  //       path: ':id(\\d+)/board',
  //       component: MissionView,
  //       props: {
  //         isNotice: false,
  //         isBoard: true
  //       }
  //     },
  //     {
  //       path: ':id/board/:boardNo',
  //       component: MissionView,
  //       props: {
  //         isNotice: false,
  //         isBoard: true
  //       }
  //     },
  //     // {
  //     //   path: ':id/modify',
  //     //   component: MissionModify
  //     // },
  //     {
  //       name: 'missionJoin',
  //       path: ':id(\\d+)/join',
  //       component: MissionJoin,
  //       props: true
  //     },
  //     {
  //       path: ':id(\\d+)/admin',
  //       component: MissionAdmin
  //     },
  //     {
  //       path: ':id(\\d+)/managerStatus',
  //       component: MissionManagerStatus
  //     },
  //     {
  //       path: ':id(\\d+)/managerParticipant',
  //       component: MissionManagerParticipant
  //     },
  //     {
  //       path: ':id(\\d+)/managerRecord',
  //       component: MissionManagerRecord
  //     },
  //     {
  //       path: ':id(\\d+)/managerSurvey',
  //       component: MissionManagerSurvey
  //     },
  //     {
  //       path: ':id(\\d+)/managerGroup',
  //       component: MissionManagerGroup
  //     },
  //     {
  //       path: ':id(\\d+)/managerIntroduction',
  //       component: MissionManagerIntroduction
  //     },
  //     {
  //       path: ':id(\\d+)/managerAdmin',
  //       component: MissionManagerAdmin
  //     },
  //     {
  //       path: ':id(\\d+)/managerPopup',
  //       component: MissionManagerPopup
  //     }
  //   ]
  // },
  // {
  //   path: '/club',
  //   component: Club,
  //   children: [
  //     {
  //       path: '',
  //       component: ClubMain
  //     },
  //     {
  //       path: 'search',
  //       component: ClubSearch,
  //       beforeEnter: (to, from, next) => {
  //         console.log('search')
  //         if (store.state.UserSet.isLogin === 'Y') {
  //           return next()
  //         }
  //         store.state.Popup.isLoginPopupBackEvent = 1
  //         store.state.Popup.isOpenLoginPopup = true
  //       }
  //     },
  //     {
  //       path: 'recruitment',
  //       component: ClubRecruitment,
  //       beforeEnter: (to, from, next) => {
  //         if (store.state.UserSet.isLogin === 'Y') {
  //           return next()
  //         }
  //         store.state.Popup.isLoginPopupBackEvent = 1
  //         store.state.Popup.isOpenLoginPopup = true
  //       }
  //     },
  //     {
  //       path: 'new',
  //       component: ClubNew,
  //       beforeEnter: (to, from, next) => {
  //         if (store.state.UserSet.isLogin === 'Y') {
  //           return next()
  //         }
  //         store.state.Popup.isLoginPopupBackEvent = 1
  //         store.state.Popup.isOpenLoginPopup = true
  //       }
  //     },
  //     {
  //       path: 'create',
  //       component: ClubCreate,
  //       beforeEnter: (to, from, next) => {
  //         if (store.state.UserSet.isLogin === 'Y') {
  //           return next()
  //         }
  //         store.state.Popup.isLoginPopupBackEvent = 1
  //         store.state.Popup.isOpenLoginPopup = true
  //       }
  //     },
  //     {
  //       path: 'myclub',
  //       component: MyClub
  //     },
  //     {
  //       path: 'manage',
  //       component: ClubManage
  //     },
  //     {
  //       path: 'managemember',
  //       component: ClubManageMember
  //     },
  //     {
  //       path: ':id(\\d+)',
  //       component: ClubDetail,
  //       beforeEnter: (to, from, next) => {
  //         if (store.state.UserSet.isLogin === 'Y') {
  //           return next()
  //         }
  //         store.state.Popup.isLoginPopupBackEvent = 1
  //         store.state.Popup.isOpenLoginPopup = true
  //       }
  //     },
  //     {
  //       path: ':id(\\d+)/member',
  //       component: ClubDetailMember
  //     },
  //     {
  //       path: ':id(\\d+)/calendar',
  //       component: ClubDetailCalendar
  //     },
  //     {
  //       path: ':id(\\d+)/rank',
  //       component: ClubDetailRank
  //     },
  //     // 클럽 - 공지
  //     {
  //       path: ':id(\\d+)/notice',
  //       component: ClubDetailNotice
  //     },
  //     {
  //       path: ':id(\\d+)/notice/write',
  //       component: ClubDetailNoticeWrite
  //     },
  //     {
  //       path: ':id(\\d+)/notice/:no(\\d+)',
  //       component: ClubDetailNoticeRead
  //     },
  //     {
  //       path: ':id(\\d+)/notice/:no(\\d+)/modify',
  //       component: ClubDetailNoticeModify
  //     },
  //     // 클럽 - 모임
  //     {
  //       path: ':id(\\d+)/meet',
  //       component: ClubDetailMeet
  //     },
  //     {
  //       path: ':id(\\d+)/meet/:no(\\d+)',
  //       component: ClubDetailMeetRead
  //     },
  //     {
  //       path: ':id(\\d+)/meet/:no(\\d+)/modify',
  //       component: ClubDetailMeetModify
  //     },
  //     {
  //       path: ':id(\\d+)/meet/write',
  //       component: ClubDetailMeetCreate
  //     },
  //     // 클럽 관리
  //     {
  //       path: ':id(\\d+)/manage',
  //       component: ClubManage
  //     },
  //     {
  //       path: ':id(\\d+)/manage/intro',
  //       component: ClubManage
  //     },
  //     {
  //       path: ':id(\\d+)/manage/board',
  //       component: ClubManageBoard
  //     },
  //     {
  //       path: ':id(\\d+)/manage/member',
  //       component: ClubManageMember
  //     },
  //     // 클럽 - 자유게시판
  //     {
  //       path: ':id(\\d+)/board/:basicNo(\\d+)',
  //       component: ClubDetailBoard
  //     },
  //     {
  //       path: ':id(\\d+)/board/:basicNo(\\d+)/:no(\\d+)',
  //       component: ClubDetailBoardRead
  //     },
  //     {
  //       path: ':id(\\d+)/board/:basicNo(\\d+)/:no(\\d+)/modify',
  //       component: ClubDetailBoardModify
  //     },
  //     {
  //       path: ':id(\\d+)/board/:basicNo(\\d+)/write',
  //       component: ClubDetailBoardWrite
  //     },
  //     {
  //       path: ':id',
  //       component: ClubDetailRecirect
  //     }
  //   ]
  // },
  // {
  //   path: '/story',
  //   component: Story,
  //   children: [
  //     {
  //       path: '',
  //       component: StoryMain
  //     },
  //     {
  //       path: 'list',
  //       component: StoryList,
  //       beforeEnter: (to, from, next) => {
  //         if (store.state.UserSet.isLogin === 'Y') {
  //           return next()
  //         }
  //         // store.state.Popup.isLoginPopupBackEvent = 0 // 취소시 뒤로가기
  //         store.state.Popup.isOpenLoginPopup = true
  //       }
  //     },
  //     {
  //       path: 'my',
  //       component: MyStory,
  //       beforeEnter: (to, from, next) => {
  //         if (store.state.UserSet.isLogin === 'Y') {
  //           return next()
  //         }
  //         store.state.Popup.isOpenLoginPopup = true
  //       }
  //     },
  //     {
  //       path: ':id',
  //       component: StoryDetail,
  //       beforeEnter: (to, from, next) => {
  //         if (store.state.UserSet.isLogin === 'Y') {
  //           return next()
  //         }
  //         store.state.Popup.isLoginPopupBackEvent = 1 // 취소시 팝업만 off
  //         store.state.Popup.isOpenLoginPopup = true
  //       }
  //     },
  //     {
  //       path: 'write',
  //       component: WriteStory
  //       // beforeEnter: (to, from, next) => {
  //       //   if (store.state.UserSet.isLogin === 'Y') {
  //       //     return next()
  //       //   }
  //       //   store.state.Popup.isLoginPopupBackEvent = 0 // 취소시 뒤로가기
  //       //   store.state.Popup.isOpenLoginPopup = true
  //       // }
  //     },
  //     {
  //       path: 'write/:id',
  //       component: WriteStory
  //       // beforeEnter: (to, from, next) => {
  //       //   if (store.state.UserSet.isLogin === 'Y') {
  //       //     return next()
  //       //   }
  //       //   store.state.Popup.isLoginPopupBackEvent = 0 // 취소시 뒤로가기
  //       //   store.state.Popup.isOpenLoginPopup = true
  //       // }
  //     }
  //   ]
  // },
  // 로그인
  { path: '/login', component: Login },
  // 회원 가입
  { path: '/member/join', component: Join },
  // 회원 가입 완료
  { path: '/member/joinComplete', component: JoinComplete },
  // 아이디 찾기
  { path: '/member/find/id', component: FindId },
  // 비밀번호 재설정
  { path: '/member/change/pw', component: ChangePassword },
  // 본인인증
  { path: '/member/auth', component: Authentication },
  // 트랭글 NOW
  // { path: '/now', component: TranggleNow },
  // 페이스북 로그인 이동
  { path: '/Loding', component: Loding },
  // 없는 url 접근 캐치
  { path: '/:catchAll(.*)', component: () => import('../views/notFoundPage') }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
