<template>
  <div class="list_page" v-if="this.totalPageCount > 0">
    <a href="#" class="i_left" @click.prevent="goPrePageBlock()"></a>
    <a href="#" v-for="i in this.pageList" :key="i" :class="{on: i === parseInt(this.currentPage)}" @click.prevent="$emit('select-list', i)">{{i}}</a>
    <a href="#" class="i_right" @click.prevent="goNextPageBlock()"></a>
  </div>
</template>

<script>
export default {
  name: 'CoursePagination',
  data () {
    return {
      pageListCount: 5, // 화면에 보일 페이지 수
      totalPageBlock: 0, // 전체 페이지 블럭 수
      currentPageBlock: 0, // 현재 페이지 블럭
      startPageNumber: 0, // 페이지 블럭 시작 번호
      endPageNumber: 0, // 페이지 블럭 끝 번호
      pageList: []
    }
  },
  watch: {
    currentPage () {
      this.createPagination()
    },
    totalPageCount () {
      this.createPagination()
    }
  },
  mounted () {
    this.createPagination()
  },
  methods: {
    goPrePageBlock () {
      if (this.currentPageBlock === 1) {
        this.$emit('select-list', 1)
      } else {
        this.$emit('select-list', ((this.currentPageBlock - 2) * this.pageListCount + 1))
      }
    },
    goNextPageBlock () {
      if (this.currentPageBlock === this.totalPageBlock) {
        this.$emit('select-list', this.totalPageCount)
      } else {
        this.$emit('select-list', (this.currentPageBlock * this.pageListCount + 1))
      }
    },
    createPagination () {
      this.pageList = []
      var pageCount = this.totalPageCount
      if (pageCount === 0) {
        pageCount = 50
      }
      this.totalPageBlock = Math.ceil(pageCount / this.pageListCount)
      this.currentPageBlock = Math.ceil(this.currentPage / this.pageListCount)
      this.startPageNumber = (Math.ceil(this.currentPage / this.pageListCount) - 1) * this.pageListCount + 1
      this.endPageNumber = ((this.startPageNumber + this.pageListCount - 1) > pageCount) ? pageCount : (this.startPageNumber + this.pageListCount - 1)

      for (let i = this.startPageNumber; i <= this.endPageNumber; i++) {
        this.pageList.push(i)
      }
    }
  },
  props: {
    totalPageCount: Number, // 전체 페이지 수
    currentPage: Number // 현재 페이지
  }
}
</script>

<style>

</style>
