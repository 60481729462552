const methods = {
  /**
   * 네/아니오 버튼 팝업 열기
   * popupHeaderMessage : 팝업 타이틀 부분 메시지
   * popupBodyMessage : 팝업 내용 부분 메시지
   * callbackMethod : 네 버튼 클릭시 실행 될 메소드 ex) this.메소드명
   */
  showConfirmPopup (popupHeaderMessage, popupBodyMessage, callbackMethod, callbackCancelMethod) {
    console.log('in')
    const data = { popupHeaderMessage: popupHeaderMessage, popupBodyMessage: popupBodyMessage, callbackMethod: callbackMethod, callbackCancelMethod: callbackCancelMethod }
    this.$store.commit('Control/setConfirmPopup', data)
    this.$store.commit('Control/controlConfirmPopup')
  },
  /**
   * 메시지 버튼 팝업 열기
   * popupHeaderMessage : 팝업 타이틀 부분 메시지
   * popupBodyMessage : 팝업 내용 부분 메시지
   * link : 확인 버튼 클릭시 이동할 링크 (없을 시 창 닫기)
   */
  showMessagePopup (popupHeaderMessage, popupBodyMessage, link) {
    const data = { popupHeaderMessage: popupHeaderMessage, popupBodyMessage: popupBodyMessage, link: link }
    this.$store.commit('Control/setMessagePopup', data)
    this.$store.commit('Control/controlMessagePopup')
  },
  /**
   * 콜백 메소드 메시지 버튼 팝업 열기
   * popupHeaderMessage : 팝업 타이틀 부분 메시지
   * popupBodyMessage : 팝업 내용 부분 메시지
   * callbackMethod : 확인 버튼 클릭시 실행 될 메소드 ex) this.메소드명
   */
  showCallBackMessagePopup (popupHeaderMessage, popupBodyMessage, callbackMethod) {
    const data = { popupHeaderMessage: popupHeaderMessage, popupBodyMessage: popupBodyMessage, callbackMethod: callbackMethod }
    this.$store.commit('Control/setCallBackMessagePopup', data)
    this.$store.commit('Control/controlCallBackMessagePopup')
  },
  /**
   * A 입력값이 빈값, NULL, undefined 인경우 A 표출
   */
  NVLAB (A, B) {
    if (A === '' || A === null || A === undefined || A === 'NaN') {
      return B
    } else {
      return A
    }
  },
  /**
   * 시간 데이터 시:분:초 형식으로 변환 (데이터 내 소수점은 초단위)
   * ex) 157 => 02:37:00, 102.16 => 01:42:16
   */
  convertTimeStampFormat: (time) => {
    const totalMinutes = parseInt(time)

    let hours = Math.floor(totalMinutes / 60)
    let minutes = Math.floor(totalMinutes - (hours * 60))
    let seconds = parseInt(parseFloat(time % 1).toFixed(2) * 100)

    if (hours < 10) { hours = '0' + hours }
    if (minutes < 10) { minutes = '0' + minutes }
    if (seconds < 10) { seconds = '0' + seconds }

    return hours + ':' + minutes + ':' + seconds
  }
}

export default {
  install (app) {
    app.config.globalProperties.$showConfirmPopup1 = methods.showConfirmPopup
    app.config.globalProperties.$showMessagePopup1 = methods.showMessagePopup
    app.config.globalProperties.$showCallBackMessagePopup1 = methods.showCallBackMessagePopup
    app.config.globalProperties.$NVLAB1 = methods.NVLAB
    app.config.globalProperties.$convertTimeStampFormat1 = methods.convertTimeStampFormat
  }
}
