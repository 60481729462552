<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <div class="popup_box popup_box_m">
        <div class="popup_top popup_top_line"><h2>트랭 소멸예정 안내</h2>
        </div>
        <div class="popup_body">
          <p>{{trangExpireDate}} 소멸예정인 트랭이 있습니다.<br>
          내 지갑에서 확인해 주십시오.<br><br>
          보유하신 트랭을 잊지말고 사용해 주시기 바랍니다.</p>
          <br><br>
          <div class="terms_check">
            <input type="checkbox" id="c1" v-model="notOpen" value="Y"><label for="c1">일주일간 보지 않기</label>
          </div>
          <div class="btn_area">
            <button type="button" class="" @click="this.close(`close`)">닫기</button>
            <button type="button" class="" @click="this.close(`trang`)">트랭확인</button>
          </div>
        </div>
      </div>
    </div>
  </div><!--/popup-->
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'ExpireTrangPopup',
  data () {
    return {
      notOpen: ''
    }
  },
  props: {
    trangExpireDate: String
  },
  methods: {
    close (type) {
      if (this.notOpen) {
        let date = dayjs()
        date = date.add(7, 'day').format('YYYY-MM-DD')
        this.$store.state.Common.notOpenTrangExpirePopupDate = date
      }
      if (type === 'close') {
        this.$router.push('/mytranggle/myinfo')
      } else if (type === 'trang') {
        this.$router.push('/mytranggle/mytranwallet')
      }
    }
  }
}

</script>

<style>
</style>
