<template>
  <div class="wrap" :class="pageClass()">
    <router-view></router-view>
    <!-- <DormantPopup v-if="isOpenDormantPopup" /> -->
    <CommonPopup />
  </div>
  <Spinner :loading="this.$store.state.Common.loadingStatus"/>
  <DormantPopup v-if="isOpenDormantPopup" />
</template>

<script>
import CommonPopup from '@/components/common/CommonPopup.vue'
import Spinner from '@/components/common/Spinner.vue'
import DormantPopup from '@/components/member/popup/DormantPopup.vue'

// import axios from 'axios'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'App',
  components: {
    CommonPopup,
    Spinner,
    DormantPopup
  },
  computed: {
    ...mapState('UserSet', [
      'isOpenDormantPopup'
    ])
  },
  mounted () {
    this.loadGetToken()
  },
  methods: {
    ...mapActions('UserSet', [
      'loadGetToken'
    ]),
    pageClass () {
      let className = ''
      if (this.$route.path.includes('policy')) {
        className = 'policy'
      } else if (this.$route.path.includes('mytranggle')) {
        className = 'mypage'
      } else if (this.$route.path.includes('member')) {
        className = 'member'
      } else if (this.$route.path.includes('login')) {
        className = 'login'
      } else if (this.$route.path.includes('course')) {
        className = 'course'
      }
      return className
    }
  }
}
</script>

<style>
  @import './assets/common/css/tranggle.css';
  @import './assets/common/css/sports.css';
  @import './assets/common/css/publish.css';
</style>
