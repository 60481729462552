// import router from '../../router'
import axios from '../../util/axios.js'

export default {
  namespaced: true,
  state: {
    pageName: '',
    home: [],
    qna: [],
    track: [],
    community: [],
    mission: [],
    trackNo: '',
    totalCount: 0,
    noticeList: [],
    searchType: '',
    searchValue: '',
    page: 1,
    count: 15,
    policyData: {}
  },
  mutations: {
    setNoticeList (state, data) {
      if (data.data.response.code === '00') {
        state.totalCount = data.data.response.content[0].total_count
        state.noticeList = data.data.response.content
      }
    },
    setNoticeClear (state) {
      state.totalCount = 0
      state.noticeList = []
      state.searchType = ''
      state.searchValue = ''
      state.page = 1
      state.count = 15
    },
    setPolicyData (state, data) {
      state.policyData = data
    }
  },
  actions: {
    getNoticeClear ({ state, commit }) {
      commit('setNoticeClear')
    },
    getNoticeList ({ state, commit }) {
      axios.get('/FaqV2/getNoticeList.json', {
        params: {
          token: this.state.Member.token,
          search_type: state.searchType,
          search_value: state.searchValue,
          page: state.page,
          count: state.count
        }
      })
        .then(data => {
          if (data.data.response.code === '00') {
            commit('setNoticeList', data)
          }
        })
    },
    // 통합 약관 API
    loadPolicyData ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('https://api.tranggle.com/v2/policy/data.json', fd)
        .then(data => {
          if (data.data.response.code === '00') {
            commit('setPolicyData', data.data.response.content)
          }
        })
    }
  },
  modules: {
  },
  getters: {

  }
}
