<template >
    <div class="popup popup_bg">
        <div class="popup_area">
            <div class="popup_box popup_box_m">
                <div class="popup_top popup_top_line"><h2>메시지 보내기</h2>
                </div>
                <div class="popup_body">
                    <p class="to">To. {{this.$store.state.Popup.sendId}}</p>
                    <textarea placeholder="메시지를 입력하세요.(최대 800자)" v-model = messagetext maxlength='800' oninput="numberMaxLength(this)"></textarea>
                    <div class="btn_area">
                        <button type="button" class="" @click="close">취소</button>
                        <button type="button" class="on" @click="sendMessage">전송</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      messagetext: ''
    }
  },
  components: {
  },
  name: 'MessagePopup',
  methods: {
    close () {
      this.$store.state.Popup.isOpenMessageSendPopup = false
    },
    sendMessage () {
      this.axios.get('/message/send.json', {
        params: {
          token: this.$store.state.Member.token,
          receive_id: this.$store.state.Popup.sendId,
          message: this.messagetext
        }
      })
        .then(data => {
          this.$store.state.Popup.isOpenMessageSendPopup = false
          this.$showMessagePopup('메시지 전송 완료', '메시지가 전송되었습니다.')
        })
    },
    numberMaxLength (e) {
      if (e.value.length > e.maxLength) {
        e.value = e.value.slice(0, e.maxLength)
      }
    }
  }
}
</script>
