<template>
  <div>
    <ol-map
      ref="mapOSM"
      :loadTilesWhileAnimating="true"
      :loadTilesWhileInteracting="true"
      style="height: calc(100vh - 62px);">
      <ol-view
        ref="view"
        :center="center"
        :rotation="rotation"
        :zoom="zoom"
        :projection="projection" />
      <ol-tile-layer>
        <ol-source-osm />
      </ol-tile-layer>
      <ol-vector-layer>
        <ol-source-vector ref="source">
          <ol-feature>
            <ol-geom-line-string :coordinates="trackPointOSM"></ol-geom-line-string>
            <ol-style>
              <ol-style-stroke :color="strokeColor" :width="strokeWidth"></ol-style-stroke>
            </ol-style>
          </ol-feature>
          <!-- start -->
          <ol-feature>
            <ol-geom-point :coordinates="trackPointOSM[0]"></ol-geom-point>
            <ol-style>
              <ol-style-icon
                :src="require('@/assets/images/course/map_pin_start.png')"
                :scale="0.5"
                :anchor="[0.5, 1]"
              ></ol-style-icon>
            </ol-style>
          </ol-feature>
          <!-- end -->
          <ol-feature>
            <ol-geom-point :coordinates="trackPointOSM[trackPointOSM.length - 1]"></ol-geom-point>
            <ol-style>
              <ol-style-icon
                :src="require('@/assets/images/course/map_pin_goal.png')"
                :scale="0.5"
                :anchor="[0.5, 1]"
              ></ol-style-icon>
            </ol-style>
          </ol-feature>
        </ol-source-vector>
      </ol-vector-layer>
    </ol-map>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ref } from 'vue'
export default {
  data () {
    return {
    }
  },
  props: {
    trackNo: String,
    menu: String,
    searchStatus: Boolean,
    searchKeyword: String,
    cSearch: String
  },
  name: 'CourseMapOSM',
  components: {
  },
  setup () {
    const mapOSM = ref()
    const view = ref()
    const source = ref(null)
    const center = ref([117.24875, 40.45064])
    const projection = ref('EPSG:4326')
    const zoom = ref(17)
    const rotation = ref(0)
    const radius = ref(40)
    const strokeWidth = ref(6)
    const strokeColor = ref('#FF7920')

    return {
      center,
      projection,
      zoom,
      rotation,
      radius,
      strokeWidth,
      strokeColor,
      view,
      mapOSM,
      source
    }
  },
  computed: {
    ...mapState('Course', ['isOpenLaps', 'currentLapsTab', 'courseInfo', 'trackPointOSM'])
  },
  watch: {
    trackPointOSM () {
      // 트랙 변경시 다시 fitbounds
      this.$nextTick(() => {
        this.trackFit()
      })
    }
  },
  methods: {
    trackFit () {
      this.view && this.view.fit(this.source.source.getExtent(), {
        duration: 10,
        padding: [50, 50, 50, 50]
      })
    }
  },
  mounted () {
    // fitbounds
    this.trackFit()
  },
  unmounted () {
  },
  created () {
  }
}
</script>

<style>

</style>
