<template>
    <div id="naver_map" style="width: 100%; height: 100%"/>
</template>

<script>
// let mapInstance = null
// const BadgeList = []
// const BadgeInfoWindowList = []
// let InfoWindowList = []
export default {
  data () {
    return {

    }
  },
  methods: {
    // 지도 생성
    initMap (x, y, zoom) {
      // console.log(document.getElementById('naver_map'))
      // console.log(this.$store.state.MyTranggle.badgeMapArray)
      // console.log(x)
      if (x === null || x === undefined) {
        y = 35.9615
        x = 127.6066
      }
      this.$store.state.MyTranggle.mapInstance = new window.naver.maps.Map('naver_map', {
        center: new window.naver.maps.LatLng(y, x),
        zoom: this.$NVLAB(zoom, 10),
        enableWheelZoom: true,
        enableDragPan: true,
        enableDblClickZoom: false,
        mapMode: 0,
        activateTrafficMap: false,
        activateBicycleMap: false,
        minZoom: 7,
        maxZoom: 16,
        zoomControl: true,
        zoomControlOptions: {
          position: window.naver.maps.Position.TOP_RIGHT,
          style: window.naver.maps.ZoomControlStyle.SMALL
        }
      })

      window.naver.maps.Event.addListener(this.$store.state.MyTranggle.mapInstance, 'click', (e) => {
        console.log(this.$store.state.MyTranggle.mapInstance.zoom)
        // console.log(e)
        // console.log(e.latlng.x)
        // console.log(e.latlng.y)

        this.marker(e, this.$store.state.MyTranggle.mapInstance.zoom)

        // this.$store.state.MyTranggle.marker = new window.naver.maps.Marker({
        //   position: new window.naver.maps.LatLng(e.latlng.y, e.latlng.x),
        //   map: this.$store.state.MyTranggle.mapInstance.map
        // })

        // if (this.courseBook == null) {
        //   this.clearCourseBookMarker()
        // } else {
        //   console.log(this.courseBook.course_track_start_x)
        //   if (this.courseBook.course_track_start_x !== null) {
        //     mapInstance.setCenter(new window.naver.maps.LatLng(this.courseBook.course_track_start_y, this.courseBook.course_track_start_x))
        //   }
        // }
      })
    },
    marker (e, zoom) {
      console.log(e)
      this.$store.state.MyTranggle.marker = null
      this.$store.state.MyTranggle.mapInstance = null
      this.initMap(e.latlng.x, e.latlng.y, zoom)
      console.log(e)
      this.$store.state.MyTranggle.marker = new window.naver.maps.Marker({
        map: this.$store.state.MyTranggle.mapInstance,
        position: new window.naver.maps.LatLng(e.latlng.y, e.latlng.x)
      })

      this.$store.state.MyTranggle.configLat = e.latlng._lat
      this.$store.state.MyTranggle.configLon = e.latlng._lng
    }
  },
  mounted () {
    this.initMap()
  }
}
</script>

<style>

</style>
