<template>
  <AttachCourseList v-show="this.$store.state.Popup.isVisibleAttachCourseList"/>
  <AttachCourseDetail v-if="this.$store.state.Popup.isVisibleAttachCourseDetail"/>
</template>

<script>
import AttachCourseList from './AttachCourseList.vue'
import AttachCourseDetail from './AttachCourseDetail.vue'
export default {
  data () {
    return {
    }
  },
  components: {
    AttachCourseList: AttachCourseList,
    AttachCourseDetail: AttachCourseDetail
  },
  name: 'AttachCoursePopup'
}
</script>

<style>

</style>
