<template>
  <!-- 계정 -->
  <div class="right_body mt">
    <div class="setting_area">
      <ul>
        <li>
          <span class="set_text">회원번호</span>
          <span class="set_input">
            <input type="text" :value="memberInfo.serial_no" readonly />
          </span>
        </li>
        <li>
          <span class="set_text">비밀번호 변경</span>
          <!-- setPop1-->
          <span class="set_input">
            <button class="btn_set btn_gray" @click="confChgIt">변경</button>
          </span>
        </li>
        <li>
          <span class="set_text">회원탈퇴</span>
          <span class="set_input">
            <button class="btn_set btn_gray" @click="confMemberWith">
              탈퇴신청
            </button>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState("Member", ["memberInfo"]),
  },
  methods: {
    ...mapMutations("MyTranggle", [
      "confChgIt",
      "confMemberWith",
      "mapMutations",
    ]),
  },
};
</script>
