import axios from '../../util/axios.js'

export default {
  namespaced: true,
  state: {
    isShowCourseDetail: false,
    selectedCourseInfo: {},
    trackNo: '',
    sidoName: '전국',
    sigunguName: '',
    getRegionLoading: false,
    dataIndex: {
      registCourse: null,
      registBadge: null,
      exerciseDistance: null,
      exerciseTime: null
    },
    startTime: '',
    endTime: '',
    selectedTrackCategory: '',
    courseList: [],
    locationCourseList: [],
    leftDownX: '',
    leftDownY: '',
    rightUpX: '',
    rightUpY: '',
    isFoldLeftMenu: false,

    selectedCourseIndex: ''
  },
  mutations: {
    setCourseList (state, data) {
      state.courseList = []
      state.courseList = data
    },
    setLocationCourseList (state, data) {
      state.locationCourseList = []
      state.locationCourseList = data
    },
    selectedCourse (state, data) {
      console.log('hello')
      state.isShowCourseDetail = false
      state.selectedCourseInfo = data
      state.isShowCourseDetail = true
    },
    closeCourseDetail (state) {
      state.isShowCourseDetail = false
      state.selectedCourseInfo = {}
    }
  },
  actions: {
    async getCourseListByLocation ({ state, commit }, data) {
      const params = {
        category: state.selectedTrackCategory,
        start_time: state.startTime,
        end_time: state.endTime,
        left_down_x: state.leftDownX,
        left_down_y: state.leftDownY,
        right_up_x: state.rightUpX,
        right_up_y: state.rightUpY
      }

      await axios.get('/trangle_now/get_course_list_by_location.json', {
        params: params
      })
        .then(data => {
          const responseCode = data.data.response.code

          if (responseCode === '00') {
            if (data.data.response.content.length > 0) {
              commit('setLocationCourseList', data.data.response.content)
            } else {
              state.locationCourseList = []
            }
          }
        })
    }
  },
  modules: {
  },
  getters: {
  }
}
