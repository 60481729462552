<template>
  <div class="popup login_bg find_box find m_pop">
    <div class="popup_area">
      <div class="popup_box popup_box_m">
        <div class="popup_top">
          <h2 class="head1">아이디 찾기</h2>
        </div>
        <div class="popup_body login_body">
          <SubTab ref="subtab" :sub-tab-info="subTabInfo" />
          <div class="login_area">
            <ul>
              <li>
                <span class="set_text">이름</span>
                <span class="set_input1 input_l">
                  <input type="text" v-model="name" maxlength="15" @keypress.enter="findId" placeholder="이름을 입력해 주세요" />
                </span>
              </li>
              <SelfAuthentication v-if="subTabInfo[0].active" @completeAuth="completeAuth" @chkDomestic="chkDomestic"/>
              <EmailAuthentication v-else @completeEmailAuth="completeEmailAuth"/>
            </ul>
          </div>
          <div class="footer_bottom">
            <MobileHint :type="subTabInfo[0].active ? 'mobile' : 'email'" />
            <div class="btn_area btn_bottom2">
              <button type="button" class="btn_f" :class="{'disabled': !validationChk()}" @click="findId">아이디 찾기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div><!--/popup-->
  <div v-if="showResult" class="popup popup_bg id_list">
    <div class="popup_area">
      <div class="popup_box popup_box_s sports_pop">
        <div class="popup_top popup_top_line"><h2>아이디 찾기</h2></div>
        <div v-if="this.findIdList.length > 1" class="popup_body login_body">
          <p>입력하신 정보로 {{this.findIdList.length}}개의 계정이<br>검색되었습니다.</p>
          <div v-for="(member, i) in findIdList" :key="i" class="login_account">
            <template v-if="member.member_naver !== ''">
              네이버 아이디로 가입하신 계정입니다.<br>가입일&nbsp;&nbsp;{{member.join_datetime.split(' ')[0]}}
              <a href="#" class="login_naver" @click.prevent="snsLogin('naver')"><span class="i_login_naver"></span>로그인</a>
            </template>
            <template v-else-if="member.member_kakaotalk !== ''">
              카카오 아이디로 가입하신 계정입니다.<br>가입일&nbsp;&nbsp;{{member.join_datetime.split(' ')[0]}}
              <a href="#" class="login_kakao" @click.prevent="snsLogin('kakao')"><span class="i_login_kakao"></span>로그인</a>
            </template>
            <template v-else-if="member.member_facebook_id !== ''">
              페이스북 아이디로 가입하신 계정입니다.<br>가입일&nbsp;&nbsp;{{member.join_datetime.split(' ')[0]}}
              <a href="#" class="login_facebook" @click.prevent="snsLogin('facebook')"><span class="i_login_facebook"></span>로그인</a>
            </template>
            <template v-else>
              아이디&nbsp;&nbsp;<span class="text_org">{{member.id}}</span><br>가입일&nbsp;&nbsp;{{member.join_datetime.split(' ')[0]}}
            </template>
          </div>
          <div class="btn_area">
            <button type="button" @click.prevent="completeFind">확인</button>
          </div>
        </div>
        <div v-else class="popup_body find_id">
          <template v-if="findIdList[0].member_naver !== ''">
            <p>네이버 아이디로 가입하신 계정입니다.<br>
            가입일&nbsp;&nbsp;{{findIdList[0].join_datetime.split(' ')[0]}}</p>
            <div class="btn_area">
              <button type="button" @click="completeFind">확인</button>
              <button type="button" class="login_naver" @click.prevent="snsLogin('naver')"><span class="i_login_naver"></span>로그인</button>
            </div>
          </template>
          <template v-else-if="findIdList[0].member_kakaotalk !== ''">
            <p>카카오 아이디로 가입하신 계정입니다.<br>
            가입일&nbsp;&nbsp;{{findIdList[0].join_datetime.split(' ')[0]}}</p>
            <div class="btn_area">
              <button type="button" @click="completeFind">확인</button>
              <button type="button" class="login_kakao" @click.prevent="snsLogin('kakao')"><span class="i_login_kakao"></span>로그인</button>
            </div>
          </template>
          <template v-else-if="findIdList[0].member_facebook_id !== ''">
            <p>페이스북 아이디로 가입하신 계정입니다.<br>
            가입일&nbsp;&nbsp;{{findIdList[0].join_datetime.split(' ')[0]}}</p>
            <div class="btn_area">
              <button type="button" @click="completeFind">확인</button>
              <button type="button" class="login_facebook" @click.prevent="snsLogin('facebook')"><span class="i_login_facebook"></span>로그인</button>
            </div>
          </template>
          <template v-else>
            <p>아이디&nbsp;&nbsp;<span class="text_org">{{findIdList[0].id}}</span><br>
            가입일&nbsp;&nbsp;{{findIdList[0].join_datetime.split(' ')[0]}}</p>
            <div class="btn_area">
              <button type="button" class="btn_m" @click="completeFind">확인</button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SelfAuthentication from '@/components/member/SelfAuthentication.vue'
import EmailAuthentication from '@/components/member/EmailAuthentication.vue'
import SubTab from '@/components/common/SubTab.vue'
import MobileHint from '@/components/common/MobileHint.vue'
export default {
  name: 'FindId',
  data () {
    return {
      subTabInfo: [{
        name: '휴대전화로 찾기',
        active: true
      },
      {
        name: '이메일로 찾기',
        active: false
      }],
      name: '',
      phone: '',
      email: '',
      showResult: false,
      validateAuth: '',
      isDomestic: 'domestic'
    }
  },
  components: {
    SelfAuthentication,
    EmailAuthentication,
    SubTab,
    MobileHint
  },
  computed: {
    ...mapState('Member', [
      'findIdList',
      'validateSmsCode'
    ])
  },
  watch: {
    findIdList () {
      this.showResult = true
    },
    // 탭 변경 감지
    subTabInfo: {
      handler () {
        this.name = ''
      },
      deep: true
    }
  },
  mounted () {

  },
  methods: {
    ...mapActions('Member', [
      'loadFindId'
    ]),
    findId () {
      const value = this.subTabInfo[0].active ? this.phone : this.email
      const typeKr = this.subTabInfo[0].active ? '휴대전화를' : '이메일을'

      if (this.name === '') {
        this.$showMessagePopup('아이디 찾기', '이름을 입력하세요')
        return false
      }
      if (this.validateAuth !== 'Y') {
        this.$showMessagePopup('아이디 찾기', `${typeKr} 인증하세요`)
        return false
      }
      this.loadFindId({
        check: this.subTabInfo[0].active ? 'MOBILE' : 'EMAIL',
        member_name: this.name,
        value: value
      })
    },
    completeFind () {
      window.location.href = '/login'
    },
    checkPhone () {
      const regType = /\d/
      if (this.phone === '' || !regType.test(this.phone)) {
        this.$showMessagePopup('아이디 찾기', '휴대폰번호를 정확하게 입력해주세요.')
        this.phone = ''
        return false
      } else {
        return true
      }
    },
    checkEmail () {
      const regType = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
      if (this.email === '' || !regType.test(this.email)) {
        this.$showMessagePopup('아이디 찾기', '이메일을 정확하게 입력해주세요.')
        this.email = ''
        return false
      } else {
        return true
      }
    },
    snsLogin (type) {
      if (type === 'naver') {
        window.location.href = 'https://www.tranggle.com/v2/Naver_login/login'
      } else if (type === 'kakao') {
        window.location.href = 'https://www.tranggle.com//v2/kakao/set_login'
      } else if (type === 'facebook') {
        window.location.href = 'https://www.tranggle.com/v2/Facebook/login'
      }
    },
    completeAuth (phone, email) {
      this.phone = phone
      this.email = email
      this.validateAuth = 'Y'
    },
    completeEmailAuth (email) {
      this.email = email
      this.validateAuth = 'Y'
    },
    chkDomestic (val) {
      this.isDomestic = val
    },
    validationChk () {
      return this.name && this.validateAuth === 'Y'
    }
  }
}
</script>
