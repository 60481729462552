<template>
  <div class="popup popup_bg dormant">
    <div class="popup_area dormant_popup">
      <div class="popup_box popup_box_lm sports_pop">
        <div class="popup_top"><h2 class="head1">회원님의 계정은 휴면계정 상태입니다.</h2></div>
        <div class="popup_body">
          <ul>
            <li class="t_head">휴면계정 상태에서는 트랭글의 모든 서비스 이용이 불가능하며, 광고성 정보 전송 등 모든 통지 대상에서 제외됩니다.</li>
            <li class="t_head mt">휴면계정이란?<br />개인정보보호법 제39조의6 및 동법 시행령 제48조의 5법령에 관련하여 1년 이상 트랭글 서비스를 이용하지 않은 회원님들의 개인정보는 휴면계정으로 분리 저장, 관리되는 것을 말합니다.</li>
          </ul>
          <div class="table_type1">
            <table>
              <colgroup>
                <col width="40%"/>
                <col width="60%"/>
              </colgroup>
              <tr>
                <th>휴면계정 전환일</th>
                <td v-if="dormanInfo.dormantDate">{{ dormanInfo.dormantDate.split(' ')[0].replaceAll('-', '.') }}</td>
              </tr>
            </table>
          </div>
          <p class="mt">본 계정으로 계속해서 트랭글GPS 서비스를 이용하기를 원하시면<br />아래 [휴면상태 해제] 버튼을 클릭하세요.</p>
      </div>
      <div class="btn_area btn_bottom2">
        <button type="button" class="btn_f" @click="dormantClose()">이전</button>
        <button type="button" class="btn_f on" @click="dormantAuth()">휴면상태 해제</button>
      </div>
      </div>
    </div>
    <DormantAuthPopup v-if="dormantAuthBool" />
  </div><!--/popup-->
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DormantAuthPopup from '@/components/member/popup/DormantAuthPopup.vue'
export default {
  name: 'DormantPopup',
  props: {
    lastLoginDate: String
    // dormantDate: String
  },
  components: {
    DormantAuthPopup
  },
  computed: {
    ...mapState('UserSet', [
      'dormanInfo'
    ])
  },
  watch: {
    $route (to, from) {
      if (this.$store.state.UserSet.isOpenDormantPopup) {
        this.dormantClose()
      }
    }
  },
  data () {
    return {
      dormantAuthBool: false
    }
  },
  mounted () {
    document.body.classList.add('overhidden')
  },
  unmounted () {
    document.body.classList.remove('overhidden')
  },
  methods: {
    ...mapActions('UserSet', [
      'loadLogout'
    ]),
    dormantAuth () {
      this.dormantAuthBool = true
    },
    dormantClose () {
      this.$store.state.UserSet.isOpenDormantPopup = false
      this.loadLogout()
    }
  }
}
</script>
