<template>
  <div>
    <div class="fixed">
      <div class="header_wrap">
        <TopMenu currentMenu="Policy" />
      </div>
    </div>
    <div class="container">
      <div class="home">
        <div class="section">
          <div class="sub_page">
            <div class="sub_left">
              <div class="fixed">
                <div class="sub_menu">
                  <span class="title">정책 및 약관</span>
                  <ul>
                    <li :class="{ on: this.$store.state.Support.pageName === 'Terms' }">
                      <router-link to="/policy/terms">서비스 이용약관</router-link>
                    </li>
                    <li :class="{ on: this.$store.state.Support.pageName === 'PersonalInformation' }">
                      <router-link to="/policy/personalinformation">개인정보처리방침</router-link>
                    </li>
                    <li :class="{ on: this.$store.state.Support.pageName === 'TranggleTalk' }">
                      <router-link to="/policy/tranggletalk">트랭글톡 이용약관</router-link>
                    </li>
                    <li :class="{ on: this.$store.state.Support.pageName === 'GeoData' }">
                      <router-link to="/policy/geodata">위치기반 서비스 이용약관</router-link>
                    </li>
                    <li :class="{ on: this.$store.state.Support.pageName === 'TrangInfo' }">
                      <router-link to="/policy/tranginfo">트랭 이용약관</router-link>
                    </li>
                    <li :class="{ on: this.$store.state.Support.pageName === 'TermMission' }">
                      <router-link to="/policy/termmission">미션 이용약관</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <ul class="list-terms">
                <!-- [D] tab 활성화시  terms-tab class에 active class 추가-->
                <li class="terms-tab" :class="{ active: this.$store.state.Support.pageName === 'Terms' }">
                  <!-- [D] tab 활성화시  terms-tab class에 active class 추가-->
                  <router-link to="/policy/terms" class="terms-tab-link">서비스 이용약관</router-link>
                </li>
                <li class="terms-tab" :class="{ active: this.$store.state.Support.pageName === 'PersonalInformation' }">
                  <router-link to="/policy/personalinformation" class="terms-tab-link">개인정보처리방침</router-link>
                </li>
                <li class="terms-tab" :class="{ active: this.$store.state.Support.pageName === 'TranggleTalk' }">
                  <router-link to="/policy/tranggletalk" class="terms-tab-link">트랭글톡 이용약관</router-link>
                </li>
                <li class="terms-tab" :class="{ active: this.$store.state.Support.pageName === 'GeoData' }">
                  <router-link to="/policy/geodata" class="terms-tab-link">위치기반 서비스 이용약관</router-link>
                </li>
                <li class="terms-tab" :class="{ active: this.$store.state.Support.pageName === 'TrangInfo' }">
                  <router-link to="/policy/tranginfo" class="terms-tab-link">트랭 이용약관</router-link>
                </li>
                <li class="terms-tab" :class="{ active: this.$store.state.Support.pageName === 'TermMission' }">
                  <router-link to="/policy/termmission" class="terms-tab-link">트랭 이용약관</router-link>
                </li>
              </ul>
            </div>
            <router-view></router-view>
          </div>
        </div><!--/section-->
      </div>
      <footers ref="footers" />
    </div><!--/container-->
  </div>
  <!-- <CoursePopup /> -->
</template>

<script>

import TopMenu from '../components/common/TopMenu.vue'
import footers from '../components/support/footer.vue'
// import CoursePopup from '../components/course/popup/CoursePopup.vue'

export default {
  computed: {
  },
  methods: {
    // eslint-disable-next-line space-before-function-paren
    goUrl(href) {
      this.$store.state.Common.courseMenuType = ''
      this.$router.push(href)
    }
    // checkUrl (menuType) {
    //   let checkUrl = true
    //   if (this.$route.path.indexOf('/course/' + this.$route.params.id) > -1) {
    //     if (this.$store.state.Common.courseMenuType !== menuType) {
    //       checkUrl = false
    //     }
    //   } else {
    //     if (this.$route.path === '/course/my/gpx' && menuType === 'my') {
    //       checkUrl = true
    //     } else if (this.$route.path.indexOf('/course') > -1 && menuType !== '') {
    //       checkUrl = false
    //     }
    //   }
    //   return checkUrl
    // }
  },
  watch: {
    // $route (to, from) {
    //   if (to.path !== from.path) {
    //     this.$store.commit('Course/clearSearchOption')
    //     // this.$store.state.Course.isOpenCompareTrackPopup = false
    //   }
    // }
  },
  components: {
    TopMenu: TopMenu,
    footers: footers
    // CoursePopup: CoursePopup
  }

}
</script>

<style></style>
