<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <div class="popup_box popup_box_s">
        <div class="popup_top popup_top_line" v-if="popupHeaderMessage !== ''">
          <h2 v-html="popupHeaderMessage"></h2>
        </div>
        <div class="popup_body">
          <p v-html="popupBodyMessage"></p>
          <div class="btn_area">
            <button type="button" @click="confirm">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      popupHeaderMessage: this.$store.state.Popup.popupHeaderMessage,
      popupBodyMessage: this.$store.state.Popup.popupBodyMessage,
      callbackMethod: this.$store.state.Popup.callbackMethod
    }
  },
  components: {
  },
  methods: {
    confirm () {
      this.$store.commit('Popup/controlCallBackMessagePopup')
      this.callbackMethod()
    }
  },
  name: 'CommonCallBackMessagePopup'
}
</script>

<style>

</style>
