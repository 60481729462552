import axios from 'axios'
import router from '../../router/index'
export default {
  namespaced: true,
  state: {
    popBg: false,
    popArea: false,
    ActivityMainPopup: false,
    ActivityChoceCompPopup: false,
    ActivityClubCompPopup: false,
    ActivityFriendCompPopup: false,
    ActCompMain: true,
    ActNewPort: false,
    ActGrpComp: false,
    ActClubComp: false,
    ActFrdComp: false,
    ActCompReTitle: false,
    ActCompDel: false,

    // left 메뉴 정보
    ActUser: [],
    userJoinDate: '',
    // 차트 데이터 배열
    ActSearch: [],

    // 나의활동 통계
    synthesizeMyActivity: [], // 나의 활동 통계 메인
    ExerciseRecordSelect: 'Number', // 운동기록 선택
    MileageIndex: null,
    MileageMaxCnt: null,
    MileagechoseCnt: null,
    PedometerIndex: null,
    PedometerMaxCnt: null,
    PedometerchoseCnt: null,
    ExerciseRecordIndex: null,
    ExerciseRecordMaxCnt: null,
    ExerciseRecordMaxCntTime: null,
    ExerciseRecordchoseCnt: null,
    ExerciseMainIndicatorList: [], // 대표종목 관련 통계
    exerciseCategory: null,

    // 나의 활동 타입별 배열
    mileagePageArray: [],
    paceCounterDetailData: [],
    trackPageArray: [],
    trackRegion: [],
    badgePageArray: [],

    // 나의 활동 타입별 메인
    MileageIndex2: null,
    MileageMaxCnt2: null,
    MileagechoseCnt2: null,
    StepIndex: null,
    StepMaxCnt: null,
    StepchoseCnt: null,
    TrackIndex: null,
    TrackMaxCnt: null,
    TrackchoseCnt: null,
    BadgeIndex: null,
    BadgeMaxCnt: null,
    BadgechoseCnt: null,

    // 운동기록 지도
    mapInstance: null,
    mainTrankResion: '',
    mainTrankResionCnt: 0,
    mainTrankResionMaxCnt: 0,

    activityCompTarget: '',
    MytrackSelect: '',

    // 나의 활동 지역 팝업
    userAddr1: '',
    userAddr2: '',
    addrNon: false,
    addrNonText: '',

    // 비교통계
    portfolioList: [],
    userSelectList: [],
    createChk: false,
    createPortNo: null,
    portfolioNo: '',
    portfolioType: '',
    portfoliotitle: '',
    portfolioTitle: '',
    portfolioRemove: '',
    clubNo: '',

    // 비교통계 상세
    detailPage: false,
    portfolioData: [],
    detailActivitySelectType: 'count',
    detailMileageIndex: 0,
    detailActivityIndex: 0,
    mileageAmass: 0,
    mileageSearchSum: 0,
    mileageTop10Sum: 0,
    selectMileage: 0,
    activityAmass: 0,
    activitySearchSum: 0,
    activityTop10Sum: 0,
    selectactivity: 0,

    badgeIndex: 0,
    badgeSelectCnt: 0,
    badgeAvgCnt: 0,
    elevateIndex: 0,
    elevateSelectCnt: 0,
    elevateAvgCnt: 0

  },
  mutations: {
    // 나의 활동 사용 메소드
    ActMainPopup (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.ActivityMainPopup = !state.ActivityMainPopup
    },
    ActGrpCompPopup (state) {
      state.activityCompTarget = 'group'
      state.portfolioType = 'GROUP'
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.ActivityChoceCompPopup = !state.ActivityChoceCompPopup
    },
    ActClubCompPopup (state) {
      state.activityCompTarget = 'club'
      state.portfolioType = 'CLUB'
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.ActivityClubCompPopup = !state.ActivityClubCompPopup
    },
    ActFriendCompPopup (state) {
      state.activityCompTarget = 'friend'
      state.portfolioType = 'USER'
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.ActivityFriendCompPopup = !state.ActivityFriendCompPopup
    },
    ActNewPortfolio (state) {
      console.log('portfolioNo : ' + state.portfolioNo)
      state.ActCompMain = !state.ActCompMain
      state.ActNewPort = !state.ActNewPort
    },
    ActGrpCompView (state) { // Comp 초기 화면에서 비교 그룹 대상군 선택시 화면 변경
      state.ActCompMain = !state.ActCompMain
      state.ActGrpComp = !state.ActGrpComp
    },
    ActClubCompView (state) {
      state.ActCompMain = !state.ActCompMain
      state.ActClubComp = !state.ActClubComp
    },
    ActFriendCompView (state) {
      state.ActCompMain = !state.ActCompMain
      state.ActFrdComp = !state.ActFrdComp
    },
    ActGrpSetToView (state) { // 비교 그룹 대상 팝업에서 설정 후 완료 버튼 누르면 작동
      state.popBg = false
      state.popArea = false
      if (state.activityCompTarget === 'group') {
        state.ActivityChoceCompPopup = false
        state.ActNewPort = false
        state.ActCompMain = true
      } else if (state.activityCompTarget === 'club') {
        state.ActivityClubCompPopup = false
        state.ActNewPort = false
        state.ActCompMain = true
      } else if (state.activityCompTarget === 'friend') {
        state.ActivityFriendCompPopup = false
        state.ActNewPort = false
        state.ActCompMain = true
      }
      if (state.createChk === true)router.push('/activity/ActivityCompDetail/' + state.createPortNo)
      state.createPortNo = null
    },
    // 비교통계
    ActGetPortpolio (state, data) {
      state.portfolioList = data
    },
    ActClubSetToView (state) {
      state.popBg = false
      state.popArea = false
      state.ActivityClubCompPopup = false
      state.ActNewPort = false
      state.ActClubComp = true
    },
    ActFrdSetToView (state) {
      state.popBg = false
      state.popArea = false
      state.ActivityFriendCompPopup = false
      state.ActNewPort = false
      state.ActFrdComp = true
    },
    ActCompReTitleM (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.ActCompReTitle = !state.ActCompReTitle
    },
    ActCompDelM (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.ActCompDel = !state.ActCompDel
    },
    closeCreatePort (state) {
      state.createPortNo = null
      this.state.Common.selectArea = []
      this.state.Common.activityAge = []
      this.state.Common.activityGender = []
      this.state.Common.searchTrackCategory = []
      this.state.Common.activityFrequency = []
      this.state.Common.activityTime = []
      this.state.Common.activityDistance = []
      this.state.Common.activitySolidity = []
      this.state.Common.activityPurpose = []
      this.state.Activity.userSelectList = []
      this.state.Activity.clubNo = ''
      this.state.Activity.addrNon = false
      this.state.Activity.addrNonText = ''
      this.state.Activity.userAddr1 = ''
      this.state.Activity.userAddr2 = ''
    }
  },
  actions: {
    // 나의활동 지역 정보 입력
    setMyInfo ({ state, commit }) {
      // console.log(this.state.Member.token)
      // console.log(state.userAddr1)
      // console.log(state.userAddr2)
      const formData = new FormData()
      formData.append('token', this.state.Member.token)
      formData.append('region', state.userAddr1)
      formData.append('region2', state.userAddr2)
      axios.post('/stats/set_my_info.json', formData)
        .then(data => {
          // console.log(data)
          commit('ActMainPopup')
          // this.$showMessagePopup('', data.data.response.message)
          // commit('ActMainPopup')
        })
    },
    getMyPortfolio ({ state, commit, dispatch }) {
      var array = []
      axios.get('/stats/get_my_portfolio.json', {
        params: {
          token: this.state.Member.token
          // token: '69202CC15E60313F5ED6BC8870C139DE6089135888506F2BFFECC13D24001D9213EDC2AA63FA7401'
        }
      })
        .then(data => {
          for (var i = 0; i < 5; i++) {
            array.push(data.data.response.content[i])
          }
          console.log(state.createPortNo)
          // console.log(data.data.response.content[state.createPortNo].portfolio_no)
          if (state.createPortNo !== null) {
            console.log('filter IN')
            state.createPortNo = data.data.response.content[state.createPortNo].portfolio_no
            if (state.portfolioType === 'GROUP')dispatch('setMyPortfolioFilter')
            if (state.portfolioType === 'USER')dispatch('setMyPortfolioFriendFilter')
          }
          console.log(state.createPortNo)
          //   this.portfolioList = data.data.response.content

          commit('ActGetPortpolio', array)

          if (state.portfolioType === 'CLUB' || state.portfolioType === 'USER' || state.portfolioType === 'GROUP') {
            commit('ActGrpSetToView')
          }
        })
    },
    setMyPortfolio ({ state, commit, dispatch }) {
      // console.log(this.revelation) portfolioNo
      this.state.Common.loadingStatus = true
      var no = ''
      if (state.createChk !== true) {
        no = state.portfolioNo
      }
      console.log(state.portfolioNo)
      console.log(no)
      const formData = new FormData()
      formData.append('token', this.state.Member.token)
      formData.append('title', state.portfolioTitle) // 최대 20글자
      formData.append('type', state.portfolioType) // DEFAULT,GROUP,CLUB,USER
      formData.append('portfolio_no', no) // 포트폴리오 번호
      if (state.portfolioType === 'CLUB')formData.append('club_no', state.clubNo)
      formData.append('remove', state.portfolioRemove) // 삭제여부 (Y/N)

      axios.post('/stats/set_my_portfolio.json', formData)
        .then(data => {
          console.log(data.data.response)
          // if (state.remove === 'N') this.ActCompReTitleM() // 수정 팝업 닫기
          // if (state.remove === 'Y') this.ActCompDelM() // 삭제 팝업 닫기
          // this.getMyPortfolio() // 포트폴리오 리스트 갱신
          // this.$showMessagePopup('', data.data.response.message)
          // commit('getMyPortfolio')
          console.log(state.createChk)
          if (state.createChk === true) {
            console.log('신규')
            dispatch('getMyPortfolio')
          }
        })
    },
    setMyPortfolioFilter ({ state, commit }) { // 그룹 필터
      var area = ''
      var age = ''
      var gender = ''
      var category = ''
      var frequency = ''
      var time = ''
      var distance = ''
      var portfolioNo = ''
      // 신규생성시에는 포트 번호를 get으로 부터 받아오기 때문에 if로 확인, 포트폴리오 상세 페이지에서의 조회는 포트 넘버를 가지고 있기때문에 저장된 포트폴리오 넘버를 사용
      console.log(state.createPortNo)
      if (state.createPortNo !== null) {
        portfolioNo = state.createPortNo
        console.log('신규 : ' + state.createPortNo)
      } else {
        portfolioNo = state.portfolioNo
        console.log('기존 : ' + state.portfolioNo)
      }
      console.log(this.state.Common.selectArea)
      for (const i in this.state.Common.selectArea) {
        if (i === '0') {
          if (this.state.Common.selectArea[i] === 'ALL') {
            area = ''
          } else {
            area = this.state.Common.selectArea[i].replace('/', ' ')
          }
        } else {
          area = area + '|' + this.state.Common.selectArea[i].replace('/', ' ')
        }
      }
      for (const i in this.state.Common.activityAge) {
        if (i === '0') {
          if (this.state.Common.activityAge[i] === 'ALL') {
            age = ''
          } else {
            age = this.state.Common.activityAge[i]
          }
        } else {
          age = age + '|' + this.state.Common.activityAge[i]
        }
      }
      for (const i in this.state.Common.activityGender) {
        if (i === '0') {
          if (this.state.Common.activityGender[i] === 'ALL') {
            gender = ''
          } else {
            gender = this.state.Common.activityGender[i]
          }
        } else {
          gender = gender + '|' + this.state.Common.activityGender[i]
        }
      }
      for (const i in this.state.Common.searchTrackCategory) {
        if (i === '0') {
          if (this.state.Common.searchTrackCategory[i] === 'ALL') {
            category = ''
          } else {
            category = this.state.Common.searchTrackCategory[i]
          }
        } else {
          category = category + '|' + this.state.Common.searchTrackCategory[i]
        }
      }
      for (const i in this.state.Common.activityFrequency) {
        if (i === '0') {
          if (this.state.Common.activityFrequency[i] === 'ALL') {
            frequency = ''
          } else {
            frequency = this.state.Common.activityFrequency[i]
          }
        } else {
          frequency = frequency + '|' + this.state.Common.activityFrequency[i]
        }
      }
      for (const i in this.state.Common.activityTime) {
        if (i === '0') {
          if (this.state.Common.activityTime[i] === 'ALL') {
            time = ''
          } else {
            time = this.state.Common.activityTime[i]
          }
        } else {
          time = time + '|' + this.state.Common.activityTime[i]
        }
      }
      for (const i in this.state.Common.activityDistance) {
        if (i === '0') {
          if (this.state.Common.activityDistance[i] === 'ALL') {
            distance = ''
          } else {
            distance = this.state.Common.activityDistance[i]
          }
        } else {
          distance = distance + '|' + this.state.Common.activityDistance[i]
        }
      }
      console.log(this.state.Member.token)
      console.log(portfolioNo)
      console.log(area)
      console.log(age)
      console.log(gender)
      console.log(category)
      console.log(frequency)
      console.log(time)
      console.log(distance)
      const formData = new FormData()
      formData.append('token', this.state.Member.token)
      formData.append('portfolio_no', portfolioNo) // 신규 포트폴리오 번호
      formData.append('region', area)
      formData.append('age', age)
      formData.append('gender', gender)
      formData.append('category', category)
      formData.append('frequency', frequency)
      formData.append('time', time)
      formData.append('distance', distance)

      axios.post('/stats/set_my_portfolio_filter.json', formData)
        .then(data => {
          console.log(data.data.response)
          commit('closeCreatePort') // 신규 생성후 분기처리때문에 해당 변수를 초기화 진행
        })
    },
    setMyPortfolioFriendFilter ({ state, commit }) { // 유저 필터
      var friend = ''
      var portfolioNo = ''
      // 신규생성시에는 포트 번호를 get으로 부터 받아오기 때문에 if로 확인, 포트폴리오 상세 페이지에서의 조회는 포트 넘버를 가지고 있기때문에 저장된 포트폴리오 넘버를 사용
      if (state.createPortNo !== null) {
        portfolioNo = state.createPortNo
        console.log('신규' + state.createPortNo)
      } else {
        portfolioNo = state.portfolioNo
        console.log('기존' + state.portfolioNo)
      }

      for (const i in state.userSelectList) {
        if (i === '0') {
          friend = state.userSelectList[i].id
        } else {
          friend = friend + '|' + state.userSelectList[i].id
        }
      }
      console.log(this.state.Member.token)
      console.log(portfolioNo)
      console.log(friend)
      const formData = new FormData()
      formData.append('token', this.state.Member.token)
      formData.append('portfolio_no', portfolioNo) // 신규 포트폴리오 번호
      formData.append('friend', friend)

      axios.post('/stats/set_my_portfolio_friend_filter.json', formData)
        .then(data => {
          console.log(data.data.response)
          commit('closeCreatePort') // 신규 생성후 분기처리때문에 해당 변수를 초기화 진행
        })
    }
  },
  modules: {
  },
  getters: {

  }
}
