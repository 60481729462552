<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <div class="popup_box popup_box_s">
        <div class="popup_top popup_top_line"><h2>공유하기<a href="#" class="btn_close" @click="cancel"></a></h2></div>
        <div class="share_link">
            <a href="#">
                <span class="i_msg_l" @click="messageBox"></span>
                <span class="text">메시지</span>
            </a>
            <a href="#" :class="{on:urlTap===true}" @click="urlTap = !urlTap">
                <span class="i_copy_l"></span>
                <span class="text">URL</span>
            </a>
        </div>
        <div class="url_copy" v-if ="urlTap">
            <a href="#" @click="copyUrl">
                <span class="sns_link" ></span>
                <span class="text">링크복사</span>
            </a>
            <a href="#" class="kakao" @click="kakaoLink">
                <span class="sns_kakao"></span>
                <span class="text">카카오톡</span>
            </a>
            <a href= "#" @click="kakaoStoryLink">
                <span class="sns_kakaos"></span>
                <span class="text">카카오스토리</span>
            </a>
            <a href="#" @click="bandLink">
                <span class="sns_bank"></span>
                <span class="text">밴드</span>
            </a>
            <a href="#" @click="faceBookLink">
                <span class="sns_facebook"></span>
                <span class="text">페이스북</span>
            </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // head () {
  //   return {
  //     script: [
  //       { src: '//developers.kakao.com/sdk/js/kakao.min.js' }
  //     ]
  //   }
  // },
  data () {
    return {
      callbackCancelMethod: this.$store.state.Popup.loginCallbackCancelMethod,
      storyUrl: 'https://story.kakao.com/share?url=' + location.href,
      urlTap: false
    }
  },
  components: {
  },
  name: 'CommonShare',
  methods: {
    cancel () {
      this.$store.commit('Popup/controlSharePopup')
      // this.$router.go()
    },
    confirm (url) {
      this.$router.push(url)
      this.$store.commit('Popup/controlSharePopup')
    },
    copyUrl () {
      this.$store.commit('Popup/controlSharePopup')
      const t = document.createElement('textarea')
      document.body.appendChild(t)
      t.value = location.href
      t.select()
      document.execCommand('copy')
      document.body.removeChild(t)
      this.$showMessagePopup('URL복사 완료', '링크(URL) 주소가 복사되었습니다. <br>입력창에 Ctrl+V를 눌러보세요.')
    },
    faceBookLink () {
      this.shareCountAdd()
      this.$store.commit('Popup/controlSharePopup')
      window.open('http://www.facebook.com/sharer.php?u=' + location.href)
    },
    kakaoStoryLink () {
      this.shareCountAdd()
      this.$store.commit('Popup/controlSharePopup')
      window.open('https://story.kakao.com/share?url=' + location.href)
    },
    bandLink () {
      this.shareCountAdd()
      this.$store.commit('Popup/controlSharePopup')
      window.open('http://band.us/plugin/share?body=' + location.href)
    },
    messageBox () {
      this.shareCountAdd()
      this.$store.commit('Popup/controlSharePopup')
      this.$store.state.Popup.isOpenShareMessageSendPopup = true
    },
    kakaoLink: function () {
      this.shareCountAdd()
      window.Kakao.init('d3d3b4e0e2bd4def2fafe9d9cc44d952')
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: '',
          description: location.href,
          imageUrl: '',
          link: {
            mobileWebUrl: 'http://localhost:8080',
            webUrl: 'http://localhost:8080'
          }
        },
        buttons: [
          {
            title: '웹으로 보기',
            link: {
              mobileWebUrl: 'http://localhost:8080',
              webUrl: 'http://localhost:8080'
            }
          }
        ]
      })
      this.$store.commit('Popup/controlSharePopup')
    },
    shareCountAdd () {
      console.log(this.$store.state.Popup.sharePostNo)
      if (this.$store.state.Popup.sharePageChk === 'story') {
        console.log('kaka')
        this.axios.get('/story/add_share_count.json', {
          params: {
            token: this.$store.state.Member.token,
            post_no: this.$store.state.Popup.sharePostNo
          }
        })
          .then(data => {
            const responseCode = data.data.response.code
            if (responseCode !== '00') {

            }
          })
      }
      if (this.$store.state.Popup.sharePageChk === 'course') {
        this.axios.get('/course/add_share_count.json', {
          params: {
            token: this.$store.state.Member.token,
            track_year: this.$store.state.Popup.shereYear,
            track_no: this.$store.state.Popup.sharePostNo
          }
        })
          .then(data => {
            const responseCode = data.data.response.code
            if (responseCode !== '00') {

            }
          })
      }
      if (this.$store.state.Popup.sharePageChk === 'coursebook') {
        this.axios.get('/coursebook/set_share_count.json', {
          params: {
            token: this.$store.state.Member.token,
            user_course_no: this.$store.state.Popup.shereYear,
            course_no: this.$store.state.Popup.sharePostNo
          }
        })
          .then(data => {
            const responseCode = data.data.response.code
            if (responseCode !== '00') {

            }
          })
      }
      this.$store.state.Popup.sharePageChk = ''
      this.$store.state.Popup.shereYear = ''
      this.$store.state.Popup.sharePostNo = ''
    }

  }
}
</script>

<style>

</style>
