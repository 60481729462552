<template>
  <div class="popup login_bg terms_popup sign_in">
    <div class="popup_area">
      <div class="popup_box popup_box_m">
        <div class="popup_top">
          <h2 class="head1">TRANGGLE 가입 약관동의</h2>
          <!-- <button type="button" class="btn_close" @click="$router.go(-1)"></button> -->
        </div>
        <div class="popup_body login_body">
          <div class="terms_check line mt">
            <input type="checkbox" v-model="allSelected" id="all" @click="selectAll()"><label for="all">약관 전체 동의</label>
          </div>
          <div class="check_list">
            <ul class="card_box list">
              <li v-for="(item, i) in termsList" :key="i">
                <div class="checkbox">
                  <input :id="item.id" v-model="selected" :value="item.id" type="checkbox">
                  <label :for="item.id">
                    <span :class="item.require ? 'default' : 'option' ">{{ item.require ? '필수' : '선택' }}</span>
                    {{ item.title }}
                  </label>
                </div>
                <button v-if="item.id !== 'termAge'" type="button" @click="select(item.id, item.title)"></button>
              </li>
            </ul>
          </div>
          <div class="footer_bottom pos_static">
            <div class="summary_box">
              <p>인증번호는 카카오톡으로 전송되며, 카카오톡 미설치시 문자로 전송됩니다.</p>
              <p>전체동의는 필수 및 선택정보에 대한 동의도 포함되어 있으며, 개별적으로도 동의를 선택하실 수 있습니다. 선택항목에 대한 동의를 거부하는 경우에도 서비스 이용이 가능합니다.</p>
              <p>‘화살표 버튼'을 누르면 관련 내용을 확인할 수 있습니다.</p>
            </div>
            <div class="btn_area btn_bottom2">
              <button type="button" class="btn_f" :disabled="!agreeBool" @click="agree()">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="popupType !== ''" class="popup popup_bg popup_join_terms popup_terms_service">
    <div class="popup_area">
      <div class="popup_box popup_box_m">
        <div class="popup_top popup_top_line">
          <h2>{{ popupTitle }}</h2>
          <button type="button" class="btn_close" @click="popupType = ''"></button>
        </div>
        <div class="popup_body">
          <div class="popup_terms">
            <!-- 서비스 이용약관 동의 -->
            <TermService v-if="popupType === 'termService'" from="join" />
            <!-- 개인정보 수집 및 이용 동의 -->
            <PersonalCollection v-if="popupType === 'termPrivacy'" from="join" />
            <!-- <TermPrivacy v-if="popupType === 'termPrivacy'" from="join" /> -->
            <!-- 위치정보 수집 및 이용 동의-->
            <TermUseLocation v-if="popupType === 'termLocation'" from="join" />
            <!-- 개인정보 제3자 제공 동의 -->
            <TermProvision v-if="popupType === 'termProvision'" from="join" />
            <!-- 광고성 정보 수신동의 -->
            <TermInfomation v-if="popupType === 'termAd'" from="join" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TermService from '@/components/policy/Terms.vue'
import PersonalCollection from '@/components/policy/PersonalCollection.vue'
import TermProvision from '@/components/policy/TermProvision.vue'
import TermUseLocation from '@/components/policy/GeoData.vue'
import TermInfomation from '@/components/policy/TermInfomation.vue'
export default {
  name: 'JoinTerms',
  components: {
    TermService,
    PersonalCollection,
    TermUseLocation,
    TermInfomation,
    TermProvision
  },
  data () {
    return {
      termsList: [
        {
          id: 'termAge',
          require: true,
          title: '연령(만 14세 이상) 확인'
        },
        {
          id: 'termService',
          require: true,
          title: '서비스 이용약관 동의'
        },
        {
          id: 'termPrivacy',
          require: true,
          title: '개인정보 수집 및 이용 동의'
        },
        {
          id: 'termLocation',
          require: true,
          title: '위치정보 수집 및 이용 동의'
        },
        {
          id: 'termProvision',
          require: false,
          title: '개인정보 제3자 제공 동의'
        },
        {
          id: 'termAd',
          require: false,
          title: '광고성 정보 수신동의'
        }
        // {
        //   id: 'termCustom',
        //   link: '/terms/customAd?type=agree&termId=termCustom',
        //   require: false,
        //   title: '맞춤형 광고 동의'
        // }
      ],
      popupType: '',
      popupTitle: '',
      allSelected: false,
      selected: [],
      agreeBool: false
    }
  },
  watch: {
    selected () {
      if (this.selected.length >= this.termsList.length) {
        this.allSelected = true
      } else {
        this.allSelected = false
      }
      const termEssential = this.termsList.filter((el) => el.require)
      let cnt = 0
      termEssential.forEach((el) => {
        if (this.selected.includes(el.id)) {
          cnt++
        }
      })
      if (cnt === termEssential.length) {
        this.agreeBool = true
      } else {
        this.agreeBool = false
      }
    }
  },
  methods: {
    agree () {
      const termEssential = this.termsList.filter((el) => el.require)
      let title = ''
      for (let i = 0; i < termEssential.length; i++) {
        if (!this.selected.includes(termEssential[i].id)) {
          title = termEssential[i].title
          break
        }
      }
      if (title) {
        // this.$showMessagePopup('약관동의', `${title}에 동의해주세요.`)
        this.$emit('termBool', false)
        return false
      } else {
        this.$emit('termBool', this.selected)
      }
    },
    selectAll () {
      this.allSelected = !this.allSelected
      if (this.allSelected) { // 전체선택일때
        this.selected = []
        this.termsList.forEach((el) => {
          this.$cookies.set(el.id, 'y')
          this.selected.push(el.id)
        })
      } else { // 선택해제
        this.selected = []
        this.termsList.forEach((el) => {
          this.$cookies.remove(el.id)
        })
      }
    },
    select (type, title) {
      this.popupType = type
      this.popupTitle = title
    }
  }
}
</script>
