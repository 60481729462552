<template>
  <div v-if="nowContent" class="mobile_hint">
    <div v-if="$route.fullPath.includes('/member/change/pw')" class="title">
      <ul class="flow">
        <li class="step" :class="{'active': type !== 'resetPwd' }">
          <span>1</span> 본인 인증 확인
        </li>
        <li class="step" :class="{'active': type === 'resetPwd'}">
          <span>2</span> 비밀번호 재설정
        </li>
      </ul>
    </div>
    <div class="box">
      <strong>{{ nowContent.title }}</strong>
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="nowContent.content"></div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'MobileHint',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      domestic: {
        title: '카카오톡 본인인증을 해주세요',
        content: `
          <p>인증번호는 카카오톡으로 전송되며, 카카오톡 미설치시 문자로 전송됩니다.</p>
          <p>전송된 인증번호를 입력하셔야 본인인증이 완료됩니다.</p>
        `
      },
      overseas: {
        title: '본인인증을 해주세요',
        content: `
          <p>인증번호는 문자로 전송됩니다. </p>
          <p>국외의 경우 이메일 찾기를 권장드립니다.</p>
        `
      },
      pwRule: {
        title: '비밀번호 안전규칙',
        content: `
          <p>반드시 8~20자의 영문 대소문자,숫자, 특수문자  3개의 조합 필수</p>
          <p>생년월일, 휴대전화와 동일한 번호는 사용 자제</p>
          <p>특수문자는 ~! @ # $ % ^ ( ) _ - = { [ } ] ' ; : &lt; &gt; , . ? / ) 사용가능</p>
        `
      },
      emailHint: {
        title: '이메일 본인인증을 해주세요',
        content: `
          <p>인증번호는 이메일로 전송됩니다. </p>
          <p>전송된 인증번호를 입력하셔야 본인인증이 완료됩니다.</p>
        `
      },
      nowContent: null
    }
  },
  computed: {
    ...mapState('Member', ['mobileDomestic'])
  },
  watch: {
    mobileDomestic () {
      if (this.mobileDomestic === 'domestic') {
        this.nowContent = this.domestic
      } else {
        this.nowContent = this.overseas
      }
    },
    type () {
      if (this.type === 'email') {
        this.nowContent = this.emailHint
      }
      if (this.type === 'mobile') {
        this.nowContent = this.domestic
      }
    }
  },
  unmounted () {
    this.$store.state.Member.mobileDomestic = 'domestic'
  },
  mounted () {
    if (this.type === 'resetPwd') {
      this.nowContent = this.pwRule
      return false
    }
    if (this.type === 'email') {
      this.nowContent = this.emailHint
      return false
    }
    if (this.mobileDomestic === 'domestic') {
      this.nowContent = this.domestic
    } else {
      this.nowContent = this.overseas
    }
  }
}
</script>
