<template>
  <li class="input_wrap mobile_input">
    <span class="set_text">휴대전화</span>
    <span class="gc">
      <ul class="m_identity_radio">
        <li class="radio_btn">
          <input
            id="mInternal"
            v-model="isDomesctic"
            type="radio"
            name="mIdentity"
            value="domestic"
            :checked="isDomesctic === 'domestic'"
            :disabled="vals.mobile === 'Y' || validateEmailCode === 'Y'"
          >
          <label for="mInternal">국내</label>
        </li>
        <li class="radio_btn">
          <input
            id="mExternal"
            v-model="isDomesctic"
            type="radio"
            name="mIdentity"
            value="overseas"
            :checked="isDomesctic === 'overseas'"
            :disabled="vals.mobile === 'Y' || validateEmailCode === 'Y'"
          >
          <label for="mExternal">국외</label>
        </li>
      </ul>
      <select v-if="isDomesctic === 'overseas'" name="cty_cd" id="cty_cd" v-model="ctyCd" @change="selectedCountry" :disabled="validateEmailCode === 'Y'" class="style1 select_l mb">
        <option value="">국가 선택</option>
        <option v-for="(list, i) in countryList" :key="i" :value="list.value">{{ list.name }}</option>
        <!-- <option :value="`email`">목록에 없음</option> -->
      </select>
      <span class="set_input1 input_l input_flex">
        <div class="request_input">
          <input
            v-model="phone"
            type="text"
            maxlength="15"
            class="input_l"
            placeholder="-없이 입력해 주세요"
            :disabled="vals.mobile === 'Y' || validateEmailCode === 'Y'"
            @keydown.enter.prevent="sendSms"
          />
        </div>
        <div class="request_wrap">
          <button
            class="request"
            :class="[{'line': isSendSms}, {'disabled': !phone || /[^0-9]/g.test(phone)}]"
            :disabled="validateEmailCode === 'Y' || vals.mobile === 'Y'"
            @click.prevent="sendSms"
          >
            {{ isSendSms ? '재요청' : '인증요청' }}
          </button>
        </div>
      </span>
      <span v-if="validateEmailCode === 'Y'" class="stext">이메일 인증되었습니다.</span>
      <div v-if="isSendSms" class="certify_wrap">
        <div class="input_flex">
          <span class="set_input1 input_l request_input">
            <div>
              <input
                v-model="smsCode"
                type="text"
                class="input_l"
                maxlength="6"
                placeholder="인증번호 6자리"
                :disabled="vals.mobile === 'Y'"
                @input="checkMaxLength"
              />
              <span v-if="isSendSms" class="confirm_time">{{ timeText }}</span>
            </div>
          </span>
          <div class="request_wrap">
            <button class="request" :class="{'disabled': !smsCode }" :disabled="vals.mobile === 'Y' || timeText === '00:00'" @click.prevent="certifySms">확인</button>
          </div>
        </div>
        <span v-if="vals.mobile !== ''" :class="[{'check_ok': vals.mobile === 'Y'}]"></span>
        <!-- <span v-if="vals.mobile !== ''" :class="[{'check_ok': vals.mobile === 'Y'}, {'check_no': vals.mobile === 'N'}]"></span> -->
      </div>
      <span v-if="vals.mobile === 'Y'" class="stext text_mint">인증되었습니다.</span>
      <span v-if="vals.mobile === 'N'" class="stext text_red">{{ vals.mobileMsg }}</span>
    </span>
    <EmailAuthPopup v-if="isOpenEmailPopup" @completeEmailAuth="completeEmailAuth" @closeEmailAuth="cancelEmailAuth" />
  </li>
</template>
<script>
// import country from '@/assets/common/data/country.js'
import { mapState, mapActions, mapMutations } from 'vuex'
import EmailAuthPopup from '@/components/member/popup/EmailAuthPopup.vue'
// let timer = null
export default {
  name: 'SelfAuthentication',
  props: {
    useType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // countryList: country.list,
      isDomesctic: 'domestic',
      phone: '',
      smsCode: '',
      email: '',
      ctyCd: '',
      validateEmailCode: '',
      validateAuth: '',
      isSendSms: false,
      isOpenEmailPopup: false,
      certifyEmailTime: 0,
      timer: null,
      timeText: '',
      vals: {
        mobile: '',
        mobileMsg: ''
      },
      findIdPop: false
    }
  },
  emits: ['completeAuth'],
  components: {
    EmailAuthPopup
  },
  computed: {
    ...mapState('Member', [
      'smsAuthBool',
      'smsAuthMessage',
      'smsCertifyBool',
      'smsCertifyMessage',
      'countryList'
    ])
  },
  watch: {
    smsAuthBool () {
      if (this.smsAuthBool === 'Y') {
        this.$showMessagePopup('인증번호 발송', '인증번호가 발송되었습니다.')
        this.isSendSms = true
        this.smsCode = ''
        this.vals.mobile = ''
        this.authTimer(5 * 60)
      }
      if (this.smsAuthBool === 'N') {
        this.vals.mobile = 'N'
        this.vals.mobileMsg = this.smsAuthMessage
        // this.$showMessagePopup('인증번호 발송', this.smsAuthMessage)
      }
      this.$store.state.Member.smsAuthBool = ''
    },
    smsCertifyBool () {
      if (this.smsCertifyBool === 'Y') {
        this.vals.mobile = 'Y'
        clearInterval(this.timer)
        this.timeText = ''
        this.completeAuth()
      }
      if (this.smsCertifyBool === 'N') {
        this.vals.mobile = 'N'
        this.vals.mobileMsg = this.smsCertifyMessage
      }
      this.$store.state.Member.smsCertifyBool = ''
    },
    isDomesctic () {
      this.$store.state.Member.mobileDomestic = this.isDomesctic
      this.$emit('chkDomestic', this.isDomesctic)
      this.isSendSms = false
      this.phone = ''
      this.email = ''
      this.vals.mobile = ''
    },
    phone () {
      const reg = /[^0-9]/g
      if (reg.test(this.phone)) {
        this.phone = this.phone.replace(/[^0-9]/g, '')
      }
    },
    smsCode () {
      const reg = /[^0-9]/g
      if (reg.test(this.smsCode)) {
        this.smsCode = this.smsCode.replace(/[^0-9]/g, '')
      }
    },
    contryList () {
      console.log(this.contryList)
    }
  },
  mounted () {
    this.getCountryList()
  },
  methods: {
    ...mapActions('Member', [
      'loadSmsAuth',
      'loadSmsCertify',
      'loadEmailAuth',
      'loadEmailCertify',
      'getCountryList'
    ]),
    ...mapMutations('Member', [
      'setValidateSmsCode'
    ]),
    sendSms () {
      this.smsCode = ''
      this.vals.mobileMsg = ''
      const reg = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/gi
      if (!this.phone) {
        this.vals.mobile = 'N'
        this.vals.mobileMsg = ''
        return false
      }
      if (this.isDomesctic === 'domestic' && !reg.test(this.phone)) {
        this.vals.mobile = 'N'
        this.vals.mobileMsg = '휴대폰번호를 정확하게 입력해주세요.'
        this.phone = ''
        return false
      }
      if (this.isDomesctic === 'overseas' && this.ctyCd === '') {
        this.$showMessagePopup('인증번호 발송', '국외의 경우 국가를 선택해주세요.')
        return false
      }
      this.loadSmsAuth({
        phone: this.phone,
        cty_cd: this.isDomesctic === 'domestic' ? '82' : this.ctyCd
      })
    },
    certifySms () {
      const regType = /\d/
      if (this.phone === '' || !regType.test(this.phone) || this.phone.toString().length < 8) {
        this.vals.mobile = 'N'
        this.vals.mobileMsg = '휴대폰번호를 정확하게 입력해주세요.'
        // this.$showMessagePopup('인증번호 확인', '휴대폰번호를 정확하게 입력해주세요.')
        this.phone = ''
      } else if (this.isDomesctic === 'overseas' && this.ctyCd === '') {
        this.$showMessagePopup('인증번호 확인', '국외의 경우 국가를 선택해주세요.')
      } else if (!this.smsCode) {
        this.vals.mobile = 'N'
        this.vals.mobileMsg = ''
      } else if (this.smsCode && !regType.test(this.smsCode)) {
        this.vals.mobile = 'N'
        this.vals.mobileMsg = '인증번호를 정확하게 입력해주세요.'
        this.smsCode = ''
      } else {
        const certifyObj = {
          phone: this.phone,
          sms_code: this.smsCode,
          cty_cd: this.isDomesctic === 'domestic' ? '82' : this.ctyCd
        }
        if (this.useType === 'join') {
          Object.assign(certifyObj, { use_type: 'Y' })
        }
        this.$store.state.Member.smsCertifyCode = ''
        this.loadSmsCertify(certifyObj)
      }
    },
    checkMaxLength (e) {
      if (e.target.value.length > e.target.maxLength) {
        e.target.value = e.target.value.slice(0, e.target.maxLength)
      }
    },
    selectedCountry () {
      if (this.ctyCd === 'email') {
        this.isOpenEmailPopup = true
      }
    },
    cancelEmailAuth () {
      this.isOpenEmailPopup = false
    },
    completeAuth () {
      this.$emit('completeAuth', this.phone, this.email)
      this.setValidateSmsCode('Y')
    },
    completeEmailAuth (email) {
      this.email = email
      this.cancelEmailAuth()
      this.completeAuth()
      this.validateEmailCode = 'Y'
    },
    authTimer (duration) {
      let timer = duration
      let minutes
      let seconds
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10)
        minutes = minutes < 10 ? `0${minutes}` : minutes
        seconds = seconds < 10 ? `0${seconds}` : seconds
        this.timeText = `${minutes}:${seconds}`
        if (--timer < 0) {
          clearInterval(this.timer)
          this.vals.mobile = 'N'
          this.vals.mobileMsg = '인증 시간이 만료되었습니다. 인증번호 재요청을 시도해주세요.'
        }
      }, 1000)
    }
  }
}
</script>
<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
</style>
