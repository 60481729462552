import router from '../../router'
import axios from '../../util/axios.js'

export default {
  namespaced: true,
  state: {

    // Write페이지 Tag
    writetags: [],

    isOpenSearchOptionPopup: false,
    searchKeyword: '',
    selectArea: ['ALL'],
    searchTrackCategory: ['ALL'],
    searchOption: false,
    keywordList: {
      popular: [],
      recent: [],
      recommend: []
    },
    searchOrder: 'POP',
    totalPageCount: 0,
    page: 1,
    viewCount: 20,
    storyList: [],
    totalCount: 0,
    listTab: 'LIST',
    // 스토리 삭제 버튼 팝업 표출 여부
    isOpenDeleteStoryPopup: false,
    deleteStoryPostNo: 0,
    completeSearch: false,
    searchCommitCnt: 0
  },
  mutations: {
    controlSearchOptionPopup (state) {
      state.isOpenSearchOptionPopup = !state.isOpenSearchOptionPopup
      state.searchOption = false
    },
    clearSearchOption (state) {
      state.searchOption = false
      state.searchKeyword = ''
      state.selectArea = ['ALL']
      state.searchTrackCategory = ['ALL']
      state.searchOrder = 'POP'
      state.page = 1
    },
    // 검색옵션 버튼 제어
    controlSearchPopup (state) {
      state.isOpenSearchPopup = !state.isOpenSearchPopup
    },
    // 검색옵션 완료 버튼
    controlSearchCommit (state) {
      state.isOpenSearchPopup = !state.isOpenSearchPopup
    },
    // 회원 설정하기 팝업 제어
    controlUpdateMemberInfo (state) {
      state.isOpenUpdateMemberInfo = !state.isOpenUpdateMemberInfo
    },
    // 스토리 삭제 버튼 제어
    controlDeleteStoryPopup (state) {
      state.isOpenDeleteStoryPopup = !state.isOpenDeleteStoryPopup
    },
    // 회원 설정하기 팝업 제어
    setSearchKeyword (state, data) {
      state.searchKeyword = data
      console.log(state.searchKeyword)
    },
    completeSearchOption (state) {
      state.isOpenSearchOptionPopup = !state.isOpenSearchOptionPopup
      if (!state.searchTrackCategory.includes('ALL') ||
        !state.selectArea.includes('ALL')) {
        state.searchOption = true
      } else {
        state.searchOption = false
      }
      state.searchCommitCnt += 1
    }
  },
  actions: {
    selectStoryList ({ state, getters }) {
      state.completeSearch = false
      const params = {
        token: this.state.Member.token,
        page: state.page,
        view_count: state.viewCount,
        search_order: state.searchOrder,
        search_value: state.searchKeyword
      }

      if (state.searchOption) {
        const searchOption = getters.getSearchOption
        Object.assign(params, searchOption)
      }

      axios.get('/story/get_story_list.json', {
        params: params
      })
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode === '00') {
            state.storyList = data.data.response.content.list
            state.totalCount = Number(data.data.response.content.total_count)
            state.completeSearch = true
            console.log(state.storyList)
          }
        })
    },
    searchStory ({ state, dispatch, commit }, data) {
      console.log(data)
      commit('clearSearchOption')
      commit('setSearchKeyword', data)
      router.replace(`/story/list?searchVal=${state.searchKeyword}&page=1`)
    },
    viewStoryDetail ({ dispatch, commit }, data) {
      router.push('/story/' + data)
    }
  },
  modules: {
  },
  getters: {
    getSearchOption (state) {
      const searchOption = {}

      // 지역
      if (!state.selectArea.includes('ALL')) {
        Object.assign(searchOption, { region: state.selectArea.join('|') })
      }

      // 운동 종목
      if (!state.searchTrackCategory.includes('ALL')) {
        Object.assign(searchOption, { category: state.searchTrackCategory.join('|') })
      }

      return searchOption
    }
  }
}
