<template>
    <div id="compare_track_map" style="width: 100%; height: 100%">
    </div>
</template>

<script>
let mapInstance = null
let coursePolyLinePointList = []
let courseBadgeList = []
let courseBadgeInfoWindowList = []
const coursePolyLineList = []

const defaultPolyLineStyle = [
  {
    strokeColor: '#FD5E99',
    strokeWeight: 8,
    strokeOpacity: 1,
    strokeStyle: 'solid'
  },
  {
    strokeColor: '#6DB8F2',
    strokeWeight: 8,
    strokeOpacity: 1,
    strokeStyle: 'solid'
  }
]

export default {
  data () {
    return {
      badgeIdx: '',
      badge: {}
    }
  },
  props: {
    trackNo: String
  },
  name: 'CompareTrackMap',
  components: {
  },
  computed: {
    checkCompareTrackBadge () {
      return this.$store.state.Course.compareTrackBadge
    }
  },
  watch: {
    checkCompareTrackBadge (compareTrackBadge) {
      this.compareTrackBadge = compareTrackBadge
      this.createBadge()
    }
  },
  methods: {
    createCourseLine (trackNo, index) {
      this.axios.get('https://api.tranggle.com/v2/map2/get_track_point_v2.json', {
        params: {
          track_key: trackNo
        }
      })
        .then(data => {
          const responseCode = data.data.response.code

          if (responseCode === '00') {
            const trackPointArray = data.data.response.content.point

            coursePolyLinePointList = trackPointArray[0].map((trackPoint) => {
              return window.naver.maps.LatLng(trackPoint.lat, trackPoint.lon)
            })

            coursePolyLineList.push(new window.naver.maps.Polyline(defaultPolyLineStyle[index]))
            coursePolyLineList[index].setPath(coursePolyLinePointList)

            coursePolyLineList[index].setMap(mapInstance)
            coursePolyLineList[index].setVisible(true)

            if (index === 0) {
              mapInstance.setCenter(coursePolyLinePointList[0])
            }

            const startMarker = new window.naver.maps.Marker({
              position: coursePolyLinePointList[0],
              icon: this.$getMapPinMarkerIcon('map_pin', (index + 1)),
              zIndex: 100
            })
            startMarker.setMap(mapInstance)

            mapInstance.setZoom(16)
          }
        })
    },
    createBadge () {
      this.clearCourseBadge()
      const badge = this.$store.state.Course.compareTrackBadge
      if (Object.keys(badge).length > 1) {
        console.log(badge)
        const badgeMarker = new window.naver.maps.Marker({
          map: mapInstance,
          position: new window.naver.maps.LatLng(Number(badge.info_org_lat), Number(badge.info_org_lon)),
          icon: {
            url: this.$photoEmpty(badge.badge_image_url),
            size: new window.naver.maps.Size(30, 30),
            scaledSize: new window.naver.maps.Size(30, 30),
            origin: new window.naver.maps.Size(0, 0),
            anchor: new window.naver.maps.Point(15, 30)
          },
          zIndex: 100
        })

        const contentString = [
          '<div class="info_window">',
          '  <a href="#none" class="btn_close" onclick="closeInfoWindow()"></a>',
          '  <div class="badge_info">',
          '    <span class="badge_img"><img src="' + this.$photoEmpty(badge.badge_image_url) + '"></span>',
          '    <div class="poi_content">',
          '      <span class="subject"><span class="icon_exc"><span class="i_exc_01"></span></span><span class="text elp">' + badge.info_badge_name + '(' + badge.info_badge_code + ')</span></span>',
          '      <span class="sub_text">' + badge.info_badge_message + '</span>',
          '    </div>',
          '    <div class="info_box line_bottom">',
          '      <ul>',
          '        <li><span class="title">배지 주인</span><span class="text">' + badge.info_register_id + '</span></li>',
          '        <li><span class="title">보유자수</span><span class="text">' + badge.info_count + '명</span></li>',
          '      </ul>',
          '    </div>',
          '    <div class="info_box">',
          '      <h4>나의 배지 획득 기록</h4>',
          '      <ul>',
          '        <li><span class="title">배지순위</span><span class="text">-</span></li>',
          '        <li><span class="title">최초획득</span><span class="text">-</span></li>',
          '        <li><span class="title">방문횟수</span><span class="text">-</span></li>',
          '      </ul>',
          '    </div>',
          '    <div class="info_btn">',
          '      <a href="/mytranggle/badge/' + badge.info_badge_id + '" target="_blank" title="새창 열림">배지 상세 정보 바로가기</a>',
          '    </div>',
          '  </div>',
          '</div>'].join('')

        const badgeInfoWindow = new window.naver.maps.InfoWindow({
          content: contentString,
          borderWidth: 0,
          backgroundColor: null
        })

        courseBadgeInfoWindowList.push(badgeInfoWindow)

        courseBadgeList.push(badgeMarker)
        mapInstance.setCenter(badgeMarker.getPosition())
        badgeInfoWindow.open(mapInstance, badgeMarker)

        window.naver.maps.Event.addListener(badgeMarker, 'click', () => {
          badgeMarker.setZIndex(101)
          badgeInfoWindow.open(mapInstance, badgeMarker)
        })
      }
    },
    clearCourseBadge () {
      courseBadgeList.forEach((badge, index, array) => {
        badge.setMap(null)
      })
      courseBadgeInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })
      courseBadgeList = []
      courseBadgeInfoWindowList = []
    },
    closeInfoWindow () {
      courseBadgeInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })
      return false
    }
  },
  mounted () {
    mapInstance = new window.naver.maps.Map('compare_track_map', {
      center: new window.naver.maps.LatLng(35.9615, 127.6066),
      zoom: 10,
      enableWheelZoom: true,
      enableDragPan: true,
      enableDblClickZoom: false,
      mapMode: 0,
      activateTrafficMap: false,
      activateBicycleMap: false,
      minZoom: 7,
      maxZoom: 21,
      zoomControl: true,
      zoomControlOptions: {
        position: window.naver.maps.Position.TOP_RIGHT,
        style: window.naver.maps.ZoomControlStyle.SMALL
      }
    })

    this.createCourseLine(this.$store.state.Course.compareTrack1.year + this.$store.state.Course.compareTrack1.no, 0)
    this.createCourseLine(this.$store.state.Course.compareTrack2.year + this.$store.state.Course.compareTrack2.no, 1)
  },
  created () {
    window.closeInfoWindow = this.closeInfoWindow
  }
}
</script>

<style>

</style>
