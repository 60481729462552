<template>
  <li class="input_wrap mobile_input">
    <span class="set_text">이메일</span>
    <span class="gc">
      <span class="set_input1 input_l mb">
        <div>
          <input
            v-model="email"
            type="text"
            class="input_l email"
            placeholder="이메일 주소"
            :disabled="vals.auth === 'Y'"
            @change="valEamil"
            @blur="valEamil"
            @keydown.enter.prevent="sendEmail"
          />
          <span class="at">@</span>
        </div>
      </span>
      <div class="input_flex">
        <div class="request_input">
          <select v-model="emailSelect" class="style1 select_l mb">
            <option value="">선택하세요</option>
            <option value="gmail.com">gmail.com</option>
            <option value="naver.com">naver.com</option>
            <option value="daum.net">daum.net</option>
            <option value="nate.com">nate.com</option>
            <option value="direct">직접입력</option>
          </select>
        </div>
        <div class="request_wrap">
          <button class="request" :class="[{'line': isSendEmail}, {'disabled': (!email || !emailSelect) || (emailSelect === 'direct' && !emailDirect )}]" :disabled="vals.auth === 'Y'" @click.prevent="sendEmail">
            {{ isSendEmail ? '재요청' : '인증요청' }}
          </button>
        </div>
      </div>
      <span v-if="emailSelect === 'direct'" class="set_input1 input_l mb">
        <input v-model="emailDirect" type="text" placeholder="" class="input_l" />
      </span>
      <span v-if="isSendEmail" class="set_input1 input_l mb">
        <div class="input_flex">
          <div class="request_input">
            <input
              v-model="emailCode"
              type="text"
              class="input_l"
              maxlength="6"
              placeholder="인증번호 6자리"
              :disabled="vals.auth === 'Y'"
              @input="checkMaxLength"
            />
            <span v-if="isSendEmail" class="confirm_time">{{ timeText }}</span>
            <span v-if="vals.auth !== ''" :class="[{'check_ok': vals.auth === 'Y'}]"></span>
            <span v-if="vals.auth === 'Y'" class="stext text_mint">인증되었습니다.</span>
          </div>
          <div class="request_wrap">
            <button :disabled="vals.auth === 'Y'|| timeText === '00:00'" class="request on" :class="{'disabled': !emailCode}" @click.prevent="certifyEmail">확인</button>
          </div>
        </div>
      </span>
      <span v-if="vals.auth === 'N'" class="stext text_red mt0"> {{ vals.authMsg }}</span>
    </span>
  </li>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      email: '',
      emailSelect: '',
      emailDirect: '',
      fullEmail: '',
      emailCode: '',
      vals: {
        email: '',
        emailMsg: '',
        auth: '',
        authMsg: ''
      },
      isSendEmail: false,
      timer: null,
      timeText: ''
    }
  },
  computed: {
    ...mapState('Member', [
      'emailAuthBool',
      'emailAuthMessage',
      'emailCertifyBool',
      'emailCertifyMessage'
    ])
  },
  watch: {
    emailAuthBool () {
      if (this.emailAuthBool === 'Y') {
        this.$showMessagePopup('이메일 인증', '입력하신 이메일 주소로 인증번호가 전송 되었습니다.')
        this.isSendEmail = true
        this.emailCode = ''
        this.authTimer(5 * 60)
      }
      if (this.emailAuthBool === 'N') {
        this.vals.auth = 'N'
        this.vals.authMsg = this.emailAuthMessage
      }
      this.$store.state.Member.emailAuthBool = ''
    },
    emailCertifyBool () {
      if (this.emailCertifyBool === 'Y') {
        this.vals.auth = 'Y'
        clearInterval(this.timer)
        this.timeText = ''
        this.$emit('completeEmailAuth', this.fullEmail)
      }
      if (this.emailCertifyBool === 'N') {
        this.vals.auth = 'N'
        this.vals.authMsg = this.emailCertifyMessage
      }
      this.$store.state.Member.emailCertifyBool = ''
    },
    emailCode () {
      const reg = /[^0-9]/g
      if (reg.test(this.emailCode)) {
        this.emailCode = this.emailCode.replace(/[^0-9]/g, '')
      }
    }
  },
  methods: {
    ...mapActions('Member', [
      'loadEmailAuth',
      'loadEmailCertify'
    ]),
    authTimer (duration) {
      let timer = duration
      let minutes
      let seconds
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10)
        minutes = minutes < 10 ? `0${minutes}` : minutes
        seconds = seconds < 10 ? `0${seconds}` : seconds
        this.timeText = `${minutes}:${seconds}`
        if (--timer < 0) {
          clearInterval(this.timer)
          // this.isSendEmail = false
          this.vals.auth = 'N'
          this.vals.authMsg = '인증 시간이 만료되었습니다. 인증번호 재요청을 시도해주세요.'
        }
      }, 1000)
    },
    valEamil () {
      if (!this.email) {
        this.vals.email = 'N'
        this.vals.emailMsg = ''
        return false
      }
      if ((!this.emailSelect && !this.emailDirect) || (this.emailSelect === 'direct' && !this.emailDirect)) { // 조건 확인 다시해보기
        this.vals.email = 'N'
        this.vals.emailMsg = ''
        return false
      }
      const regEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/gi
      this.fullEmail = `${this.email}@${this.emailSelect !== 'direct' ? this.emailSelect : this.emailDirect}`
      if (!this.fullEmail) {
        this.vals.email = 'N'
        this.vals.emailMsg = '이메일 주소를 입력하세요.'
        return false
      }
      if (!regEmail.test(this.fullEmail)) {
        // console.log('ifif')
        this.vals.auth = 'N'
        this.vals.authMsg = '이메일 주소 형식이 아닙니다.'
        return false
      }
      this.vals.email = 'Y'
    },
    sendEmail () {
      this.valEamil()
      if (this.vals.email === 'Y') {
        this.loadEmailAuth({
          email: this.fullEmail
        })
      }
    },
    certifyEmail () {
      const regType = /\d/
      if (!this.emailCode) {
        this.vals.auth = 'N'
        this.vals.authMsg = ''
        return false
      }
      if (this.emailCode && !regType.test(this.emailCode)) {
        this.vals.auth = 'N'
        this.vals.authMsg = '인증번호를 정확하게 입력해주세요.'
        return false
      }
      this.loadEmailCertify({
        email: this.fullEmail,
        code: this.emailCode
      })
    }
    // completeEmailAuth () {
    //   if (this.vals.auth === 'Y') {
    //     this.$emit('completeEmailAuth', this.fullEmail)
    //   }
    // }
  }
}
</script>
