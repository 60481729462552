<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <div class="popup_box popup_box_l">
        <div class="popup_top popup_top_line"><h2>{{this.$store.state.Popup.popupTitle}}</h2>
            <span class="top_left"><a href="#" class="btn" @click="this.$store.commit('Popup/controlSearchPopup')">취소</a></span>
            <span class="top_right">
                <a href="#" class="btn_refresh" @click="OptClaer">옵션 초기화</a>
                <a href="#" class="btn_default" @click="commitData()">완료</a>
            </span>
        </div>
        <div class="popup_body">
            <div class="option_area">
                <ul>
                    <li v-if = "this.$store.state.Popup.SearchAddr">
                        <span class="t1">지역</span>
                        <span class="t2">
                            <a href="#" class="btn_all" @click="selectSigunguList(0)" :class="{on:this.$store.state.Common.selectArea.includes('ALL')}">ALL</a>
                            <span class="t2_inner">
                                <span class="option_select" @click="selectCityBar()">지역 선택
                                    <span class="select_city_layer" v-if="selectBar">
                                        <ul class="city">
                                            <li :class="{'on' : sidoChose == sido.id}" v-for = "(sido,i) in sidoList" :key="i" @click.stop="selectSigunguList(sido.id)">{{sido.name}}</li>
                                        </ul>
                                        <ul class="town" @click.stop="selectCityBar()">
                                            <li @click ="choseArea(0)" v-if="this.sidoChose !== 0">전체</li>
                                            <li v-for="(sigungu,i) in selectSigungu" :key="i" @click ="choseArea(sigungu.id)">{{sigungu.name}}</li>
                                        </ul>
                                    </span>
                                </span>
                                <span class="option_select_text" v-if="!this.$store.state.Common.selectArea.includes('ALL')">
                                    <span v-for ="(area,i) in this.$store.state.Common.selectArea" :key=i>{{area.replace(' ', '\/')}}<a class="text_del" @click="deleteArea(i)"></a></span>
                                </span>
                            </span>
                        </span>
                    </li>
                    <li v-if = "this.$store.state.Popup.SearchEvent">
                        <span class="t1">운동 종목</span>
                        <span class="t2">
                            <a href="#" class="btn_all" @click="toggleTrackCategory('ALL')" :class="{on:this.$store.state.Common.searchTrackCategory.includes('ALL')}">ALL</a>
                            <span class="t2_inner">
                                <span
                                    v-for="(name, value) in this.$getTrackCategoryCustom()"
                                    :key="value"
                                    class="op_tag"
                                    :class="{ on: this.$store.state.Common.searchTrackCategory.includes(value) }"
                                    :data-value="value"
                                    @click="toggleTrackCategory(value)">
                                    {{name}}
                                </span>
                            </span>
                        </span>
                    </li>
                    <li v-if = "this.$store.state.Popup.SearchSportDistance">
                        <span class="t1">운동 거리</span>
                        <span class="t2">
                            <a href="#" class="btn_all" @click="toggleDistanceCategory('ALL')" :class="{on:this.$store.state.Common.searchDistanceCategory.includes('ALL')}">ALL</a>
                            <span class="t2_inner">
                                <!-- <span class="op_tag" data-start-distance="5">5km 이내</span>
                                <span class="op_tag">1~10km 이내</span>
                                <span class="op_tag">10~15km 이내</span>
                                <span class="op_tag">15~20km 이내</span>
                                <span class="op_tag">20~30km 이내</span>
                                <span class="op_tag">30km 이상</span> -->
                                <span
                                    v-for="(name, value) in this.$getDistanceCategory()"
                                    :key="value"
                                    class="op_tag"
                                    :class="{ on: this.$store.state.Common.searchDistanceCategory.includes(value) }"
                                    :data-value="value"
                                    @click="toggleDistanceCategory(value)">
                                      {{name}}
                                  </span>
                            </span>
                        </span>
                    </li>
                    <li v-if = "this.$store.state.Popup.SearchSportTime">
                        <span class="t1">운동 시간</span>
                        <span class="t2">
                            <!-- <a href="#" class="btn_all">ALL</a>
                            <span class="t2_inner">
                                <span class="op_tag">10분 이내</span>
                                <span class="op_tag">10~30분 이내</span>
                                <span class="op_tag">30~60분 이내</span>
                                <span class="op_tag">1~2시간 이내</span>
                                <span class="op_tag">2~4시간 이내</span>
                                <span class="op_tag">4시간 이상</span>
                            </span> -->
                            <a href="#" class="btn_all" @click="toggleSportTimeCategory('ALL')" :class="{on:this.$store.state.Common.searchSportTimeCategory.includes('ALL')}">ALL</a>
                              <span class="t2_inner">
                                  <span
                                    v-for="(name, value) in this.$getSportTimeCategory()"
                                    :key="value"
                                    class="op_tag"
                                    :class="{ on: this.$store.state.Common.searchSportTimeCategory.includes(value) }"
                                    :data-value="value"
                                    @click="toggleSportTimeCategory(value)">
                                      {{name}}
                                  </span>
                              </span>
                        </span>
                    </li>
                    <li v-if = "this.$store.state.Popup.SearchBadgeType">
                        <span class="t1">배지 유형</span>
                        <span class="t2">
                            <a href="#" class="btn_all" @click="toggleBadgeTypeCategory('ALL')" :class="{on:this.$store.state.Common.searchBadgeTypeCategory.includes('ALL')}">ALL</a>
                            <span class="t2_inner">
                                <!-- <span class="op_tag on">공원</span>
                                <span class="op_tag">만보기</span>
                                <span class="op_tag">여행지</span>
                                <span class="op_tag">이벤트</span> -->
                                <span
                                  v-for="(name, value) in this.$getBadgeTypeCategory()"
                                  :key="value"
                                  class="op_tag"
                                  :class="{ on: this.$store.state.Common.searchBadgeTypeCategory.includes(value) }"
                                  :data-value="value"
                                  @click="toggleBadgeTypeCategory(value)">
                                    {{name}}
                                </span>
                            </span>
                        </span>
                    </li>
                    <li v-if = "this.$store.state.Popup.SearchSecureBdage">
                        <span class="t1">획득 배지 수</span>
                        <span class="t2">
                            <a href="#" class="btn_all" @click="toggleSecureBdageCategory('ALL')" :class="{on:this.$store.state.Common.searchSecureBdageCategory.includes('ALL')}">ALL</a>
                            <span class="t2_inner">
                                <!-- <span class="op_tag">0개</span>
                                <span class="op_tag">1~5개</span>
                                <span class="op_tag">6~10개</span>
                                <span class="op_tag">11~20개</span>
                                <span class="op_tag">21~50개</span>
                                <span class="op_tag">51개 이상</span> -->
                              <span
                                v-for="(name, value) in this.$getSecureBdageCategory()"
                                :key="value"
                                class="op_tag"
                                :class="{ on: this.$store.state.Common.searchSecureBdageCategory.includes(value) }"
                                :data-value="value"
                                @click="toggleSecureBdageCategory(value)">
                                  {{name}}
                              </span>
                            </span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div><!-- 설정 화면 팝업 -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import City from '@/assets/common/data/city.js'
export default {
  data () {
    return {
      selectBar: false,
      sidoChose: 0,
      selectSigungu: [],
      selectArea: [],
      sidoList: City.sidoList,
      sigunguList: City.sigunguList
    }
  },
  methods: {
    ...mapGetters('MyTranggle', {
      selectedBadge: 'selectedBadge',
      popupChange: 'popupChange'
    }),
    commitData () {
      this.$store.commit('Popup/controlSearchCommit')
      if (this.$store.state.Common.selectArea[0] === 'ALL') {
        this.$store.state.Common.selectArea = []
      }
      if (this.$store.state.MyTranggle.badgeSearchTap[0] === 'ALL') {
        this.$store.state.MyTranggle.badgeSearchTap = []
      }
      console.log(this.$store.state.Common.searchTrackCategory)
      if (this.$store.state.Common.searchBadgeTypeCategory[0] === 'ALL') {
        this.$store.state.Common.searchBadgeTypeCategory = []
      }
      if (this.$store.state.Common.searchTrackCategory[0] === 'ALL') {
        this.$store.state.Common.searchTrackCategory = []
      }

      if (this.$store.state.MyTranggle.badgeSearchTap.length !== 0 || this.$store.state.Common.searchBadgeTypeCategory.length !== 0 || this.$store.state.Common.searchTrackCategory.length !== 0 || this.$store.state.Common.selectArea.length !== 0) {
        this.$store.state.MyTranggle.badgeSearchTap = true
      }

      this.popupChange()
    },
    OptClaer () {
      this.selectArea = []
      this.$store.state.Common.selectArea = []
      this.$store.state.Common.searchPeriodCategory = []
      this.$store.state.Common.searchTrackCategory = []
      this.$store.state.Common.searchDistanceCategory = []
      this.$store.state.Common.searchSportTimeCategory = []
      this.$store.state.Common.searchBadgeTypeCategory = []
      this.$store.state.Common.searchSecureBdageCategory = []
      this.$store.state.MyTranggle.badgeSearchTap = false
    },
    selectCityBar () {
      this.selectBar = !this.selectBar
    },
    selectSigunguList (sidoCode) {
      this.sidoChose = sidoCode
      this.selectSigungu = []
      if (sidoCode !== 0) {
        for (const i in this.sigunguList) {
          if (this.sigunguList[i].id.toString().substr(0, 2) === sidoCode.toString()) {
            this.selectSigungu.push(this.sigunguList[i])
          }
        }
      } else {
        this.$store.state.Common.selectArea = ['ALL']
      }
    },
    choseArea (sigunguCode) {
      var SidoName = ''
      var SigunguName = ''
      if (sigunguCode === 0) {
        // sidoChose
        for (const i in this.sidoList) {
          if (this.sidoChose === this.sidoList[i].id) {
            SidoName = this.sidoList[i].name
          }
        }
      } else {
        for (const i in this.sidoList) {
          if (sigunguCode.toString().substr(0, 2) === this.sidoList[i].id.toString()) {
            SidoName = this.sidoList[i].name
          }
        }
        for (const i in this.sigunguList) {
          if (sigunguCode === this.sigunguList[i].id) {
            SigunguName = ' ' + this.sigunguList[i].name
          }
        }
      }
      if (this.$store.state.Common.selectArea.includes('ALL')) {
        this.$store.state.Common.selectArea.splice(0, 1)
      }
      if (!this.$store.state.Common.selectArea.includes(SidoName + SigunguName)) {
        this.$store.state.Common.selectArea.push(SidoName + SigunguName)
      }
    },
    deleteArea (index) {
      this.$store.state.Common.selectArea.splice(index, 1)
      if (Object.keys(this.$store.state.Common.selectArea).length === 0) {
        this.$store.state.Common.selectArea = ['ALL']
      }
    },
    togglePeriodCategory (e) {
      if (e === 'ALL') {
        this.$store.state.Common.searchPeriodCategory = []
        this.$store.state.Common.searchPeriodCategory.push(e)
      } else if (!this.$store.state.Common.searchPeriodCategory.includes(e)) {
        this.$store.state.Common.searchPeriodCategory.push(e)
      } else {
        this.$store.state.Common.searchPeriodCategory.splice(this.$store.state.Common.searchPeriodCategory.indexOf(e), 1)
      }
      if (this.$store.state.Common.searchPeriodCategory.includes('ALL') && this.$store.state.Common.searchPeriodCategory.length > 1) {
        this.$store.state.Common.searchPeriodCategory.splice(this.$store.state.Common.searchPeriodCategory.indexOf('ALL'), 1)
      } else if (this.$store.state.Common.searchPeriodCategory.length === 0) {
        this.$store.state.Common.searchPeriodCategory.push('ALL')
      }
    },
    toggleTrackCategory (e) {
      if (e === 'ALL') {
        this.$store.state.Common.searchTrackCategory = []
        this.$store.state.Common.searchTrackCategory.push(e)
      } else if (!this.$store.state.Common.searchTrackCategory.includes(e)) {
        this.$store.state.Common.searchTrackCategory.push(e)
      } else {
        this.$store.state.Common.searchTrackCategory.splice(this.$store.state.Common.searchTrackCategory.indexOf(e), 1)
      }
      if (this.$store.state.Common.searchTrackCategory.includes('ALL') && this.$store.state.Common.searchTrackCategory.length > 1) {
        this.$store.state.Common.searchTrackCategory.splice(this.$store.state.Common.searchTrackCategory.indexOf('ALL'), 1)
      } else if (this.$store.state.Common.searchTrackCategory.length === 0) {
        this.$store.state.Common.searchTrackCategory.push('ALL')
      }
    },
    toggleDistanceCategory (e) {
      if (e === 'ALL') {
        this.$store.state.Common.searchDistanceCategory = []
        this.$store.state.Common.searchDistanceCategory.push(e)
      } else if (!this.$store.state.Common.searchDistanceCategory.includes(e)) {
        this.$store.state.Common.searchDistanceCategory.push(e)
      } else {
        this.$store.state.Common.searchDistanceCategory.splice(this.$store.state.Common.searchDistanceCategory.indexOf(e), 1)
      }
      if (this.$store.state.Common.searchDistanceCategory.includes('ALL') && this.$store.state.Common.searchDistanceCategory.length > 1) {
        this.$store.state.Common.searchDistanceCategory.splice(this.$store.state.Common.searchDistanceCategory.indexOf('ALL'), 1)
      } else if (this.$store.state.Common.searchDistanceCategory.length === 0) {
        this.$store.state.Common.searchDistanceCategory.push('ALL')
      }
    },
    toggleSportTimeCategory (e) {
      if (e === 'ALL') {
        this.$store.state.Common.searchSportTimeCategory = []
        this.$store.state.Common.searchSportTimeCategory.push(e)
      } else if (!this.$store.state.Common.searchSportTimeCategory.includes(e)) {
        this.$store.state.Common.searchSportTimeCategory.push(e)
      } else {
        this.$store.state.Common.searchSportTimeCategory.splice(this.$store.state.Common.searchSportTimeCategory.indexOf(e), 1)
      }
      if (this.$store.state.Common.searchSportTimeCategory.includes('ALL') && this.$store.state.Common.searchSportTimeCategory.length > 1) {
        this.$store.state.Common.searchSportTimeCategory.splice(this.$store.state.Common.searchSportTimeCategory.indexOf('ALL'), 1)
      } else if (this.$store.state.Common.searchSportTimeCategory.length === 0) {
        this.$store.state.Common.searchSportTimeCategory.push('ALL')
      }
    },
    toggleBadgeTypeCategory (e) {
      if (e === 'ALL') {
        this.$store.state.Common.searchBadgeTypeCategory = []
        this.$store.state.Common.searchBadgeTypeCategory.push(e)
      } else if (!this.$store.state.Common.searchBadgeTypeCategory.includes(e)) {
        this.$store.state.Common.searchBadgeTypeCategory.push(e)
      } else {
        this.$store.state.Common.searchBadgeTypeCategory.splice(this.$store.state.Common.searchBadgeTypeCategory.indexOf(e), 1)
      }
      if (this.$store.state.Common.searchBadgeTypeCategory.includes('ALL') && this.$store.state.Common.searchBadgeTypeCategory.length > 1) {
        this.$store.state.Common.searchBadgeTypeCategory.splice(this.$store.state.Common.searchBadgeTypeCategory.indexOf('ALL'), 1)
      } else if (this.$store.state.Common.searchBadgeTypeCategory.length === 0) {
        this.$store.state.Common.searchBadgeTypeCategory.push('ALL')
      }
    },
    toggleSecureBdageCategory (e) {
      if (e === 'ALL') {
        this.$store.state.Common.searchSecureBdageCategory = []
        this.$store.state.Common.searchSecureBdageCategory.push(e)
      } else if (!this.$store.state.Common.searchSecureBdageCategory.includes(e)) {
        this.$store.state.Common.searchSecureBdageCategory.push(e)
      } else {
        this.$store.state.Common.searchSecureBdageCategory.splice(this.$store.state.Common.searchSecureBdageCategory.indexOf(e), 1)
      }
      if (this.$store.state.Common.searchSecureBdageCategory.includes('ALL') && this.$store.state.Common.searchSecureBdageCategory.length > 1) {
        this.$store.state.Common.searchSecureBdageCategory.splice(this.$store.state.Common.searchSecureBdageCategory.indexOf('ALL'), 1)
      } else if (this.$store.state.Common.searchSecureBdageCategory.length === 0) {
        this.$store.state.Common.searchSecureBdageCategory.push('ALL')
      }
    }
  },
  components: {
  },
  name: 'CommonConfirmPopup',
  mounted () {
    if (this.$store.state.Common.selectArea[0] === 'ALL') {
      this.selectArea = []
    } else {
      this.selectArea = this.$store.state.Common.selectArea
    }
  }
}
</script>

<style>

</style>
