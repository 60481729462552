<template>
  <div class="right_body mt">
    <div class="setting_area">
        <ul>
          <li>
              <span class="set_text">전체 알림</span>
              <span class="set_input">
                <span class="g">
                  <input type="radio" id="normalGuidanceOn" v-model="allAlarm" value="ON" name="all" @click="normalGuidanceControl('ON')"/>
                  <label for="normalGuidanceOn">ON</label>
                  <input type="radio" id="normalGuidanceOFF" v-model="allAlarm" value="OFF" name="all" @click="normalGuidanceControl('OFF')"/>
                  <label for="normalGuidanceOFF">OFF</label>
                </span>
                <span class="stext">푸시 메시지, 팝업, 하산 등 전체 알림이 ON / OFF 됩니다.</span>
              </span>
          </li>
        </ul>
        <div class="stitle">푸시 메시지</div>
        <ul>
            <li>
              <span class="set_text">앱 업데이트</span>
              <span class="set_input">
                <span class="g">
                  <input type="radio" id="appUpdateNoticeTrue" v-model="pushAppUpdate" value="TRUE" name="pushAppUpdate" @change="normalGuidanceChange()"/>
                  <label for="appUpdateNoticeTrue">ON</label>
                  <input type="radio" id="appUpdateNoticeFalse" v-model="pushAppUpdate" value="FALSE" name="pushAppUpdate" @change="normalGuidanceChange()"/>
                  <label for="appUpdateNoticeFalse">OFF</label>
                </span>
              </span>
            </li>
            <li>
              <span class="set_text">공지사항</span>
              <span class="set_input">
                <span class="g">
                  <input type="radio" id="publicNoticeTrue" v-model="pushNotice" value="TRUE" name="pushNotice" @change="normalGuidanceChange()"/>
                  <label for="publicNoticeTrue">ON</label>
                  <input type="radio" id="publicNoticeFalse" v-model="pushNotice" value="FALSE" name="pushNotice" @change="normalGuidanceChange()"/>
                  <label for="publicNoticeFalse">OFF</label>
                </span>
              </span>
            </li>
            <li>
              <span class="set_text">메시지 도착</span>
              <span class="set_input">
                <span class="g">
                  <input type="radio" id="messageNoticeTrue" v-model="pushMessage" value="TRUE" name="pushMessage" @change="normalGuidanceChange()"/>
                  <label for="messageNoticeTrue">ON</label>
                  <input type="radio" id="messageNoticeFalse" v-model="pushMessage" value="FALSE" name="pushMessage" @change="normalGuidanceChange()"/>
                  <label for="messageNoticeFalse">OFF</label>
                </span>
              </span>
            </li>
            <li>
              <span class="set_text">친구 초대</span>
              <span class="set_input">
                <span class="g">
                  <input type="radio" id="friendInviteNoticeTrue" v-model="pushFrInvite" value="TRUE" name="pushFrInvite" @change="normalGuidanceChange()"/>
                  <label for="friendInviteNoticeTrue">ON</label>
                  <input type="radio" id="friendInviteNoticeFalse" v-model="pushFrInvite" value="FALSE" name="pushFrInvite" @change="normalGuidanceChange()"/>
                  <label for="friendInviteNoticeFalse">OFF</label>
                </span>
              </span>
            </li>
            <li>
              <span class="set_text">내 게시글 댓글 등록</span>
              <span class="set_input">
                <span class="g">
                  <input type="radio" id="commentNoticeTrue" v-model="pushCommentNotice" value="TRUE" name="pushCommentNotice" @change="normalGuidanceChange()"/>
                  <label for="commentNoticeTrue">ON</label>
                  <input type="radio" id="commentNoticeFalse" v-model="pushCommentNotice" value="FALSE" name="pushCommentNotice" @change="normalGuidanceChange()"/>
                  <label for="commentNoticeFalse">OFF</label>
                </span>
              </span>
            </li>
            <li>
              <span class="set_text">신규 코스북 등록</span>
              <span class="set_input">
                <span class="g">
                  <input type="radio" id="coursebookRegistNoticeTrue" v-model="pushNewCoursebook" value="TRUE" name="pushNewCoursebook" @change="normalGuidanceChange()"/>
                  <label for="coursebookRegistNoticeTrue">ON</label>
                  <input type="radio" id="coursebookRegistNoticeFalse" v-model="pushNewCoursebook" value="FALSE" name="pushNewCoursebook" @change="normalGuidanceChange()"/>
                  <label for="coursebookRegistNoticeFalse">OFF</label>
                </span>
              </span>
            </li>
            <li>
              <span class="set_text">친구 기록 등록</span>
              <span class="set_input">
                <span class="g">
                  <input type="radio" id="friendRecordNoticeTrue" v-model="pushFrRecord" value="TRUE" name="pushFrRecord" @change="normalGuidanceChange()"/>
                  <label for="friendRecordNoticeTrue">ON</label>
                  <input type="radio" id="friendRecordNoticeFalse" v-model="pushFrRecord" value="FALSE" name="pushFrRecord" @change="normalGuidanceChange()"/>
                  <label for="friendRecordNoticeFalse">OFF</label>
                </span>
              </span>
            </li>
            <li>
              <span class="set_text">이벤트</span>
              <span class="set_input">
                <span class="g">
                  <input type="radio" id="eventNoticeTrue" v-model="pushEvent" value="TRUE" name="pushEvent" @change="normalGuidanceChange()"/>
                  <label for="eventNoticeTrue">ON</label>
                  <input type="radio" id="eventNoticeFalse" v-model="pushEvent" value="FALSE" name="pushEvent" @change="normalGuidanceChange()"/>
                  <label for="eventNoticeFalse">OFF</label>
                </span>
              </span>
            </li>
            <li>
              <span class="set_text">광고 / 이벤트</span>
              <span class="set_input">
                <span class="g">
                  <input type="radio" id="adNoticeTrue" v-model="pushAD" value="TRUE" name="pushAD" @change="normalGuidanceChange()"/>
                  <label for="adNoticeTrue">ON</label>
                  <input type="radio" id="adNoticeFalse" v-model="pushAD" value="FALSE" name="pushAD" @change="normalGuidanceChange()"/>
                  <label for="adNoticeFalse">OFF</label>
                </span>
              </span>
            </li>
        </ul>
        <div class="stitle">팝업</div>
        <ul>
          <li>
            <span class="set_text">배지획득</span>
            <span class="set_input">
              <span class="g">
                <input type="radio" id="badgePopupNoticeTrue" value="TRUE" v-model="popupBadge" @change="normalGuidanceChange()"/>
                <label for="badgePopupNoticeTrue">ON</label>
                <input type="radio" id="badgePopupNoticeFalse" value="FALSE" v-model="popupBadge" @change="normalGuidanceChange()"/>
                <label for="badgePopupNoticeFalse">OFF</label>
              </span>
            </span>
          </li>
        </ul>
        <div class="stitle">하산</div>
        <ul>
          <!-- <li>
            <span class="set_text">하산 알림</span>
            <span class="set_input">
              <span class="g">
                <input type="radio" id="configClimbDownTimeTrue" value="TRUE" v-model="configClimbDownTimeChk"  @click="configClimbDownTimeControl('TRUE')"/>
                <label for="configClimbDownTimeTrue">ON</label>
                <input type="radio" id="configClimbDownTimeFalse" value="FALSE" v-model="configClimbDownTimeChk"  @click="configClimbDownTimeControl('FALSE')"/>
                <label for="configClimbDownTimeFalse">OFF</label>
              </span>
            </span>
          </li> -->
          <li>
            <!-- <span class="set_text">하산 알림 시간</span> -->
            <span class="set_input">
              <!-- <span class="g">
                <input type="radio" id="configClimbDownTime0" value="0" v-model="configClimbDownTime"  @change="configClimbDownTimeChange('0')"/>
                <label for="configClimbDownTime0">사용 안함</label>
                <input type="radio" id="configClimbDownTime30" value="30" v-model="configClimbDownTime" @change="configClimbDownTimeChange('30')"/>
                <label for="configClimbDownTime30">일몰 30분 전</label>
                <input type="radio" id="configClimbDownTime60" value="60" v-model="configClimbDownTime" @change="configClimbDownTimeChange('60')"/>
                <label for="configClimbDownTime60">일몰 1시간 전</label>
                <input type="radio" id="configClimbDownTime90" value="90" v-model="configClimbDownTime" @change="configClimbDownTimeChange('90')"/>
                <label for="configClimbDownTime90">일몰 1시간 30분 전</label>
                <input type="radio" id="configClimbDownTime120" value="120" v-model="configClimbDownTime" @change="configClimbDownTimeChange('120')"/>
                <label for="configClimbDownTime120">일몰 2시간 전</label>
                <input type="radio" id="configClimbDownTime180" value="180" v-model="configClimbDownTime"  @change="configClimbDownTimeChange('180')"/>
                <label for="configClimbDownTime180">일몰 3시간 전</label>
              </span> -->
              <span class="time_box">
                <span class="t_bold">Today</span>
                <span>일출 시간 <span class="text_blue">오전 07:00</span></span>
                <span>일몰 시간 <span class="text_blue">오후 18:00</span></span>
              </span>
            </span>
          </li>
        </ul>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      allAlarm: '',
      pushAppUpdate: '',
      pushNotice: '',
      pushMessage: '',
      pushFrInvite: '',
      pushCommentNotice: '',
      pushNewCoursebook: '',
      pushFrRecord: '',
      pushEvent: '',
      pushAD: '',
      popupBadge: '',
      configClimbDownTimeChk: '',
      configClimbDownTime: ''
    }
  },
  methods: {
    ...mapActions('Member', [
      'updateMemberConfig'
    ]),
    normalGuidanceControl (state) {
      this.allAlarm = state
      if (state === 'OFF') {
        this.configClimbDownTimeChk = 'FALSE'
        this.pushAppUpdate = 'FALSE'
        this.pushNotice = 'FALSE'
        this.pushMessage = 'FALSE'
        this.pushFrInvite = 'FALSE'
        this.pushCommentNotice = 'FALSE'
        this.pushNewCoursebook = 'FALSE'
        this.pushFrRecord = 'FALSE'
        this.pushEvent = 'FALSE'
        this.pushAD = 'FALSE'
        this.popupBadge = 'FALSE'
      }
      if (state === 'ON') {
        this.configClimbDownTimeChk = 'TRUE'
        this.pushAppUpdate = 'TRUE'
        this.pushNotice = 'TRUE'
        this.pushMessage = 'TRUE'
        this.pushFrInvite = 'TRUE'
        this.pushCommentNotice = 'TRUE'
        this.pushNewCoursebook = 'TRUE'
        this.pushFrRecord = 'TRUE'
        this.pushEvent = 'TRUE'
        this.pushAD = 'TRUE'
        this.popupBadge = 'TRUE'
      }
      this.memberConfigDataUpt()
    },
    normalGuidanceChange () {
      this.allAlarm = 'OFF'
      this.memberConfigDataUpt()
    },
    memberConfigDataUpt () {
      const data = {
        config_is_badge_popup_notice: this.popupBadge,
        config_is_ad_notice: this.pushAD,
        config_is_event_notice: this.pushEvent,
        config_is_friend_record_notice: this.pushFrRecord,
        config_is_coursebook_regist_notice: this.pushNewCoursebook,
        config_is_comment_notice: this.pushCommentNotice,
        config_is_friend_invite_notice: this.pushFrInvite,
        config_is_message_notice: this.pushMessage,
        config_is_public_notice: this.pushNotice,
        config_is_app_update_notice: this.appUpdateNotice
      }
      this.updateMemberConfig(data)
    },
    configClimbDownTimeChange (state) {
      if (state === '0') {
        this.configClimbDownTimeChk = 'FALSE'
      } else {
        this.configClimbDownTimeChk = 'TRUE'
      }
      this.normalGuidanceChange()
    },
    configClimbDownTimeControl (state) {
      this.configClimbDownTimeChk = state
      if (state === 'FALSE') {
        this.configClimbDownTime = '0'
      }
      this.normalGuidanceChange()
    }
  }
}
</script>
