<template>
  <div class="popup login_bg find_box dm">
    <div class="popup_area">
      <div class="popup_box popup_box_m sports_pop">
        <div class="popup_top">
          <h2 class="head1">휴면 계정 해제</h2>
        </div>
        <div class="popup_body login_body">
          <SubTab ref="subtab" :sub-tab-info="subTabInfo" />
          <div class="login_area">
            <ul>
              <!-- <li>
                <span class="set_text">이름</span>
                <span class="set_input1 input_l">
                  <input type="text" v-model="name" maxlength="15" @keypress.enter="wakeUpMember" placeholder="이름을 입력해 주세요" />
                </span>
              </li> -->
              <SelfAuthentication v-if="subTabInfo[0].active" @completeAuth="completeAuth" />
              <EmailAuthentication v-else @completeEmailAuth="completeEmailAuth"/>
            </ul>
          </div>
          <div class="footer_bottom dormat">
            <MobileHint :type="subTabInfo[0].active ? 'mobile' : 'email'" />
            <div class="btn_area btn_bottom2">
              <button type="button" class="btn_f" :class="{'disabled': !validationChk()}" @click="wakeUpMember">완료</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import SubTab from '@/components/common/SubTab.vue'
import SelfAuthentication from '@/components/member/SelfAuthentication.vue'
import EmailAuthentication from '@/components/member/EmailAuthentication.vue'
import MobileHint from '@/components/common/MobileHint.vue'
export default {
  name: 'DormantAuthPopup',
  data () {
    return {
      // check: 'MOBILE',
      subTabInfo: [{
        name: '휴대전화로 인증',
        active: true
      },
      {
        name: '이메일로 인증',
        active: false
      }],
      // name: '',
      phone: '',
      email: '',
      validateAuth: '',
      isDomestic: 'domestic'
    }
  },
  components: {
    SubTab,
    SelfAuthentication,
    EmailAuthentication,
    MobileHint
  },
  computed: {
    ...mapState('Member', [
      'validateSmsCode',
      'memberWakeUp'
    ])
  },
  watch: {
    $route (to, from) {
      this.loadLogout()
    },
    memberWakeUp () {
      if (this.memberWakeUp) {
        this.$store.state.Member.memberWakeUp = false
        this.$store.state.UserSet.isOpenDormantPopup = false
        this.$showMessagePopup('휴면 해제 완료', '회원님의 휴면상태가 해제되었습니다. 로그인 후 트랭글을 이용해 주세요.', '/login', '로그인')
      }
    }
  },
  unmounted () {
    this.loadLogout()
  },
  methods: {
    ...mapActions('Member', [
      'loadMemberWakeUp'
    ]),
    ...mapActions('UserSet', [
      'loadLogout'
    ]),
    completeAuth (phone, email) {
      this.phone = phone
      this.email = email
      this.validateAuth = 'Y'
    },
    completeEmailAuth (email) {
      this.email = email
      this.validateAuth = 'Y'
    },
    wakeUpMember () {
      // if (!this.name) {
      //   this.$showMessagePopup('휴면상태 해제', '이름을 입력하세요')
      //   return false
      // }
      if (this.validateAuth !== 'Y') {
        const typeKr = this.subTabInfo[0].active ? '휴대전화를' : '이메일을'
        this.$showMessagePopup('휴면상태 해제', `${typeKr} 인증해주세요`)
        return false
      }
      this.loadMemberWakeUp()
    },
    validationChk () {
      return this.validateAuth === 'Y'
    }
  }
}
</script>
