<template>
  <div class="right_body mt">
    <div class="setting_area">
      <ul>
        <li class="line_bottom">
          <span class="set_text">전체 배지 보기</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="badgeControlON" value="ON" v-model="badgeControl" @click="badgeViewControl('ON')" />
              <label for="badgeControlON">ON</label>
              <input type="radio" id="badgeControlOFF" value="OFF" v-model="badgeControl" @click="badgeViewControl('OFF')" />
              <label for="badgeControlOFF">OFF</label>
            </span>
            <span class="stext">ON선택시 지도에서 모든 배지가 보여지며 OFF 선택시 개별 배지 보기를 선택하실 수 있습니다.</span>
          </span>
        </li>
        <li class="mt">
          <span class="set_text">산봉우리 배지</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="mountaintopBadgeSeeOn" value="TRUE" v-model="mountaintopBadgeSee" @change="badgeViewChange()" />
              <label for="mountaintopBadgeSeeOn">ON</label>
              <input type="radio" id="mountaintopBadgeSeeOff" value="FALSE" v-model="mountaintopBadgeSee" @change="badgeViewChange()" />
              <label for="mountaintopBadgeSeeOff">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">자전거 인증센터 배지</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="bicycleCertBadgeSeeOn" value="TRUE" v-model="bicycleCertBadgeSee" @change="badgeViewChange()"/>
              <label for="bicycleCertBadgeSeeOn">ON</label>
              <input type="radio" id="bicycleCertBadgeSeeOff" value="FALSE" v-model="bicycleCertBadgeSee" @change="badgeViewChange()"/>
              <label for="bicycleCertBadgeSeeOff">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">공원 배지</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="parkBadgeSeeOn" value="TRUE" v-model="parkBadgeSee" @change="badgeViewChange()"/>
              <label for="parkBadgeSeeOn">ON</label>
              <input type="radio" id="parkBadgeSeeOff" value="FALSE" v-model="parkBadgeSee" @change="badgeViewChange()"/>
              <label for="parkBadgeSeeOff">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">이벤트 배지</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="eventBadgeSeeOn" value="TRUE" v-model="eventBadgeSee" @change="badgeViewChange()"/>
              <label for="eventBadgeSeeOn">ON</label>
              <input type="radio" id="eventBadgeSeeOff" value="FALSE" v-model="eventBadgeSee" @change="badgeViewChange()"/>
              <label for="eventBadgeSeeOff">OFF</label>
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      badgeControl: '',
      mountaintopBadgeSee: '',
      bicycleCertBadgeSee: '',
      parkBadgeSee: '',
      eventBadgeSee: ''
    }
  },
  methods: {
    ...mapActions('Member', [
      'updateMemberConfig'
    ]),
    badgeViewControl (state) {
      this.badgeControl = state
      if (state === 'OFF') {
        this.mountaintopBadgeSee = 'FALSE'
        this.bicycleCertBadgeSee = 'FALSE'
        this.parkBadgeSee = 'FALSE'
        this.eventBadgeSee = 'FALSE'
      }
      if (state === 'ON') {
        this.mountaintopBadgeSee = 'TRUE'
        this.bicycleCertBadgeSee = 'TRUE'
        this.parkBadgeSee = 'TRUE'
        this.eventBadgeSee = 'TRUE'
      }
      this.memberConfigDataUpt()
    },
    badgeViewChange () {
      this.badgeControl = 'OFF'
      this.memberConfigDataUpt()
    },
    memberConfigDataUpt () {
      const data = {
        config_is_mountaintop_badge_see: this.mountaintopBadgeSee,
        config_is_bicycle_cert_badge_see: this.bicycleCertBadgeSee,
        config_is_park_badge_see: this.parkBadgeSee,
        config_is_event_badge_see: this.eventBadgeSee
      }
      this.updateMemberConfig(data)
    }
  }
}
</script>
