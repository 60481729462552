<template>
  <div class="left_content" :style="this.getLeftContentHeight">
    <div class="list_badge">
      <ul>
        <li v-for="(badge, i) in this.$store.state.Course.badgeList" :key="i" :class="{on: i === this.$store.state.Course.selectedBadgeIndex}" @click="this.selectedBadge(i)">
          <span class="td1"><span class="poi_badge"><img :src="this.$photoEmpty(badge.badge_image_url)" /></span></span>
          <span class="td2">
            <span class="poi_content">
              <span class="subject"><span class="text elp">{{badge.info_badge_name}}</span></span>
              <span class="sub_text">{{badge.info_badge_message}}</span>
              <span class="data">
                <span class="text"><span class="t1">획득 경험치</span>{{badge.info_reward}}</span>
                <span class="text"><span class="t1">배지 획득 방법</span>{{badge.how_to_get}}</span>
              </span>
            </span>
          </span>
        </li>
      </ul>
    </div><!--/course_badge-->
  </div>
</template>

<script>

export default {
  name: 'courseDetailBadge',
  data () {
    return {
      selectedBadgeIndex: -1
    }
  },
  props: {
    courseInfo: Object,
    trackYear: String
  },
  computed: {
    getLeftContentHeight () {
      if (this.$store.state.Common.courseMenuType === 'my' &&
        this.$store.state.Member.memberId === this.courseInfo.member.member_id) {
        return 'height: calc(100vh - 500px);'
      } else {
        return 'height: calc(100vh - 414px);'
      }
    }
  },
  methods: {
    selectedBadge (index) {
      this.$store.commit('Course/setSelectedBadgeIndex', index)
    }
  },
  mounted () {
    this.$store.state.Course.isVisibleCourseBadge = true
  }
}
</script>

<style>

</style>
