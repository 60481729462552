import dayjs from 'dayjs'
export default {
  namespaced: true,
  state: {
    popBg: false,
    popArea: false,
    attachAddress: {
      address: '',
      x: '',
      y: ''
    },
    meetAddress: {
      x: '',
      y: ''
    },
    clubSearchMenu: false, // 클럼 검색 메뉴 옵션
    clubSearchOption: false, // 클럽 검색 옵션
    clubCreateNotice: false, // 클럽 개설 notice
    clubCreateComplet: false, // 클럽 개설 완료
    clubCreateImageSelect: false, // 클럽 개설 대표이미지 선택
    clubCreateFlagSelect: false, // 클럽 개설 깃발 선택
    clubMeetCreate: false, // 클럽 모임 개설 완료
    clubMeetMemberList: false, // 클럽 모임 멤버 리스트
    clubMeetMemberListData: [],
    clubRankSearchOption: false, // 클럽 랭크 검색 옵션
    clubManageClose: false, // 클럽 관리 폐쇄
    clubManageBoardAdd: false, // 클럽 관리 게시판 추가
    clubAddressSearch: false, // 주소 검색
    clubAddressPopup: false, // 장소 표시
    clubMainImage: {},
    clubFlagImage: {},
    selectArea: [],
    clubRankingType: '0',
    clubRankingSWeek: '',
    clubRankingEWeek: '',
    clubRankingWeekNum: 0,
    clubRankingSdate: dayjs(new Date()).format('YYYY-MM-DD'),
    clubRankingEdate: dayjs(new Date()).add(15, 'day').format('YYYY-MM-DD'),
    clubRankingCategory: 'TOTAL',
    clubRankingOption: '',
    clubRankingGender: [],
    clubRankingAges: [],
    clubSearchTrackCategory: [],
    clubSearchCompetCategory: [],
    msgstat: false, // 미션 - 쪽지 팝업
    msgMessage: '', // 미션 - 쪽지 Comp
    msgReceiveId: '',
    msgReceiveParticipant: [],
    // 여기까지 쪽지
    searchCommitCnt: 0
  },
  mutations: {
    controlAddressPopupShow (state, data) {
      state.clubAddressSearch = true
      state.popBg = true
      state.popArea = true
    },
    controlAddressPopupHide (state) {
      state.clubAddressSearch = false
      state.popBg = false
      state.popArea = false
    },
    setAttachAddress (state, data) {
      console.log('setAttachAddress', data)
      state.attachAddress = {
        address: data == null ? '' : data.address,
        x: data == null ? '' : data.x,
        y: data == null ? '' : data.y
      }
      state.clubAddressSearch = false
      state.popBg = false
      state.popArea = false
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    // 팝업 전체 닫기
    // missionCloseAll (state) {
    //   state.missionCreate = !state.missionCreate
    //   state.termsChk = 'on'
    // },
    // 팝업 배경
    clubBgOn (state) {
      state.popBg = true
      state.popArea = true
    },
    clubBgOff (state) {
      state.popBg = false
      state.popArea = false
    },
    // 클럽 검색 옵션 팝업
    clubSearchOpenShow (state, getters) {
      state.clubSearchOption = true
      return getters.clubBgOn
    },
    clubSearchOpenHide (state, getters) {
      state.clubSearchOption = false
      state.searchCommitCnt += 1
      return getters.clubBgOff
    },
    // 클럽 개설 Notice
    clubCreateNoticeShow (state, getters) {
      state.clubCreateNotice = true
      return getters.clubBgOn
    },
    clubCreateNoticeHide (state, getters) {
      state.clubCreateNotice = false
      return getters.clubBgOff
    },
    // 클럽 개설 완료
    clubCreateCompletShow (state, getters) {
      state.clubCreateComplet = true
      return getters.clubBgOn
    },
    clubCreateCompletHide (state, getters) {
      state.clubCreateComplet = false
      return getters.clubBgOff
    },
    // 클럽 개설 이미지 선택
    clubCreateImageSelectShow (state, getters) {
      state.clubCreateImageSelect = true
      console.log('셀렉트', state)
      return getters.clubBgOn
    },
    clubCreateImageSelectHide (state, getters) {
      state.clubCreateImageSelect = false
      return getters.clubBgOff
    },
    // 클럽 개설 깃발 선택
    clubCreateFlagSelectShow (state, getters) {
      state.clubCreateFlagSelect = true
      return getters.clubBgOn
    },
    clubCreateFlagSelectHide (state, getters) {
      state.clubCreateFlagSelect = false
      return getters.clubBgOff
    },
    // 클럽 모임 개설 완료
    clubMeetCreateShow (state, getters) {
      // console.log('aa')
      state.clubMeetCreate = true
      return getters.clubBgOn
    },
    clubMeetCreateHide (state, getters) {
      state.clubMeetCreate = false
      return getters.clubBgOff
    },
    // 클럽 모임 멤버 리스트
    clubMeetMemberListShow (state, getters) {
      state.clubMeetMemberList = true
      return getters.clubBgOn
    },
    clubMeetMemberListHide (state, getters) {
      state.clubMeetMemberList = false
      return getters.clubBgOff
    },
    // 클럽 관리 폐쇄
    clubManageCloseShow (state, getters) {
      state.clubManageClose = true
      return getters.clubBgOn
    },
    clubManageCloseHide (state, getters) {
      state.clubManageClose = false
      return getters.clubBgOff
    },
    // 클럽 관리 폐쇄
    clubManageBoardAddShow (state, getters) {
      state.clubManageBoardAdd = true
      return getters.clubBgOn
    },
    clubManageBoardAddHide (state, getters) {
      state.clubManageBoardAdd = false
      return getters.clubBgOff
    },
    // 클럽 랭크 검색 옵션
    clubRankSearchOptionShow (state, getters) {
      console.log('aa')
      state.clubRankSearchOption = true
      return getters.clubBgOn
    },
    clubSearchMenuPush (state, getters) {
      state.clubSearchMenu = false
      // state.clubSearchOpt = true
      state.clubSearchOption = false
      return getters.clubBgOff
    },
    clubRankSearchOptionHide (state, getters) {
      state.clubRankSearchOption = false
      state.searchCommitCnt += 1
      return getters.clubBgOff
    },
    // 클럽 - 쪽지
    msgSendComp (state) {
      state.msgstat = !state.msgstat
      state.MessageSendEnd = !state.MessageSendEnd
    },
    msgPopShow (state, getters) {
      console.log('msgPopShow')
      state.msgstat = true
      return getters.clubBgOn
    },
    msgPopHide (state, getters) {
      state.msgstat = false
      return getters.clubBgOff
    },
    clubAddressPopupShow (state, getters) {
      state.clubAddressPopup = true
      // state.popBg = true
      // state.popArea = true
      return getters.clubBgOn
    },
    clubAddressPopupHide (state, getters) {
      state.clubAddressPopup = false
      // state.popBg = false
      // state.popArea = false
      return getters.clubBgOff
    }
  }
}
