<template>
  <div :id="`${this.menu}_upload_gpx_file_map`" style="width: 100%; height: 100%">
  </div>
</template>

<script>
import { mapState } from 'vuex'
let mapInstance = null
let coursePolyLine = null
let courseOutPolyLine = null
let startPointMarker = null
let endPointMarker = null

const defaultPolyLineStyle = {
  strokeColor: '#000000',
  strokeWeight: 6,
  strokeOpacity: 0.8,
  strokeStyle: 'solid',
  strokeLineCap: 'round',
  strokeLineJoin: 'round'
}
const outPolyLineStyle = {
  strokeColor: '#FF7920 ',
  strokeWeight: 4,
  strokeOpacity: 1,
  strokeStyle: 'solid',
  strokeLineCap: 'round',
  strokeLineJoin: 'round'
}
let coursePinMarkerList = []

export default {
  data () {
    return {
    }
  },
  props: {
    menu: String
  },
  name: 'UploadGpxFileMap',
  computed: {
    ...mapState('Course', ['courseList']),
    checkSelectedGpxFile () {
      return this.$store.state.Course.selectedGpxFile
    },
    checkSelectedGpxTrackKey () {
      return this.$store.state.Course.selectedGpxTrackKey
    }
  },
  watch: {
    checkSelectedGpxFile (gpxFile) {
      this.clearCourseMarker()
      if (Object.keys(gpxFile).length > 0 && this.menu === 'popup') {
        console.log(gpxFile, gpxFile)
        this.createUploadGpxFile(gpxFile)
      }
    },
    checkSelectedGpxTrackKey (trackKey) {
      this.clearCourseMarker()
      if (trackKey != null && trackKey !== '') {
        this.createGpxFileTrack(trackKey)
      }
    },
    courseList () {
      console.log(this.courseList)
      // 외부기록은 좌표가 없네...
      // this.createCourseMarker()
    }
  },
  methods: {
    clearCourseMarker () {
      if (coursePolyLine != null) {
        coursePolyLine.setMap(null)
      }
      if (courseOutPolyLine != null) {
        courseOutPolyLine.setMap(null)
      }
      if (startPointMarker != null) {
        startPointMarker.setMap(null)
      }
      if (endPointMarker != null) {
        endPointMarker.setMap(null)
      }
    },
    createUploadGpxFile (gpxFile) {
      if (gpxFile.coords != null) {
        const coursePolyLinePointList = gpxFile.coords.map((trackPoint, index) => {
          return window.naver.maps.LatLng(trackPoint.lat, trackPoint.lon)
        })

        coursePolyLine = new window.naver.maps.Polyline(defaultPolyLineStyle)
        coursePolyLine.setPath(coursePolyLinePointList)

        coursePolyLine.setMap(mapInstance)
        coursePolyLine.setVisible(true)

        courseOutPolyLine = new window.naver.maps.Polyline(outPolyLineStyle)
        courseOutPolyLine.setPath(coursePolyLinePointList)

        courseOutPolyLine.setMap(mapInstance)
        courseOutPolyLine.setVisible(true)

        const polyBounds = coursePolyLine.getBounds()
        this.setFitBounds(polyBounds)
        // mapInstance.setCenter(coursePolyLinePointList[0])

        startPointMarker = new window.naver.maps.Marker({
          position: coursePolyLinePointList[0],
          map: mapInstance,
          icon: this.$getMapPinMarkerIcon('map_pin_start', ''),
          zIndex: 100
        })

        endPointMarker = new window.naver.maps.Marker({
          position: coursePolyLinePointList[coursePolyLinePointList.length - 1],
          map: mapInstance,
          icon: this.$getMapPinMarkerIcon('map_pin_goal', ''),
          zIndex: 100
        })

        // mapInstance.setZoom(16)
      }
    },
    createGpxFileTrack (trackKey) {
      this.axios.get('/map2/get_track_point_v2.json', {
        params: {
          track_key: trackKey
        }
      })
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode === '00') {
            const trackPointArray = data.data.response.content.point
            if (!trackPointArray) {
              alert('트랙 정보 오류로 코스를 불러오는데 실패했습니다.')
            }
            if (trackPointArray.length === 1) {
              const coursePolyLinePointList = trackPointArray[0].map((trackPoint, index) => {
                return window.naver.maps.LatLng(trackPoint.lat, trackPoint.lon)
              })

              coursePolyLine = new window.naver.maps.Polyline(defaultPolyLineStyle)
              coursePolyLine.setPath(coursePolyLinePointList)

              coursePolyLine.setMap(mapInstance)
              coursePolyLine.setVisible(true)

              courseOutPolyLine = new window.naver.maps.Polyline(outPolyLineStyle)
              courseOutPolyLine.setPath(coursePolyLinePointList)

              courseOutPolyLine.setMap(mapInstance)
              courseOutPolyLine.setVisible(true)

              // mapInstance.setCenter(coursePolyLinePointList[0])
              const polyBounds = coursePolyLine.getBounds()
              this.setFitBounds(polyBounds)

              startPointMarker = new window.naver.maps.Marker({
                position: coursePolyLinePointList[0],
                map: mapInstance,
                icon: this.$getMapPinMarkerIcon('map_pin_start', ''),
                zIndex: 100
              })

              endPointMarker = new window.naver.maps.Marker({
                position: coursePolyLinePointList[coursePolyLinePointList.length - 1],
                map: mapInstance,
                icon: this.$getMapPinMarkerIcon('map_pin_goal', ''),
                zIndex: 100
              })

              // mapInstance.setZoom(16)
            }
          }
        })
    },
    setFitBounds (bounds) {
      mapInstance.fitBounds(bounds, { top: 20, right: 20, bottom: 20, left: 20 })
    },
    createCourseMarker () {
      // this.deleteCourseMarker()
      coursePinMarkerList = []
      // const courseArray = []
      this.courseList.forEach((course, index, array) => {
        const pinClassName = 'map_pin'
        if (index === 0) {
          mapInstance.setCenter(new window.naver.maps.LatLng(course.log_position_start_y, course.log_position_start_x))
        }
        const marker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(course.log_position_start_y, course.log_position_start_x),
          map: mapInstance,
          icon: this.$getMapPinMarkerIcon(pinClassName, (index + 1)),
          zIndex: 100
        })

        // fitbounds용 좌표 배열
        // courseArray.push(new window.naver.maps.LatLng(course.log_position_start_y, course.log_position_start_x))

        coursePinMarkerList.push(marker)
      })

      // 검색시 좌표들 fitbounds
      // if (this.searchStatus && !this.cSearch) {
      //   this.setFitBounds(courseArray)
      // }

      // for (let i = 0; i < coursePinMarkerList.length; i++) {
      //   window.naver.maps.Event.addListener(coursePinMarkerList[i], 'click', () => {
      //     this.selectedCourseMarker(i)
      //   })
      // }
    }
  },
  mounted () {
    mapInstance = new window.naver.maps.Map(`${this.menu}_upload_gpx_file_map`, {
      center: new window.naver.maps.LatLng(35.9615, 127.6066),
      zoom: 10,
      enableWheelZoom: true,
      enableDragPan: true,
      enableDblClickZoom: false,
      mapMode: 0,
      activateTrafficMap: false,
      activateBicycleMap: false,
      minZoom: 7,
      maxZoom: 21,
      zoomControl: true,
      zoomControlOptions: {
        position: window.naver.maps.Position.TOP_RIGHT,
        style: window.naver.maps.ZoomControlStyle.SMALL
      }
    })

    if (Object.keys(this.$store.state.Course.selectedGpxFile).length > 0 && this.menu === 'popup') {
      this.createUploadGpxFile(this.$store.state.Course.selectedGpxFile)
    }
  }
}
</script>

<style>

</style>
