<template>
  <div class="left_content" :style="this.getLeftContentHeight">
    <div class="course_comment">
      <div class="comment_write">
        <div class="comment_textarea">
          <textarea v-model="commentText" placeholder="댓글을 작성해 주세요" maxlength="210"></textarea>
          <span class="comment_btn">
            <button style="display:block" class="btn_lock_s" :class="{on: commentLock !== 'N'}" @click="commentLock==='Y'?commentLock = 'N' : commentLock = 'Y'"></button>
            <span class="text_count">({{this.commentText.length}}/210)</span>
            <a href="#" class="text_org" @click="insertComment()">등록</a>
          </span>
        </div>
      </div>
      <div class="tab_menu3">
        <ul>
            <li :class="{on: this.commentOpt === 'ALL'}"><a href="#" @click="commentReLoad('ALL')">전체 보기</a></li>
            <li :class="{on: this.commentOpt === 'MY'}"><a href="#" @click="commentReLoad('MY')">내글만 보기</a></li>
        </ul>
      </div>
      <div class="comment_box" v-for="(comment, i) in this.commentList" :key="i">
        <div class="comment_g" v-if="!comment.toggle">
          <span class="c_profile">
            <router-link :to="`/mytranggle/friendInfo/` + comment.track_comment_member_id" style="color:#333">
              <img :src="comment.profile" />
            </router-link>
          </span>
          <span class="c_article">
            <span class="c_id">
              <router-link :to="`/mytranggle/friendInfo/` + comment.track_comment_member_id" style="color:#333">
                {{this.$NVLAB(comment.member_nickname, comment.track_comment_member_id)}} (LV{{comment.info_level}})
              </router-link>
            </span>
            <span class="c_event">
              <button class="btn_lock_s on" v-if="comment.pwd_chk === 'Y' && comment.is_block === 'N'"></button>
              <button class="btn_s" v-if = "comment.is_block === 'Y'" @click="blockUser(comment.track_comment_member_id, 'BLOCK_CLEAR')">차단해제</button>
              <span class="menu_more" v-if = "comment.track_comment_member_id !== this.$store.state.Member.memberId && comment.is_block === 'N'">
                    <a href="#" class="btn_menu" @click.prevent="commentReport(i)"></a>
                    <div class="menu_more_layer" v-show = "commentReportBox === true && commentReportNum === i">
                        <a href="#" @click="messageSend(comment.track_comment_member_id)">메시지 보내기</a>
                        <a href="#" @click="reportUser(comment.track_comment_member_id, comment.track_comment_content, comment.track_comment_no, comment.member_nickname)">신고하기</a>
                        <a href="#" @click="blockUser(comment.track_comment_member_id, 'BLOCK')">차단하기</a>
                    </div>
              </span>
            </span>
            <!-- <span class="c_event" v-if="!chkVisible(comment)"><button class="btn_lock_s on"></button></span> -->
            <span class="c_text" v-if="chkVisible(comment)" v-html="$replaceTextareaNewLine(comment.track_comment_content)"></span>
            <span class="c_text" v-if="!chkVisible(comment)">비밀글 입니다.</span>
            <span class="date">{{comment.track_comment_reg_date}}</span>
            <span class="btn_reply_view" @click="showReplyList(comment.track_comment_no, i)">댓글 {{comment.commentCount}}</span>
            <span class="btn_reply">
              <a href="#" v-if="chkEditable(comment)" @click.prevent="showModifyComment(comment)" class="btn_modify"></a>
              <a href="#" class="btn_trash" @click.prevent="deleteReply('comment', comment.track_comment_no, i)"
                 v-if="this.$store.state.Member.memberId === comment.track_comment_member_id"></a>
              <a href="#" class="btn_s" @click.prevent="writeReply(i)" v-if="this.$store.state.Member.memberId !== ''">댓글</a>
            </span>
          </span>
        </div>
        <div class="comment_box" v-if="comment.toggle">
            <div class="comment_modify">
                <div class="comment_textarea">
                    <span class="modify_id">{{this.$NVLAB(comment.member_nickname, comment.track_comment_member_id)}}</span>
                    <textarea v-model="comment.tempContents"></textarea>
                    <span class="comment_btn">
                      <button style="display:block" class="btn_lock_s" :class="{on: comment.pwd_chk !== 'N'}" @click="comment.pwd_chk==='Y'?comment.pwd_chk = 'N' : comment.pwd_chk = 'Y'"></button>
                      <span class="text_count">({{comment.tempContents ? comment.tempContents.length : 0}}/210)</span>
                      <a href="javascript:" @click="comment.toggle = !comment.toggle">취소</a>
                      <a href="javascript:" @click="updateComment(comment)" class="text_org">수정</a>
                    </span>
                </div>
            </div>
        </div>
        <div class="reply_area">
          <div class="comment_g" v-if="comment.insertReply">
            <div class="comment_textarea">
              <textarea :id="'reply_text_' + i" v-model="comment.tempReply" placeholder="댓글을 작성해 주세요"></textarea>
              <span class="comment_btn">
                <button style="display:block" class="btn_lock_s" :class="{on: comment.reply_pwd_chk && comment.reply_pwd_chk !== 'N'}" @click="comment.reply_pwd_chk && comment.reply_pwd_chk==='Y' ? comment.reply_pwd_chk = 'N' : comment.reply_pwd_chk = 'Y'"></button>
                <span class="text_count">({{comment.tempReply ? comment.tempReply.length : 0}}/210)</span>
                <a href="#" @click.prevent="comment.insertReply = false">취소</a>
                <a href="#" class="text_org" @click="insertReply(comment, i)">등록</a>
              </span>
            </div>
          </div>
          <template v-for="(nestedComment, j) in comment.nestedCommentList" :key="j">
            <div class="comment_g" v-if="!nestedComment.modifyReply">
              <span class="c_profile">
                <router-link :to="`/mytranggle/friendInfo/` + nestedComment.track_comment_member_id" style="color:#333">
                  <img :src="nestedComment.profile" />
                </router-link>
              </span>
              <span class="c_article">
                <span class="c_id">
                  <router-link :to="`/mytranggle/friendInfo/` + nestedComment.track_comment_member_id" style="color:#333">
                    {{this.$NVLAB(nestedComment.member_nickname, nestedComment.track_comment_member_id)}} (LV{{nestedComment.info_level}})
                  </router-link>
                </span>
                <span class="c_event" >
                  <button class="btn_lock_s on" v-if="nestedComment.pwd_chk === 'Y' && nestedComment.is_block === 'N'"></button>
                  <button class="btn_s" v-if = "nestedComment.is_block === 'Y'" @click="blockUser(nestedComment.track_comment_member_id, 'BLOCK_CLEAR')">차단해제</button>
                  <span class="menu_more" v-if = "nestedComment.track_comment_member_id !== this.$store.state.Member.memberId && nestedComment.is_block === 'N'">
                    <a href="#" class="btn_menu" @click.prevent="commentReport('s'+j)"></a>
                    <div class="menu_more_layer" v-show = "commentReportBox === true && commentReportNum === 's'+j">
                        <a href="#" @click="messageSend(nestedComment.track_comment_member_id)">메시지 보내기</a>
                        <a href="#" @click="reportUser(nestedComment.track_comment_member_id, nestedComment.track_comment_content, nestedComment.track_comment_no ,nestedComment.member_nickname)">신고하기</a>
                        <a href="#" @click="blockUser(nestedComment.track_comment_member_id, 'BLOCK')">차단하기</a>
                    </div>
                  </span>
                </span>
                <span class="c_text" v-if="chkVisibleReply(comment, nestedComment)" v-html="$replaceTextareaNewLine(nestedComment.track_comment_content)"></span>
                <span class="c_text" v-if="!chkVisibleReply(comment, nestedComment)">비밀글 입니다.</span>
                <span class="date">{{nestedComment.track_comment_reg_date}}</span>
                <span class="btn_reply">
                  <a href="#" v-if="chkEditable(nestedComment)" @click.prevent="showModifyReply(nestedComment)" class="btn_modify"></a>
                  <a href="#" v-if="chkDeletable(nestedComment)" class="btn_trash" @click.prevent="deleteReply('nested', nestedComment.track_comment_no, i, j)"></a>
                </span>
              </span>
            </div>
            <div class="comment_g" v-if="nestedComment.modifyReply">
              <div class="comment_modify">
                  <div class="comment_textarea">
                      <span class="modify_id">{{this.$NVLAB(nestedComment.member_nickname, nestedComment.track_comment_member_id)}}</span>
                      <textarea id="textarea5" v-model="nestedComment.tempReply" placeholder="댓글을 작성해주세요."></textarea>
                      <span class="comment_btn">
                        <button style="display:block" class="btn_lock_s" :class="{on: nestedComment.pwd_chk !== 'N'}" @click="nestedComment.pwd_chk==='Y'?nestedComment.pwd_chk = 'N' : nestedComment.pwd_chk = 'Y'"></button>
                        <span class="text_count">({{nestedComment.tempReply.length}}/210)</span>
                        <a href="javascript:" @click="nestedComment.modifyReply = !nestedComment.modifyReply">취소</a>
                        <a href="javascript:" @click="updateReply(nestedComment, i)" class="text_org">수정</a>
                      </span>
                  </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'courseDetailComment',
  data () {
    return {
      commentText: '',
      commentLock: 'N',
      commentOpt: 'ALL',
      page: 1,
      commentList: [],
      nestedCommentList: [],
      commentReportBox: false,
      commentReportNum: null
    }
  },
  props: {
    courseInfo: Object,
    trackYear: String
  },
  components: {
  },
  computed: {
    getLeftContentHeight () {
      if (this.$store.state.Common.courseMenuType === 'my' &&
        this.$store.state.Member.memberId === this.courseInfo.member.member_id) {
        return 'height: calc(100vh - 500px);'
      } else {
        return 'height: calc(100vh - 416px);'
      }
    }
  },
  methods: {
    blockUser (targetId, mode) { // 차단하기, 차단 해제하기
      this.commentReportBox = false
      var herder = ''
      var message = ''
      if (mode === 'BLOCK') {
        herder = '차단하기'
        message = '닉네임님이 트랭글에서 작성한 글과<br/>댓글이 보이지 않고,<br/> 알림도 발송되지 않습니다.'
      }
      if (mode === 'BLOCK_CLEAR') {
        herder = '차단해제'
        message = '차단을 해제하시겠습니까?'
      }
      this.$showConfirmPopup(herder, message, () => {
        this.axios.get('/friend/update.json', {
          params: {
            token: this.$store.state.Member.token,
            friend_id: targetId,
            status: mode
          }
        })
          .then(data => {
            if (data.data.response.code === '00') {
              this.$showMessagePopup('', data.data.response.message)
              this.selectCommentList(1)
            } else {
              this.$showMessagePopup('', data.data.response.message)
            }
          })
      })
    },
    reportUser (targetId, text, no, targetNick) { // 신고하기
      console.log(this.commentList)
      this.commentReportBox = false
      this.$store.state.Popup.isOpenReportPopup = true
      this.$store.state.Popup.reportData = { reportText: text, board_id: 'course', post_no: no, declare_id: targetId, declare_nick: targetNick }
    },
    messageSend (targetId) {
      this.commentReportBox = false
      this.$store.state.Popup.isOpenMessageSendPopup = true
      this.$store.state.Popup.sendId = targetId
    },
    commentReport (num) {
      if (this.commentReportBox === false) {
        this.commentReportBox = true
        this.commentReportNum = num
      } else if (this.commentReportBox === true && this.commentReportNum !== num) {
        this.commentReportBox = true
        this.commentReportNum = num
      } else if (this.commentReportBox === true && this.commentReportNum === num) {
        this.commentReportBox = false
        this.commentReportNum = null
      }
    },
    commentReLoad (code) {
      this.commentOpt = code
      this.selectCommentList(1)
    },
    selectCommentList (page) {
      this.page = page
      this.commentList = []
      if (this.commentOpt === 'MY') {
        console.log(this.courseInfo.track.log_track_no)
        this.axios.get('/course/getReplyFromUserTrackForMe.json', {
          params: {
            token: this.$store.state.Member.token,
            view_count: 100,
            page: this.page,
            user_track_no: this.courseInfo.track.log_track_no
          }
        })
          .then(data => {
            // console.log(data)
            if (data.data.response.code === '00') {
              console.log(data)
              this.commentList = data.data.response.content.recommend
              this.commentList.forEach((comment, index) => {
                comment.showReplyList = false
              })
            }
          })
      } else {
        this.axios.get('/course/getReplyFromUserTrack.json', {
          params: {
            token: this.$store.state.Member.token,
            view_count: 100,
            page: this.page,
            user_track_no: this.courseInfo.track.log_track_no
          }
        })
          .then(data => {
            if (data.data.response.code === '00') {
              console.log(data)
              this.commentList = data.data.response.content.recommend
              this.commentList.forEach((comment, index) => {
                comment.showReplyList = false
              })
            }
          })
      }
    },
    selectNestedCommentList (trackCommentNo, index) {
      this.axios.get('/course/getReReplyFromUserTrack.json', {
        params: {
          token: this.$store.state.Member.token,
          view_count: 100,
          page: 1,
          user_track_no: this.courseInfo.track.log_track_no,
          track_comment_no: trackCommentNo
        }
      })
        .then(data => {
          if (data.data.response.code === '00') {
            console.log(data)
            console.log('data')
            if (this.commentOpt === 'MY') {
              var tempArray = []
              for (const i in data.data.response.content.comment) {
                if (data.data.response.content.comment[i].track_comment_member_id === this.$store.state.Member.memberId) {
                  tempArray.push(data.data.response.content.comment[i])
                }
              }
              this.commentList[index].nestedCommentList = tempArray
              this.commentList[index].commentCount = tempArray.length
            } else {
              this.commentList[index].nestedCommentList = data.data.response.content.comment
              this.commentList[index].commentCount = data.data.response.content.comment.length
            }
          }
        })
    },
    showReplyList (trackCommentNo, index) {
      if (this.commentList[index].commentCount > 0) {
        this.commentList[index].showReplyList = !this.commentList[index].showReplyList
        if (this.commentList[index].showReplyList) {
          this.selectNestedCommentList(trackCommentNo, index)
        } else {
          this.commentList[index].nestedCommentList = []
        }
      }
    },
    insertComment () {
      if (this.commentText.length > 0) {
        const formData = new FormData()
        formData.append('token', this.$store.state.Member.token)
        formData.append('user_track_no', this.courseInfo.track.log_track_no)
        formData.append('track_no', this.courseInfo.track.log_no)
        formData.append('content', this.commentText)
        formData.append('pwd_chk', this.commentLock)

        this.axios.post('/course/commentWrite.json', formData)
          .then(data => {
            const responseCode = data.data.response.code

            if (responseCode === '00') {
              this.$showMessagePopup('', data.data.response.message)
              this.selectCommentList(1)
              this.commentText = ''
            } else {
              this.$showMessagePopup('', '오류가 발생했습니다.<br />재등록 부탁드립니다.')
            }
          })
      } else {
        this.$showMessagePopup('', '댓글을 입력해주세요.')
      }
    },
    updateComment (comment) {
      this.$showConfirmPopup('', '저장하시겠습니까?', () => {
        const formData = new FormData()
        formData.append('token', this.$store.state.Member.token)
        formData.append('track_comment_no', comment.track_comment_no)
        formData.append('content', comment.tempContents)

        this.axios.post('/course/commentModify.json', formData)
          .then(data => {
            if (data.data.response.code === '00') {
              this.updateCommentLock(comment)
              this.$showMessagePopup('', data.data.response.message)
              this.selectCommentList(1)
            } else {
              this.$showMessagePopup('', data.data.response.message)
            }
          })
      })
    },
    updateCommentLock (comment) {
      const formData = new FormData()
      formData.append('token', this.$store.state.Member.token)
      formData.append('track_comment_no', comment.track_comment_no)
      formData.append('pwd_chk', comment.pwd_chk)

      this.axios.post('/course/setPwdCommentUpdate.json', formData)
        .then(data => {
          if (data.data.response.code !== '00') {
            this.$showMessagePopup('', data.data.response.message)
          }
        })
    },
    writeReply (index) {
      this.commentList.forEach((comment, commentIndex) => {
        if (commentIndex === index) {
          comment.insertReply = true
        } else {
          comment.insertReply = false
        }
      })
    },
    insertReply (comment, index) {
      if (comment.tempReply.length > 0) {
        const formData = new FormData()
        formData.append('token', this.$store.state.Member.token)
        formData.append('user_track_no', this.courseInfo.track.log_track_no)
        formData.append('track_no', this.courseInfo.track.log_no)
        formData.append('track_comment_no', comment.track_comment_no)
        formData.append('content', comment.tempReply)
        formData.append('pwd_chk', comment.reply_pwd_chk == null ? 'N' : comment.reply_pwd_chk)

        this.axios.post('/course/commentWrite.json', formData)
          .then(data => {
            const responseCode = data.data.response.code

            if (responseCode === '00') {
              this.selectNestedCommentList(comment.track_comment_no, index)
              comment.insertReply = false
              comment.showReplyList = true
              comment.tempReply = ''
              this.$showMessagePopup('', data.data.response.message)
            } else {
              this.$showMessagePopup('', '오류가 발생했습니다.<br />재등록 부탁드립니다.')
            }
          })
      } else {
        this.$showMessagePopup('', '댓글을 입력해주세요.')
      }
    },
    updateReply (nestedComment, index) {
      this.$showConfirmPopup('', '저장하시겠습니까?', () => {
        const formData = new FormData()
        formData.append('token', this.$store.state.Member.token)
        formData.append('track_comment_no', nestedComment.track_comment_no)
        formData.append('content', nestedComment.tempReply)

        this.axios.post('/course/commentModify.json', formData)
          .then(data => {
            if (data.data.response.code === '00') {
              this.updateCommentLock(nestedComment)
              this.$showMessagePopup('', data.data.response.message)
              this.selectNestedCommentList(nestedComment.track_comment_parent_no, index)
            } else {
              this.$showMessagePopup('', data.data.response.message)
            }
          })
      })
    },
    deleteReply (type, trackCommentNo, index, nestedIndex) {
      this.$showConfirmPopup('댓글 삭제', '삭제하시겠습니까?', () => {
        const formData = new FormData()
        formData.append('token', this.$store.state.Member.token)
        formData.append('track_comment_no', trackCommentNo)

        this.axios.post('/course/commentDelete.json', formData)
          .then(data => {
            const responseCode = data.data.response.code

            if (responseCode === '00') {
              if (type === 'comment') {
                this.commentList.splice(index, 1)
              } else if (type === 'nested') {
                this.commentList[index].nestedCommentList.splice(nestedIndex, 1)
                this.commentList[index].commentCount = this.commentList[index].commentCount - 1
              }
            } else {
              this.$showMessagePopup('댓글 삭제', data.data.response.message)
            }
          })
      })
    },
    showModifyComment (comment) {
      comment.tempContents = comment.track_comment_content
      comment.toggle = true
    },
    showModifyReply (nestedComment) {
      nestedComment.tempReply = nestedComment.track_comment_content
      nestedComment.modifyReply = true
    },
    chkEditable (list) {
      if (list.track_comment_member_id === this.$store.state.Member.memberId) {
        return true
      } else {
        return false
      }
    },
    chkDeletable (list) {
      if (list.track_comment_member_id === this.$store.state.Member.memberId) {
        return true
      } else {
        return false
      }
    },
    chkVisible (list) {
      if (list.pwd_chk === 'N' || list.track_comment_member_id === this.$store.state.Member.memberId || this.courseInfo.member.member_id === this.$store.state.Member.memberId) {
        return true
      } else {
        return false
      }
    },
    chkVisibleReply (comment, nestedComment) {
      if (nestedComment.pwd_chk === 'N' || nestedComment.track_comment_member_id === this.$store.state.Member.memberId ||
        this.courseInfo.member.member_id === this.$store.state.Member.memberId || comment.track_comment_member_id === this.$store.state.Member.memberId) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    this.selectCommentList(1)
  }
}
</script>

<style>

</style>
