<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <div class="popup_box popup_box_l">
        <div class="popup_top popup_top_line"><h2>내 기록 수정</h2>
          <span class="top_left"><a href="#" class="btn" @click="this.$store.commit('Course/controlModifyMyRecordPopup')">취소</a></span>
          <span class="top_right">
            <a href="#" class="btn_default" @click="completeMyRecord">완료</a>
          </span>
        </div>
        <div class="popup_body">
          <div class="option_area">
            <ul>
              <li>
                <span class="t1">제목</span>
                <span class="t2">
                  <input type="text" v-model="trackName" class="default" />
                </span>
              </li>
              <li>
                <span class="t1">#해시태그<br/>(여러개일 경우 ,로 구분)</span>
                <span class="t2">
                  <Hashtags ref= "tagTap"/>
                </span>
              </li>
              <li>
                <span class="set_text">이미지</span>
                <span class="set_input">
                  <span class="src_photo_g">
                    <label for="mainImage1" id="file_01_label">
                      <span class="src_photo" :class="{'on': file_01_preview !== ''}">
                        <img v-if="file_01_preview" :src="file_01_preview" style="margin-right: 15px;" />
                        <a href="javascript:" @click="imageDelete('file_01')" v-if="file_01_preview"><span class="img_del"></span></a>
                      </span>
                      <input type="file" id="mainImage1" ref="file_01" v-show="false" @change="imageUpload('file_01', 'file_01_preview', $event)" accept="image/*"/>
                    </label>
                    <label for="mainImage2" id="file_02_label">
                      <span class="src_photo" :class="{'on': file_02_preview !== ''}">
                        <img v-if="file_02_preview" :src="file_02_preview" style="margin-right: 15px;" />
                        <a href="javascript:" @click="imageDelete('file_02')" v-if="file_02_preview"><span class="img_del"></span></a>
                      </span>
                      <input type="file" id="mainImage2" ref="file_02" v-show="false" @change="imageUpload('file_02', 'file_02_preview', $event)" accept="image/*"/>
                    </label>
                    <label for="mainImage3" id="file_03_label">
                      <span class="src_photo" :class="{'on': file_03_preview !== ''}">
                        <img v-if="file_03_preview" :src="file_03_preview" style="margin-right: 15px;" />
                        <a href="javascript:" @click="imageDelete('file_03')" v-if="file_03_preview"><span class="img_del"></span></a>
                      </span>
                      <input type="file" id="mainImage3" ref="file_03" v-show="false" @change="imageUpload('file_03', 'file_03_preview', $event)" accept="image/*"/>
                    </label>
                  </span>
                </span>
              </li>
              <li>
                <span class="t1">내용</span>
                <span class="t2">
                  <quill-editor ref="editor"
                    :toolbar="[[{ 'header': [1, 2, 3, false] }],
                      , ['bold', 'italic', 'underline', 'link', 'image']
                      , [{ 'list': 'ordered'}, { 'list': 'bullet' }]
                      , ['clean']]"
                    v-model:content="intro" contentType="html" @ready="onEditorReady($event)">
                  </quill-editor>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div><!--/container-->
</template>
<script>
import Hashtags from '@/components/story/HashTags.vue'
import { QuillEditor } from '@vueup/vue-quill'

export default {
  data () {
    return {
      trackName: this.courseInfo.track.log_track_name,
      intro: this.courseInfo.track.log_content != null ? this.courseInfo.track.log_content : '',
      file_01_loaded: false,
      file_01: '',
      file_01_preview: '',
      file_01_imageNo: '',
      file_02_loaded: false,
      file_02: '',
      file_02_preview: '',
      file_02_imageNo: '',
      file_03_loaded: false,
      file_03: '',
      file_03_preview: '',
      file_03_imageNo: '',
      deleteImage: []
    }
  },
  name: 'ModifyMyRecordPopup',
  components: {
    QuillEditor: QuillEditor,
    Hashtags: Hashtags
  },
  props: {
    courseInfo: Object
  },
  async mounted () {
    this.setHashTags()
    await this.$store.dispatch('Course/getTrackImage')
    this.setTrackImageList()
  },
  methods: {
    setHashTags () {
      this.$store.state.Story.writetags = []
      this.courseInfo.tags.forEach((tag, index) => {
        this.$store.state.Story.writetags.push({ value: tag.tag_name, select: false })
      })
      this.$refs.tagTap.pushTags()
    },
    onEditorReady (e) {
      e.container.querySelector('.ql-blank').innerHTML = this.intro
    },
    completeMyRecord () {
      if (this.trackName !== '') {
        this.$showConfirmPopup('내 기록 수정', '저장하시겠습니까?', () => {
          const formData = new FormData()
          formData.append('token', this.$store.state.Member.token)
          formData.append('track_key', this.courseInfo.track.log_track_no)
          formData.append('track_name', this.trackName)
          formData.append('track_content', this.intro)
          formData.append('deleteIamgeNo', this.deleteImage.join('|'))

          if (!(this.file_01 === '' && this.file_01_preview === '')) {
            formData.append('file_01', this.file_01)
          }
          if (!(this.file_02 === '' && this.file_02_preview === '')) {
            formData.append('file_02', this.file_02)
          }
          if (!(this.file_03 === '' && this.file_03_preview === '')) {
            formData.append('file_03', this.file_03)
          }

          var tag = ''

          if (this.$store.state.Story.writetags.length > 0) {
            for (const i in this.$store.state.Story.writetags) {
              if (i === '0') {
                tag = this.$store.state.Story.writetags[i].value
              } else {
                tag = tag + ',' + this.$store.state.Story.writetags[i].value
              }
            }
          }
          formData.append('tag', tag)

          this.axios.post('/course/updateTrack.json', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then(data => {
              const responseCode = data.data.response.code
              if (responseCode === '00') {
                this.$emit('complete')
                this.$store.state.Course.trackImageList = []
                this.$store.commit('Course/controlModifyMyRecordPopup')
              } else {
                this.$showMessagePopup('내 기록 수정', data.data.response.message)
              }
            })
        })
      } else {
        this.$showMessagePopup('내 기록 수정', '제목을 입력하세요.')
      }
    },
    imageDelete (file, e) {
      // e.target.files[0].value = ''
      this[file] = ''
      this[file + '_preview'] = ''
      this.$refs[file].value = ''
      if (!this[file + '_loaded']) {
        this.deleteImage.push(this[file + '_imageNo'])
      }

      const content = document.getElementById(file + '_label')
      const parent = content.parentNode
      this.insertAfter(content, parent.lastElementChild)
    },
    imageUpload (file, preview, e) {
      this.inputChanged = true
      if (e.target.files[0] === undefined) return
      this[file] = e.target.files[0]
      var reader = new FileReader()
      reader.onload = (e) => {
        this[preview] = e.target.result
      }
      reader.readAsDataURL(e.target.files[0])
      this[file + '_loaded'] = true
    },
    setTrackImageList () {
      this.$store.state.Course.trackImageList.forEach((value, index) => {
        this['file_0' + (index + 1) + '_preview'] = this.$addHtml(value.resource_filename)
        this['file_0' + (index + 1) + '_imageNo'] = value.image_no
      })
    },
    insertAfter (newNode, lastNode) {
      if (!lastNode.nextSibling) {
        lastNode.parentNode.insertBefore(newNode, lastNode.nextSibling)
      } else {
        lastNode.parentNode.appendChild(newNode)
      }
    }
  }
}
</script>

<style scoped>
  .option_area li:last-of-type .t2 {display: block; height: 150px;}
  .popup_body p {text-align: left;}
  .option_area span.set_text {
    flex-basis: 155px;
    flex-shrink: 0;
    font-size: 1.5rem;
    padding-top: 12px;
  }
  .src_photo.on {background-image: none;}
</style>
