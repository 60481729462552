import router from '@/router'

export default {
  namespaced: true,
  state: {
    // 네/아니오 버튼 팝업 표시 여부
    isOpenConfirmPopup: false,
    // 신고하기 취소/신고 버튼 팝업 표시 여부
    isOpenConfirmReportPopup: false,
    // 회원가입 팝업 표시 여부
    isOpenLoginPopup: false,
    isLoginPopupBackEvent: 0,
    // 신고하기 팝업 표시 여부
    isOpenReportPopup: false,
    // 신고하기 텍스트
    reportData: {},
    // 공유하기 팝업 표시 여부
    isOpenSharePopup: false,
    // 메세지 보내기 팝업 표시 여부
    isOpenMessageSendPopup: false,
    // 메세지 보낼 아이디
    sendId: '',
    sendNickName: '',
    // 메시지 버튼 팝업 표시 여부
    isOpenMessagePopup: false,
    // 공유 메세지 팝업 표시 여부
    isOpenShareMessageSendPopup: false,
    // 콜백 메소드 메시지 버튼 팝업 표시 여부
    isOpenCallBackMessagePopup: false,
    // 팝업 헤더 메시지
    popupHeaderMessage: '',
    // 팝업 바디 메시지
    popupBodyMessage: '',
    // 링크
    link: null,
    // 팝업 텍스트 이름
    popupButtonText: '확인',
    // 네 버튼 클릭시 실행할 메소드
    callbackMethod: null,

    // 검색옵션 팝업 표출 여부
    isOpenSearchPopup: false,
    // 검색옵션 팝업 헤더 메세지
    popupTitle: '',

    // 검색옵션 내용 표출 여부
    // 검색옵션 지역
    SearchAddr: false,
    // 검색옵션 기간
    SearchPeriod: false,
    // 검색옵션 종목
    SearchEvent: false,
    // 검색옵션 운동거리
    SearchSportDistance: false,
    // 검색옵션 운동시간
    SearchSportTime: false,
    // 검색옵션 배지 유형
    SearchBadgeType: false,
    // 검색옵션 획득 배지수
    SearchSecureBdage: false,
    // 페이지 화면
    SearchPage: '',
    SearchOldPage: '',
    // 회원 설정하기 팝업 오픈 여부
    isOpenUpdateMemberInfo: false,
    isOpenAttachCoursePopup: false,
    // 기록 첨부 된 트랙
    attachTrack: {
      track_name: '',
      track_key: '',
      track_year: '',
      track_no: '',
      category: ''
    },
    // 기록 첨부 리스트 페이지 노출 여부
    isVisibleAttachCourseList: true,
    // 기록 첨부 상세 페이지 노출 여부
    isVisibleAttachCourseDetail: false,
    // 기록 첨부 리스트 페이지에서 선택 된 임시 트랙 키
    tempAttachTrackKey: '',
    // 운동 정보 팝업 오픈 여부
    isOpenTrackInfoPopup: false,
    // 운동 정보 팝업에 쓰이는 트랙 키 (track_year + log_no 또는 track_key)
    trackInfoTrackKey: '',
    // 이미지 뷰 팝업
    isOpenViewImagePopup: false,
    // 이미지 뷰 팝업 이미지 리스트
    imageList: [],
    // 이미지 뷰 팝업 이미지 리스트 인덱스
    imageListIndex: 0,

    // 공유하기 진입 페이지 체크
    sharePageChk: '',
    sharePostNo: '',
    shereYear: ''
  },
  mutations: {
    // 네/아니오 팝업 세팅
    setConfirmPopup (state, data) {
      state.popupHeaderMessage = data.popupHeaderMessage
      state.popupBodyMessage = data.popupBodyMessage
      state.callbackMethod = data.callbackMethod
      state.callbackCancelMethod = data.callbackCancelMethod
    },
    // 취소/신고 팝업 세팅
    setConfirmReportPopup (state, data) {
      state.popupHeaderMessage = data.popupHeaderMessage
      state.popupBodyMessage = data.popupBodyMessage
      state.callbackMethod = data.callbackMethod
      state.callbackCancelMethod = data.callbackCancelMethod
    },
    // 메시지 팝업 세팅
    setMessagePopup (state, data) {
      state.popupHeaderMessage = data.popupHeaderMessage
      state.popupBodyMessage = data.popupBodyMessage
      state.popupButtonText = data.popupButtonText
      state.link = data.link
    },
    // 메시지 팝업 세팅
    setCallBackMessagePopup (state, data) {
      state.popupHeaderMessage = data.popupHeaderMessage
      state.popupBodyMessage = data.popupBodyMessage
      state.callbackMethod = data.callbackMethod
    },
    // 네/아니오 버튼 팝업 제어
    controlConfirmPopup (state) {
      state.isOpenConfirmPopup = !state.isOpenConfirmPopup
    },
    // 취소/신고 버튼 팝업 제어
    controlConfirmReportPopup (state) {
      state.isOpenConfirmReportPopup = !state.isOpenConfirmReportPopup
    },
    // 회원가입 버튼 취소 제어
    controlLoginPopup (state) {
      state.isOpenLoginPopup = !state.isOpenLoginPopup
    },
    // 공유하기 버튼 취소 제어
    controlSharePopup (state) {
      state.isOpenSharePopup = !state.isOpenSharePopup
    },
    // 메시지 버튼 팝업 제어
    controlMessagePopup (state) {
      state.isOpenMessagePopup = !state.isOpenMessagePopup
      if (state.link != null && !state.isOpenMessagePopup) { // 팝업 확인 버튼 클릭 및 링크 파라미터 있을 경우
        state.popupButtonText = '확인'
        if (state.link.includes('http')) {
          window.location.href = state.link
        } else {
          router.replace(state.link)
        }
      }
    },
    // 콜백메소드 메시지 버튼 팝업 제어
    controlCallBackMessagePopup (state) {
      state.isOpenCallBackMessagePopup = !state.isOpenCallBackMessagePopup
    },
    // 검색 설정 입력
    setSearchData (state, data) {
      state.popupTitle = data.popupTitle
      state.SearchPage = data.opt
      if (state.SearchPage !== state.SearchOldPage) {
        this.state.Common.selectArea = []
        this.state.Common.searchPeriodCategory = []
        this.state.Common.searchTrackCategory = []
        this.state.Common.searchDistanceCategory = []
        this.state.Common.searchSportTimeCategory = []
        this.state.Common.searchBadgeTypeCategory = []
        this.state.Common.searchSecureBdageCategory = []
      }
      if (data.opt === 'badgelist') {
        state.SearchOldPage = data.opt
        // state.SearchAddr = true
        state.SearchEvent = true
        // state.SearchBadgeType = true
      } else if (data.opt === 'badgeDetails') {
        state.SearchOldPage = data.opt
        // state.SearchAddr = true
        state.SearchEvent = true
        // state.SearchBadgeType = true
      } else if (data.opt === 'myFriend') {
        state.SearchOldPage = data.opt
        state.SearchAddr = true
        state.SearchEvent = true
      } else if (data.opt === 'badgesearch') {
        state.SearchOldPage = data.opt
        state.SearchAddr = true
        state.SearchEvent = true
        state.SearchBadgeType = true
      }
    },
    // 검색옵션 취소 버튼 제어
    controlSearchPopup (state) {
      state.isOpenSearchPopup = !state.isOpenSearchPopup
    },
    // 검색옵션 완료 버튼
    controlSearchCommit (state) {
      // console.log(state.SearchPage)
      state.isOpenSearchPopup = !state.isOpenSearchPopup
    },
    // 회원 설정하기 팝업 제어
    controlUpdateMemberInfo (state) {
      state.isOpenUpdateMemberInfo = !state.isOpenUpdateMemberInfo
    },
    controlAttachCoursePopup (state, data) {
      state.isOpenAttachCoursePopup = !state.isOpenAttachCoursePopup
      state.isVisibleAttachCourseList = true
      state.isVisibleAttachCourseDetail = false
    },
    controlImagePopup (state) {
      state.isOpenViewImagePopup = !state.isOpenViewImagePopup
    },
    setAttachTrack (state, data) {
      console.log('data.track_name', data)
      state.attachTrack = {
        track_name: data == null ? '' : data.track_name,
        track_key: data == null ? '' : data.track_key,
        track_year: data == null ? '' : data.track_year,
        track_no: data == null ? '' : data.track_no,
        category: data == null ? '' : data.category
      }
      state.isOpenAttachCoursePopup = false
      state.isVisibleAttachCourseList = true
      state.isVisibleAttachCourseDetail = false
    },
    switchAttachPopup (state) {
      state.isVisibleAttachCourseList = !state.isVisibleAttachCourseList
      state.isVisibleAttachCourseDetail = !state.isVisibleAttachCourseDetail
    },
    openTrackInfoPopup (state, data) {
      state.isOpenTrackInfoPopup = true
      state.trackInfoTrackKey = data
    },
    closeTrackInfoPopup (state) {
      state.isOpenTrackInfoPopup = false
    },
    setImageList (state, data) {
      state.imageList = []
      state.imageList = data
    },
    setImageListIndex (state, data) {
      state.imageListIndex = data
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
  }
}
