<template>
  <!-- 운동 설정 -->
  <div class="right_body mt">
    <div class="setting_area">
        <ul>
            <li>
                <span class="set_text">만보기 사용</span>
                <span class="set_input">
                    <span class="g">
                        <input type="radio" id="configIsPaceCountersUseTrue" value="TRUE"  v-model="configIsPaceCountersUse" @change="configIsPaceCountersUseChange('TRUE')" />
                        <label for ="configIsPaceCountersUseTrue">사용</label>
                        <input type="radio" id="configIsPaceCountersUseFalse" value="FALSE" v-model="configIsPaceCountersUse" @change="configIsPaceCountersUseChange('FALSE')"/>
                        <label for ="configIsPaceCountersUseFalse">미사용</label>
                    </span>
                </span>
            </li>
            <li class="line_bottom">
                <span class="set_text">목표 걸음수</span>
                <span class="set_input">
                    <span class="g">
                        <input type="radio" id="configTargetWalk5000" value="5000" v-model="configTargetWalk" :disabled="configIsPaceCountersUse === 'FALSE'" @change="memberConfigDataUpt()"/>
                        <label for ="configTargetWalk5000">5,000</label>
                        <input type="radio" id="configTargetWalk7500" value="7500" v-model="configTargetWalk" :disabled="configIsPaceCountersUse === 'FALSE'" @change="memberConfigDataUpt()"/>
                        <label for ="configTargetWalk7500">7,500</label>
                        <input type="radio" id="configTargetWalk10000" value="10000" v-model="configTargetWalk" :disabled="configIsPaceCountersUse === 'FALSE'" @change="memberConfigDataUpt()"/>
                        <label for ="configTargetWalk10000">10,000</label>
                        <input type="radio" id="configTargetWalk12500" value="12500" v-model="configTargetWalk" :disabled="configIsPaceCountersUse === 'FALSE'" @change="memberConfigDataUpt()"/>
                        <label for ="configTargetWalk12500">12,500</label>
                        <input type="radio" id="configTargetWalk15000" value="15000" v-model="configTargetWalk" :disabled="configIsPaceCountersUse === 'FALSE'" @change="memberConfigDataUpt()"/>
                        <label for ="configTargetWalk15000">15,000</label>
                        <input type="radio" id="configTargetWalk20000" value="20000" v-model="configTargetWalk" :disabled="configIsPaceCountersUse === 'FALSE'" @change="memberConfigDataUpt()"/>
                        <label for ="configTargetWalk20000">20,000</label>
                    </span>
                </span>
            </li>
            <li class="mt">
                <span class="set_text">휴식모드 자동전환</span>
                <span class="set_input">
                    <span class="g">
                        <input type="radio" id="configIsBreakModeAutoUseTrue" value="TRUE" v-model="configIsBreakModeAutoUse" @change="memberConfigDataUpt()"/>
                        <label for="configIsBreakModeAutoUseTrue">사용</label>
                        <input type="radio" id="configIsBreakModeAutoUseFalse" value="FALSE" v-model="configIsBreakModeAutoUse" @change="memberConfigDataUpt()"/>
                        <label for="configIsBreakModeAutoUseFalse">미사용</label>
                    </span>
                </span>
            </li>
            <li>
                <span class="set_text">자전거 자동 휴식</span>
                <span class="set_input">
                    <span class="g">
                        <input type="radio" id="configTargetAutoBreak2" value="2" v-model="configTargetAutoBreak" :disabled="configIsBreakModeAutoUse === 'FALSE'" @change="memberConfigDataUpt()"/>
                        <label for="configTargetAutoBreak2">2km/h</label>
                        <input type="radio" id="configTargetAutoBreak5" value="5" v-model="configTargetAutoBreak" :disabled="configIsBreakModeAutoUse === 'FALSE'" @change="memberConfigDataUpt()"/>
                        <label for="configTargetAutoBreak5">5km/h</label>
                    </span>
                </span>
            </li>
        </ul>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      configIsPaceCountersUse: '',
      configTargetWalk: '',
      configIsBreakModeAutoUse: '',
      configTargetAutoBreak: ''
    }
  },
  methods: {
    ...mapActions('Member', [
      'updateMemberConfig'
    ]),
    configIsPaceCountersUseChange (bool) {
      if (bool === '') {
        this.configIsPaceCountersUse = 'ON'
      } else {
        this.configIsPaceCountersUse = bool
      }
      this.memberConfigDataUpt()
    },
    memberConfigDataUpt () {
      const data = {
        config_is_break_mode_auto_use: this.configIsBreakModeAutoUse,
        config_is_pace_counters_use: this.configIsPaceCountersUse,
        config_target_auto_break: this.configTargetAutoBreak,
        config_target_walk: this.configTargetWalk
      }
      this.updateMemberConfig(data)
    }
  }
}
</script>
