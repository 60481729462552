<template>
  <CourseSearchOptionPopup v-if="isOpenSearchOptionPopup" />
  <CourseBookBuyPopup v-if="isOpenCourseBookBuyPopup" />
  <CourseBookAgreePopup v-if="isOpenCourseBookAgreePopup" />
  <CompareTrackPopup v-if="isOpenCompareTrackLayerPopup" />
  <UploadGPXFilePopup v-if="isOpenUploadGPXFilePopup" />
</template>

<script>
import { mapState } from 'vuex'
import CourseSearchOptionPopup from './CourseSearchOptionPopup.vue'
import CourseBookBuyPopup from './CourseBookBuyPopup.vue'
import CourseBookAgreePopup from './CourseBookAgreePopup.vue'
import CompareTrackPopup from './CompareTrackPopup.vue'
import UploadGPXFilePopup from './UploadGPXFilePopup.vue'

export default {
  data () {
    return {
    }
  },
  components: {
    CourseSearchOptionPopup: CourseSearchOptionPopup,
    CourseBookBuyPopup: CourseBookBuyPopup,
    CourseBookAgreePopup: CourseBookAgreePopup,
    CompareTrackPopup: CompareTrackPopup,
    UploadGPXFilePopup: UploadGPXFilePopup
  },
  name: 'CoursePopup',
  computed: {
    ...mapState('Course', {
      isOpenSearchOptionPopup: 'isOpenSearchOptionPopup',
      isOpenCourseBookBuyPopup: 'isOpenCourseBookBuyPopup',
      isOpenCourseBookAgreePopup: 'isOpenCourseBookAgreePopup',
      isOpenCompareTrackLayerPopup: 'isOpenCompareTrackLayerPopup',
      isOpenUploadGPXFilePopup: 'isOpenUploadGPXFilePopup'
    })
  }
}
</script>

<style>

</style>
