<template>
  <div class="popup login_bg m_pop">
    <div class="popup_area">
      <div class="popup_box popup_box_lm">
        <div class="popup_top"><h2 class="head1" @click="popupcon = true">회원정보 설정</h2></div>
        <div class="popup_body login_body">
          <p>트랭글의 다양한 맞춤 서비스를 위하여 회원정보 설정을 완료해 주세요.<br><br>설정을 완료하시면 맞춤형 코스 제안, 코스별 예상시간 제공, 운동 성향에 따른 비교 등 나에게 필요한 다양한 정보를 얻을 수 있습니다.</p>
          <br><br>
          <div class="login_area">
            <ul>
              <li>
                <span class="set_text">닉네임</span>
                <span class="set_input1 input_l">
                  <input
                    type="text"
                    v-model="nickname"
                    @change="checkNickname"
                    placeholder="2~20자리로 입력하세요"
                    minlength="2"
                    maxlength="20"
                  />
                  <span v-if="validateNickname === 'Y' && duplicateNickname === 'N'" class="check_ok"></span>
                  <button v-if="validateNickname === 'Y' && duplicateNickname === ''" class="on" @click.prevent="checkDuplicate('NICKNAME', this.nickname)">중복확인</button>
                  <span v-if="duplicateNickname === 'N'" class="stext">사용가능한 닉네임입니다.</span>
                  <span v-if="duplicateNickname === 'Y'" class="stext text_red">이미 사용중인 닉네임으로 사용하실 수 없습니다.</span>
                  <span v-if="this.nickname !== '' && validateNickname === 'N'" class="stext text_red">사용 불가능한 닉네임입니다.</span>
                </span>
              </li>
              <!-- <li>
                <span class="set_text">이메일</span>
                <span class="set_input1 input_l">
                  <input type="text" v-model="email"
                  @change="checkEmail"
                  placeholder="이메일 주소를 입력하세요" />
                  <span class="check_ok" v-if="this.$store.state.Member.memberInfo.email != null && validateEmail === 'Y' && duplicateEmail === 'N'"></span>
                  <button class="on" v-if="validateEmail === 'Y' && duplicateEmail === ''" @click.prevent="checkDuplicate('EMAIL', this.email)">중복확인</button>
                  <span class="stext" v-if="this.email !== '' && duplicateEmail === 'N'">사용가능한 이메일입니다.</span>
                  <span class="stext text_red" v-if="duplicateEmail === 'Y'">이미 사용중인 이메일이므로 사용하실 수 없습니다.</span>
                  <span class="stext text_red" v-if="this.email !== '' && validateEmail === 'N'">사용 불가능한 이메일입니다.</span>
                </span>
              </li> -->
              <!-- :readonly="!(this.$store.state.Member.memberInfo.email === 'null' || this.$store.state.Member.memberInfo.email == null)" -->
              <li>
                <span class="set_text">이메일</span>
                <span class="set_input">
                  <input
                    type="text"
                    placeholder="email"
                    class="input_l"
                    v-model="email"
                    @keydown="checkEmail2"
                  />
                  <!-- <select v-if="domainName !== ''" name="" id="" class="style1 select_lm" required v-model="domainName" @change="checkEmail2">
                      <option disabled selected>선택하세요</option>
                      <option value="gmail.com">gmail</option>
                      <option value="naver.com">naver.com</option>
                      <option value="daum.net">daum.net</option>
                      <option value="nate.com">nate.com</option>
                      <option value="">직접입력</option>
                  </select>
                  <input v-else type="text" placeholder="직접입력" class="input_m" v-model="domainNameDetail" @keydown="checkEmail2"/> -->
                  <!-- <button class="on" v-if="validateEmail === 'Y' && duplicateEmail === ''" @click.prevent="checkDuplicate('EMAIL', this.email)">중복확인</button> -->
                  <span v-if="this.email !== '' && duplicateEmail === 'N'" class="stext">사용가능한 이메일입니다.</span>
                  <span v-if="duplicateEmail === 'Y'" class="stext text_red">이미 사용중인 이메일이므로 사용하실 수 없습니다.</span>
                  <span v-if="this.email !== '' && validateEmail === 'N'" class="stext text_red">사용 불가능한 이메일입니다.</span>
                </span>
              </li>
              <li>
                <span class="set_text">관심운동종목</span>
                <span class="set_input" @click="confingCategory">
                  <!--<span class="set_setting">설정하기</span>-->
                  <span class="set_setting" @click="isOpenTrackCategoryPopup = true">
                    <span class="text elp">{{ trackNameCategory.join(',') }}</span>
                  </span>
                </span>
              </li>
              <li>
                <span class="set_text">주소</span>
                <span class="set_input">
                  <span class="input_2line">
                    <span>
                      <input v-model="addr1" type="text" class="input_l"  placeholder="주소" readonly @click="findAddress" />
                      <span class="input_in_text_l"><a href="#" class="i_fin" @click="findAddress"></a>
                      </span>
                    </span>
                    <span>
                      <input v-model="addr2" type="text" class="input_l" placeholder="상세주소를 입력하세요"/>
                    </span>
                    <!--<span class="stext">기념품 수령을 위해 정확한 주소 입력 부탁드립니다.</span>-->
                  </span>
                </span>
              </li>
              <li>
                <span class="set_text">신체정보</span>
                <span class="set_input">
                  <span class="input_1line">
                    <input
                      v-model="height"
                      type="text"
                      class="input_s"
                      maxlength="3"
                      @keyup="height=height.replace(/[^0-9]/g, '')"
                      placeholder="키"
                    />cm
                    <input
                      v-model="weight"
                      type="text"
                      class="input_s"
                      maxlength="3"
                      @keyup="weight=weight.replace(/[^0-9]/g, '')"
                      placeholder="몸무게"
                    />kg
                  </span>
                </span>
              </li>
              <li>
                <span class="set_text">추천인ID</span>
                <span class="set_input">
                  <input v-model="recommandId" type="text" placeholder="추천인 ID를 입력하세요" />
                </span>
              </li>
            </ul>
          </div>
          <br>
          <br>
          <div class="btn_area btn_bottom2">
            <button type="button" class="" @click="closeUpdateMemberInfo()">취소</button>
            <button type="button" class="on" @click="updateMemberInfo">설정 완료</button>
          </div>
        </div>
      </div>
    </div>
  </div><!--/popup-->

  <div class="popup popup_bg" v-show="isOpenTrackCategoryPopup">
    <div class="popup_area">
      <div class="popup_box popup_box_lm sports_pop">
        <div class="popup_top popup_top_line"><h2>관심운동종목 선택</h2>
        </div>
        <div class="popup_body">
          <p>자주하는 운동 혹은 관심 운동을 선택해주세요 (최대 5개)<br /><br />
          미설정 시 개별 종목별 비교, 랭킹, 코스 제안 등의<br />
          맞춤 서비스 제공에 한계가 있습니다.</p>
          <br /><br />
          <div class="to_sports">
            <span
              v-for="(name, value) in $getTrackCategoryCustom()"
              :key="value"
              :class="{'on': trackCategory.includes(value) }"
              :data-value="value"
              @click="toggleTrackCategory(value, name)">
              <span :class="`i_s ${$categoryImage(value)}`"></span>
              <span class="to_text">{{ name }}</span>
            </span>
          </div>
        </div>
        <div class="btn_area btn_bottom2">
          <button type="button" @click="close()">취소</button>
          <button type="button" class="on" @click="applyTrackCategory">확인</button>
        </div>
      </div>
    </div>
  </div>
  <div class="popup popup_bg" v-if="popupcon">
    <div class="popup_area">
      <div class="popup_box popup_box_s">
        <div class="popup_top popup_top_line"><h2>이메일 주소</h2></div>
        <div class="popup_body">
          <div class="login_area">
            <span class="set_text">@ </span>
            <span class="set_input">
                <span>
                    <input type="text" class="input_email" placeholder=""/>
                </span>
            </span>
          </div>
          <div class="btn_area">
            <button type="button" class="btn_m" @click="popupcon = false">취소</button>
            <button type="button" class="btn_m on">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateMemberInfo',
  data () {
    // console.log(this.$emailExp(this.$store.state.Member.memberInfo.email).split('@')[1])
    return {
      popupcon: false,
      isOpenTrackCategoryPopup: false,
      zipcode: '',
      addr1: '',
      addr2: '',
      nickname: '',
      email: this.$store.state.Member.memberInfo.email === 'null' || this.$store.state.Member.memberInfo.email == null ? '' : this.$emailExp(this.$store.state.Member.memberInfo.email),
      // domainName: this.$emailDomain(this.$store.state.Member.memberInfo.email) !== '' ? '' : this.$emailDomain(this.$store.state.Member.memberInfo.email),
      // email: '',
      // domainName: '',
      height: '',
      weight: '',
      recommandId: '',
      preferCategory: '',
      validateNickname: '',
      duplicateNickname: '',
      validateEmail: this.$store.state.Member.memberInfo.email === 'null' || this.$store.state.Member.memberInfo.email == null ? 'Y' : '',
      validate2Email: this.$store.state.Member.memberInfo.email === 'null' || this.$store.state.Member.memberInfo.email == null ? 'Y' : '',
      duplicateEmail: this.$store.state.Member.memberInfo.email === 'null' || this.$store.state.Member.memberInfo.email == null ? 'N' : '',
      trackCategory: [],
      trackNameCategory: []
    }
  },
  methods: {
    close () {
      this.isOpenTrackCategoryPopup = false
      // this.nickname = ''
      // this.email = ''
      this.trackNameCategory = []
      // this.addr1 = ''
      // this.addr2 = ''
      // this.height = ''
      // this.weight = ''
      // this.recommandId = ''
      this.trackCategory = []
    },
    checkNickname () {
      this.validateNickname = (this.nickname.length >= 2 && this.nickname.length <= 20) ? 'Y' : 'N'
      this.duplicateNickname = ''
    },
    checkEmail () {
      const regEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
      this.validateEmail = regEmail.test(this.email) ? 'Y' : 'N'
      this.duplicateEmail = ''
    },
    checkEmail2 () {
      // 확인할 정규식
      const regEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
      var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
      // var chkEmail = ''
      // if (this.domainName === '') {
      //   chkEmail = this.email
      // } else {
      //   chkEmail = this.email + '@' + this.domainName
      // }
      this.validateEmail = regEmail.test(this.email) ? 'Y' : 'N'
      this.validate2Email = regExp.test(this.email) ? 'Y' : 'N'
      this.duplicateEmail = ''
      if (this.validateEmail === 'Y' && this.validate2Email === 'Y') {
        this.checkDuplicate('EMAIL', this.email)
      }
    },
    checkDuplicate (type, value) {
      if (value !== '') {
        let check = ''
        const formData = new FormData()
        formData.append('item', type)
        formData.append('string', value)
        this.axios.post('/Member/validation_check.json', formData)
          .then(data => {
            const responseCode = data.data.response.code

            if (responseCode === '00') {
              check = 'N'
            } else {
              check = 'Y'
            }

            if (type === 'NICKNAME') {
              this.duplicateNickname = check
            } else if (type === 'EMAIL') {
              this.duplicateEmail = check
            }
          })
      } else {
        this.$showMessagePopup('중복 확인', '중복 확인할 값을 정확하게 입력해주세요.')
      }
    },
    findAddress () {
      new window.daum.Postcode({
        oncomplete: (data) => {
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
          // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let fullRoadAddr = data.roadAddress // 도로명 주소 변수
          let extraRoadAddr = '' // 도로명 조합형 주소 변수

          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
            extraRoadAddr += data.bname
          } // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== '' && data.apartment === 'Y') {
            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName)
          }
          // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraRoadAddr !== '') {
            extraRoadAddr = ' (' + extraRoadAddr + ')'
          }
          // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
          if (fullRoadAddr !== '') {
            fullRoadAddr += extraRoadAddr
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          this.zipcode = data.zonecode // 5자리 새우편번호 사용
          this.addr1 = fullRoadAddr
        }
      }).open(this.$refs.embed)
    },
    updateMemberInfo () {
      if (this.validateNickname !== 'Y') {
        this.$showMessagePopup('회원정보 설정', '올바른 닉네임을 입력해주세요.')
        return false
      }
      if (this.duplicateNickname !== 'N') {
        this.$showMessagePopup('회원정보 설정', '닉네임 중복확인을 해주세요.')
        return false
      }
      if (this.validateEmail !== 'Y') {
        this.$showMessagePopup('회원정보 설정', '올바른 이메일을 입력해주세요.')
        return false
      }
      if (this.duplicateEmail !== 'N') {
        this.$showMessagePopup('회원정보 설정', '이메일 중복확인을 해주세요.')
        return false
      }

      const formData = new FormData()
      // formData.append('token', this.$store.state.Member.token)
      const memberObj = {
        nickname: this.nickname,
        email: this.email,
        zip_code: this.zipcode,
        addr1: this.addr1,
        addr2: this.addr2,
        height: this.height,
        weight: this.weight,
        recommand_id: this.recommandId,
        prefer_category: this.preferCategory
      }
      Object.keys(memberObj).forEach(el => {
        formData.append(el, memberObj[el])
      })
      this.axios.post('/Member/member_data_upt.json', formData)
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode === '00') {
            this.$showMessagePopup('회원정보 설정', '설정이 완료되었습니다', '/')
          } else {
            this.$showMessagePopup('회원정보 설정', data.data.response.message)
          }
        })
    },
    toggleTrackCategory (code, name) {
      if (!this.trackCategory.includes(code)) {
        if (this.trackCategory.length === 5) {
          this.$showMessagePopup('회원정보 설정', '관심운동종목은 최대 5개까지 선택 가능합니다.')
        } else {
          this.trackCategory.push(code)
          this.trackNameCategory.push(name)
        }
      } else {
        this.trackCategory.splice(this.trackCategory.indexOf(code), 1)
        this.trackNameCategory.splice(this.trackNameCategory.indexOf(name), 1)
      }
    },
    checkMaxLength (e) {
      if (e.target.value.length > e.target.maxLength) {
        e.target.value = e.target.value.slice(0, e.target.maxLength)
      }
    },
    applyTrackCategory () {
      this.preferCategory = this.trackCategory.join('|')
      this.isOpenTrackCategoryPopup = false
    },
    closeUpdateMemberInfo () {
      this.$store.state.Popup.isOpenUpdateMemberInfo = false
      this.$router.replace('/')
    }
  },
  mounted () {
  },
  components: {
  }

}
</script>

<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
</style>
