<template>
  <CommonConfirmPopup v-if="isOpenConfirmPopup" />
  <CommonMessagePopup v-if="isOpenMessagePopup" />
  <CommonCallBackMessagePopup v-if="isOpenCallBackMessagePopup" />
  <CommonSearchPopup v-if="isOpenSearchPopup" />
  <AttachCoursePopup v-if="isOpenAttachCoursePopup" />
  <TrackInfoPopup v-if="isOpenTrackInfoPopup" />
  <ViewImagePopup v-if="isOpenViewImagePopup" />
  <CommonLoginPopup v-if="isOpenLoginPopup"/>
  <ReportPopup v-if ="isOpenReportPopup"/>
  <MessagePopup v-if ="isOpenMessageSendPopup"/>
  <CommonShare v-if = "isOpenSharePopup"/>
  <MessageSharePopup v-if = "isOpenShareMessageSendPopup"/>
  <CommonConfirmReportPopup v-if="isOpenConfirmReportPopup"/>
</template>

<script>
import { mapState } from 'vuex'
import CommonConfirmPopup from './CommonConfirmPopup.vue'
import CommonConfirmReportPopup from './CommonConfirmReportPopup.vue'
import CommonLoginPopup from './CommonLoginPopup.vue'
import CommonMessagePopup from './CommonMessagePopup.vue'
import CommonCallBackMessagePopup from './CommonCallBackMessagePopup.vue'
import CommonSearchPopup from './CommonSearchPopup.vue'
import AttachCoursePopup from '@/components/course/popup/AttachCoursePopup.vue'
import TrackInfoPopup from '@/components/course/popup/TrackInfoPopup.vue'
import ViewImagePopup from './ViewImagePopup.vue'
import ReportPopup from './ReportPopup.vue'
import MessagePopup from './MessagePopup.vue'
import CommonShare from './CommonShare.vue'
import MessageSharePopup from './MessageSharePopup.vue'

export default {
  data () {
    return {
    }
  },
  components: {
    CommonConfirmPopup: CommonConfirmPopup,
    CommonLoginPopup: CommonLoginPopup,
    CommonSearchPopup: CommonSearchPopup,
    CommonMessagePopup: CommonMessagePopup,
    CommonCallBackMessagePopup: CommonCallBackMessagePopup,
    AttachCoursePopup: AttachCoursePopup,
    TrackInfoPopup: TrackInfoPopup,
    ViewImagePopup: ViewImagePopup,
    ReportPopup: ReportPopup,
    MessagePopup: MessagePopup,
    CommonShare: CommonShare,
    MessageSharePopup: MessageSharePopup,
    CommonConfirmReportPopup: CommonConfirmReportPopup
  },
  computed: {
    ...mapState('Popup', {
      isOpenConfirmPopup: 'isOpenConfirmPopup',
      isOpenMessagePopup: 'isOpenMessagePopup',
      isOpenCallBackMessagePopup: 'isOpenCallBackMessagePopup',
      isOpenSearchPopup: 'isOpenSearchPopup',
      isOpenAttachCoursePopup: 'isOpenAttachCoursePopup',
      isOpenTrackInfoPopup: 'isOpenTrackInfoPopup',
      isOpenViewImagePopup: 'isOpenViewImagePopup',
      isOpenLoginPopup: 'isOpenLoginPopup',
      isOpenReportPopup: 'isOpenReportPopup',
      isOpenMessageSendPopup: 'isOpenMessageSendPopup',
      isOpenSharePopup: 'isOpenSharePopup',
      isOpenShareMessageSendPopup: 'isOpenShareMessageSendPopup',
      isOpenConfirmReportPopup: 'isOpenConfirmReportPopup'
    })
  },
  name: 'CommonPopup'
}
</script>

<style>

</style>
