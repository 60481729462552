<template>
  <span class="calendar">
    <Datepicker v-model="currentDate" :format="format" locale="ko" @update:modelValue="selectedDate" autoApply :enableTimePicker="false" @cleared="cleared"
      :minDate="this.minDate" :maxDate="this.maxDate" :disabled="disabled" />
  </span>
  <select name="시간" ref="currentTime" v-model="currentTime" :disabled="disabled || hourDisabled" @change="selectedDate" class="time">
    <option value="00:00">오전 12:00</option>
    <option value="00:30">오전 12:30</option>
    <option value="01:00">오전 1:00</option>
    <option value="01:30">오전 1:30</option>
    <option value="02:00">오전 2:00</option>
    <option value="02:30">오전 2:30</option>
    <option value="03:00">오전 3:00</option>
    <option value="03:30">오전 3:30</option>
    <option value="04:00">오전 4:00</option>
    <option value="04:30">오전 4:30</option>
    <option value="05:00">오전 5:00</option>
    <option value="05:30">오전 5:30</option>
    <option value="06:00">오전 6:00</option>
    <option value="06:30">오전 6:30</option>
    <option value="07:00">오전 7:00</option>
    <option value="07:30">오전 7:30</option>
    <option value="08:00">오전 8:00</option>
    <option value="08:30">오전 8:30</option>
    <option value="09:00">오전 9:00</option>
    <option value="09:30">오전 9:30</option>
    <option value="10:00">오전 10:00</option>
    <option value="10:30">오전 10:30</option>
    <option value="11:00">오전 11:00</option>
    <option value="11:30">오전 11:30</option>
    <option value="12:00">오후 12:00</option>
    <option value="12:30">오후 12:30</option>
    <option value="13:00">오후 1:00</option>
    <option value="13:30">오후 1:30</option>
    <option value="14:00">오후 2:00</option>
    <option value="14:30">오후 2:30</option>
    <option value="15:00">오후 3:00</option>
    <option value="15:30">오후 3:30</option>
    <option value="16:00">오후 4:00</option>
    <option value="16:30">오후 4:30</option>
    <option value="17:00">오후 5:00</option>
    <option value="17:30">오후 5:30</option>
    <option value="18:00">오후 6:00</option>
    <option value="18:30">오후 6:30</option>
    <option value="19:00">오후 7:00</option>
    <option value="19:30">오후 7:30</option>
    <option value="20:00">오후 8:00</option>
    <option value="20:30">오후 8:30</option>
    <option value="21:00">오후 9:00</option>
    <option value="21:30">오후 9:30</option>
    <option value="22:00">오후 10:00</option>
    <option value="22:30">오후 10:30</option>
    <option value="23:00">오후 11:00</option>
    <option value="23:30">오후 11:30</option>
  </select>
</template>

<script>
import dayjs from 'dayjs'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

export default {
  name: 'DatePicker',
  props: {
    disabled: Boolean,
    hourDisabled: Boolean,
    dateId: String,
    paramMinDate: String,
    paramMaxDate: String
  },
  watch: {
    dateId (val) {
      if (val === '') {
        this.cleared()
      } else {
        this.currentDate = val.substr(0, 10)
        this.currentTime = val.substr(11, 5)
      }
    },
    paramMinDate (val) {
      this.minDate = val !== '' && val != null ? dayjs(val).format('YYYY-MM-DD') : null
    },
    paramMaxDate (val) {
      this.maxDate = val !== '' && val != null ? dayjs(val).format('YYYY-MM-DD') : null
    }
  },
  setup () {
    const format = (date) => {
      let day = String(date.getDate())
      let month = String(date.getMonth() + 1)
      const year = date.getFullYear()

      if (month.length === 1) {
        month = '0' + month
      }

      if (day.length === 1) {
        day = '0' + day
      }

      return `${year}.${month}.${day}`
    }
    return {
      format
    }
  },
  data () {
    return {
      currentDate: new Date(),
      currentTime: '00:00',
      minDate: null,
      maxDate: null
    }
  },
  methods: {
    cleared () {
      this.$emit('update:dateId', '')
      this.currentDate = ''
      this.currentTime = ''
    },
    selectedDate () {
      let year = '0000'
      let month = '00'
      let day = '00'
      if (this.currentDate !== null && this.currentDate !== '') {
        const tempDate = new Date(this.currentDate)
        year = tempDate.getFullYear()
        month = String(tempDate.getMonth() + 1)
        if (month.length === 1) {
          month = '0' + month
        }
        day = String(tempDate.getDate())
        if (day.length === 1) {
          day = '0' + day
        }
        if (this.currentTime === '') {
          this.currentTime = '00:00'
        }
      }
      this.$emit('update:dateId', `${year}-${month}-${day}` + ' ' + `${this.currentTime}:00`)
    }
  },
  components: {
    Datepicker: Datepicker
  },
  mounted () {
    this.currentDate = this.dateId.substr(0, 10)
    this.currentTime = this.dateId.substr(11, 5)
  }
}
</script>
<style>
  .dp__input {width: 135px;height: 36px;background-color: #f2f2f2;font-size: 1.2rem;border: none}
</style>
