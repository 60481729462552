
<template>
  <div class="footer">
    <div class="section">
      <div class="footer_g">
        <div class="f_head">
          <span class="f_logo"></span>
        </div>
        <div class="f_menu">
          <router-link to="/policy/terms">서비스 이용약관</router-link>
          <span class="foot_vertical">|</span>
          <router-link to="/policy/personalinformation" class="person_bold">개인정보처리방침</router-link>
        </div>
        <div class="f_company">
          <span class="company_name">(주)비글</span>
          <span class="company_text">
            대표 : 장치국 &nbsp;&nbsp; 사업자등록번호: 105-86-79276<br>
            주소: 경기도 성남시 분당구 판교역로 240 (삼평동 678) <br class="m-br" /><span>삼환하이팩스 9층</span><br>
            메일: trangglecs@tranggle.com<br>
            <!-- 임시 대표전화: 010-2137-0023<br> -->
            <!-- 대표전화: 070-7931-1552<br> -->
            통신판매업신고 제 2018-성남분당-1034호 &nbsp;&nbsp;<br class="m-br" />
            위치기반서비스사업자신고번호 제402호
          </span>
          <div class="f_sns_box">
            <a href="https://www.facebook.com/trangglegps" target="_blank" class="i_login_facebook"></a>
            <a href="https://pf.kakao.com/_xiEsCs" target="_blank" class="i_login_kakao"></a>
            <a href="https://www.instagram.com/trangglegps/" target="_blank" class="i_login_insta"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">Copyright ⓒ BEAGLE. All rights reservered</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>
