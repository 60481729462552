<template >
    <div class="popup popup_bg" style="z-index: 999;">
      <div class="popup_area">
        <div class="popup_box popup_box_l">
          <div class="popup_top"><h2>메시지 보내기<a href="#" class="btn_close" @click = "close"></a></h2>
            <span class="top_left"><a href="#" class="btn_close"></a></span>
          </div>
          <div class="popup_body">
            <div class="msg_send">
              <div class="msg_write">
                  <div class="title">메시지 작성</div>
                  <div class="msg_id">
                      <span class="stext">받는 사람</span>
                      <span class="input_text">
                        <span class="tag_id" v-for = "(list, i) in readyList" :key="i">{{this.$NVLAB(list.name,list.id)}}<a href="#" @click="deleteTarget(list.id)"></a></span>
                      </span>
                      <span class="text_count">{{readyCnt}}/500 명</span>
                  </div>

                  <div class="msg_text">
                    <span class="stext">메시지 내용</span>
                    <textarea placeholder="메시지를 입력하세요." v-model = "messegeText" @keydown="textInput" maxlength='800' oninput="numberMaxLength(this)"></textarea>
                    <span class="text_count">{{messegeTextCnt}}/800 자</span>
                  </div>
                  <button class= "on" @click="messageInput">메시지 보내기</button>
              </div>
              <div class="msg_member" >
                <div class="title">받는 사람 검색
                    <a href="#" class="btn_add" @click="infectTarget()"></a>
                    <a href="#" class="btn_back" @click="friendOn" v-if = "friendTap == false"/>
                </div>
                <div class="msg_id" >
                    <span class="search">
                        <span class="search_input input_def">
                            <input type="text" placeholder="닉네임, 아이디" id= "searchBox" v-model="searchText" @keydown.enter="friendSearch()"/>
                            <a href="#" class="i_fin" @click="friendSearch()"></a>
                            <a href="#" class="i_del"></a>
                        </span>
                    </span>
                </div>
                <div class="msg_search_list" v-if = "this.friendTap === true" @scroll="friendListScroll">
                    <div class="fold_area" :class="{'fold': this.useListTap === false}">
                        <div class="stitle">최근 사용<a class="btn_fold" @click="useListChg"></a></div>
                        <div class="result_member">
                            <ul>
                                <li v-for= "(list, i) in recentUseList" :key="i">
                                    <span class="i_check"><input type="checkbox" :id= list.no v-model="list.selected" @change="selected('recentUseList', list.friend_id)"/><label :for= list.no></label></span>
                                    <span class="profile"><img :src="this.$photoEmpty(list.friend_photo)" /></span>
                                    <span class="profile_info">
                                        <span class="info_nick">{{this.$NVLAB(list.friend_nickname,list.friend_id)}}</span>
                                        <span class="info_id">{{list.friend_id}}</span>
                                        <span class="info_rank">LV.{{this.$NVLAB(list.level,0)}}</span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="stitle">친구 ({{friendCnt}})</div>
                    <div class="result_member">
                        <ul>
                            <li v-for = "(list, i) in friendList" :key="i">
                                <span class="i_check"><input type="checkbox" :id= list.no v-model="list.selected" @change="selected('friendList',list.friend_id)"/><label :for= list.no></label></span>
                                <span class="profile"><img :src="this.$photoEmpty(list.friend_photo)" /></span>
                                <span class="profile_info">
                                    <span class="info_nick">{{this.$NVLAB(list.friend_nickname,list.friend_id)}}</span>
                                    <span class="info_id">{{list.friend_id}}</span>
                                    <span class="info_rank">LV.{{this.$NVLAB(list.level,0)}}</span>
                                </span>
                            </li>
                        </ul>
                    </div><!--/result_member-->
                </div>
                <!-- <infinite-scroll @infinite-scroll="loadDataFromServer" :message="message" :noResult="noResult"> -->
                  <div class="msg_search_list" v-if = "this.friendTap === false" @scroll="handleNotificationListScroll">
                      <div class="stitle">검색결과 ({{searchCnt}})</div>
                      <div class="result_member">
                          <!-- <ul ref="notification-list" @scroll="handleNotificationListScroll"> -->
                          <ul>
                              <li v-for = "(list, i) in searchList" :key="i">
                                  <span class="i_check"><input type="checkbox" :id= list.UserID v-model="list.selected" @change="selected('searchList',list.UserID)"/><label :for= list.UserID></label></span>
                                  <span class="profile"><img :src="this.$photoEmpty(list.Photo)" /></span>
                                  <span class="profile_info">
                                      <span class="info_nick">{{list.NickName}}</span>
                                      <span class="info_id">{{list.UserID}}</span>
                                      <span class="info_rank">LV.{{list.info_level}}</span>
                                  </span>
                              </li>
                              <!-- <li>
                                  <span class="i_check"><input type="checkbox" id="c7" /><label for="c7"></label></span>
                                  <span class="profile"><img src="../../assets/images/sample/profile.png" /></span>
                                  <span class="profile_info">
                                      <span class="info_nick">보리건빵</span>
                                      <span class="info_id">abdckel</span>
                                      <span class="info_rank">짐꾼 / LV.30</span>
                                  </span>
                              </li> -->
                          </ul>
                      </div><!--/result_member-->
                  </div>
                <!-- </infinite-scroll> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div><!--/container-->
</template>

<script>
export default {
  data () {
    return {
      msgMain: true,
      msgOneChat: false,
      oneChatNick: '',
      oneChatId: '',
      msgMultiChat: false,
      multiChatNick: '',
      multiUserInfo: [],
      openChatNo: '',
      chatBax: false,
      chatInput: false,
      chatText: '',
      msgRead: false,
      msgSend: true,
      NickNameBox: false,
      pop: false,
      drop_step: 'drop_down',
      noReadCount: 0,
      allCheck: false,
      messageRow: 0,
      messageRows: 0,

      // 대화창
      messageUsers: [],
      room_mid: '',

      // 메세지 보내기 화면
      searchText: '',
      searchList: [],
      searchCnt: 0,
      friendTap: true,
      useListTap: true,
      selectList: [],
      selectSearchList: [],
      readyList: [],
      readyCnt: 0,
      messegeText: '',
      messegeTextCnt: 0,
      errSendMessegeCnt: 0,
      errSendMessegeId: '',
      recentUseList: [],
      friendList: [],
      friendCnt: 0,
      friendRow: 0,
      searchRow: 0
    }
  },
  components: {
  },
  name: 'MessageSharePopup',
  methods: {
    close () {
      this.$store.state.Popup.isOpenShareMessageSendPopup = false
    },
    numberMaxLength (e) {
      if (e.value.length > e.maxLength) {
        e.value = e.value.slice(0, e.maxLength)
      }
    },
    handleNotificationListScroll (e) { // 받는사람 검색 스크롤
      const { scrollHeight, scrollTop, clientHeight } = e.target
      const isAtTheBottom = scrollHeight === scrollTop + clientHeight
      // 일정 한도 밑으로 내려오면 함수 실행
      if (isAtTheBottom) this.friendSearch()
    },
    friendListScroll (e) { // 친구 리스트 스크롤
      const { scrollHeight, scrollTop, clientHeight } = e.target
      const isAtTheBottom = scrollHeight === scrollTop + clientHeight
      // 일정 한도 밑으로 내려오면 함수 실행
      if (isAtTheBottom) this.friendRead()
    },
    messegeListScroll (e) { // 메세지 리스트 스크롤
      const { scrollHeight, scrollTop, clientHeight } = e.target
      const isAtTheBottom = scrollHeight === scrollTop + clientHeight
      // 일정 한도 밑으로 내려오면 함수 실행
      if (isAtTheBottom) this.friendRead()
    },
    messegeScroll (e) { // 메세지 스크롤
      // console.log(e)
      const { scrollTop } = e.target
      const isAtTheBottom = scrollTop === 0
      // // 일정 한도 밑으로 내려오면 함수 실행
      if (isAtTheBottom) {
        this.messageRows = this.messageRows + 10
        this.messageLists('no')
      }
    },
    textInput () {
      // console.log('dd')
      this.messegeTextCnt = this.messegeText.length
      // console.log(this.messegeTextCnt)
    },
    deleteTarget (e) {
      this.readyList = this.readyList.filter((item) => item.id !== e)
      console.log('dele')
      for (const i in this.friendList) {
        if (this.friendList[i].selected) {
          if (this.friendList[i].friend_id === e) {
            this.friendList[i].selected = false
          }
        }
      }
      for (const i in this.recentUseList) {
        if (this.recentUseList[i].selected) {
          if (this.recentUseList[i].friend_id === e) {
            this.recentUseList[i].selected = false
          }
        }
      }
      for (const i in this.searchList) {
        if (this.searchList[i].selected) {
          if (this.searchList[i].UserID === e) {
            this.searchList[i].selected = false
          }
        }
      }
    },
    messageInput () {
      var member = ''
      for (const i in this.readyList) {
        // console.log(this.readyList[i].id)
        this.errSendMessegeCnt = 0
        if (i === '0') {
          member = this.readyList[i].id
        } else {
          member = member + ',' + this.readyList[i].id
        }
      }
      console.log(member)
      if (member !== '' && this.messegeText !== '') {
        this.sendMessage(member, member, this.messegeText, 'multi')
        if (this.errSendMessegeCnt === 0) {
          this.close()
          this.$showMessagePopup('', '쪽지 발송이 완료되었습니다.')
          this.currentMessageUsers()
          this.shareCountAdd()
        } else {
          this.$showMessagePopup('', this.errSendMessegeCnt + ' 건의 쪽지 발송이 실패하였습니다.[' + this.errSendMessegeId + ']')
        }
      } else {
        if (member === '') {
          this.$showMessagePopup('', '메세지를 보낼 대상이 없습니다')
        } else if (this.messegeText === '') {
          this.$showMessagePopup('', '메세지를 입력하세요')
        }
      }
    },
    sendMessage (targetId, targetNicName, message, type) {
      console.log(targetId)
      const formData = new FormData()
      formData.append('token', this.$store.state.Member.token)
      formData.append('receive_id', targetId)
      formData.append('message', message)
      this.axios.post('/message/send.json', formData)
        .then(data => {
          console.log(data)
          if (type === 'multi') {
            if (data.data.response.code !== '00') {
              this.errSendMessegeCnt = this.errSendMessegeCnt + 1
              if (this.errSendMessegeId.length === 0) {
                this.errSendMessegeId = targetNicName
              } else {
                this.errSendMessegeId = this.errSendMessegeId + ',' + targetNicName
              }
            }
          } else if (type === 'one') {
            console.log('one')
            this.$showMessagePopup('', data.data.response.message)
            this.messageRows = this.messageRows + 1
            this.messageLists()
          }
        })
    },
    infectTarget () {
      const tempArray = this.readyList
      if (this.selectList.length > 500) {
        this.$showMessagePopup('', '한번에 500명 이상 메세지를 송신할 수 없습니다.')
      } else {
        for (const i in this.selectList) {
          tempArray.push(this.selectList[i])
        }
        console.log(tempArray)

        this.readyList = tempArray.filter((item, i) => {
          return (
            tempArray.findIndex((item2, j) => {
              return item.id === item2.id
            }) === i
          )
        })
        console.log(this.readyList)
        this.readyCnt = this.readyList.length
        this.friendTap = true
        this.searchText = ''
      }
    },
    selected (page, id) {
      this.selectList = []
      this.selectSearchList = []
      console.log('dd')
      // 이미 체크된 아이디면 기존 체크 아이디 해제
      if (page === 'recentUseList') {
        for (const i in this.friendList) {
          if (this.friendList[i].selected) {
            if (this.friendList[i].friend_id === id) {
              this.friendList[i].selected = false
            }
          }
        }
        // for (const i in this.searchList) {
        //   this.searchList[i].selected = false
        // }
      }
      if (page === 'friendList') {
        for (const i in this.recentUseList) {
          if (this.recentUseList[i].selected) {
            if (this.recentUseList[i].friend_id === id) {
              this.recentUseList[i].selected = false
            }
          }
        }
        // for (const i in this.searchList) {
        //   this.searchList[i].selected = false
        // }
      }
      if (page === 'searchList') {
        for (const i in this.friendList) {
          if (this.friendList[i].selected) {
            if (this.friendList[i].friend_id === id) {
              this.friendList[i].selected = false
            }
          }
        }
        for (const i in this.recentUseList) {
          if (this.recentUseList[i].selected) {
            if (this.recentUseList[i].friend_id === id) {
              this.recentUseList[i].selected = false
            }
          }
        }
      }

      // 배열에 추가
      for (const i in this.recentUseList) {
        if (this.recentUseList[i].selected) {
          this.selectList.push({ id: this.recentUseList[i].friend_id, name: this.recentUseList[i].friend_nickname })
        }
      }
      for (const i in this.friendList) {
        if (this.friendList[i].selected) {
          this.selectList.push({ id: this.friendList[i].friend_id, name: this.friendList[i].friend_nickname })
        }
      }
      for (const i in this.searchList) {
        if (this.searchList[i].selected) {
          this.selectList.push({ id: this.searchList[i].UserID, name: this.searchList[i].NickName })
        }
      }
      console.log(this.selectList)
    },
    useListChg () {
      this.useListTap = !this.useListTap
    },
    currentMessageUsers () { // 최근사용 친구 리스트
      this.axios.get('/message/current_message_users.json', {
        params: {
          // token: '69202CC15E60313F5ED6BC8870C139DE6089135888506F2BFFECC13D24001D9213EDC2AA63FA7401',
          token: this.$store.state.Member.token,
          rows: 3,
          page: '1'
        }
      })
        .then(data => {
          const reSort = data.data.response.content.list.sort(function (a, b) {
            return b.no - a.no
          })
          // console.log(data.data.response.content.list)
          // console.log(reSort)
          this.recentUseList = reSort
          // this.messageUsers = data.data.response.content.list
        })
    },
    roomLists () {
      // console.log(this.$store.state.Member.token)
      this.chatText = ''
      this.messageRow = this.messageRow + 10
      this.axios.get('/message/room_lists.json', {
        params: {
        // 임시
          token: this.$store.state.Member.token,
          // token: '69202CC15E60313F5ED6BC8870C139DE6089135888506F2BFFECC13D24001D9213EDC2AA63FA7401',
          rows: this.messageRow,
          page: '1'
        }
      })
        .then(data => {
          console.log(data)
          this.messageUsers = data.data.response.content.list
        })
    },
    messageLists (switchText) {
      console.log(switchText)
      if (this.messageRows === 0) {
        this.messageRows = this.messageRows + 10
      }
      // console.log(this.$store.state.Member.token)
      console.log(this.room_mid)
      this.chatText = ''
      this.axios.get('/message/room_message_lists.json', {
        params: {
        // 임시
          token: this.$store.state.Member.token,
          // token: '69202CC15E60313F5ED6BC8870C139DE6089135888506F2BFFECC13D24001D9213EDC2AA63FA7401',
          room_mid: this.room_mid,
          rows: this.messageRows,
          page: '1'
        }
      })
        .then(data => {
          console.log(data)
          this.messageUsers = data.data.response.content.list.reverse()
          console.log(this.messageRows)
          // 스크롤 맨 아래로
          this.$nextTick(() => {
            const messages = this.$refs.messages
            if (switchText === undefined) {
              messages.scrollTo({ top: messages.scrollHeight, behavior: 'smooth' })
            } else {
              messages.scrollTo({ top: messages.scrollTop + 50, behavior: 'smooth' })
            }
          })
        })
    },
    msgChat (no, friendId, friendNickname, groupId, groupNickName) {
      // 페이지 이동에 따른 초기화
      this.oneChatId = ''
      this.openChatNo = ''
      this.oneChatNick = ''
      this.multiChatNick = ''
      this.messageUsers = []
      this.multiUserInfo = []
      console.log(friendId)
      if (groupId === null) {
        // 단일 유저 대화방
        console.log('single')
        this.oneChatId = friendId
        if (friendNickname === '' || friendNickname === null) {
          this.oneChatNick = friendId
        } else {
          this.oneChatNick = friendNickname
        }
        this.msgMain = !this.msgMain
        this.room_mid = friendId
        this.msgOneChat = true
        this.msgMultiChat = false
        this.chatBax = true
        this.chatInput = true
      } else {
        // 다중 유저 대화방
        console.log('multy')
        this.oneChatId = friendId
        this.multiChatNick = groupNickName
        for (const i in groupId.split(',')) {
          this.friendDataRead(groupId.split(',')[i])
        }
        this.msgMain = !this.msgMain
        this.room_mid = groupId
        this.msgOneChat = false
        this.msgMultiChat = true
        this.chatBax = true
        this.chatInput = true
      }
      this.openChatNo = no
      this.messageLists()
    },
    friendDataRead (id) { // 친구 정보 호출
      this.axios.get('/friend/friend_search.json', {
        params: {
          token: this.$store.state.Member.token,
          string: id,

          member_address: '',
          prefer_categories: '',
          type: '', // 친구인 유저만 검색
          page: '1',
          rows: 1
        }
      })
        .then(data => {
          if (data.data.response.content.list.length !== 0) {
            this.multiUserInfo.push(data.data.response.content.list[0])
          }
        })
    },
    msgChatClose () {
      this.msgMain = true
      this.room_mid = ''
      this.msgMultiChat = false
      this.msgOneChat = false
      this.chatBax = false
      this.chatInput = false
      this.roomLists()
    },
    msgSendBtn () {
      this.$router.push('/mytranggle/messagesend')
      // this.msgRead = !this.msgRead
      // this.msgSend = !this.msgSend
    },
    drop_click () {
      if (this.drop_step === 'drop_down') {
        this.drop_step = 'drop_up'
        this.NickNameBox = true
      } else {
        this.drop_step = 'drop_down'
        this.NickNameBox = false
      }
    },
    friendRead () { // 친구 목록
      this.friendRow = this.friendRow + 20
      this.axios.get('/friend/lists.json', {
        params: {
          // token: '69202CC15E60313F5ED6BC8870C139DE6089135888506F2BFFECC13D24001D9213EDC2AA63FA7401',
          token: this.$store.state.Member.token,
          rows: this.friendRow,
          page: 1,
          type: 'NORMAL'
        }
      })
        .then(data => {
        //   this.listCount = data.data.response.content.list_count
        //   this.noReadCount = data.data.response.content.no_read_count
        //   this.listArroy = data.data.response.content.list
          // console.log(data)
          this.friendList = data.data.response.content.list
          this.friendCnt = data.data.response.content.list_count
          if (this.selectList.length !== 0) {
            for (const i in this.friendList) {
              for (const j in this.selectList) {
                if (this.friendList[i].friend_id === this.selectList[j].id) {
                  this.friendList[i].selected = true
                }
              }
            }
          }
        })
    },
    friendSearch (row) { // 친구 찾기
      this.searchRow = this.searchRow + 10
      if (this.searchText !== '') {
        this.friendTap = false
        this.axios.get('/friend/friend_search.json', {
          params: {
          // 임시
          // token: '69202CC15E60313F5ED6BC8870C139DE6089135888506F2BFFECC13D24001D9213EDC2AA63FA7401',
            token: this.$store.state.Member.token,
            string: this.searchText,
            // string: 'test',
            member_address: '',
            prefer_categories: '',
            type: '', // 친구인 유저만 검색
            page: '1',
            rows: this.searchRow
          }
        })
          .then(data => {
            if (data.data.response.content !== undefined) {
              console.log(data.data.response.content)
              console.log(data.data.response.content.list.length)

              this.searchList = data.data.response.content.list
              this.searchCnt = data.data.response.content.list_count

              for (const j in this.readyList) {
                for (const i in this.searchList) {
                  if (this.readyList[j].id === this.searchList[i].UserID) {
                    this.searchList[i].selected = true
                  }
                }
              }
            }
          })
      } else {
        this.$showMessagePopup('', '검색할 친구 ID를 입력해주세요.')
      }
    },
    friendOn () { // 메세지 보내기창으로 페이지 변경
      this.friendTap = true
      this.searchText = ''
    },
    removeMessage (no) { // 메세지 삭제
      this.axios.get('/message/remove.json', {
        params: {
          // token: '69202CC15E60313F5ED6BC8870C139DE6089135888506F2BFFECC13D24001D9213EDC2AA63FA7401',
          token: this.$store.state.Member.token,
          remove_no: no,
          type: 'SEND'
        }
      })
        .then(data => {
          // console.log(data)
          this.$router.go()
        })
      this.msgChatClose()
    },
    freindDitail (friendId) { // 친구 클릭시 친구 상세 페이지 이동
      this.$router.push('/mytranggle/friendInfo/' + friendId)
    },
    checkedAll (checked) {
      this.allCheck = !this.allCheck
      for (const i in this.messageUsers) {
        this.messageUsers[i].selected = this.allCheck
      }
    },
    checkDelete () {
      for (const i in this.messageUsers) {
        if (this.messageUsers[i].selected === true) {
          console.log(this.messageUsers[i])
          console.log(this.messageUsers[i].no)
          this.removeMessage(this.messageUsers[i].no)
          this.messageLists()
        }
      }
    },
    roomName (chack, A, B) {
      if (chack !== null) {
        return A
      } else {
        return B
      }
    },
    idChk (id) {
      // id === this.$store.state.Member.memberId ? 'msg_i' : 'msg_you'
      if (id === this.$store.state.Member.memberId) {
        return 'msg_i'
      } else {
        return 'msg_you'
      }
    },
    shareCountAdd () {
      if (this.$store.state.Popup.sharePageChk === 'story') {
        console.log(this.$store.state.Popup.sharePostNo)
        this.axios.get('/story/add_share_count.json', {
          params: {
            token: this.$store.state.Member.token,
            post_no: this.$store.state.Popup.sharePostNo
          }
        })
          .then(data => {
            const responseCode = data.data.response.code
            if (responseCode !== '00') {

            }
          })
      }
      if (this.$store.state.Popup.sharePageChk === 'course') {
        this.axios.get('/course/add_share_count.json', {
          params: {
            token: this.$store.state.Member.token,
            track_year: this.$store.state.Popup.shereYear,
            track_no: this.$store.state.Popup.sharePostNo
          }
        })
          .then(data => {
            const responseCode = data.data.response.code
            if (responseCode !== '00') {

            }
          })
      }
      this.$store.state.Popup.sharePageChk = ''
      this.$store.state.Popup.shereYear = ''
      this.$store.state.Popup.sharePostNo = ''
    }
  },
  mounted () {
    // 메세지
    // this.messageLists()
    this.roomLists()

    // 메세지 보내기
    this.currentMessageUsers()
    this.friendRead()

    // 작성자 받는사람 표기
    // this.readyList.push({ id: this.$store.state.Popup.sendId, name: this.$store.state.Popup.sendNickName })
    // this.readyCnt = this.readyList.length
    // this.infectTarget()
  }
}
</script>
