import axios from '../../util/axios.js'

export default {
  namespaced: true,
  state: {
    isOpenCourseBookAgreePopup: false,
    isOpenCourseBookBuyPopup: false,
    isOpenSearchOptionPopup: false,
    // 코스 비교 등록 팝업
    isOpenCompareTrackPopup: false,
    // 코스 비교 화면 레이어 팝업
    isOpenCompareTrackLayerPopup: false,
    isOpenModifyMyRecordPopup: false,
    isOpenUploadGPXFilePopup: false,
    selectedCourseIndex: '',
    selectedCourseBookIndex: '',
    selectedBadgeIndex: '',
    courseInfo: {
      track: {},
      badge_list: [],
      waypoints: [],
      waypoint: []
    },
    courseList: [],
    courseBookInfo: {
      badge: [],
      image: [],
      view: {},
      recommend: [],
      reply: []
    },
    courseBookList: [],
    badgeList: [],
    searchType: '',
    selectArea: ['ALL'],
    courseSearchOption: false,
    courseSearchAllPeriod: true,
    courseSearchStartDate: '',
    courseSearchEndDate: '',
    courseSearchTrackCategory: ['ALL'],
    courseSearchDistance: ['ALL'],
    courseSearchTime: ['ALL'],
    courseSearchBadge: ['ALL'],
    compareDate: '',
    // 코스 비교 팝업에서 사용 되는 코스 비교 1
    compareTrack1: {
      name: '',
      year: '',
      no: null
    },
    // 코스 비교 팝업에서 사용 되는 코스 비교 2
    compareTrack2: {
      name: '',
      year: '',
      no: null
    },
    compareTrackBadge: {},
    themeInfo: '',
    currentTrackPoint: 0,
    courseLaps: {},
    trackPoints: {},
    trackDetailData: {},
    chartLabel: [],
    currentLapsTab: 'detail',
    selectedGpxFile: {},
    selectedGpxTrackKey: '',
    searchKeyword: '',
    isOpenLaps: false,
    courseBadgeList: [],
    isVisibleCourseBadge: false,
    completeBadge: null,
    isShowBadgeInfoLayer: false,
    myBadgeRecord: null,
    trackImageList: [],
    searchCommitCnt: 0,
    searchOrder: 'new',
    uploadGPXSuccess: false,
    nation: {
      minLat: 33.112775,
      maxLat: 38.703748,
      minLng: 124.607880,
      maxLng: 131.872823
    },
    domestic: true,
    trackPointOSM: []
  },
  mutations: {
    setCourseInfo (state, data) {
      state.courseInfo = data
    },
    setCourseBookInfo (state, data) {
      state.courseBookInfo = data
    },
    setCourseList (state, data) {
      state.courseList = []
      state.courseList = data
      state.courseBadgeList = []
      const courseBadgeList = []
      state.courseList.forEach((v, i) => {
        if (v.badge_list && v.badge_list.length > 0) {
          v.badge_list.forEach(badge => {
            if (courseBadgeList.length === 0) {
              courseBadgeList.push(badge)
            } else {
              let checkDup = true
              courseBadgeList.forEach(courseBadge => {
                if (courseBadge.info_badge_code === badge.info_badge_code) {
                  checkDup = false
                  return false
                }
              })

              if (checkDup) {
                courseBadgeList.push(badge)
              }
            }
          })
        }
      })
      state.courseBadgeList = courseBadgeList
    },
    setCourseBookList (state, data) {
      state.courseBookList = []
      state.courseBookList = data
    },
    setBadgeList (state, data) {
      state.badgeList = []
      state.badgeList = data
    },
    setSelectedCourseIndex (state, data) {
      state.selectedCourseIndex = data
    },
    setSelectedCourseBookIndex (state, data) {
      state.selectedCourseBookIndex = data
    },
    setSelectedBadgeIndex (state, data) {
      state.selectedBadgeIndex = data
    },
    setCourseTrackData (state, data) {
      state.courseLaps = data.laps
      state.trackPoints = data.point

      state.trackDetailData.pointCount = data.point[0].length
      state.trackDetailData.time = []
      let totalElapsedTime = 0
      data.point[0].map(function (point) {
        totalElapsedTime += point.elapsed_time
        state.trackDetailData.time.push(totalElapsedTime)
      })
      state.trackDetailData.speed = data.point[0].map(point => point.speed)
      state.trackDetailData.distance = data.point[0].map(point => point.distance_total)
      state.trackDetailData.altitude = data.point[0].map(point => point.ele)
    },
    setNationCheck (state, data) {
      let nationCheck = true // 국내: true, 국외: false
      const arrOSM = []
      // 여러구간일 경우 모든구간 처리
      data.forEach((val) => {
        // 국내 국외 판단
        nationCheck = parseFloat(val.lat) > state.nation.minLat && parseFloat(val.lat) < state.nation.maxLat && parseFloat(val.lon) > state.nation.minLng && parseFloat(val.lon) < state.nation.maxLng
        if (!nationCheck) {
          // 국외(OSM)
          arrOSM.push([
            parseFloat(val.lon), parseFloat(val.lat)
          ])
        }
      })
      state.domestic = nationCheck
      state.trackPointOSM = arrOSM
    },
    setCourseBookTrackData (state, data) {
      state.courseLaps = data.laps
      state.trackPoints = data.point
      state.trackDetailData.pointCount = 0
      state.trackDetailData.time = []
      state.trackDetailData.speed = []
      state.trackDetailData.distance = []
      state.trackDetailData.altitude = []
      let totalElapsedTime = 0
      const trackPoint = []

      data.point.forEach((value, index) => {
        value.forEach((point, pointIndex) => {
          trackPoint.push(point)
          state.trackDetailData.pointCount++
          totalElapsedTime += point.elapsed_time
          state.trackDetailData.time.push(totalElapsedTime)
          state.trackDetailData.speed.push(point.speed)
          state.trackDetailData.distance.push(point.distance_total)
          state.trackDetailData.altitude.push(point.ele)
          return false
        })
        return false
      })
      state.trackPoints = { 0: trackPoint }
    },
    controlCourseBookAgreePopup (state) {
      state.isOpenCourseBookAgreePopup = !state.isOpenCourseBookAgreePopup
    },
    controlCourseBookBuyPopup (state) {
      state.isOpenCourseBookBuyPopup = !state.isOpenCourseBookBuyPopup
    },
    controlSearchOptionPopup (state, data) {
      state.isOpenSearchOptionPopup = !state.isOpenSearchOptionPopup
      state.searchType = data
      state.courseSearchOption = false
    },
    controlModifyMyRecordPopup (state) {
      state.isOpenModifyMyRecordPopup = !state.isOpenModifyMyRecordPopup
    },
    controlUploadGPXFilePopup (state) {
      state.isOpenUploadGPXFilePopup = !state.isOpenUploadGPXFilePopup
    },
    controlIsOpenLaps (state) {
      state.isOpenLaps = !state.isOpenLaps
    },
    controlIsVisibleCourseBadge (state) {
      state.isVisibleCourseBadge = !state.isVisibleCourseBadge
    },
    completeSearchOption (state) {
      state.isOpenSearchOptionPopup = !state.isOpenSearchOptionPopup
      if (!state.courseSearchTrackCategory.includes('ALL') ||
        !state.selectArea.includes('ALL') ||
        state.courseSearchStartDate !== '' ||
        state.courseSearchEndDate !== '' ||
        !state.courseSearchDistance.includes('ALL') ||
        !state.courseSearchTime.includes('ALL') ||
        !state.courseSearchBadge.includes('ALL')) {
        state.courseSearchOption = true
      } else {
        state.courseSearchOption = false
      }
      state.searchCommitCnt += 1
    },
    clearSearchOption (state) {
      state.courseSearchOption = false
      state.courseSearchAllPeriod = true
      state.courseSearchStartDate = ''
      state.courseSearchEndDate = ''
      state.selectArea = ['ALL']
      state.courseSearchTrackCategory = ['ALL']
      state.courseSearchDistance = ['ALL']
      state.courseSearchTime = ['ALL']
      state.courseSearchBadge = ['ALL']
    },
    showCompareTrackLayerPopup (state, data) {
      state.compareTrack1 = data.compareTrack1
      state.compareTrack2 = data.compareTrack2
      state.compareDate = data.compareDate
      state.isOpenCompareTrackLayerPopup = true
    },
    closeCompareTrackLayerPopup (state) {
      state.compareTrack1 = {
        name: '',
        year: '',
        no: null
      }
      state.compareTrack2 = {
        name: '',
        year: '',
        no: null
      }
      state.compareDate = ''
      state.isOpenCompareTrackLayerPopup = false
    },
    clearCompleteBadge (state) {
      state.completeBadge = null
      state.isShowBadgeInfoLayer = false
      state.myBadgeRecord = null
    },
    setUploadGPXSuccess (state) {
      state.uploadGPXSuccess = !state.uploadGPXSuccess
    }
  },
  actions: {
    convertCourseRegDate ({ data }) {
      return data.length > 10 ? data.substring(0, 10) : data
    },
    showBadgeInfo ({ state, commit }, data) {
      if (state.completeBadge == null) {
        axios.get('/Badge/search_detail.json', {
          params: {
            token: this.state.Member.token,
            badge_id: data
          }
        })
          .then(responseData => {
            const responseCode = responseData.data.response.code

            if (responseCode === '00') {
              state.completeBadge = responseData.data.response.content

              axios.get('/Badge/get_badge_rank_by_round.json', {
                params: {
                  token: this.state.Member.token,
                  badge_id: data,
                  page: 1,
                  rows: 100
                }
              })
                .then(data => {
                  state.isShowBadgeInfoLayer = true
                  if (data.data.response.content.get_list.length !== 0) {
                    const getList = data.data.response.content.get_list
                    state.myBadgeRecord = {
                      rank: getList[0].log_regist_rank,
                      firstDate: getList[getList.length - 1].log_badge_datetime,
                      totalCount: data.data.response.content.total_count
                    }
                  }
                })
            }
          })
      } else {
        state.isShowBadgeInfoLayer = true
      }
    },
    async getTrackImage ({ state, commit }) {
      if (state.trackImageList.length === 0) {
        await axios.get('/Track/get_track_image.json', {
          params: {
            token: this.state.Member.token,
            track_no: state.courseInfo.track.log_no,
            track_year: state.courseInfo.track.log_track_no.substring(0, 4)
          }
        })
          .then(data => {
            if (data.data.response.code === '00') {
              state.trackImageList = data.data.response.content
            }
          })
      }
    }
  },
  modules: {
  },
  getters: {
    getSearchOption (state) {
      const searchOption = {}

      // 지역
      if (!state.selectArea.includes('ALL')) {
        Object.assign(searchOption, { region: state.selectArea.join('|') })
      }

      // 운동 종목
      if (!state.courseSearchTrackCategory.includes('ALL')) {
        Object.assign(searchOption, { category: state.courseSearchTrackCategory.join('|') })
      }

      // 시작기간
      if (state.courseSearchStartDate !== '') {
        Object.assign(searchOption, { start_date: state.courseSearchStartDate })
      }

      // 종료기간
      if (state.courseSearchEndDate !== '') {
        Object.assign(searchOption, { end_date: state.courseSearchEndDate })
      }

      // 운동 거리
      if (!state.courseSearchDistance.includes('ALL')) {
        Object.assign(searchOption, { distance: state.courseSearchDistance.join('|') })
      }

      // 운동 시간
      if (!state.courseSearchTime.includes('ALL')) {
        Object.assign(searchOption, { pass_time: state.courseSearchTime.join('|') })
      }

      // 획득 배지 수
      if (!state.courseSearchBadge.includes('ALL')) {
        Object.assign(searchOption, { badge_cnt: state.courseSearchBadge.join('|') })
      }

      return searchOption
    }
  }
}
