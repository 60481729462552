<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <div class="popup_box popup_box_ms">
        <div class="popup_top popup_top_line">
          <h2>회원전용 메뉴</h2>
        </div>
        <div class="popup_body">
          <p >트랭글 회원이 되시면 해당 메뉴 사용이 가능합니다.<br>
          트랭글 회원 가입하시겠습니까?</p>
          <div class="btn_area">
            <button type="button" @click="cancel">취소</button>
            <button type="button" class="on" @click="confirm('/login')">로그인</button>
            <button type="button" class="on" @click="confirm('/member/join')">회원가입</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      callbackCancelMethod: this.$store.state.Popup.loginCallbackCancelMethod
    }
  },
  components: {
  },
  name: 'CommonLoginPopup',
  methods: {
    cancel () {
      if (this.$store.state.Popup.isLoginPopupBackEvent === 0) {
        this.$router.go(-1)
      }

      this.$store.commit('Popup/controlLoginPopup')
    },
    confirm (url) {
      this.$router.push(url)
      this.$store.commit('Popup/controlLoginPopup')
    }
  }
}
</script>

<style>

</style>
