<template>
  <div
    class="header pos"
    :class="{ sub: main !== 'Y', themeBlack: blackTheme }"
  >
    <h1 class="top_logo">
      <router-link to="/">
        <img src="@/assets/images/logo_text_white.png" alt="Tranggle" />
      </router-link>
    </h1>
    <!-- 홈페이지 메인 메뉴 -->
    <div class="nav icon">
      <!-- // TODO: 로그인/로그아웃 햄버거버튼 주석 luke 24.02.15
      <a href="javascript:void(0);" class="menu-trigger" @click="mobileMenu">
        <span></span>
        <span></span>
      </a>
      -->
      <!-- <ul>
        <li v-for="(list, i) in navList" :key="i" :class="{ 'on' : list.currentMenu || list.url === $route.fullPath }" @click="activeClass(i)">
          <router-link :to="checkUrl(list.url)">{{ list.name }}</router-link>
        </li>
      </ul> -->
    </div>
    <div class="gnb">
      <!-- <ul class="icon">
        <li
          v-for="(list, i) in navList"
          :key="i"
          :class="{ on: list.currentMenu || list.url === $route.fullPath }"
          @click="activeClass(i)"
        >
          <router-link :to="checkUrl(list.url)">{{ list.name }}</router-link>
        </li>
      </ul> -->
      <template v-if="isLogin !== 'Y'">
        <router-link to="/login" class="login ml50 white">로그인</router-link>
        <!-- // 
          TODO: 로그인/로그아웃 주석 luke 24.02.15
          TODO: 로그인 제외한 주석 해제 eddie 24.05.02
        <router-link to="/member/join">회원가입</router-link>
        -->
      </template>
      <template v-else>
        <!-- <router-link to="/mytranggle/mytranwallet" class="trang">
          <span class="i_trang" @click="this.$router.push('/mytranggle/mytranwallet')"></span>{{this.$addComma(this.$store.getters['Member/getTrang'])}} 트랭
        </router-link> -->
        <!-- <router-link to="/mytranggle/myinfo">My 트랭글</router-link> -->
        <router-link
          :to="checkUrl('/mytranggle/mytrangconf')"
          class="member_info ml50"
          >회원정보</router-link
        >
        <!-- <router-link to="/v2/login/logout" class="logout">로그아웃</router-link> -->
        <a href="#" class="logout" @click="logout">로그아웃</a>
      </template>
    </div>
    <!-- 반응형 작업 -->
    <div class="m-gnb">
      <ul v-if="isLogin !== 'Y'" class="list-gnb">
        <!-- // TODO: 로그인/로그아웃 주석 luke 24.02.15
        <li class="login">
          <router-link to="/login">로그인</router-link>
        </li>
        <li>
          <router-link to="/member/join">회원가입</router-link>
        </li>
        -->
      </ul>
      <ul v-else class="list-gnb">
        <li>
          <router-link :to="checkUrl('/mytranggle/mytrangconf')"
            >회원정보</router-link
          >
        </li>
        <li>
          <a href="#" class="logout" @click="logout">로그아웃</a>
        </li>
      </ul>
    </div>
    <!-- <div class="header_submenu" v-if="activityOver && $store.state.UserSet.isLogin !== 'Y'" @mouseleave="activityOver = false">
      <div class="submenu_area">
        <router-link to="/activity/main">
          <div class="submenu_box">
            <span class="activity_img1"></span>
            <span class="submenu_text">
              <span class="submenu_title">나의 활동 통계</span>
              기간에 따른 활동 데이터들의 통계를<br>확인하실 수 있습니다.
            </span>
          </div>
        </router-link>
        <router-link to="/activity/comp">
          <div class="submenu_box">
            <span class="activity_img2"></span>
            <span class="submenu_text">
              <span class="submenu_title">활동 비교 통계</span>
              직접 설정한 기준으로 비교대상을 선정하고<br>그룹 내 통계 결과를 비교 합니다.
            </span>
          </div>
        </router-link>
      </div>
    </div> -->
  </div>
  <!--/header-->
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "TopMenu",
  props: {
    // currentMenu: String,
    main: {
      type: String,
      defualt: "",
    },
    blackTheme: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activityOver: false,
      navList: [
        // {
        //   name: '트랭글 Now',
        //   url: '/now',
        //   currentMenu: false
        // },
        // {
        //   name: '나의 활동',
        //   url: '/activity/main',
        //   currentMenu: false
        // },
        // { // TODO: 코스 주석 luke 24.02.15
        //   name: '코스',
        //   // name: '코스보기',
        //   url: '/course',
        //   currentMenu: false
        // }
        // {
        //   name: '미션',
        //   url: '/mission',
        //   currentMenu: false
        // }
        // {
        //   name: '클럽',
        //   url: '/club',
        //   currentMenu: false
        // },
        // {
        //   name: '스토리',
        //   url: '/story',
        //   currentMenu: false
        // }
      ],
    };
  },
  computed: {
    ...mapState("UserSet", {
      isLogin: "isLogin",
    }),
    // getTrang () {
    //   return this.$addComma(this.$store.state.Member.memberInfo.trang.point + this.$store.state.Member.memberInfo.trang.save + this.$store.state.Member.memberInfo.trang.charge)
    // }
  },
  mounted() {
    const headerWrap = document.querySelector(".header_wrap");
    if (headerWrap) {
      headerWrap.querySelector(".m-gnb").classList.remove("active");
    }
    document.body.classList.remove("m_menu_show");
    this.loadGetToken();
    let idx;
    this.navList.forEach((el, i) => {
      el.currentMenu = false;
      if (this.$route.fullPath.includes(el.url)) {
        idx = i;
      }
    });
    if (typeof idx === "number") {
      this.navList[idx].currentMenu = true;
    }
  },
  unmounted() {
    console.log("unmounted");
  },
  methods: {
    ...mapActions("UserSet", ["loadGetToken", "loadLogout"]),
    checkUrl(url) {
      if (this.isLogin === "Y") {
        return url;
      } else {
        return "/login";
      }
    },
    logout() {
      this.loadLogout();
    },
    activeClass(idx) {
      this.navList.forEach((el) => {
        el.currentMenu = false;
      });
      this.navList[idx].currentMenu = true;
      sessionStorage.setItem("loginUrl", this.navList[idx].url);
    },
    mobileMenu() {
      const headerWrap = document.querySelector(".header_wrap");
      if (headerWrap) {
        headerWrap.querySelector(".m-gnb").classList.toggle("active");
      }
      document.body.classList.toggle("m_menu_show");
      if (document.body.classList.length === 0 && !this.blackTheme) {
        document.querySelector(".header.pos").classList.remove("themeBlack");
      } else {
        document.querySelector(".header.pos").classList.add("themeBlack");
      }
    },
  },
};
</script>

<style></style>
