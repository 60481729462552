import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/index.js'
import common from '@/assets/common/js/common.js'
import commonAdmin from '@/assets/common/js/commonAdmin.js'
import axios from './util/axios.js'
import Vuecookies from 'vue-cookies'
// osm
import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'

const app = createApp(App)
app.config.globalProperties.axios = axios
app.use(store)
app.use(router)
app.use(common)
app.use(commonAdmin)
app.use(Vuecookies)
app.use(OpenLayersMap)
app.mount('#app')
