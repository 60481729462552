export default {
  namespaced: true,
  state: {
    userToken: 'E25693D564BFEA50647ECF52C2AD140B88995FCDED87A415FBD43C741A745C3ADB61A6030ACA4F3D94D99167F116B1F1AA99256B6980DE5F',
    userId: 'jwshim97'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  getters: {
  }
}
