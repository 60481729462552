import axios from "../../util/axios.js";
import router from "../../router/index.js";
export default {
  namespaced: true,
  state: {
    isLogin: "",
    memberId: "",
    memberNick: "",
    isOpenDormantPopup: false,
    isOpenthirdPopup: false,
    dormanInfo: {
      loginDate: "",
      dormantDate: "",
    },
    from: "",
  },
  mutations: {
    setIsLogin(state, data) {
      state.isLogin = data;
    },
    setMemberId(state, data) {
      state.memberId = data;
    },
    setMemberNick(state, data) {
      state.memberNick = data;
    },
    setFrom(state, data) {
      state.from = data;
    },
  },
  actions: {
    // 로그인 체크 API
    loadGetToken({ state, commit, dispatch }, url) {
      axios.get("/login/getToken.json", {}).then((res) => {
        if (location.href.includes("localhost")) {
          localStorage.setItem(
            "sessionID",
            res.data.response.content.session_id
          );
          const sessionId = localStorage.getItem("sessionID")
            ? localStorage.getItem("sessionID")
            : "devsession_id";

          if (!axios.defaults.baseURL.includes("dev.html/")) {
            axios.defaults.baseURL = `//local-www.tranggle.com/v2/`;
          }
        }
        let bool = "";

        if (res.data.response.code === "00") {
          bool = "Y";
        } else {
          bool = "N";
        }

        commit("setIsLogin", bool);
        commit("setMemberId", res.data.response.content.member_id);
        commit("setMemberNick", res.data.response.content.member_nickname);

        if (res.data.response.content.dormant_yn === "TRUE") {
          // 휴면계정이라면 로그아웃 시킨다
          if (res.data.response.content.member_id.charAt(0) === "@") {
            // sns 계정이라면
            state.isOpenDormantPopup = true;
            state.dormanInfo.dormantDate =
              res.data.response.content.dormant_date;
          } else {
            // 일반계정이라면
            dispatch("loadLogout");
          }
        }

        if (bool === "Y") {
          // 240503 앱스토어를 통해서 들어왔을 때 분기처리
          if (state.from === "appstore") {
            router.replace("/mytranggle/mytrangconf");
          } else {
            router.replace(url);
            sessionStorage.removeItem("loginUrl");
          }
        }
        // 제3자 팝업 체크
        state.isOpenthirdPopup =
          localStorage.getItem("AgreePopupCheck") !== "Y";
      });
    },
    // 로그아웃
    loadLogout({ state, commit }) {
      axios.get("/login/logout.json", {}).then((res) => {
        if (res.data.response.code === "00") {
          commit("setIsLogin", "N");
          commit("setFrom", "");
          // this.$store.state.UserSet.isLogin = ''
          router.replace("/login");
          localStorage.removeItem("sessionID");
        }
      });
    },
  },
  modules: {},
  getters: {
    getFrom(state) {
      return state.from;
    },
  },
};
