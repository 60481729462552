import axios from '../../util/axios.js'

export default {
  namespaced: true,
  state: {
    // 이미지 링크 도메인
    imageDomain: 'http://175.113.237.187',
    // 회원관심운동 종목
    missionStatus: {
      PROGRESS: '진행중',
      EXPECTED: '예정',
      END: '종료'
    },
    statusCategory: {},
    trackCategory: {},
    trackCategoryCustom: {},
    trackCategoryETCDel: {},
    competCategory: {},

    // 운동 거리
    distanceCategory: { // 임시 수치값 확인 필요
      5: '5km 이내',
      10: '5~10km 이내',
      15: '10~15km 이내',
      20: '15~20km 이내',
      30: '20~30km 이내',
      40: '30km 이상'
    },
    // 운동 시간
    sportTimeCategory: { // 임시 수치값 확인 필요
      10: '10분 이내',
      30: '10~30분 이내',
      60: '30~60분 이내',
      120: '1~2시간 이내',
      240: '2~4시간 이내',
      300: '4시간 이상'
    },
    // 배지 유형
    badgeTypeCategory: {
      CYCLE: '자전거',
      EVENT: '이벤트',
      MOUNT: '등산',
      PARK: '공원',
      PEDOMETER: '걸음수',
      STAMP: '전자스탬프'
    },
    // 획득 배지 수
    secureBdageCategory: {
      0: '0 개',
      5: '1~5 개',
      10: '6~10 개',
      20: '11~20 개',
      50: '21~50 개',
      51: '51개 이상'
    },
    // 검색옵션 값
    selectArea: ['ALL'],
    searchPeriodCategory: ['ALL'],
    searchTrackCategory: ['ALL'],
    searchDistanceCategory: ['ALL'],
    searchSportTimeCategory: ['ALL'],
    searchBadgeTypeCategory: ['ALL'],
    searchSecureBdageCategory: ['ALL'],

    // 코스 초기화 방지 파라미터 시작
    isPossibleCourseCompare: false,
    compareTrack1: {
      name: '',
      year: '',
      no: null
    },
    compareTrack2: {
      name: '',
      year: '',
      no: null
    },
    courseMenuType: '',
    themeCategoryNo: '',
    // 코스 초기화 방지 파라미터 종료
    notOpenTrangExpirePopupDate: '', // 트랭 소멸 안내 팝업 보지 않기 일자
    loadingStatus: false,

    // 나의활동 설정 파라메터
    activityAgeCategory: {
      '0~20': '20세 이하',
      '21~25': '21 ~ 25세',
      '26~30': '26 ~ 30세',
      '31~35': '31 ~ 35세',
      '36~40': '36 ~ 40세',
      '41~45': '41 ~ 45세',
      '46~50': '46 ~ 50세',
      '51~55': '51 ~ 55세',
      '56~70': '56 ~ 70세',
      '71 ': '71세 이상'
    },
    activityGenderCategory: {
      남: '남',
      여: '여'
    },
    activityFrequencyCategory: {
      '0~1': '0 ~ 1회',
      '2~3': '2 ~ 3회',
      '4~5': '4 ~ 5회',
      '6~7': '6 ~ 7회'
    },
    activityTimeCategory: {
      '0~10': '10분 이내',
      '10~30': '10 ~ 30분 이내',
      '30~60': '30 ~ 60분 이내',
      '60~120': '1 ~ 2시간 이내',
      '120~240': '2 ~ 4시간 이내',
      '240 ': '4시간 이상'
    },
    activityDistanceCategory: {
      '0~5': '5KM 이내',
      '5~10': '5 ~ 10 km 이내',
      '10~15': '10 ~ 15 km 이내',
      '15~20': '15 ~ 20 km 이내',
      '20~30': '20 ~ 30 km 이내',
      '30 ': '30 km 이상'
    },
    // 나의활동 설정 검색옵션 값
    activityAge: ['ALL'],
    activityGender: ['ALL'],
    activityFrequency: ['ALL'],
    activityTime: ['ALL'],
    activityDistance: ['ALL'],

    // 나의활동 배열 체크
    activityChk: false
  },
  mutations: {
    setTrackCategory (state, data) {
      const array = {}
      Object.assign(array, data.data.response.content.category)
      delete array.ETC
      array.ETC = '기타'
      state.trackCategory = array
    },
    setTrackCategoryCustom (state, data) {
      const arrayCustom = {}
      Object.assign(arrayCustom, data.data.response.content.category)
      delete arrayCustom.ETC
      delete arrayCustom.STEP
      arrayCustom.ETC = '기타'
      state.trackCategoryCustom = arrayCustom
    },
    setTrackCategoryETCDel (state, data) {
      const arrayCustom = {}
      Object.assign(arrayCustom, data.data.response.content.category)
      delete arrayCustom.ETC
      delete arrayCustom.STEP
      state.trackCategoryETCDel = arrayCustom
    },
    setCompetCategory (state, data) {
      state.competCategory = data.data.response.content
    },
    setCompareTrack (state, data) {
      this.state.Course.isOpenCompareTrackPopup = true
      if ((state.compareTrack1.year === data.year && state.compareTrack1.no === data.no) ||
            (state.compareTrack2.year === data.year && state.compareTrack2.no === data.no)) {
        const dataObject = { popupHeaderMessage: '', popupBodyMessage: '이미 코스 비교에 등록된 코스입니다.' }
        this.commit('Popup/setMessagePopup', dataObject)
        this.commit('Popup/controlMessagePopup')
      } else if (state.compareTrack1.name === '') {
        state.compareTrack1.name = data.name
        state.compareTrack1.year = data.year
        state.compareTrack1.no = data.no
        if ((state.compareTrack1.name !== '' && state.compareTrack2.name !== '') || (state.compareTrack1.name !== null && state.compareTrack2.name !== null)) {
          state.isPossibleCourseCompare = true
        }
      } else if (state.compareTrack2.name === '') {
        state.compareTrack2.name = data.name
        state.compareTrack2.year = data.year
        state.compareTrack2.no = data.no
        // state.isPossibleCourseCompare = true
        if ((state.compareTrack1.name !== '' && state.compareTrack2.name !== '') || (state.compareTrack1.name !== null && state.compareTrack2.name !== null)) {
          state.isPossibleCourseCompare = true
        }
      }
      // if (state.compareTrack1.name !== '' && state.compareTrack2.name !== '') {
      //   const dataObject = {
      //     popupHeaderMessage: '',
      //     popupBodyMessage: '이미 2개의 코스가 비교 대상으로<br>등록되어 있습니다.<br>새롭게 코스 비교하시겠습니까?',
      //     link: () => {
      //       this.state.Common.compareTrack1.name = ''
      //       this.state.Common.compareTrack1.year = ''
      //       this.state.Common.compareTrack1.no = ''
      //       this.state.Common.compareTrack2.name = ''
      //       this.state.Common.compareTrack2.year = ''
      //       this.state.Common.Common.compareTrack2.no = ''
      //     }
      //   }
      //   this.commit('Popup/setConfirmPopup', dataObject)
      //   this.commit('Popup/controlConfirmPopup')
      // }
    },
    deleteCompareTrack (state, data) {
      state.isPossibleCourseCompare = false
      if (data === '1') { // 첫 번째 코스 삭제시
        // if (state.compareTrack2.name === '') {
        state.compareTrack1.name = ''
        state.compareTrack1.year = ''
        state.compareTrack1.no = ''
        // this.state.Course.isOpenCompareTrackPopup = false
        // } else {
        //   state.compareTrack1.name = state.compareTrack2.name
        //   state.compareTrack1.year = state.compareTrack2.year
        //   state.compareTrack1.no = state.compareTrack2.no
        //   state.compareTrack2.name = ''
        //   state.compareTrack2.year = ''
        //   state.compareTrack2.no = ''
        // }
      } else if (data === '2') { // 두 번째 코스 삭제시
        state.compareTrack2.name = ''
        state.compareTrack2.year = ''
        state.compareTrack2.no = ''
      }

      if ((state.compareTrack1.name === '' && state.compareTrack2.name === '')) {
        this.state.Course.isOpenCompareTrackPopup = false
      }
    },
    compareTrack (state) {
      if (state.compareTrack1.name !== '' && state.compareTrack2.name !== '') {
        const param = {
          compareTrack1: state.compareTrack1,
          compareTrack2: state.compareTrack2,
          compareDate: ''
        }
        this.commit('Course/showCompareTrackLayerPopup', param)
      } else {
        const dataObject = { popupHeaderMessage: '', popupBodyMessage: '코스 2개를 선택하세요' }
        this.commit('Popup/setMessagePopup', dataObject)
        this.commit('Popup/controlMessagePopup')
      }
    },
    startSpinner (state) {
      state.loadingStatus = true
    },
    endSpinner (state) {
      state.loadingStatus = false
    }
  },
  actions: {
    // 회원관심운동 종목 조회 API 호출
    loadTrackCategory ({ state, commit }) {
      if (Object.keys(state.trackCategory).length === 0 || Object.keys(state.trackCategory)[0] !== 'WALKING' || !Object.keys(state.trackCategory).includes('STEP') ||
       Object.keys(state.trackCategoryCustom).length === 0 || Object.keys(state.trackCategoryCustom)[0] !== 'WALKING' || Object.keys(state.trackCategoryETCDel)[0] !== 'WALKING') {
        axios.get('/track_category/getTrackCategory.json', {
          params: {
            sort: true,
            rtype: 'array',
            lang: 'ko'
          }
        })
          .then(data => {
            commit('setTrackCategory', data)
            commit('setTrackCategoryCustom', data)
            commit('setTrackCategoryETCDel', data)
          })
      }
    },
    // 경쟁종목 카테고리정보 조회 API 호출
    loadCompetCategory ({ state, commit }) {
      if (Object.keys(state.competCategory).length === 0) {
        axios.get('/mission/getCompetCategory.json', {
          params: {
          }
        })
          .then(data => {
            commit('setCompetCategory', data)
          })
      }
    }
  },
  modules: {
  },
  getters: {
    getMissionStatus (state) {
      return state.missionStatus
    },
    getStatusCategory (state) {
      return state.statusCategory
    },
    getTrackCategory (state) {
      return state.trackCategory
    },
    getTrackCategoryCustom (state) {
      return state.trackCategoryCustom
    },
    getTrackCategoryETCDel (state) {
      return state.trackCategoryETCDel
    },
    getCompetCategory (state) {
      return state.competCategory
    },
    getDistanceCategory (state) {
      return state.distanceCategory
    },
    getSportTimeCategory (state) {
      return state.sportTimeCategory
    },
    getBadgeTypeCategory (state) {
      return state.badgeTypeCategory
    },
    getSecureBdageCategory (state) {
      return state.secureBdageCategory
    },
    getSecureBdageTypeCategory (state) {
      return state.badgeTypeCategory
    },
    getActivityAgeCategory (state) {
      return state.activityAgeCategory
    },
    getActivityGenderCategory (state) {
      return state.activityGenderCategory
    },
    getActivityFrequencyCategory (state) {
      return state.activityFrequencyCategory
    },
    getActivityTimeCategory (state) {
      return state.activityTimeCategory
    },
    getActivityDistanceCategory (state) {
      return state.activityDistanceCategory
    }
  }
}
