<template>
<div class="course_left">
  <div class="section">
    <div class="top_title">나의 코스
      <span class="btn_area_r">
        <select v-model="recordType" @change="changeRecordType()" class="default select_lm">
          <option value="tranggle">트랭글 기록</option>
          <option value="gpx">외부 기록</option>
        </select>
      </span>
    </div>
  </div>
  <div class="tab_menu">
    <ul>
      <li><a href="#" >트랭글 외 기타 기록 {{this.gpxTrackCount}}개</a></li>
    </ul>
  </div>
    <div class="left_content" style="height: calc(100vh - 161px);">
    <div class="list_poi">
      <span class="bin" v-if="this.completeSearch && courseList.length === 0">
        <span class="bin_img_result"></span>
        <span class="text">검색 결과가 없습니다.</span>
      </span>
      <ul v-else>
        <li v-for="(course, i) in courseList" :key="i" :id="`list_${i}`"
          :class="{on: (course.log_track_no) === this.$store.state.Course.selectedGpxTrackKey}"
          @click="this.$store.state.Course.selectedGpxTrackKey = course.log_track_no">
          <span class="td1"><span class="poi_pin">{{i + 1}}</span></span>
          <span class="td3">
            <span class="poi_content">
              <span class="subject"><span :class="`icon_exc ` + this.$categoryImage(course.log_category)"></span><span class="text elp">{{course.log_track_name}}</span></span>
              <span class="data">
                  <span class="text"><span class="t1">전체 거리</span>{{this.$convertKilometerFormatCustom(course.log_distance)}} km</span>
                  <span class="text"><span class="t1">전체 시간</span>{{this.$convertTimeStampFormat(course.log_total_time)}}</span>
                  <span class="text"><span class="t1">평균 속도</span>{{this.$convertSpeedFormat(course.log_aver_speed)}} km/h</span>
              </span>
            </span>
          </span>
          <span class="poi_date">{{this.convertCourseRegDate(course.log_datetime)}}</span>
        </li>
      </ul>
    </div><!--/list_poi-->
    <Pagination @select-list="selectList" :totalCount="this.gpxTrackCount" :viewCount="this.viewCount" :currentPage="this.page" v-if="isOpenPagination" />
  </div>
</div><!--/course_left-->
<div class="content">
    <UploadGpxFileMap ref="map" :menu="'list'" />
</div><!--/content-->
</template>

<script>
import UploadGpxFileMap from './map/UploadGpxFileMap.vue'
import Pagination from '@/components/common/Pagination.vue'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      courseList: [],
      page: 1,
      showKeywordClearButton: 'none',
      searchKeyword: '',
      totalPageCount: 0,
      viewCount: 50,
      listTab: 'MY',
      myCourseCount: 0,
      myDownloadCount: 0,
      category: '',
      year: new Date().getFullYear(),
      region: '',
      completeSearch: false,
      isOpenPagination: true,
      recordType: 'gpx',
      gpxTrackCount: 0
    }
  },
  name: 'CourseMyGpx',
  components: {
    UploadGpxFileMap: UploadGpxFileMap,
    Pagination: Pagination
  },
  mounted () {
    this.selectList(1)
  },
  watch: {
    uploadGPXSuccess () {
      console.log('uploadGPXSuccess')
      // this.selectList(1)
      this.$router.go()
    }
  },
  computed: {
    ...mapState('Course', ['uploadGPXSuccess']),
    getYearList () {
      const yearList = []
      const year = Number(new Date().getFullYear())

      for (let i = year; i > 2010; i--) {
        yearList.push(i)
      }
      return yearList
    }
  },
  methods: {
    selectList (page) {
      this.page = page
      this.completeSearch = false
      this.isOpenPagination = false
      this.showKeywordClearButton = this.searchKeyword !== '' ? 'block' : 'none'

      const params = {
        token: this.$store.state.Member.token,
        year: this.year,
        search_val: this.searchKeyword,
        category: this.category,
        region: this.region,
        view_count: this.viewCount,
        page: this.page
      }

      const listApiURl = '/course/get_external_log_list.json'

      this.axios.get(listApiURl, {
        params: params
      })
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode === '00') {
            this.courseList = data.data.response.content.list
            this.$store.commit('Course/setCourseList', this.courseList)
            this.completeSearch = true
            this.isOpenPagination = true
            console.log(this.courseList)
            this.$nextTick(() => {
              if (this.courseList.length) {
                this.$store.state.Course.selectedGpxTrackKey = this.courseList[0].log_track_no
              }
            })
            if (this.recordType === 'gpx') {
              this.gpxTrackCount = parseInt(data.data.response.content.totalCount)
            }
          }
        })
    },
    selectedCourse (index) {
      this.$refs.map.selectedCourseMarker(index)
    },
    viewCourseInfo (course) {
      this.$store.state.Common.courseMenuType = 'my'
      this.$router.push('/course/' + course.log_track_no)
    },
    changeListTab (tab) {
      this.listTab = tab
      this.selectList(1)
    },
    clearSearchKeyword () {
      this.searchKeyword = ''
      this.selectList(1)
    },
    changeRecordType () {
      if (this.recordType === 'tranggle') {
        this.$router.push('/course/my')
      } else if (this.recordType === 'gpx') {
        this.$router.push('/course/my/gpx')
      }
    },
    convertCourseRegDate (date) {
      return date.length > 10 ? date.substring(0, 10) : date
    }
  }

}
</script>

<style>

</style>
