<template>
  <!-- 회원정보 -->
  <div class="right_body mt">
    <div class="setting_area">
      <p><span class="list_title">{{ setName }}</span> 님의 정확한 운동분석과 통계제공을 위하여 생년월일, 성별 및 주소 등을 정확히 입력해 주세요.</p>
      <ul>
        <li>
          <span class="set_text">프로필 이미지</span>
          <span class="set_input">
            <span class="profile_noti">등록 시 모든 회원에게 프로필 사진이 공개됩니다.</span>
            <div class="set_profile">
              <img :src="$photoEmpty(userImg)"/>
              <label for="file" >
                <span class="btn_photo"></span>
              </label>
              <input type="file" id="file" ref="files" accept="image/*" @change="imageUpload()" style="display: none;"/>
            </div>
          </span>
        </li>
        <br>
        <li>
          <span class="set_text">이름</span>
          <span class="set_input"><input type="text" v-model="userName" @change="detectChange()"/></span>
        </li>
        <li>
          <span class="set_text">생년월일</span>
          <span class="set_input">
            <input
              type="text"
              v-model="birthDate"
              @change="detectChange()"
              maxlength="10"
              @keydown="birthDayAutoDash($event, birthDate)"
              @blur="valBirth()"
              placeholder="YYYY-MM-DD 숫자만 입력해주세요."
            />
            <span v-if="vals.birth === 'N'" class="stext text_red">{{ vals.birthMsg }}</span>
          </span>
        </li>
        <li>
          <span class="set_text">성별</span>
          <span class="set_input mobile_input">
            <ul class="m_identity_radio">
              <li class="radio_btn">
                <input
                  id="mInternal"
                  v-model="gender"
                  type="radio"
                  name="mIdentity"
                  value="MALE"
                  :checked="gender === 'MALE'"
                >
                <label for="mInternal">남성</label>
              </li>
              <li class="radio_btn">
                <input
                  id="mExternal"
                  v-model="gender"
                  type="radio"
                  name="mIdentity"
                  value="FEMALE"
                  :checked="gender === 'FEMALE'"
                >
                <label for="mExternal">여성</label>
              </li>
            </ul>
            <!-- <span class="tab1">
              <span :class="{'on':gender =='MALE'}" @click="genderChange('MALE')" >남성</span>
              <span :class="{'on':gender =='FEMALE'}" @click="genderChange('FEMALE')">여성</span>
            </span> -->
          </span>
        </li>
        <li>
          <span class="set_text">닉네임</span>
          <span class="set_input">
            <!-- <input type="text" placeholder="2~20자리로 입력하세요" v-model="nickName" @change="detectChange"/> -->
            <span class="input_btn">
              <input type="text" v-model="nickName" @change="checkNickname()" placeholder="2~20자리로 입력하세요" />
              <span v-if="validateNickname === 'Y' && duplicateNickname === 'N'" class="check_ok"></span>
              <button v-if="validateNickname === 'Y' && duplicateNickname === ''" class="on" @click.prevent="checkDuplicate('NICKNAME', nickName)">중복확인</button>
            </span>
            <span v-if="duplicateNickname === 'N'" class="stext">사용가능한 닉네임입니다.</span>
            <span v-if="duplicateNickname === 'Y'" class="stext text_red">이미 사용중인 닉네임으로 사용하실 수 없습니다.</span>
            <span v-if="nickName !== '' && validateNickname === 'N'" class="stext text_red">사용 불가능한 닉네임입니다.</span>
          </span>
        </li>
        <li>
          <span class="set_text">이메일</span>
          <!-- <span class="set_input"><input type="text" v-model="userEmail" @change="detectChange"/></span> -->
          <span class="set_input">
            <span class="input_btn">
              <input type="text" v-model="userEmail" @change="checkEmail()" placeholder="이메일 주소를 입력하세요" />
              <span v-if="validateEmail === 'Y' && duplicateEmail === 'N'" class="check_ok"></span>
              <button v-if="validateEmail === 'Y' && duplicateEmail === ''" class="on" @click.prevent="checkDuplicate('EMAIL', userEmail)">중복확인</button>
            </span>
            <span v-if="duplicateEmail === 'N'" class="stext">사용가능한 이메일입니다.</span>
            <span v-if="duplicateEmail === 'Y' && userEmail !== userEmailChk" class="stext text_red">이미 사용중인 이메일이므로 사용하실 수 없습니다.</span>
            <span v-if="userEmail !== '' && validateEmail === 'N' && userEmail !== userEmailChk" class="stext text_red">사용 불가능한 이메일입니다.</span>
          </span>
        </li>
        <li>
          <span class="set_text">관심운동종목</span>
          <span class="set_input">
            <!--<span class="set_setting">설정하기</span>-->
            <span class="set_setting" @click="interestExercise()">
              <input v-model="$store.state.MyTranggle.categoryPreferText" type="text" class="elp" readonly />
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">주소</span>
          <span class="set_input">
            <span class="input_2line">
              <input v-model="userAddr1" type="text" readonly @click="showApi()" @change="detectChange()"/><br>
              <input v-model="userAddr2" type="text" @change="detectChange()"/>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">신체정보</span>
          <span class="set_input">
            <span class="input_1line">
              <span class="input_1line">
                <input
                  v-model="userStature"
                  type="text"
                  class="input_s"
                  @keyup="userStature=userStature.replace(/[^0-9]/g,'')"
                  @change="detectChange()"
                  maxlength="3"
                  placeholder="키"
                />cm
                <input
                v-model="userWeight"
                  type="text"
                  class="input_s"
                  @keyup="userWeight=userWeight.replace(/[^0-9]/g,'')"
                  @change="detectChange()"
                  maxlength="3"
                  placeholder="몸무게"
                />kg
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div class="btn_area_bottom">
        <button @click="memberDataUpt()">저장</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      setName: '',
      userImg: '',
      userName: '',
      birthDate: '',
      vals: {
        birth: '',
        birthMsg: ''
      },
      gender: '',
      nickName: '',
      validateNickname: '',
      duplicateNickname: '',
      userEmail: '',
      userEmailChk: '',
      validateEmail: '',
      duplicateEmail: '',
      userAddr1: '',
      userAddr2: '',
      userStature: '',
      userWeight: '',
      files: [],
      uploadImageIndex: 0,
      infoChangeChk: false,
      duplicateChk: true,
      errorMessege: '',
      preferCategory: ''
    }
  },
  computed: {
    ...mapState('Member', [
      'memberInfo',
      'updateMemberInfoCode',
      'updateMemberInfoMsg'
    ]),
    ...mapState('MyTranggle', [
      'categoryPreferText'
    ])
  },
  watch: {
    memberInfo () {
      let array = []
      // let text = ''

      this.setName = this.memberInfo.name
      this.userName = this.memberInfo.name
      this.userImg = this.memberInfo.photo
      this.birthDate = this.memberInfo.birthday
      this.userSex = this.memberInfo.gender
      if (this.memberInfo.nickname === 'null') {
        this.nickName = ''
      } else {
        this.nickName = this.memberInfo.nickname
      }
      if (this.memberInfo.email === 'null') {
        this.userEmail = ''
      } else {
        this.userEmail = this.memberInfo.email
        this.userEmailChk = this.memberInfo.email
      }
      this.userAddr1 = this.memberInfo.address[1]
      this.userAddr2 = this.memberInfo.address[2]
      this.userStature = this.memberInfo.height
      this.userWeight = this.memberInfo.weight
      this.disclosure = this.memberInfo.dormant.is
      this.gender = this.memberInfo.gender
      this.$store.state.MyTranggle.categoryPrefer = this.memberInfo.categoryPrefer
      // 공개설정
      this.revelation = this.memberInfo.config.record_open_type
      this.messageSend = this.memberInfo.config.message_receive_type
      this.friendRequest = this.memberInfo.config.friends_receive_type

      this.memberNo = this.memberInfo.member_no
      if (this.memberInfo.categoryPrefer !== '') {
        array = this.memberInfo.categoryPrefer.split('|')
        this.$store.state.MyTranggle.categoryPreferArray = this.memberInfo.categoryPrefer.split('|')
      }

      for (const i in array) {
        // console.log(this.$changeSportsLanguage(array[i].toUpperCase()))
        // console.log(array[i])
        if (array[i] !== '') {
          if (i === '0') {
            this.preferCategory = this.$changeSportsLanguage(array[i].toUpperCase())
          } else {
            this.preferCategory += ', ' + this.$changeSportsLanguage(array[i].toUpperCase())
          }
        }
      }
      this.$store.state.MyTranggle.categoryPreferText = this.preferCategory
      this.tempCategoryText = this.preferCategory
      this.setName = this.memberInfo.name
    },
    updateMemberInfoCode () {
      if (this.updateMemberInfoCode !== '') {
        if (this.updateMemberInfoCode === '00') {
          this.$showMessagePopup('', '회원 정보가 수정 되었습니다.')
          this.getMemberInfo()
          this.infoChangeChk = false
        } else {
          this.$showMessagePopup('', this.updateMemberInfoMsg)
        }
        this.$store.state.Member.updateMemberInfoCode = ''
        this.$store.state.Member.updateMemberInfoMsg = ''
      }
    },
    infoChangeChk () {
      if (this.infoChangeChk) {
        this.$emit('changeInfo', true)
      } else {
        this.$emit('changeInfo', false)
      }
    },
    categoryPreferText () {
      if (this.$store.state.MyTranggle.categoryPreferText !== this.preferCategory) {
        this.infoChangeChk = true
      }
    },
    gender () {
      if (this.gender && this.gender !== this.memberInfo.gender) {
        this.$showConfirmPopup('', '생체정보 변경 시, 운동분석 및 통계에 영향이 있을 수 있습니다. <br/>변경 하시겠습니까?', () => {
          this.infoChangeChk = true
        }, () => {
          this.gender = this.memberInfo.gender
        })
      }
    }
  },
  // beforeRouteLeave (to, from, next) {
  //   if (this.infoChangeChk) {
  //     console.log(this.infoChangeChk)
  //     console.log('in')
  //     next(false)
  //     this.$showConfirmPopup('설정취소', '현재 변경된 사항을 취소하고 페이지를 이동하시겠습니까?', next)
  //   } else {
  //     return next
  //   }
  //   // this.$showConfirmPopup('설정취소', '현재 변경된 사항을 취소하고 페이지를 이동하시겠습니까?', next)
  //   // next(false)
  // },
  mounted () {
    this.getMemberInfo()
  },
  methods: {
    ...mapMutations('MyTranggle', [
      'interestExercise'
    ]),
    ...mapActions('Member', [
      'getMemberInfo',
      'updateMemberInfo'
    ]),
    imageUpload () {
      this.files = []
      let num = -1
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.files = [
          ...this.files,
          // 이미지 업로드
          {
            // 실제 파일
            file: this.$refs.files.files[i],
            // 이미지 프리뷰
            preview: URL.createObjectURL(this.$refs.files.files[i]),
            // 삭제및 관리를 위한 number
            number: i
          }
        ]
        num = i
        // 이미지 업로드용 프리뷰
        // this.filesPreview = [
        //   ...this.filesPreview,
        //   { file: URL.createObjectURL(this.$refs.files.files[i]), number: i }
        // ];
      }
      this.uploadImageIndex = num + 1 // 이미지 index의 마지막 값 + 1 저장
      // console.log(this.files)
      // this.userImg = ''
      this.userImg = this.files[0].preview
      this.infoChangeChk = true
      // console.log(this.files[0].preview)
      // console.log(this.filesPreview);
    },
    detectChange () {
      this.infoChangeChk = true
    },
    birthDayAutoDash ($event, str) {
      if ($event.keyCode !== 8) {
        if (str.length === 4 || str.length === 7) {
          this.birthDate += '-'
        }
      }
      if (str.length >= 10) {
        this.valBirth()
      }
    },
    valBirth () {
      const reg = /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
      const birthStr = this.birthDate.replaceAll('-', '')
      if (!this.birthDate || birthStr.length !== 8) {
        this.vals.birth = 'N'
        this.vals.birthMsg = '생년월일을 입력하세요.'
        return false
      }
      if (!reg.test(this.birthDate)) {
        this.vals.birth = 'N'
        this.vals.birthMsg = '생년월일을 바르게 입력하세요.'
        return false
      }
      // 만 14세 체크
      const userBirth = this.birthDate.replaceAll('-', '')
      const current = new Date()
      const y = current.getFullYear()
      const m = current.getMonth() + 1 < 10 ? `0${current.getMonth() + 1}` : current.getMonth() + 1
      const d = current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()
      if (parseInt(`${y}${m}${d}`) - parseInt(userBirth) < 140000) {
        this.vals.birth = 'N'
        this.vals.birthMsg = '만 14세 미만은 가입할 수 없습니다.'
        return false
      }
      this.vals.birth = 'Y'
      this.vals.birthMsg = ''
      return this.vals.birth
    },
    // genderChange (gender) {
    //   this.$showConfirmPopup('', '생체정보 변경 시, 운동분석 및 통계에 영향이 있을 수 있습니다. <br/>변경 하시겠습니까?', () => {
    //     this.gender = gender
    //     this.infoChangeChk = true
    //   })
    // },
    checkNickname () {
      if (this.memberInfo.nickname !== this.nickName) {
        this.validateNickname = (this.nickName.length >= 2 && this.nickName.length <= 20) ? 'Y' : 'N'
        this.duplicateNickname = ''
        this.nicknameChageChk = 'Y'
        this.detectChange()
      } else {
        this.validateNickname = ''
        this.duplicateNickname = ''
        this.nicknameChageChk = ''
      }
    },
    checkEmail () {
      if (this.memberInfo.email !== this.userEmail) {
        const regEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        this.validateEmail = regEmail.test(this.userEmail) ? 'Y' : 'N'
        this.duplicateEmail = ''
        this.emailChageChk = 'Y'
        this.detectChange()
      } else {
        this.validateEmail = ''
        this.duplicateEmail = ''
        this.emailChageChk = ''
      }
    },
    checkDuplicate (type, value) {
      const userSavedInfo = type === 'NICKNAME' ? this.memberInfo.nickname : this.memberInfo.email
      if (value !== '' && value !== userSavedInfo) {
        let check = ''
        const formData = new FormData()
        formData.append('item', type)
        formData.append('string', value)
        this.axios.post('/Member/validation_check.json', formData)
          .then(data => {
            const responseCode = data.data.response.code
            if (responseCode === '00') {
              check = 'N'
            } else {
              check = 'Y'
            }
            if (type === 'NICKNAME') {
              this.duplicateNickname = check
              this.nicknameChageChk = 'N'
            } else if (type === 'EMAIL') {
              this.duplicateEmail = check
              this.emailChageChk = 'N'
            }
          })
      } else {
        this.$showMessagePopup('중복 확인', '중복 확인할 값을 정확하게 입력해주세요.')
      }
    },
    showApi () {
      new window.daum.Postcode({
        oncomplete: (data) => {
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
          // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let fullRoadAddr = data.roadAddress // 도로명 주소 변수
          let extraRoadAddr = '' // 도로명 조합형 주소 변수

          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
            extraRoadAddr += data.bname
          } // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== '' && data.apartment === 'Y') {
            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName)
          }
          // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraRoadAddr !== '') {
            extraRoadAddr = ' (' + extraRoadAddr + ')'
          }
          // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
          if (fullRoadAddr !== '') {
            fullRoadAddr += extraRoadAddr
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          this.userAddr1 = fullRoadAddr
          this.zip = data.zonecode
        }
      }).open(this.$refs.embed)
    },
    chkData () {
      this.duplicateChk = true
      // 생년월일 유효성 체크
      if (this.vals.birth === 'N') {
        this.duplicateChk = false
        this.errorMessege = this.vals.birthMsg
      }
      // 닉네임 유효성체크
      if (this.duplicateNickname === 'Y') {
        this.duplicateChk = false
        this.errorMessege = '이미 사용중인 닉네임으로 사용하실 수 없습니다.'
      }
      if (this.validateNickname === 'Y' && this.duplicateNickname === '') {
        if (this.validateNickname === 'Y' && this.duplicateNickname === 'N') {
          this.duplicateChk = true
        } else {
          this.duplicateChk = false
          this.errorMessege = '닉네임의 중복확인이 필요합니다.'
        }
      }
      if (this.validateNickname === 'N') {
        this.duplicateChk = false
        this.errorMessege = '사용 불가능한 닉네임입니다.'
      }
      // 이메일 유효성 체크
      if (!this.userEmail) {
        this.duplicateChk = false
        this.errorMessege = '이메일을 입력하세요.'
      }
      if (this.validateEmail === 'Y' && this.duplicateEmail === '') {
        if (this.validateEmail === 'Y' && this.duplicateEmail === 'N') {
          this.duplicateChk = false
        } else {
          this.duplicateChk = false
          this.errorMessege = '이메일의 중복확인이 필요합니다.'
        }
      }
      if (this.duplicateEmail === 'Y' && this.userEmail !== this.userEmailChk) {
        this.duplicateChk = false
        this.errorMessege = '이미 사용중인 이메일이므로 사용하실 수 없습니다.'
      }
      if (this.userEmail !== '' && this.validateEmail === 'N' && this.userEmail !== this.userEmailChk) {
        this.duplicateChk = false
        this.errorMessege = '사용 불가능한 이메일입니다.'
      }
    },
    memberDataUpt () {
      this.chkData()
      if (this.duplicateChk) {
        if (this.$store.state.MyTranggle.categoryPrefer === 'ALL') {
          this.$store.state.MyTranggle.categoryPrefer = ''
        }
        const data = {
          nickname: this.nickName,
          email: this.userEmail,
          member_birthday: this.birthDate,
          member_name: this.userName,
          zip_code: this.zip ? this.zip : '',
          addr1: this.userAddr1,
          addr2: this.userAddr2,
          height: this.userStature,
          weight: this.userWeight,
          gender: this.gender,
          prefer_category: this.$store.state.MyTranggle.categoryPrefer,
          profile_image: this.files[0] ? this.files[0].file : ''
        }
        this.updateMemberInfo(data)
      } else {
        this.$showMessagePopup('', this.errorMessege)
      }
    }
  }
}
</script>
