import axios from "axios";
import store from "../store/index";
// import router from '../router'

const sessionId = localStorage.getItem("sessionID")
  ? localStorage.getItem("sessionID")
  : "devsession_id";
// 개발용 api 분기처리
// axios.defaults.baseURL = (location.href.includes('dev')) ? `//stage.tranggle.com/dev.html/${sessionId}/v2/` : '/v2/'
axios.defaults.baseURL = location.href.includes("dev")
  ? `//dev-new.tranggle.com/v2/`
  : "/v2/";

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
  (config) => {
    // 개발환경일 경우 test파라미터 추가
    // if (location.href.includes('dev')) {
    //   config.params.test = 'Y'
    // }
    store.commit("Common/startSpinner");
    return config;
  },
  (error) => {
    // alert('데이터를 불러오는데 실패하였습니다.\n새로고침을 해주세요.')
    store.commit("Common/endSpinner");
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.response.code === "1004") {
      store.state.UserSet.isLogin = "N";
      // const data = { popupHeaderMessage: '', popupBodyMessage: '로그인 정보가 존재하지 않습니다.<br />로그인후 이용하시기 바랍니다.', link: '/login' }
      // store.commit('Popup/setMessagePopup', data)
      // store.commit('Popup/controlMessagePopup')
      if (location.href.includes("dev")) {
        localStorage.removeItem("sessionID");
      }
    }
    store.commit("Common/endSpinner");
    return response;
  },
  (error) => {
    // alert('데이터를 불러오는데 실패하였습니다.\n새로고침을 해주세요.')
    store.commit("Common/endSpinner");
    return Promise.reject(error);
  }
);

// axios.defaults.baseURL = 'https://stage.tranggle.com/v2/'

export default axiosInstance;
