<template>
  <div>
  </div>
    <div class="facebookwrap">
      <img src="http://175.113.237.187/html/images/ajax-loader_1.gif" alt="logo" /><br/>
      <span style="font-size:25px;">잠시만 기다려 주세요.</span>
    </div>
</template>

<script>

export default {
  data () {
    return {
      currentLeftMenu: '',
      fbAsyncInit: () => {
        window.FB.init({
          appId: '287066011402705',
          status: true,
          cookie: true,
          xfbml: false,
          version: 'v14.0'
        })
      }
    }
  },
  methods: {
    moveMenu (menu) {
      this.currentLeftMenu = menu
      this.$router.push(menu)
    },
    setLeftMenu () {
      var currentPath = this.$route.path
      this.currentLeftMenu = currentPath.substring(currentPath.lastIndexOf('/') + 1, currentPath.length)
    },
    fnFbCustomLogin () {
      window.FB.AppEvents.logPageView()
      // console.log('dd')
      // this.isFacebook = true
      window.FB.login((response) => {
        // console.log(response)
        if (response.status === 'connected') {
          window.FB.api('/me', 'get', { fields: 'name,email' }, function (r) {
            var form = document.createElement('form')
            form.setAttribute('method', 'post')
            form.setAttribute('action', 'https://www.tranggle.com/v2/Facebook/login')
            document.charset = 'UTF-8'

            var hiddenField = document.createElement('input')
            hiddenField.setAttribute('type', 'hidden')
            hiddenField.setAttribute('name', 'app_ver')
            hiddenField.setAttribute('value', '')
            form.appendChild(hiddenField)

            hiddenField = document.createElement('input')
            hiddenField.setAttribute('type', 'hidden')
            hiddenField.setAttribute('name', 'name')
            hiddenField.setAttribute('value', r.name)
            form.appendChild(hiddenField)

            hiddenField = document.createElement('input')
            hiddenField.setAttribute('type', 'hidden')
            hiddenField.setAttribute('name', 'id')
            hiddenField.setAttribute('value', r.id)
            form.appendChild(hiddenField)

            hiddenField = document.createElement('input')
            hiddenField.setAttribute('type', 'hidden')
            hiddenField.setAttribute('name', 'email')
            hiddenField.setAttribute('value', r.email)
            form.appendChild(hiddenField)

            document.body.appendChild(form)
            form.submit()
            // this.isFacebook = false
          })
        } else if (response.status === 'not_authorized') {
          alert('앱에 로그인해야 이용가능한 기능입니다.')
          history.back()
        } else {
          // console.log(response.status)
          alert('페이스북에 로그인해야 이용가능한 기능입니다.')
          history.back()
        }
        // this.isFacebook = false
      }, { scope: 'public_profile,email' })
    }
  },
  mounted () {
    this.fnFbCustomLogin()
  },
  components: {

  }

}

</script>

<style type="text/css">
.facebookwrap{width:70%; text-align:center; margin:200px auto;}
.facebook img{width:10%; height:auto}
</style>
