<template>
    <div :id="this.mapId" style="width: 100%; height: 100%">
    </div>
    <div class="map_fnb" v-if="this.trackNo != null">
      <div :class="[{'fnb_area': true}, {'fold': !this.$store.state.Course.isOpenLaps}]">
        <div class="fnb_area_btn">
          <a href="#" @click.prevent="controlPanel('laps')"><span class="i_height"></span><span class="i_timelapse2"></span></a>
          <div class="fnb_area_top"></div>
        </div>
        <Laps v-if="this.$store.state.Course.isOpenLaps"/>
      </div>
    </div><!--/map_fnb-->
</template>

<script>
import Laps from '../map/Laps.vue'

let mapInstance = null
let coursePinMarkerList = []
let courseInfoWindowList = []
let coursePolyLinePointList = []
const courseBadgeList = []
const courseBadgeInfoWindowList = []
let coursePolyLine = null
let courseOutPolyLine = null
let startPointMarker = null
let endPointMarker = null

const defaultPolyLineStyle = {
  strokeColor: '#000000',
  strokeWeight: 6,
  strokeOpacity: 0.8,
  strokeStyle: 'solid'
}
const outPolyLineStyle = {
  strokeColor: '#FF7920 ',
  strokeWeight: 4,
  strokeOpacity: 1,
  strokeStyle: 'solid'
}

export default {
  data () {
    return {
      courseList: this.$store.state.Course.courseList,
      badgeIdx: '',
      mapId: this.menu != null ? this.menu + '_naver_map' : 'naver_map',
      badge: {}
    }
  },
  props: {
    trackNo: String,
    menu: String
  },
  name: 'AttachCourseMap',
  components: {
    Laps: Laps
  },
  computed: {
    checkCourseList () {
      return this.$store.state.Course.courseList
    },
    checkBadgeList () {
      return this.$store.state.Course.badgeList
    },
    checkSelectedBadgeIndex () {
      return this.$store.state.Course.selectedBadgeIndex
    }
  },
  watch: {
    checkCourseList (courseList) {
      this.courseList = courseList
      this.createCourseMarker()
    },
    checkBadgeList (badgeList) {
      this.badgeList = badgeList
      this.createCourseBadges()
      this.showCourseBadgeList()
    },
    checkSelectedBadgeIndex (selectedBadgeIndex) {
      if (selectedBadgeIndex !== '') {
        this.selectedCourseBadge(selectedBadgeIndex)
      }
    }
  },
  methods: {
    createCourseMarker () {
      this.deleteCourseMarker()
      coursePinMarkerList = []
      courseInfoWindowList = []
      this.courseList.forEach((course, index, array) => {
        const pinClassName = 'map_pin'
        if (index === 0) {
          mapInstance.setCenter(new window.naver.maps.LatLng(course.log_position_start_y, course.log_position_start_x))
        }
        const marker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(course.log_position_start_y, course.log_position_start_x),
          map: mapInstance,
          icon: this.$getMapPinMarkerIcon(pinClassName, (index + 1)),
          zIndex: 100
        })

        coursePinMarkerList.push(marker)

        const contentString = [
          '<div class="info_window">',
          '  <a href="#none" class="btn_close" onclick="closeInfoWindow()"></a>',
          '  <div class="course_info">',
          '    <ul class="only">',
          '      <li class="on">',
          '        <span class="info_td2">',
          '          <span class="poi_info">',
          '            <span class="subject"><span class="icon_exc ' + this.$categoryImage(course.log_category) + '"></span><span class="text elp">' + course.log_track_name + '</span></span>',
          '            <span class="data">',
          '              <span class="info_distance">' + this.$convertKilometerFormat(course.log_distance) + ' km</span>',
          '              <span class="info_time">' + this.$convertTimeStampFormat(course.log_total_time) + '</span>',
          '            </span>',
          '          </span>',
          // '          <a href="' + this.viewCourseInfo(course) + '" class="btn_detail">코스상세</a>',
          '        </span>',
          '      </li>',
          '    </ul>',
          '  </div>',
          '</div>'].join('')

        const infoWindow = new window.naver.maps.InfoWindow({
          content: contentString,
          borderWidth: 0,
          backgroundColor: null
        })

        courseInfoWindowList.push(infoWindow)
      })

      for (let i = 0; i < coursePinMarkerList.length; i++) {
        window.naver.maps.Event.addListener(coursePinMarkerList[i], 'click', () => {
          this.selectedCourseMarker(i)
        })
      }
    },
    viewCourseInfo (course) {
      console.log(course)
      // this.$store.state.Popup.tempAttachTrackKey = course.log_track_no
      // this.$store.commit('Popup/switchAttachPopup')
    },
    selectedCourseMarker (index) {
      if (this.$store.state.Course.selectedCourseIndex !== index) {
        this.clearCourseMarker()
        coursePinMarkerList[index].setZIndex(101)
        coursePinMarkerList[index].setIcon(this.$getMapPinMarkerIcon('map_pin on', (index + 1)))
        courseInfoWindowList[index].open(mapInstance, coursePinMarkerList[index])

        mapInstance.setCenter(coursePinMarkerList[index].getPosition())

        this.createCourseLine(this.courseList[index].log_track_no)

        this.$store.commit('Course/setSelectedCourseIndex', index)

        mapInstance.setZoom(14)
      } else {
        this.closeInfoWindow()
        courseInfoWindowList[index].open(mapInstance, coursePinMarkerList[index])
      }
    },
    clearCourseMarker () {
      coursePinMarkerList.forEach((value, index, array) => {
        value.setZIndex(100)
        value.setIcon(this.$getMapPinMarkerIcon('map_pin', (index + 1)))
      })
      courseInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })
      if (coursePolyLine != null) {
        coursePolyLine.setMap(null)
      }
      if (courseOutPolyLine != null) {
        courseOutPolyLine.setMap(null)
      }

      this.$store.commit('Course/setSelectedCourseIndex', '')
    },
    deleteCourseMarker () {
      coursePinMarkerList.forEach((marker, index, array) => {
        marker.setMap(null)
      })
      courseInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })
      if (coursePolyLine != null) {
        coursePolyLine = null
      }
      if (courseOutPolyLine != null) {
        courseOutPolyLine = null
      }
      if (startPointMarker != null) {
        startPointMarker.setMap(null)
      }
      if (endPointMarker != null) {
        endPointMarker.setMap(null)
      }
    },
    createCourseLine (trackNo) {
      this.axios.get('https://api.tranggle.com/v2/map2/get_track_point_v2.json', {
        params: {
          track_key: trackNo
        }
      })
        .then(data => {
          const trackPointArray = data.data.response.content.point
          if (trackPointArray.length === 1) {
            if (this.trackNo != null) {
              this.$store.commit('Course/setCourseTrackData', data.data.response.content)
            }
            coursePolyLinePointList = trackPointArray[0].map((trackPoint, index) => {
              return window.naver.maps.LatLng(trackPoint.lat, trackPoint.lon)
            })

            coursePolyLine = new window.naver.maps.Polyline(defaultPolyLineStyle)
            coursePolyLine.setPath(coursePolyLinePointList)

            coursePolyLine.setMap(mapInstance)
            coursePolyLine.setVisible(true)

            courseOutPolyLine = new window.naver.maps.Polyline(outPolyLineStyle)
            courseOutPolyLine.setPath(coursePolyLinePointList)

            courseOutPolyLine.setMap(mapInstance)
            courseOutPolyLine.setVisible(true)

            mapInstance.setCenter(coursePolyLinePointList[0])

            if (this.trackNo != null) {
              startPointMarker = new window.naver.maps.Marker({
                position: coursePolyLinePointList[0],
                map: mapInstance,
                icon: this.$getMapPinMarkerIcon('map_pin_start', ''),
                zIndex: 100
              })

              endPointMarker = new window.naver.maps.Marker({
                position: coursePolyLinePointList[coursePolyLinePointList.length - 1],
                map: mapInstance,
                icon: this.$getMapPinMarkerIcon('map_pin_goal', ''),
                zIndex: 100
              })

              mapInstance.setZoom(16)
            }
          }
        })
    },
    createCourseBadges () {
      this.badgeList.forEach((badge, index, array) => {
        const badgeMarker = new window.naver.maps.Marker({
          position: new window.naver.maps.LatLng(Number(badge.info_org_lat), Number(badge.info_org_lon)),
          icon: {
            url: this.$photoEmpty(badge.badge_image_url),
            size: new window.naver.maps.Size(30, 30),
            scaledSize: new window.naver.maps.Size(30, 30),
            origin: new window.naver.maps.Size(0, 0),
            anchor: new window.naver.maps.Point(15, 30)
          },
          zIndex: 100
        })

        courseBadgeList.push(badgeMarker)

        const contentString = [
          '<div class="info_window">',
          '  <a href="#none" class="btn_close" onclick="closeInfoWindow()"></a>',
          '  <div class="badge_info">',
          '    <span class="badge_img"><img src="' + this.$photoEmpty(badge.badge_image_url) + '"></span>',
          '    <div class="poi_content">',
          '      <span class="subject"><span class="icon_exc"><span class="i_exc_01"></span></span><span class="text elp">' + badge.info_badge_name + '(' + badge.info_badge_code + ')</span></span>',
          '      <span class="sub_text">' + badge.info_badge_message + '</span>',
          '    </div>',
          '    <div class="info_box line_bottom">',
          '      <ul>',
          '        <li><span class="title">배지 주인</span><span class="text">' + badge.info_register_id + '</span></li>',
          '        <li><span class="title">보유자수</span><span class="text">' + badge.info_count + '명</span></li>',
          '      </ul>',
          '    </div>',
          '    <div class="info_box">',
          '      <h4>나의 배지 획득 기록</h4>',
          '      <ul>',
          '        <li><span class="title">배지순위</span><span class="text">-</span></li>',
          '        <li><span class="title">최초획득</span><span class="text">-</span></li>',
          '        <li><span class="title">방문횟수</span><span class="text">-</span></li>',
          '      </ul>',
          '    </div>',
          '    <div class="info_btn">',
          '      <a href="/mytranggle/badge/' + badge.info_badge_id + '">배지 상세 정보 바로가기</a>',
          '    </div>',
          '  </div>',
          '</div>'].join('')

        const badgeInfoWindow = new window.naver.maps.InfoWindow({
          content: contentString,
          borderWidth: 0,
          backgroundColor: null
        })

        courseBadgeInfoWindowList.push(badgeInfoWindow)
      })

      for (let i = 0; i < courseBadgeList.length; i++) {
        window.naver.maps.Event.addListener(courseBadgeList[i], 'click', () => {
          console.log('badge click')
          this.selectedCourseBadge(i)
        })
      }
    },
    selectedCourseBadge (index) {
      if (this.badgeIdx !== index) {
        this.clearCourseBadge()

        mapInstance.setZoom(14)
        courseBadgeList[index].setZIndex(101)
        mapInstance.setCenter(courseBadgeList[index].getPosition())
        courseBadgeInfoWindowList[index].open(mapInstance, courseBadgeList[index])

        this.$store.commit('Course/setSelectedBadgeIndex', index)
        this.badgeIdx = index
      }
    },
    clearCourseBadge () {
      courseBadgeInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })

      this.$store.commit('Course/setSelectedBadgeIndex', '')
      this.badgeIdx = ''
    },
    showCourseBadgeList () {
      courseBadgeList.forEach((badge, index, array) => {
        badge.setMap(mapInstance)
      })
    },
    hideCourseBadgeList () {
      this.$store.commit('Course/setSelectedBadgeIndex', '')
      courseBadgeList.forEach((badge, index, array) => {
        badge.setMap(null)
      })
      courseBadgeInfoWindowList.forEach((bdageInfoWindow, index, array) => {
        bdageInfoWindow.close()
      })
    },
    controlPanel (type) {
      if (type === 'laps') {
        this.$store.commit('Course/controlIsOpenLaps')
      }
    },
    closeInfoWindow () {
      courseBadgeInfoWindowList.forEach((bdageInfoWindow, index, array) => {
        bdageInfoWindow.close()
      })
      courseInfoWindowList.forEach((infoWindow, index, array) => {
        infoWindow.close()
      })
      return false
    }
  },
  mounted () {
    mapInstance = new window.naver.maps.Map(this.mapId, {
      center: new window.naver.maps.LatLng(35.9615, 127.6066),
      zoom: 10,
      enableWheelZoom: true,
      enableDragPan: true,
      enableDblClickZoom: false,
      mapMode: 0,
      activateTrafficMap: false,
      activateBicycleMap: false,
      minZoom: 7,
      maxZoom: 21,
      zoomControl: true,
      zoomControlOptions: {
        position: window.naver.maps.Position.TOP_RIGHT,
        style: window.naver.maps.ZoomControlStyle.SMALL
      }
    })

    if (this.trackNo != null) {
      this.createCourseLine(this.trackNo)
    }
  },
  created () {
    window.closeInfoWindow = this.closeInfoWindow
  }
}
</script>

<style>

</style>
