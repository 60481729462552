<template>
  <div class="sub_content terms_contents">
    <h3 v-if="from !== 'join'" class="line_bottom">{{ policyInfo.title }}</h3>
    <div class="text wrapper">
      <div v-html="policyInfo.content"></div>
    </div>
    <div v-if="policySelect.length && (policySelect.length > 1)" class="terms_history">
      <select class="style1 select_lg" @change="changeTerms($event)" v-model="selectedIndex">
        <option v-for="(list, i) in policySelect" :key="i" :value="list.version">{{ list.title }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    from: {
      type: String,
      defualt: ''
    }
  },
  data () {
    return {
      selectedIndex: '',
      policyInfo: {},
      policySelect: []
    }
  },
  computed: {
    ...mapState('Support', [
      'policyData'
    ])
  },
  watch: {
    policyData () {
      this.policyInfo = this.policyData.data
      this.policySelect = this.policyData.list
      this.selectedIndex = this.policyData.data.version
    }
  },
  methods: {
    ...mapActions('Support', [
      'loadPolicyData'
    ]),
    changeTerms (event) {
      this.selectedIndex = event.target.value
      this.callApi()
      window.scrollTo(0, 0)
    },
    callApi () {
      this.loadPolicyData({
        code: 'trang',
        version: this.selectedIndex
      })
    }
  },
  mounted () {
    this.callApi()
    window.scrollTo(0, 0)
    this.$store.state.Support.pageName = 'TrangInfo'
  }
}
</script>
