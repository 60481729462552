<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <div class="popup_box popup_box_l">
        <div class="popup_top popup_top_line">
          <h2 v-if="this.$store.state.Course.searchType === 'course'">코스 검색 옵션</h2>
          <h2 v-if="this.$store.state.Course.searchType === 'courseBook'">코스북 검색 옵션</h2>
          <span class="top_left"><a href="#" @click.prevent="this.$store.commit('Course/controlSearchOptionPopup')" class="btn">취소</a></span>
          <span class="top_right">
            <a href="#" class="btn_refresh" @click="clearAllSearchOption">옵션 초기화</a>
            <a href="#" class="btn_default" @click="this.$store.commit('Course/completeSearchOption')">완료</a>
          </span>
        </div>
        <div class="popup_body">
          <div class="option_area">
            <ul>
              <li>
                  <span class="t1">지역</span>
                  <span class="t2">
                      <a href="#" class="btn_all" @click="selectSigunguList(0)" :class="{on:this.$store.state.Course.selectArea.includes('ALL')}">ALL</a>
                      <span class="t2_inner">
                          <span class="option_select" @click="controlRegionBar()">지역 선택
                              <span class="select_city_layer" v-if="selectBar">
                                  <ul class="city">
                                      <li :class="{'on' : sidoChose == sido.id}" v-for = "(sido,i) in sidoList" :key="i" @click.stop="selectSigunguList(sido.id)">{{sido.name}}</li>
                                  </ul>
                                  <ul class="town" @click.stop="controlRegionBar()">
                                      <li @click ="choseArea(0)" v-if="this.sidoChose !== 0">전체</li>
                                      <li v-for="(sigungu,i) in selectSigungu" :key="i" @click ="choseArea(sigungu.id)">{{sigungu.name}}</li>
                                  </ul>
                              </span>
                          </span>
                          <span class="option_select_text" v-if="!this.$store.state.Course.selectArea.includes('ALL')">
                              <span v-for ="(area,i) in this.$store.state.Course.selectArea" :key=i>{{area.replace(' ', '\/')}}<a class="text_del" @click="deleteArea(i)"></a></span>
                          </span>
                      </span>
                  </span>
              </li>
              <li v-if="this.$store.state.Course.searchType === 'course'">
                <span class="t1">기간</span>
                <span class="t2">
                  <a href="#" class="btn_all" :class="{on:this.$store.state.Course.courseSearchAllPeriod}" @click="this.$store.state.Course.courseSearchAllPeriod = true">ALL</a>
                  <span class="t2_inner">
                    <DateTimePicker ref="startPicker" :dateId="sdate" :paramMaxDate="this.$store.state.Course.courseSearchEndDate" @update:dateId="val => updateDate(val, 'courseSearchStartDate')"/>
                    ~ &nbsp;
                    <DateTimePicker ref="endPicker" :dateId="edate" :paramMinDate="this.$store.state.Course.courseSearchStartDate" @update:dateId="val => updateDate(val, 'courseSearchEndDate')" />
                  </span>
                </span>
              </li>
              <li>
                <span class="t1">운동 종목</span>
                <span class="t2">
                  <a href="#" class="btn_all" @click="toggleTrackCategory('ALL')" :class="{on:this.$store.state.Course.courseSearchTrackCategory.includes('ALL')}">ALL</a>
                  <span class="t2_inner">
                      <span
                        v-for="(name, value) in this.$getTrackCategoryCustom()"
                        :key="value"
                        class="op_tag"
                        :class="{ on: this.$store.state.Course.courseSearchTrackCategory.includes(value) }"
                        :data-value="value"
                        @click="toggleTrackCategory(value)">
                          {{name}}
                      </span>
                  </span>
                </span>
              </li>
              <li>
                <span class="t1">운동 거리</span>
                <span class="t2">
                  <a href="#" class="btn_all" @click="toggleDistance('ALL')" :class="{on:this.$store.state.Course.courseSearchDistance.includes('ALL')}">ALL</a>
                  <span class="t2_inner">
                    <span
                      v-for="(value, key) in this.distanceValueArray"
                      :key="key"
                      class="op_tag"
                      :class="{ on: this.$store.state.Course.courseSearchDistance.includes(value) }"
                      :data-value="value"
                      @click="toggleDistance(value)">
                        {{key}}
                    </span>
                  </span>
                </span>
              </li>
              <li>
                <span class="t1">운동 시간</span>
                <span class="t2">
                  <a href="#" class="btn_all" @click="toggleTime('ALL')" :class="{on:this.$store.state.Course.courseSearchTime.includes('ALL')}">ALL</a>
                  <span class="t2_inner">
                    <span
                      v-for="(value, key) in this.timaValueArray"
                      :key="key"
                      class="op_tag"
                      :class="{ on: this.$store.state.Course.courseSearchTime.includes(value) }"
                      :data-value="value"
                      @click="toggleTime(value)">
                        {{key}}
                    </span>
                  </span>
                </span>
              </li>
              <li>
                <span class="t1">획득 배지 수</span>
                <span class="t2">
                  <a href="#" class="btn_all" @click="toggleBadge('ALL')" :class="{on:this.$store.state.Course.courseSearchBadge.includes('ALL')}">ALL</a>
                  <span class="t2_inner">
                    <span
                      v-for="(value, key) in this.badgeValueArray"
                      :key="key"
                      class="op_tag"
                      :class="{ on: this.$store.state.Course.courseSearchBadge.includes(value) }"
                      :data-value="value"
                      @click="toggleBadge(value)">
                        {{key}}
                    </span>
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import City from '@/assets/common/data/city.js'
import DateTimePicker from '@/components/common/DateTimePicker.vue'
export default {
  data () {
    return {
      sdate: this.$store.state.Course.courseSearchStartDate,
      edate: this.$store.state.Course.courseSearchEndDate,
      distanceValueArray: { '5km 이내': '0,5', '1~10km 이내': '1,10', '10~15km 이내': '10,15', '15~20km 이내': '15,20', '30km 이상': '30,-1' },
      timaValueArray: { '10분 이내': '0,10', '10~30분 이내': '10,30', '30~60분 이내': '30,60', '1~2시간 이내': '60,120', '4시간 이상': '240,-1' },
      badgeValueArray: { '0개': '0,0', '1~5개': '1,5', '6~10개': '6,10', '11~20개': '11,20', '21~50개': '21,50', '51개 이상': '51,-1' },
      selectBar: false,
      sidoChose: 0,
      selectSigungu: [],
      selectArea: [],
      sidoList: City.sidoList,
      sigunguList: City.sigunguList
    }
  },
  mounted () {
  },
  components: {
    DateTimePicker: DateTimePicker
  },
  methods: {
    clearAllSearchOption () {
      this.$store.state.Course.selectArea = ['ALL']
      this.toggleTrackCategory('ALL')
      this.toggleDistance('ALL')
      this.toggleTime('ALL')
      this.toggleBadge('ALL')
      this.clearCourseSearchDate()
    },
    controlRegionBar () {
      this.selectBar = !this.selectBar
    },
    selectSigunguList (sidoCode) {
      this.sidoChose = sidoCode
      this.selectSigungu = []
      if (sidoCode !== 0) {
        for (const i in this.sigunguList) {
          if (this.sigunguList[i].id.toString().substr(0, 2) === sidoCode.toString()) {
            this.selectSigungu.push(this.sigunguList[i])
          }
        }
      } else {
        this.$store.state.Course.selectArea = ['ALL']
      }
    },
    choseArea (sigunguCode) {
      var SidoName = ''
      var SigunguName = ''
      if (sigunguCode === 0) {
        // sidoChose
        for (const i in this.sidoList) {
          if (this.sidoChose === this.sidoList[i].id) {
            SidoName = this.sidoList[i].name
          }
        }
      } else {
        for (const i in this.sidoList) {
          if (sigunguCode.toString().substr(0, 2) === this.sidoList[i].id.toString()) {
            SidoName = this.sidoList[i].name
          }
        }
        for (const i in this.sigunguList) {
          if (sigunguCode === this.sigunguList[i].id) {
            SigunguName = ' ' + this.sigunguList[i].name
          }
        }
      }
      if (this.$store.state.Course.selectArea.includes('ALL')) {
        this.$store.state.Course.selectArea.splice(0, 1)
      }
      if (!this.$store.state.Course.selectArea.includes(SidoName + SigunguName)) {
        this.$store.state.Course.selectArea.push(SidoName + SigunguName)
      }
    },
    deleteArea (index) {
      this.$store.state.Course.selectArea.splice(index, 1)
      if (Object.keys(this.$store.state.Course.selectArea).length === 0) {
        this.$store.state.Course.selectArea = ['ALL']
      }
    },
    toggleTrackCategory (e) {
      if (e === 'ALL') {
        this.$store.state.Course.courseSearchTrackCategory = []
        this.$store.state.Course.courseSearchTrackCategory.push(e)
      } else if (!this.$store.state.Course.courseSearchTrackCategory.includes(e)) {
        this.$store.state.Course.courseSearchTrackCategory.push(e)
      } else {
        this.$store.state.Course.courseSearchTrackCategory.splice(this.$store.state.Course.courseSearchTrackCategory.indexOf(e), 1)
      }
      if (this.$store.state.Course.courseSearchTrackCategory.includes('ALL') && this.$store.state.Course.courseSearchTrackCategory.length > 1) {
        this.$store.state.Course.courseSearchTrackCategory.splice(this.$store.state.Course.courseSearchTrackCategory.indexOf('ALL'), 1)
      } else if (this.$store.state.Course.courseSearchTrackCategory.length === 0) {
        this.$store.state.Course.courseSearchTrackCategory.push('ALL')
      }
    },
    toggleDistance (e) {
      if (e === 'ALL') {
        this.$store.state.Course.courseSearchDistance = []
        this.$store.state.Course.courseSearchDistance.push(e)
      } else if (!this.$store.state.Course.courseSearchDistance.includes(e)) {
        this.$store.state.Course.courseSearchDistance.push(e)
      } else {
        this.$store.state.Course.courseSearchDistance.splice(this.$store.state.Course.courseSearchDistance.indexOf(e), 1)
      }
      if (this.$store.state.Course.courseSearchDistance.includes('ALL') && this.$store.state.Course.courseSearchDistance.length > 1) {
        this.$store.state.Course.courseSearchDistance.splice(this.$store.state.Course.courseSearchDistance.indexOf('ALL'), 1)
      } else if (this.$store.state.Course.courseSearchDistance.length === 0) {
        this.$store.state.Course.courseSearchDistance.push('ALL')
      }
    },
    toggleTime (e) {
      if (e === 'ALL') {
        this.$store.state.Course.courseSearchTime = []
        this.$store.state.Course.courseSearchTime.push(e)
      } else if (!this.$store.state.Course.courseSearchTime.includes(e)) {
        this.$store.state.Course.courseSearchTime.push(e)
      } else {
        this.$store.state.Course.courseSearchTime.splice(this.$store.state.Course.courseSearchTime.indexOf(e), 1)
      }
      if (this.$store.state.Course.courseSearchTime.includes('ALL') && this.$store.state.Course.courseSearchTime.length > 1) {
        this.$store.state.Course.courseSearchTime.splice(this.$store.state.Course.courseSearchTime.indexOf('ALL'), 1)
      } else if (this.$store.state.Course.courseSearchTime.length === 0) {
        this.$store.state.Course.courseSearchTime.push('ALL')
      }
    },
    toggleBadge (e) {
      if (e === 'ALL') {
        this.$store.state.Course.courseSearchBadge = []
        this.$store.state.Course.courseSearchBadge.push(e)
      } else if (!this.$store.state.Course.courseSearchBadge.includes(e)) {
        this.$store.state.Course.courseSearchBadge.push(e)
      } else {
        this.$store.state.Course.courseSearchBadge.splice(this.$store.state.Course.courseSearchBadge.indexOf(e), 1)
      }
      if (this.$store.state.Course.courseSearchBadge.includes('ALL') && this.$store.state.Course.courseSearchBadge.length > 1) {
        this.$store.state.Course.courseSearchBadge.splice(this.$store.state.Course.courseSearchBadge.indexOf('ALL'), 1)
      } else if (this.$store.state.Course.courseSearchBadge.length === 0) {
        this.$store.state.Course.courseSearchBadge.push('ALL')
      }
    },
    updateDate (value, key) {
      if (value === '' || value.substring(0, 4) === '0000') {
        this.$store.state.Course[key] = ''
      } else {
        this.$store.state.Course[key] = value
      }

      if (this.$store.state.Course.courseSearchStartDate === '' && this.$store.state.Course.courseSearchEndDate === '') {
        this.clearCourseSearchDate()
      } else {
        this.$store.state.Course.courseSearchAllPeriod = false
      }
    },
    clearCourseSearchDate () {
      this.sdate = ''
      this.edate = ''
      this.$store.state.Course.courseSearchStartDate = ''
      this.$store.state.Course.courseSearchEndDate = ''
      this.$store.state.Course.courseSearchAllPeriod = true
    }
  },
  name: 'CourseSearchOptionPopup'
}
</script>
