<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
// import LeftMenu from './common/LeftMenu.vue'

export default {
  data () {
    return {
    }
  },
  name: 'Course',
  components: {
    // LeftMenu: LeftMenu
  },
  mounted () {
  }

}
</script>

<style>

</style>
