<template>
  <!-- 음성 안내 -->
  <div class="right_body mt">
    <div class="setting_area">
      <ul>
        <li>
          <span class="set_text">전체 음성 안내</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="audioGuidanceON" v-model="allCheck" value="ON" name="all" @click="audioGuidanceControl('ON')"/>
              <label for="audioGuidanceON">ON</label>
              <input type="radio" id="audioGuidanceOFF" v-model="allCheck" value="OFF" name="all" @click="audioGuidanceControl('OFF')"/>
              <label for="audioGuidanceOFF">OFF</label>
            </span>
            <span class="stext">안내, 운동 중 안내, 따라가기 등 전체 음성 안내가 ON / OFF 됩니다.</span>
          </span>
        </li>
      </ul>
      <div class="stitle">안내</div>
      <ul>
        <li>
          <span class="set_text">갈림길 안내</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="crossroadGuideOn" v-model="crossroadGuide" value="TRUE" name="crossroadGuide" @change="audioGuidanceChange()"/>
              <label for="crossroadGuideOn">ON</label>
              <input type="radio" id="crossroadGuideOff" v-model="crossroadGuide" value="FALSE" name="crossroadGuide" @change="audioGuidanceChange()"/>
              <label for="crossroadGuideOff">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">GPS 상태 안내</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="gpsConditionGuideOn" v-model="gpsConditionGuide" value="TRUE" name="gpsConditionGuide" @change="audioGuidanceChange()"/>
              <label for="gpsConditionGuideOn">ON</label>
              <input type="radio" id="gpsConditionGuideOff" v-model="gpsConditionGuide" value="FALSE" name="gpsConditionGuide" @change="audioGuidanceChange()"/>
              <label for="gpsConditionGuideOff">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">배지 획득 안내</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="badgeGuideOn" v-model="badgeGuide" value="TRUE" name="badgeGuide" @change="audioGuidanceChange()"/>
              <label for="badgeGuideOn">ON</label>
              <input type="radio" id="badgeGuideOff" v-model="badgeGuide" value="FALSE" name="badgeGuide" @change="audioGuidanceChange()"/>
              <label for="badgeGuideOff">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">제한 속도 초과</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="limitSpeedGuideOn" v-model="limitSpeedGuide" value="TRUE" name="limitSpeedGuide" @change="audioGuidanceChange()"/>
              <label for="limitSpeedGuideOn">ON</label>
              <input type="radio" id="limitSpeedGuideOff" v-model="limitSpeedGuide" value="FALSE" name="limitSpeedGuide" @change="audioGuidanceChange()"/>
              <label for="limitSpeedGuideOff">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">트랭글 시작</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="traggleStartGuideOn" v-model="tranggleStartGuide" value="TRUE" name="tranggleStartGuide" @change="audioGuidanceChange()"/>
              <label for="tranggleStartGuideOn">ON</label>
              <input type="radio" id="tranggleStartGuideOff" v-model="tranggleStartGuide" value="FALSE" name="tranggleStartGuide" @change="audioGuidanceChange()"/>
              <label for="tranggleStartGuideOff">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">운동 시작과 종료</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="startEndGuideOn" v-model="startEndGuide" value="TRUE" name="startEndGuide" @change="audioGuidanceChange()"/>
              <label for="startEndGuideOn">ON</label>
              <input type="radio" id="startEndGuideOff" v-model="startEndGuide" value="FALSE" name="startEndGuide" @change="audioGuidanceChange()"/>
              <label for="startEndGuideOff">OFF</label>
            </span>
          </span>
        </li>
      </ul>
      <div class="stitle">운동 중 안내</div>
      <ul>
        <li>
          <span class="set_text">갈림길 안내</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="exerciseTypeON" v-model="crossOnOff" value="ON" name="crossOnOff" @change="audioGuidanceChange()"/>
              <label for="exerciseTypeON">ON</label>
              <input type="radio" id="exerciseTypeOFF" v-model="crossOnOff" value="OFF" name="crossOnOff" @click="exerciseType = 'OFF'" @change="audioGuidanceChange()"/>
              <label for="exerciseTypeOFF">OFF</label>
            </span>
            <span class="input_1line">
              <input type="radio" id="exerciseValueDistnace" v-model="exerciseType" value="DISTANCE" name="exerciseType" :disabled="crossOnOff === 'OFF'"/>
              <label for="exerciseValueDistnace"> 거리 마다
                <select name="" id="exerciseGuideDistnace" v-model="exerciseGuideDistnace" class="style1" :disabled="crossOnOff === 'OFF'" @change="audioGuidanceChange()">
                  <option value="500">500m</option>
                  <option value="1000">1Km</option>
                  <option value="2000">2Km</option>
                  <option value="5000">5Km</option>
                  <option value="10000">10Km</option>
                  <option value="">선택안함</option>
                </select>
              </label>
              <br>
              <input type="radio" id="exerciseValueTime" v-model="exerciseType" value="TIME" name="exerciseType" :disabled="crossOnOff === 'OFF'"/>
              <label for="exerciseValueTime"> 시간 마다
                <select name="" id="exerciseGuideTime" v-model="exerciseGuideTime"  class="style1" :disabled="crossOnOff === 'OFF'" @change="audioGuidanceChange()">
                  <option value="5">5분</option>
                  <option value="10">10분</option>
                  <option value="30">30분</option>
                  <option value="">선택안함</option>
                </select>
              </label>
            </span>
          </span>
        </li>
      </ul>
      <div class="stitle">따라가기</div>
      <ul>
        <li>
          <span class="set_text">경로이탈 시 알림</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="configIsDeviateGuideOn" v-model="deviateGuide" value="TRUE" name="deviateGuide" @change="audioGuidanceChange()"/>
              <label for="configIsDeviateGuideOn">ON</label>
              <input type="radio" id="configIsDeviateGuideOff" v-model="deviateGuide" value="FALSE" name="deviateGuide" @change="audioGuidanceChange()"/>
              <label for="configIsDeviateGuideOff">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">경로이탈 기준거리</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="configDeviateDistnace30" v-model="deviateDistnace" value="30" name="deviateDistnace" :disabled="deviateGuide === 'FALSE'" @change="audioGuidanceChange()"/>
              <label for="configDeviateDistnace30">30m</label>
              <input type="radio" id="configDeviateDistnace50" v-model="deviateDistnace" value="50" name="deviateDistnace" :disabled="deviateGuide === 'FALSE'" @change="audioGuidanceChange()"/>
              <label for="configDeviateDistnace50">50m</label>
              <input type="radio" id="configDeviateDistnace70" v-model="deviateDistnace" value="70" name="deviateDistnace" :disabled="deviateGuide === 'FALSE'" @change="audioGuidanceChange()"/>
              <label for="configDeviateDistnace70">70m</label>
              <input type="radio" id="configDeviateDistnace100" v-model="deviateDistnace" value="100" name="deviateDistnace" :disabled="deviateGuide === 'FALSE'" @change="audioGuidanceChange()"/>
              <label for="configDeviateDistnace100">100m</label>
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      allCheck: '',
      crossroadGuide: '',
      gpsConditionGuide: '',
      badgeGuide: '',
      limitSpeedGuide: '',
      tranggleStartGuide: '',
      startEndGuide: '',
      crossOnOff: '',
      exerciseType: '',
      exerciseGuideDistnace: '',
      exerciseGuideTime: '',
      deviateGuide: '',
      deviateDistnace: ''
    }
  },
  methods: {
    ...mapActions('Member', [
      'updateMemberConfig'
    ]),
    audioGuidanceControl (state) {
      this.allCheck = state
      if (state === 'OFF') {
        this.crossroadGuide = 'FALSE'
        this.gpsConditionGuide = 'FALSE'
        this.badgeGuide = 'FALSE'
        this.limitSpeedGuide = 'FALSE'
        this.tranggleStartGuide = 'FALSE'
        this.startEndGuide = 'FALSE'

        this.crossOnOff = 'OFF'

        this.deviateGuide = 'FALSE'
      }
      if (state === 'ON') {
        this.crossroadGuide = 'TRUE'
        this.gpsConditionGuide = 'TRUE'
        this.badgeGuide = 'TRUE'
        this.limitSpeedGuide = 'TRUE'
        this.tranggleStartGuide = 'TRUE'
        this.startEndGuide = 'TRUE'

        this.crossOnOff = 'ON'

        this.deviateGuide = 'TRUE'
      }
      this.memberConfigDataUpt()
    },
    audioGuidanceChange () {
      this.allCheck = 'OFF'
      this.memberConfigDataUpt()
    },
    memberConfigDataUpt () {
      const data = {
        config_is_crossroad_guide: this.crossroadGuide,
        config_is_gps_condition_guide: this.gpsConditionGuide,
        config_is_badge_guide: this.badgeGuide,
        config_is_limit_speed_guide: this.limitSpeedGuide,
        config_is_traggle_start_guide: this.tranggleStartGuide,
        config_is_start_end_guide: this.startEndGuide,
        config_exercise_type: this.exerciseType,
        config_exercise_guide_distnace: this.exerciseGuideDistnace,
        config_exercise_guide_time: this.exerciseGuideTime,
        config_is_deviate_guide: this.deviateGuide,
        config_deviate_distnace: this.deviateDistnace
      }
      this.updateMemberConfig(data)
    }
  }
}
</script>
