import dayjs from 'dayjs'
const methods = {
  /**
   * 시간 데이터 시:분:초 형식으로 변환 (데이터 내 소수점은 초단위)
   * ex) 157 => 02:37:00, 102.16 => 01:42:16
   */
  convertTimeStampFormat: (time) => {
    const totalMinutes = parseInt(time)

    let hours = Math.floor(totalMinutes / 60)
    let minutes = Math.floor(totalMinutes - (hours * 60))
    let seconds = parseInt(parseFloat(time % 1).toFixed(2) * 100)

    if (hours < 10) { hours = '0' + hours }
    if (minutes < 10) { minutes = '0' + minutes }
    if (seconds < 10) { seconds = '0' + seconds }

    return hours + ':' + minutes + ':' + seconds
  },
  // 코스 예상 소요시간 ex) 12시간 10분
  convertTimeStampFormatCustom: (time) => {
    const totalMinutes = parseInt(time)

    const hours = Math.floor(totalMinutes / 60)
    const minutes = Math.floor(totalMinutes - (hours * 60))

    return hours ? `${hours}시간 ${minutes}분` : `${minutes}분`
  },
  convertTimeMinToHour: (min) => {
    var days = Math.floor(min / 60 / 24)
    var hours = Math.floor((min - (days * 60 * 24)) / 60)
    var mins = min - (days * 60 * 24) - (hours * 60)
    var hourStr = (hours > 9) ? hours : '0' + hours
    var minStr = (mins > 9) ? mins : '0' + mins
    if (min > 1440) {
      return parseInt(hourStr) + (parseInt(days) * 24) + '시간 ' + parseInt(minStr) + '분'
    } else if (min > 60) {
      return parseInt(hourStr) + '시간 ' + parseInt(minStr) + '분'
    } else {
      return min + '분'
    }
  },
  /**
   * 고도 데이터 포맷 변환 (소수점 제거)
   * ex) 102.00 => 102, 173.9 => 174
   */
  convertAltitudeFormat: (altitude) => {
    return Math.floor(altitude)
  },
  /**
   * 고도 데이터 포맷 변환 (소수점 제거)
   * ex) 102.00 => 102, 173.9 => 174
   */
  convertAltitudeFormatCustom: (altitude) => {
    return parseFloat(altitude).toFixed(1)
  },
  /**
   * 속도 데이터 포맷 변환 (소수점 1자리 까지)
   * ex) 102.00 => 102.0, 173.49 => 173.5
   */
  convertSpeedFormat: (speed) => {
    return parseFloat(speed).toFixed(1)
  },
  /**
   * 거리 데이터 포맷 변환 (소수점 1자리 까지)
   * ex) 102.00 => 102.0, 173.49 => 173.5
   */
  convertDistanceFormat: (distance) => {
    return Math.floor(parseFloat(distance))
  },
  /**
   * 거리 데이터 포맷 변환 (소수점 1자리 까지)
   * ex) 102.00 => 102.0, 173.49 => 173.5
   */
  convertDistanceFormatCustom: (distance) => {
    return parseFloat(distance).toFixed(1)
  },
  /**
   * 거리 데이터 킬로미터 단위로 변환 (소수점 1자리 까지)
   * ex) 9349.0 => 9.3, 3479 => 3.5
   */
  convertKilometerFormat: (distance) => {
    return Math.floor(parseFloat(distance / 1000))
  },
  /**
   * 거리 데이터 킬로미터 단위로 변환 (소수점 2자리 까지)
   * ex) 9349.0 => 9.3, 3479 => 3.5
   */
  convertKilometerFormatCustom: (distance) => {
    return parseFloat(distance / 1000).toFixed(1)
  },
  /**
   * 칼로리 데이터 포맷 변환 (소수점 1자리 까지)
   * ex) 102.00 => 102, 173.9 => 174
   */
  convertKcalFormat: (kcal) => {
    return Math.floor(kcal)
  },
  /**
   * 숫자 3자리마다 comma 추가
   */
  addComma: (data) => {
    return String(data).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  /**
   * 숫자 1자리 일경우 앞에 0 추가
   */
  addZero: (data) => {
    return String(data).length === 1 ? '0' + data : data
  },
  /**
   * 년월일 까지만 추출 (시간 제거)
   * ex) 2021-10-21 17:23:44 => 2021-10-21
   */
  convertDateFormat: (date) => {
    return String(date).substring(0, 10)
  },
  /**
   * 년월일 시분 까지만 추출 (시간 제거)
   * ex) 2021-10-21 17:23:44 => 2021-10-21 17:23
   */
  convertDateFormat2: (date) => {
    return String(date).substring(0, 16)
  },
  /**
   * 영문 종목명 한글로 변경 표출
   */
  changeSportsLanguage: (SportsName) => {
    var changeName = ''
    if (SportsName === 'WALKING' || SportsName === 'walking') { changeName = '걷기' } else
    if (SportsName === 'HIKING' || SportsName === 'hiking') { changeName = '등산' } else
    if (SportsName === 'BICYCLE' || SportsName === 'bicycle') { changeName = '자전거' } else
    if (SportsName === 'CANOE' || SportsName === 'canoe') { changeName = '카누' } else
    if (SportsName === 'EMOBILITY' || SportsName === 'emobility') { changeName = '모터사이클' } else
    if (SportsName === 'ETC' || SportsName === 'etc') { changeName = '기타' } else
    if (SportsName === 'FOOTBALL' || SportsName === 'football') { changeName = '축구' } else
    if (SportsName === 'GOLF' || SportsName === 'golf') { changeName = '골프' } else
    if (SportsName === 'INLINE' || SportsName === 'inline') { changeName = '인라인' } else
    if (SportsName === 'JUMPROPE' || SportsName === 'jumprope') { changeName = '줄넘기' } else
    if (SportsName === 'KAYAK' || SportsName === 'kayak') { changeName = '카약' } else
    if (SportsName === 'PARAGLIDING' || SportsName === 'paragliding') { changeName = '패러글라이딩' } else
    if (SportsName === 'RIDING' || SportsName === 'riding') { changeName = '승마' } else
    if (SportsName === 'ROWING' || SportsName === 'rowing') { changeName = '조정' } else
    if (SportsName === 'RUGBY' || SportsName === 'rugby') { changeName = '럭비' } else
    if (SportsName === 'SKIBOARD' || SportsName === 'skiboard') { changeName = '스노우보드' } else
    if (SportsName === 'SKYDIVING' || SportsName === 'skydiving') { changeName = '스카이다이빙' } else
    if (SportsName === 'STEPPER' || SportsName === 'stepper') { changeName = '스텝퍼' } else
    if (SportsName === 'TRAILRUNNING' || SportsName === 'trailrunning') { changeName = '트레일러닝' } else
    if (SportsName === 'TRAMPOLINE' || SportsName === 'trampoline') { changeName = '트램폴린' } else
    if (SportsName === 'TREADMILL' || SportsName === 'treadmill') { changeName = '런닝머신' } else
    if (SportsName === 'WINDSURFING' || SportsName === 'windsurfing') { changeName = '윈드서핑' } else
    if (SportsName === 'YACHT' || SportsName === 'yacht') { changeName = '요트' } else
    if (SportsName === 'SWIMMING' || SportsName === 'swimming') { changeName = '수영' } else
    if (SportsName === 'RUNNING' || SportsName === 'running') { changeName = '런닝' } else
    if (SportsName === 'STEP' || SportsName === 'step') { changeName = '스텝' } else
    if (SportsName === 'JOGGING' || SportsName === 'jogging') { changeName = '런닝' } else
    if (SportsName === 'SURFING' || SportsName === 'surfing') { changeName = '서핑' } else
    if (SportsName === 'CAMPING' || SportsName === 'camping') { changeName = '캠핑' } else { changeName = '-' }
    return changeName
  },
  /**
   * 지도에 표출 되는 마커 Icon 옵션 생성
   */
  getMapPinMarkerIcon: (className, text) => {
    return {
      content: '<div class="' + className + '">' + text + '</div>',
      size: new window.naver.maps.Size(30, 36),
      origin: new window.naver.maps.Size(0, 0),
      anchor: new window.naver.maps.Point(15, 36)
    }
  },
  /**
   * 카테고리 명 별 이미지
   * category는 운동명, index 0은 큰 이미지 나머지 숫자는 작은 이미지
   */
  categoryImage (category, index) {
    if (category === 'walking' || category === 'WALKING') { if (index === 9) { return 'i_sports_walking' } else if (index === 0) { return 'i_sports_walking i_xl' } else if (index === 5) { return 'icon_exc i_sports_walking' } else { return 'i_sports_walking i_m' } } else
    if (category === 'bicycle' || category === 'BICYCLE') { if (index === 9) { return 'i_sports_bicycle' } else if (index === 0) { return 'i_sports_bicycle i_xl' } else if (index === 5) { return 'icon_exc i_sports_bicycle' } else { return 'i_sports_bicycle i_m' } } else
    if (category === 'canoe' || category === 'CANOE') { if (index === 9) { return 'i_sports_canoe' } else if (index === 0) { return 'i_sports_canoe i_xl' } else if (index === 5) { return 'icon_exc i_sports_canoe' } else { return 'i_sports_canoe i_m' } } else
    if (category === 'emobility' || category === 'EMOBILITY') { if (index === 9) { return 'i_sports_emobility' } else if (index === 0) { return 'i_sports_emobility i_xl' } else if (index === 5) { return 'icon_exc i_sports_emobility' } else { return 'i_sports_emobility i_m' } } else
    if (category === 'etc' || category === 'ETC') { if (index === 9) { return 'i_sports_etc' } else if (index === 0) { return 'i_sports_etc i_xl' } else if (index === 5) { return 'icon_exc i_sports_etc' } else { return 'i_sports_etc i_m' } } else
    if (category === 'football' || category === 'FOOTBALL') { if (index === 9) { return 'i_sports_football' } else if (index === 0) { return 'i_sports_football i_xl' } else if (index === 5) { return 'icon_exc i_sports_football' } else { return 'i_sports_football i_m' } } else
    if (category === 'golf' || category === 'GOLF') { if (index === 9) { return 'i_sports_golf' } else if (index === 0) { return 'i_sports_golf i_xl' } else if (index === 5) { return 'icon_exc i_sports_golf' } else { return 'i_sports_golf i_m' } } else
    if (category === 'inline' || category === 'INLINE') { if (index === 9) { return 'i_sports_inline' } else if (index === 0) { return 'i_sports_inline i_xl' } else if (index === 5) { return 'icon_exc i_sports_inline' } else { return 'i_sports_inline i_m' } } else
    if (category === 'jumprope' || category === 'JUMPROPE') { if (index === 9) { return 'i_sports_jumprope' } else if (index === 0) { return 'i_sports_jumprope i_xl' } else if (index === 5) { return 'icon_exc i_sports_jumprope' } else { return 'i_sports_jumprope i_m' } } else
    if (category === 'kayak' || category === 'KAYAK') { if (index === 9) { return 'i_sports_kayak' } else if (index === 0) { return 'i_sports_kayak i_xl' } else if (index === 5) { return 'icon_exc i_sports_kayak' } else { return 'i_sports_kayak i_m' } } else
    if (category === 'paragliding' || category === 'PARAGLIDING') { if (index === 9) { return 'i_sports_paragliding' } else if (index === 0) { return 'i_sports_paragliding i_xl' } else if (index === 5) { return 'icon_exc i_sports_paragliding' } else { return 'i_sports_paragliding i_m' } } else
    if (category === 'riding' || category === 'RIDING') { if (index === 9) { return 'i_sports_riding' } else if (index === 0) { return 'i_sports_riding i_xl' } else if (index === 5) { return 'icon_exc i_sports_riding' } else { return 'i_sports_riding i_m' } } else
    if (category === 'rowing' || category === 'ROWING') { if (index === 9) { return 'i_sports_rowing' } else if (index === 0) { return 'i_sports_rowing i_xl' } else if (index === 5) { return 'icon_exc i_sports_rowing' } else { return 'i_sports_rowing i_m' } } else
    if (category === 'rugby' || category === 'RUGBY') { if (index === 9) { return 'i_sports_rugby' } else if (index === 0) { return 'i_sports_rugby i_xl' } else if (index === 5) { return 'icon_exc i_sports_rugby' } else { return 'i_sports_rugby i_m' } } else
    if (category === 'skiing' || category === 'SKIING' || category === 'skiboard' || category === 'SKIBOARD') { if (index === 9) { return 'i_sports_skiboard' } else if (index === 0) { return 'i_sports_skiboard i_xl' } else if (index === 5) { return 'icon_exc i_sports_skiboard' } else { return 'i_sports_skiboard i_m' } } else
    if (category === 'skydiving' || category === 'SKYDIVING') { if (index === 9) { return 'i_sports_skydiving' } else if (index === 0) { return 'i_sports_skydiving i_xl' } else if (index === 5) { return 'icon_exc i_sports_skydiving' } else { return 'i_sports_skydiving i_m' } } else
    if (category === 'stepper' || category === 'STEPPER') { if (index === 9) { return 'i_sports_stepper' } else if (index === 0) { return 'i_sports_stepper i_xl' } else if (index === 5) { return 'icon_exc i_sports_stepper' } else { return 'i_sports_stepper i_m' } } else
    if (category === 'trailrunning' || category === 'TRAILRUNNING') { if (index === 9) { return 'i_sports_trailrunning' } else if (index === 0) { return 'i_sports_trailrunning i_xl' } else if (index === 5) { return 'icon_exc i_sports_trailrunning' } else { return 'i_sports_trailrunning i_m' } } else
    if (category === 'trampoline' || category === 'TRAMPOLINE') { if (index === 9) { return 'i_sports_trampoline' } else if (index === 0) { return 'i_sports_trampoline i_xl' } else if (index === 5) { return 'icon_exc i_sports_trampoline' } else { return 'i_sports_trampoline i_m' } } else
    if (category === 'treadmill' || category === 'TREADMILL') { if (index === 9) { return 'i_sports_treadmill' } else if (index === 0) { return 'i_sports_treadmill i_xl' } else if (index === 5) { return 'icon_exc i_sports_treadmill' } else { return 'i_sports_treadmill i_m' } } else
    if (category === 'windsurfing' || category === 'WINDSURFING') { if (index === 9) { return 'i_sports_windsurfing' } else if (index === 0) { return 'i_sports_windsurfing i_xl' } else if (index === 5) { return 'icon_exc i_sports_windsurfing' } else { return 'i_sports_windsurfing i_m' } } else
    if (category === 'yacht' || category === 'YACHT') { if (index === 9) { return 'i_sports_yacht' } else if (index === 0) { return 'i_sports_yacht i_xl' } else if (index === 5) { return 'icon_exc i_sports_yacht' } else { return 'i_sports_yacht i_m' } } else
    if (category === 'hiking' || category === 'HIKING') { if (index === 9) { return 'i_sports_hiking' } else if (index === 0) { return 'i_sports_hiking i_xl' } else if (index === 5) { return 'icon_exc i_sports_hiking' } else { return 'i_sports_hiking i_m' } } else
    if (category === 'swimming' || category === 'SWIMMING') { if (index === 9) { return 'i_sports_swimming' } else if (index === 0) { return 'i_sports_swimming i_xl' } else if (index === 5) { return 'icon_exc i_sports_swimming' } else { return 'i_sports_swimming i_m' } } else
    if (category === 'running' || category === 'RUNNING') { if (index === 9) { return 'i_sports_running' } else if (index === 0) { return 'i_sports_running i_xl' } else if (index === 5) { return 'icon_exc i_sports_running' } else { return 'i_sports_running i_m' } } else
    if (category === 'step' || category === 'STEP') { if (index === 9) { return 'i_sports_step' } else if (index === 0) { return 'i_sports_step i_xl' } else if (index === 5) { return 'icon_exc i_sports_step' } else { return 'i_sports_step i_m' } } else
    if (category === 'jogging' || category === 'JOGGING') { if (index === 9) { return 'i_sports_running' } else if (index === 0) { return 'i_sports_running i_xl' } else if (index === 5) { return 'icon_exc i_sports_running' } else { return 'i_sports_running i_m' } } else
    if (category === 'surfing' || category === 'SURFING') { if (index === 9) { return 'i_sports_windsurfing' } else if (index === 0) { return 'i_sports_windsurfing i_xl' } else if (index === 5) { return 'icon_exc i_sports_windsurfing' } else { return 'i_sports_windsurfing i_m' } } else
    if (category === 'camping' || category === 'CAMPING') { if (index === 9) { return 'i_sports_camping' } else if (index === 0) { return 'i_sports_camping i_xl' } else if (index === 5) { return 'icon_exc i_sports_camping' } else { return 'i_sports_camping i_m' } } else
    if (category === 'all' || category === 'ALL') { if (index === 9) { return 'i_sports_all' } else if (index === 0) { return 'i_sports_all i_xl' } else if (index === 5) { return 'icon_exc i_sports_all' } else { return 'i_sports_all i_m' } } else {
      if (index === 9) { return 'i_sports_etc' } else if (index === 0) { return 'i_sports_etc i_xl' } else if (index === 5) { return 'icon_exc i_sports_etc' } else { return 'i_sports_etc i_m' }
    }
  },
  nowCategoryImage (category, index) {
    var type
    if (index === 16 || index === 17) {
      type = ' z_level_one'
    } else if (index === 14 || index === 15) {
      type = ' z_level_two'
    } else if (index === 13) {
      type = ' z_level_three'
    } else if (index === 12) {
      type = ' z_level_tour'
    } else if (index === 11 || index === 10) {
      type = ' z_level_five'
    } else if (index === 9 || index === 8 || index === 7) {
      type = ' z_level_six'
    } else {
      type = ''
    }

    if (category === 'walking' || category === 'WALKING') {
      return 'i_sports_walking' + type
    } else if (category === 'bicycle' || category === 'BICYCLE') {
      return 'i_sports_bicycle' + type
    } else if (category === 'canoe' || category === 'CANOE') {
      return 'i_sports_canoe' + type
    } else if (category === 'emobility' || category === 'EMOBILITY') {
      return 'i_sports_emobility' + type
    } else if (category === 'football' || category === 'FOOTBALL') {
      return 'i_sports_football' + type
    } else if (category === 'golf' || category === 'GOLF') {
      return 'i_sports_golf' + type
    } else if (category === 'inline' || category === 'INLINE') {
      return 'i_sports_inline' + type
    } else if (category === 'jumprope' || category === 'JUMPROPE') {
      return 'i_sports_jumprope' + type
    } else if (category === 'kayak' || category === 'KAYAK') {
      return 'i_sports_kayak' + type
    } else if (category === 'paragliding' || category === 'PARAGLIDING') {
      return 'i_sports_paragliding' + type
    } else if (category === 'riding' || category === 'RIDING') {
      return 'i_sports_riding' + type
    } else if (category === 'rowing' || category === 'ROWING') {
      return 'i_sports_rowing' + type
    } else if (category === 'rugby' || category === 'RUGBY') {
      return 'i_sports_rugby' + type
    } else if (category === 'skiing' || category === 'SKIING' || category === 'skiboard' || category === 'SKIBOARD') {
      return 'i_sports_skiboard' + type
    } else if (category === 'skydiving' || category === 'SKYDIVING') {
      return 'i_sports_skydiving' + type
    } else if (category === 'stepper' || category === 'STEPPER') {
      return 'i_sports_stepper' + type
    } else if (category === 'trailrunning' || category === 'TRAILRUNNING') {
      return 'i_sports_trailrunning' + type
    } else if (category === 'trampoline' || category === 'TRAMPOLINE') {
      return 'i_sports_trampoline' + type
    } else if (category === 'treadmill' || category === 'TREADMILL') {
      return 'i_sports_treadmill' + type
    } else if (category === 'windsurfing' || category === 'WINDSURFING') {
      return 'i_sports_windsurfing' + type
    } else if (category === 'yacht' || category === 'YACHT') {
      return 'i_sports_yacht' + type
    } else if (category === 'hiking' || category === 'HIKING') {
      return 'i_sports_hiking' + type
    } else if (category === 'swimming' || category === 'SWIMMING') {
      return 'i_sports_swimming' + type
    } else if (category === 'running' || category === 'RUNNING') {
      return 'i_sports_running' + type
    } else if (category === 'step' || category === 'STEP') {
      return 'i_sports_step' + type
    } else if (category === 'jogging' || category === 'JOGGING') {
      return 'i_sports_running' + type
    } else if (category === 'surfing' || category === 'SURFING') {
      return 'i_sports_windsurfing' + type
    } else if (category === 'camping' || category === 'CAMPING') {
      return 'i_sports_camping' + type
    } else if (category === 'all' || category === 'ALL') {
      return 'i_sports_all' + type
    } else { return 'i_sports_etc' + type }
  },
  /**
   * 종목 명 별 텍스트
   * category는 운동명, index 0은 큰 이미지 나머지 숫자는 작은 이미지
   */
  competKoText (compet) {
    if (compet === 'DISTANCE') { return '최장거리' } else
    if (compet === 'SPEED') { return '최고평속' } else
    if (compet === 'ALT') { return '최고고도' } else
    if (compet === 'MILEAGE') { return '최다경험치' } else
    if (compet === 'COURSE') { return '코스완주/종주' } else
    if (compet === 'STEP') { return '목표달성' } else
    if (compet === 'STEPSUM') { return '누적걸음수' } else
    if (compet === 'TIME') { return '최단시간' } else
    if (compet === 'ALTSUM') { return '누적고도' } else
    if (compet === 'BADGE') { return '최다배지획득' } else
    if (compet === 'BADGEGOAL') { return '목표배지개수' } else
    if (compet === 'DISTANCEGOAL') { return '목표거리' } else { return '' }
  },
  /**
   * 종목 명 별 이미지
   * category는 운동명, index 0은 큰 이미지 나머지 숫자는 작은 이미지
   */
  competImage (compet) {
    if (compet === 'DISTANCE' || compet === '최장거리') { return 'i_distance' } else
    if (compet === 'SPEED' || compet === '최고평속') { return 'i_speed' } else
    if (compet === 'ALT' || compet === '최고고도') { return 'i_altitude' } else
    if (compet === 'MILEAGE' || compet === '최다경험치') { return 'i_exp' } else
    if (compet === 'COURSE' || compet === '코스완주/종주') { return 'i_finish' } else
    if (compet === 'STEP' || compet === '목표달성') { return 'i_sports_etc' } else
    if (compet === 'STEPSUM' || compet === '누적걸음수' || compet === '목표걸음수') { return 'i_trophy' } else
    if (compet === 'TIME' || compet === '최단시간') { return 'i_shorttime' } else
    if (compet === 'ALTSUM' || compet === '누적고도') { return 'i_accaltiude' } else
    if (compet === 'DISTANCEGOAL' || compet === '목표거리') { return 'i_goal' } else { return '' }
  },
  /**
   * 회원관심운동 종목 조회
   */
  getMissionStatus () {
    return this.$store.getters['Common/getMissionStatus']
  },
  /**
   * 회원관심운동 종목 조회
   */
  getStatusCategory () {
    return this.$store.getters['Common/getStatusCategory']
  },
  /**
   * 회원관심운동 종목 조회
   */
  getTrackCategory () {
    this.$store.dispatch('Common/loadTrackCategory')
    return this.$store.getters['Common/getTrackCategory']
  },
  /**
   * 회원관심운동 종목 조회
   * 걸음수 제거
   */
  getTrackCategoryCustom () {
    this.$store.dispatch('Common/loadTrackCategory')
    if (this.$store.getters['Common/getTrackCategoryCustom'].STEP !== undefined) {
      delete this.$store.getters['Common/getTrackCategoryCustom'].STEP
      delete this.$store.getters['Common/getTrackCategoryCustom'].ETC
      this.$store.getters['Common/getTrackCategoryCustom'].ETC = '기타'
    }
    return this.$store.getters['Common/getTrackCategoryCustom']
  },
  getTrackCategoryETCDel () {
    this.$store.dispatch('Common/loadTrackCategory')
    console.log('트랙호출')
    if (this.$store.getters['Common/getTrackCategoryETCDel'].STEP !== undefined) {
      delete this.$store.getters['Common/getTrackCategoryETCDel'].STEP
      delete this.$store.getters['Common/getTrackCategoryETCDel'].ETC
    }
    // console.log(this.$store.Common.trackCategoryETCDel)
    return this.$store.getters['Common/getTrackCategoryETCDel']
  },
  /**
   * 경쟁부분 카테고리 조회
   */
  getCompetCategory () {
    this.$store.dispatch('Common/loadCompetCategory')
    return this.$store.getters['Common/getCompetCategory']
  },
  /**
   * 운동 거리 카테고리 조회
   */
  getDistanceCategory () {
    return this.$store.getters['Common/getDistanceCategory']
  },
  /**
   * 운동 시간 카테고리 조회
   */
  getSportTimeCategory () {
    return this.$store.getters['Common/getSportTimeCategory']
  },
  /**
   * 배지 유형 카테고리 조회
   */
  getBadgeTypeCategory () {
    return this.$store.getters['Common/getBadgeTypeCategory']
  },
  /**
   * 획득 배지 수 카테고리 조회
   */
  getSecureBdageCategory () {
    return this.$store.getters['Common/getSecureBdageCategory']
  },
  /**
   * 획득 배지 유형 카테고리 조회
   */
  getSecureBdageTypeCategory () {
    return this.$store.getters['Common/getSecureBdageTypeCategory']
  },
  /**
   * 네/아니오 버튼 팝업 열기
   * popupHeaderMessage : 팝업 타이틀 부분 메시지
   * popupBodyMessage : 팝업 내용 부분 메시지
   * callbackMethod : 네 버튼 클릭시 실행 될 메소드 ex) this.메소드명
   */
  showConfirmPopup (popupHeaderMessage, popupBodyMessage, callbackMethod, callbackCancelMethod) {
    const data = { popupHeaderMessage: popupHeaderMessage, popupBodyMessage: popupBodyMessage, callbackMethod: callbackMethod, callbackCancelMethod: callbackCancelMethod }
    this.$store.commit('Popup/setConfirmPopup', data)
    this.$store.commit('Popup/controlConfirmPopup')
  },
  /**
   * 취소/신고 버튼 팝업 열기
   * popupHeaderMessage : 팝업 타이틀 부분 메시지
   * popupBodyMessage : 팝업 내용 부분 메시지
   * callbackMethod : 네 버튼 클릭시 실행 될 메소드 ex) this.메소드명
   */
  showConfirmReportPopup (popupHeaderMessage, popupBodyMessage, callbackMethod, callbackCancelMethod) {
    const data = { popupHeaderMessage: popupHeaderMessage, popupBodyMessage: popupBodyMessage, callbackMethod: callbackMethod, callbackCancelMethod: callbackCancelMethod }
    this.$store.commit('Popup/setConfirmReportPopup', data)
    this.$store.commit('Popup/controlConfirmReportPopup')
  },
  /**
   * 메시지 버튼 팝업 열기
   * popupHeaderMessage : 팝업 타이틀 부분 메시지
   * popupBodyMessage : 팝업 내용 부분 메시지
   * link : 확인 버튼 클릭시 이동할 링크 (없을 시 창 닫기)
   */
  showMessagePopup (popupHeaderMessage, popupBodyMessage, link, popupButtonText) {
    const data = { popupHeaderMessage: popupHeaderMessage, popupBodyMessage: popupBodyMessage, link: link, popupButtonText: popupButtonText }
    this.$store.commit('Popup/setMessagePopup', data)
    this.$store.commit('Popup/controlMessagePopup')
  },
  /**
   * 콜백 메소드 메시지 버튼 팝업 열기
   * popupHeaderMessage : 팝업 타이틀 부분 메시지
   * popupBodyMessage : 팝업 내용 부분 메시지
   * callbackMethod : 확인 버튼 클릭시 실행 될 메소드 ex) this.메소드명
   */
  showCallBackMessagePopup (popupHeaderMessage, popupBodyMessage, callbackMethod) {
    const data = { popupHeaderMessage: popupHeaderMessage, popupBodyMessage: popupBodyMessage, callbackMethod: callbackMethod }
    this.$store.commit('Popup/setCallBackMessagePopup', data)
    this.$store.commit('Popup/controlCallBackMessagePopup')
  },
  /**
   * 종목 경쟁 부분 한글화
   */
  missCompetKo (missCompet) {
    if (missCompet === 'DISTANCE') { return '최장거리' } else
    if (missCompet === 'STEP') { return '걸음수' } else
    if (missCompet === 'SPEED') { return '최고평속' } else
    if (missCompet === 'MILEAGE') { return '최다경험치' } else
    if (missCompet === 'ALT') { return '최고고도' } else
    if (missCompet === 'COURSE') { return '코스완주/종주' } else { return '기타' }
  },
  /**
   * 숫자 요일 변환
   * num (요일 - 일요일 : 0, 토요일 : 6)
   * type 0 일요일 그외 일
   */
  numToWeekKo (num, type) {
    if (num === 0) { if (type === 0) { return '일요일' } else { return '일' } } else
    if (num === 1) { if (type === 0) { return '월요일' } else { return '월' } } else
    if (num === 2) { if (type === 0) { return '화요일' } else { return '화' } } else
    if (num === 3) { if (type === 0) { return '수요일' } else { return '수' } } else
    if (num === 4) { if (type === 0) { return '목요일' } else { return '목' } } else
    if (num === 5) { if (type === 0) { return '금요일' } else { return '금' } } else
    if (num === 6) { if (type === 0) { return '토요일' } else { return '토' } }
  },
  searchOPT (title, opt) {
    const data = { popupTitle: title, opt: opt }
    console.log(opt)
    this.$store.commit('Popup/setSearchData', data)
    this.$store.commit('Popup/controlSearchPopup')
  },
  /**
   * 미션 종목 난이도
   * H-상,M-중,L-하
   */
  getMissGradeKo (grade) {
    if (grade === 'H') { return '상' } else
    if (grade === 'M') { return '중' } else
    if (grade === 'L') { return '하' } else { return '' }
  },
  /**
   * 참가비
   * F-무료,P-유료
   */
  getMissPaymentKo (payment) {
    if (payment === 'F') { return '무료' } else
    if (payment === 'P') { return '유료' } else { return '' }
  },
  /**
   * 참가대상
   * AUTO-자동승인,NMPH-이름+휴대전화,PANUM-참가자고유번호,PWD-비밀번호)
   */
  getMissTargettKo (target) {
    if (target === 'AUTO') { return '자동승인' } else
    if (target === 'NMPH') { return '이름+휴대전화' }
    if (target === 'PANUM') { return '참가자고유번호' }
    if (target === 'PWD') { return '비밀번호' } else { return '' }
  },
  getCompetRecordText (compet, value) {
    if (compet === '최다경험치' || compet === 'MILEAGE') { return methods.addComma(parseInt(value)) + 'p' } else
    if (compet === '누적걸음수' || compet === 'STEPSUM') { return methods.addComma(parseInt(value)) + '걸음' } else
    if (compet === '목표달성' || compet === 'STEP') { return methods.convertKilometerFormat(value) + 'km' } else
    if (compet === '최단시간' || compet === 'TIME') { return value + '' } else
    if (compet === '목표거리' || compet === 'DISTANCEGOAL') { return methods.convertKilometerFormat(value) + 'km' } else
    if (compet === '최고평속' || compet === 'SPEED') { return methods.convertKilometerFormat(value) + 'km/h' } else
    if (compet === '최장거리' || compet === 'DISTANCE') { return methods.convertKilometerFormat(value) + 'km' } else
    if (compet === '코스완주/종주' || compet === 'COURSE') { return value + '' } else
    if (compet === '누적고도' || compet === 'ALTSUM') { return methods.addComma(parseInt(value)) + 'm' } else
    if (compet === '최고고도' || compet === 'ALT') { return methods.addComma(parseInt(value)) + 'm' } else { return '' }
  },
  /**
   * 날짜 사이의 날짜 차이 조회
   * sdate : 2021-12-30 07:00:00
   * edate : 2021-12-30 07:00:00
   * option : y(년) M(월) d(일)
   */
  getDateDiff (sdate, edate, option) {
    const startDate = dayjs(sdate)
    const endDate = dayjs(edate)
    return endDate.diff(startDate, option) + 1
  },
  /**
   * textarea에서 작성 된 텍스트 줄바꿈이 필요할 때 사용
   * 태그 내에 v-html로 값을 넣어줘야 됨
   */
  replaceTextareaNewLine (text) {
    return text.split('\n').join('<br />')
  },
  /**
   * 퍼센트 조회
   * maxNum : 전체 일 또는 최종값
   * inquiryNum : 조회 값
   */
  percent (maxNum, inquiryNum) {
    return Math.floor(inquiryNum / maxNum * 100)
  },
  /**
   * 소수점 한자리까지만 출력
   */
  roundData (e) {
    return Math.round(e * 10) / 10
  },
  /**
   * float 데이터 round 처리
   */
  roundNum (e) {
    return Math.round(e)
  },
  /**
   * 사용자 이미지가 없는경우 NO 썸네일 이미지 할당
   * src : 사용자 이미지 접근 웹 경로
   * url의 앞에 http가 안 붙는경우 이미지 로드 오류로 인해 없는경우 http:// 할당
   * (추가) 22-5-17 이미지 url이 있어도 404 발생시 기본이미지 표출 추가
   */
  photoEmpty (src) {
    var returnData = ''
    // var httpStatus = ''
    // var http = new XMLHttpRequest()
    if (src === '' || src === null || src === undefined) {
      // return 'https://www.tranggle.com/resource/img/main/thum_no_image.jpg'
      returnData = require('@/assets/images/common/img_profile.png')
    } else if (String(src).substring(0, 4) === 'blob') {
      returnData = src
    } else if (String(src).substring(0, 4) !== 'http') {
      returnData = 'http://' + src
    } else {
      returnData = src
    }
    return returnData
    // if (String(returnData).substring(0, 4) === 'blob') {
    //   return returnData
    // } else {
    //   http.open('HEAD', returnData, false)
    //   http.send()
    //   httpStatus = http.status
    //   if (httpStatus === 404) {
    //     return require('@/assets/images/common/img_profile.png')
    //   } else {
    //     return returnData
    //   }
    // }
  },

  /**
   * A 입력값이 빈값, NULL, undefined 인경우 A 표출
   */
  NVLAB (A, B) {
    if (A === '' || A === null || A === undefined || A === 'NaN') {
      return B
    } else {
      return A
    }
  },
  filterNameKo (category) {
    if (category === 'REGION') { return '지역' } else
    if (category === 'AGE') { return '나이' } else
    if (category === 'GENDER') { return '성별' } else
    if (category === 'CATEGORY') { return '운동 종목' } else
    if (category === 'FREQUENCY') { return '운동 빈도' } else
    if (category === 'TIME') { return '운동 시간' } else
    if (category === 'DISTANCE') { return '운동 거리' } else { return '-' }
  },

  /**
   * 배열값이 존재하면 true 없으면 false
   */
  arrayDataChk (array) {
    if (typeof array !== 'undefined' && array != null && array.length != null && array.length > 0) {
      return true
    } else {
      return false
    }
  },
  /**
   * ref로 스크롤 이동
   * ref : ref명
   * margin : 상단여백
   */
  scrollToRef (ref, margin) {
    if (margin === undefined) {
      margin = 0
    }
    var element = this.$refs[ref]
    var top = element.offsetTop - margin
    window.scrollTo(0, top)
  },
  /**
   * targetRef에서 ref로 스크롤 이동
   * ref : ref명
   * margin : 상단여백
   */
  scrollToRefToTarget (targetRef, ref, margin) {
    if (margin === undefined) {
      margin = 0
    }
    var target = this.$refs[targetRef]
    console.log('target', targetRef, target)
    var element = this.$refs[ref][0]
    console.log('ref', ref, element)
    var top = element.offsetTop - margin
    console.log('top', top)
    target.scrollTo(0, top)
  },
  /**
   * 전화번호 * 표시
   */
  hidePhoneNum (phone) {
    phone = phone.split('-').join('')
    if (phone.length === 11) {
      return phone.substr(0, 3) + '****' + phone.substr(7, 4)
    } else if (phone.length === 10) {
      return phone.substr(0, 2) + '****' + phone.substr(6, 4)
    } else {
      return phone
    }
  },
  /**
   * 회원관심운동 종목 조회
   */
  getActivityAgeCategory () {
    return this.$store.getters['Common/getActivityAgeCategory']
  },
  /**
   * 회원관심운동 종목 조회
   */
  getActivityGenderCategory () {
    return this.$store.getters['Common/getActivityGenderCategory']
  },
  /**
   * 회원관심운동 종목 조회
   */
  getActivityFrequencyCategory () {
    return this.$store.getters['Common/getActivityFrequencyCategory']
  },
  /**
   * 회원관심운동 종목 조회
   */
  getActivityTimeCategory () {
    return this.$store.getters['Common/getActivityTimeCategory']
  },
  /**
   * 회원관심운동 종목 조회
   */
  getActivityDistanceCategory () {
    return this.$store.getters['Common/getActivityDistanceCategory']
  },
  /**
   * 회원관심운동 종목 조회
   */
  getActivitySolidityCategory () {
    return this.$store.getters['Common/getActivitySolidityCategory']
  },
  /**
   * 회원관심운동 종목 조회
   */
  getActivityPurposeCategory () {
    return this.$store.getters['Common/getActivityPurposeCategory']
  },
  /**
   * img tag src 오류 발생할 시 no image 로 대체
   * ex) <img src="aa.png" @error="replaceByNoImage" />
   */
  replaceByNoImage (e) {
    e.target.src = require('@/assets/images/common/thumbnail_noimg.png')
  },
  /**
   * 프로필 이미지 img tag src 오류 발생할 시 no profile image 로 대체
   * ex) <img src="aa.png" @error="replaceByNoProfileImage" />
   */
  replaceByNoProfileImage (e) {
    e.target.src = 'https://resource.tranggle.com/profile/no_image.png'
  },
  /**
   * 에디터로 작성된 HTML 데이터를 tag 없이 텍스트만 추출
   */
  removeHtmlTag (text) {
    text = text.replace(/<br\/>/ig, '\n')
    text = text.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/ig, '')

    text = text.replace(/(<([^>]+)>)/gi, '')
    text = text.replace(/&nbsp;/gi, '')
    text = text.replace(/<img .*(\/)?/g, '')
    text = text.trim()

    return text
  },
  onlyNumber (evt) {
    if (evt.target.value === '') evt.target.value = ''
    else evt.target.value = String(evt.target.value).replace(/[^0-9]/g, '')
  },
  onlyNumberAndComma (evt) {
    if (evt.target.value === '') evt.target.value = ''
    else evt.target.value = String(evt.target.value).replace(/[^-\\.0-9]/g, '')
  },
  addHtml (link) {
    if (link.indexOf('http') === -1) {
      link = 'http://' + link
    }

    return link
  },
  emailExp (email) {
    if (email === '') {
      return ''
    } else {
      if (email.split('@')[1] === 'naver.com' || email.split('@')[1] === 'gmail.com' || email.split('@')[1] === 'daum.net' || email.split('@')[1] === 'nate.com') {
        return email.split('@')[0]
      } else {
        return email
      }
    }
  },
  emailDomain (email) {
    console.log(email)
    if (email === '' || email === null) {
      return ''
    } else {
      return email.split('@')[1]
    }
  }
}

export default {
  install (app) {
    app.config.globalProperties.$convertTimeStampFormat = methods.convertTimeStampFormat
    app.config.globalProperties.$convertTimeStampFormatCustom = methods.convertTimeStampFormatCustom
    app.config.globalProperties.$convertAltitudeFormat = methods.convertAltitudeFormat
    app.config.globalProperties.$convertAltitudeFormatCustom = methods.convertAltitudeFormatCustom
    app.config.globalProperties.$convertSpeedFormat = methods.convertSpeedFormat
    app.config.globalProperties.$convertDistanceFormat = methods.convertDistanceFormat
    app.config.globalProperties.$convertDistanceFormatCustom = methods.convertDistanceFormatCustom
    app.config.globalProperties.$convertKilometerFormat = methods.convertKilometerFormat
    app.config.globalProperties.$convertKilometerFormatCustom = methods.convertKilometerFormatCustom
    app.config.globalProperties.$convertKcalFormat = methods.convertKcalFormat
    app.config.globalProperties.$addComma = methods.addComma
    app.config.globalProperties.$addZero = methods.addZero
    app.config.globalProperties.$convertDateFormat = methods.convertDateFormat
    app.config.globalProperties.$convertDateFormat2 = methods.convertDateFormat2
    app.config.globalProperties.$changeSportsLanguage = methods.changeSportsLanguage
    app.config.globalProperties.$getMapPinMarkerIcon = methods.getMapPinMarkerIcon
    app.config.globalProperties.$categoryImage = methods.categoryImage
    app.config.globalProperties.$competImage = methods.competImage
    app.config.globalProperties.$getStatusCategory = methods.getStatusCategory
    app.config.globalProperties.$getMissionStatus = methods.getMissionStatus
    app.config.globalProperties.$getTrackCategory = methods.getTrackCategory
    app.config.globalProperties.$getTrackCategoryCustom = methods.getTrackCategoryCustom
    app.config.globalProperties.$getTrackCategoryETCDel = methods.getTrackCategoryETCDel
    app.config.globalProperties.$getCompetCategory = methods.getCompetCategory
    app.config.globalProperties.$getDistanceCategory = methods.getDistanceCategory
    app.config.globalProperties.$getSportTimeCategory = methods.getSportTimeCategory
    app.config.globalProperties.$getBadgeTypeCategory = methods.getBadgeTypeCategory
    app.config.globalProperties.$getSecureBdageCategory = methods.getSecureBdageCategory
    app.config.globalProperties.$showConfirmPopup = methods.showConfirmPopup
    app.config.globalProperties.$showConfirmReportPopup = methods.showConfirmReportPopup
    app.config.globalProperties.$showMessagePopup = methods.showMessagePopup
    app.config.globalProperties.$showCallBackMessagePopup = methods.showCallBackMessagePopup
    app.config.globalProperties.$missCompetKo = methods.missCompetKo
    app.config.globalProperties.$numToWeekKo = methods.numToWeekKo
    app.config.globalProperties.$searchOPT = methods.searchOPT
    app.config.globalProperties.$getMissGradeKo = methods.getMissGradeKo
    app.config.globalProperties.$getMissPaymentKo = methods.getMissPaymentKo
    app.config.globalProperties.$getMissTargettKo = methods.getMissTargettKo
    app.config.globalProperties.$getCompetRecordText = methods.getCompetRecordText
    app.config.globalProperties.$getDateDiff = methods.getDateDiff
    app.config.globalProperties.$replaceTextareaNewLine = methods.replaceTextareaNewLine
    app.config.globalProperties.$percent = methods.percent
    app.config.globalProperties.$photoEmpty = methods.photoEmpty
    app.config.globalProperties.$getSecureBdageTypeCategory = methods.getSecureBdageTypeCategory
    app.config.globalProperties.$NVLAB = methods.NVLAB
    app.config.globalProperties.$scrollToRef = methods.scrollToRef
    app.config.globalProperties.$scrollToRefToTarget = methods.scrollToRefToTarget
    app.config.globalProperties.$hidePhoneNum = methods.hidePhoneNum
    app.config.globalProperties.$competKoText = methods.competKoText
    app.config.globalProperties.$getActivityAgeCategory = methods.getActivityAgeCategory
    app.config.globalProperties.$getActivityGenderCategory = methods.getActivityGenderCategory
    app.config.globalProperties.$getActivityFrequencyCategory = methods.getActivityFrequencyCategory
    app.config.globalProperties.$getActivityTimeCategory = methods.getActivityTimeCategory
    app.config.globalProperties.$getActivityDistanceCategory = methods.getActivityDistanceCategory
    app.config.globalProperties.$getActivitySolidityCategory = methods.getActivitySolidityCategory
    app.config.globalProperties.$getActivityPurposeCategory = methods.getActivityPurposeCategory
    app.config.globalProperties.$replaceByNoImage = methods.replaceByNoImage
    app.config.globalProperties.$replaceByNoProfileImage = methods.replaceByNoProfileImage
    app.config.globalProperties.$removeHtmlTag = methods.removeHtmlTag
    app.config.globalProperties.$roundData = methods.roundData
    app.config.globalProperties.$roundNum = methods.roundNum
    app.config.globalProperties.$onlyNumber = methods.onlyNumber
    app.config.globalProperties.$onlyNumberAndComma = methods.onlyNumberAndComma
    app.config.globalProperties.$arrayDataChk = methods.arrayDataChk
    app.config.globalProperties.$filterNameKo = methods.filterNameKo
    app.config.globalProperties.$addHtml = methods.addHtml
    app.config.globalProperties.$nowCategoryImage = methods.nowCategoryImage
    app.config.globalProperties.$convertTimeMinToHour = methods.convertTimeMinToHour
    app.config.globalProperties.$emailExp = methods.emailExp
    app.config.globalProperties.$emailDomain = methods.emailDomain
  }
}
