<template>
    <!-- <MyPopup ref="PopUpViewRef"/> -->
    <div class="fixed">
      <div class="header_wrap">
        <TopMenu/>
      </div>
    </div>
    <div class="container">
      <div class="main mypage_mobile">
        <div class="section m_auto">
          <!-- <MTLeftMenu /> -->
          <router-view></router-view>
        </div><!--/section-->
      </div><!--/main-->
    </div><!--/container-->
    <!-- 비밀번호 변경 팝업 -->
    <MyTranggleChangePassword v-if="MyTrangConfPopUpStep4" />
    <MyPopup v-else />
</template>

<script>
import TopMenu from '../components/common/TopMenu.vue'
// import MTLeftMenu from '../components/mytranggle/MTLeftMenu.vue'
import MyPopup from '../components/mytranggle/MyPopup.vue'
import MyTranggleChangePassword from '@/components/mytranggle/mytrangconf/popup/ChangePassword.vue'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      currentLeftMenu: ''
    }
  },
  components: {
    TopMenu,
    // MTLeftMenu,
    MyPopup,
    MyTranggleChangePassword
  },
  computed: {
    ...mapState('MyTranggle', ['MyTrangConfPopUpStep4'])
  },
  watch: {
    // $route () {
    //   this.$store.state.Common.selectArea = []
    //   this.$store.state.Common.searchTrackCategory = []
    // }
  },
  async mounted () {
    if (this.$store.state.UserSet.isLogin === 'Y') {
      // this.setLeftMenu()
    }
    // window.addEventListener('beforeunload', this.unLoadEvent)
  },
  methods: {
    setLeftMenu () {
      var currentPath = this.$route.path
      this.currentLeftMenu = currentPath.substring(currentPath.lastIndexOf('/') + 1, currentPath.length)
      // 페이지 이동시 검색 옵션 초기화
      this.$store.state.Common.selectArea = []
      this.$store.state.Common.searchTrackCategory = []
      this.$store.state.Common.searchBadgeTypeCategory = []
    }
  }
}
</script>
