<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <div class="popup_box popup_box_lm">
        <div class="popup_top"><h2 class="head1">휴대전화 인증</h2></div>
        <div class="popup_body">
          <div class="title_img"><img src="@/assets/images/common/img_password.png" /></div>
          <p class="t_bold">휴대전화 인증이 필요합니다.</p>
          <p>아이디, 비밀번호를 잊어버렸을 때나 기타 이유로 로그인을 해야할 때<br>
            인증하신 연락처로 쉽게 해결할 수 있습니다.<br><br>
            한 계정당 <span class="text_org">필수적</span>으로 휴대전화인증을 진행해야하며<br>
            다수 단말 사용자를 고려하여 휴대전화번호 <span class="text_org">중복사용</span>이 가능합니다.<br><br>
            <span class="text_org">모든 계정의 휴대전화 인증</span>을 부탁드립니다.<br>
            고객님의 정보는 암호화되어 안전하게 처리합니다.
          </p>
          <p class="stext_line_top">본인 명의 휴대폰 번호로 본인확인을 진행해 주세요.</p>
          <div class="login_area">
            <ul>
              <SelfAuthentication @completeAuth="completeAuth"/>
            </ul>
          </div>
        </div>
        <div class="btn_area btn_bottom2">
          <button type="button" class="btn_f" @click="complete">완료</button>
        </div>
      </div>
    </div>
  </div><!--/popup-->
</template>

<script>
import SelfAuthentication from '@/components/member/SelfAuthentication.vue'

export default {
  name: 'MobileAuthenticationPopup',
  data () {
    return {
      phone: '',
      email: '',
      validateSmsCode: ''
    }
  },
  methods: {
    completeAuth (phone, email) {
      this.phone = phone
      this.email = email
      this.validateSmsCode = 'Y'
    },
    complete () {
      this.$emit('setMemberInfo')
      this.$showMessagePopup('휴대전화 인증', '인증 완료되었습니다.', '/')
    }
  },
  components: {
    SelfAuthentication: SelfAuthentication
  }
}

</script>

<style>
</style>
