<template >
    <div class="popup popup_bg">
        <div class="popup_area">
            <div class="popup_box popup_box_m">
                <div class="popup_top popup_top_line"><h2>신고하기<a href="#" class="btn_close" @click="close"></a></h2>
                </div>
                <div class="popup_body">
                    <div class="report_area">
                        <ul class="report_title">
                            <li>
                                <span class="set_text">작성자</span>
                                <span class="set_input">{{this.$NVLAB(this.$store.state.Popup.reportData.declare_nick, this.$store.state.Popup.reportData.declare_id)}}</span>
                            </li>
                            <li>
                                <span class="set_text">내&nbsp;&nbsp;&nbsp;용</span>
                                <span class="set_input">{{this.$store.state.Popup.reportData.reportText}} </span>
                            </li>
                        </ul>
                        <div class="title">신고이유</div>
                        <div class="report_box">
                            <span class="td1">
                                <!-- <input type="radio" id = "revelationALL" value="TRUE" v-model="revelation" @click="revelationChange ('TRUE')"/><label for = "revelationALL">공개</label>
                                <input type="radio" id = "revelationFRIENDS" value="FRIENDS" v-model="revelation" @click="revelationChange ('FRIENDS')"/><label for = "revelationFRIENDS">친구만</label>
                                <input type="radio" id = "revelationCLOSE" value="FALSE" v-model="revelation" @click="revelationChange ('FALSE')"/><label for = "revelationCLOSE">비공개</label> -->
                                <input type="radio" id = "declareNo1" value = "1" v-model = "declareNo" @click="declareNo = 1"/>
                                <label for = "declareNo1">스팸홍보, 도배글 입니다.<span class="stext">부적절한 스팸 홍보행위 및 동일하거나 유사한 글 반복 게시</span></label>
                            </span>
                            <span class="td1">
                                <input type="radio" id = "declareNo2" value = "2" v-model = "declareNo" @click="declareNo = 2"/>
                                <label for = "declareNo2">부적절, 혐오 표현글입니다.<span class="stext">직,간접적 욕설 사용하여 모욕감을 주거나 불쾌함, 혐오, 차별적 표현글</span></label>                            </span>
                            <span class="td1">
                                <input type="radio" id = "declareNo3" value = "3" v-model = "declareNo" @click="declareNo = 3"/>
                                <label for = "declareNo3">불법정보 제공 글입니다.<span class="stext">직, 간접적 욕설 사용하여 모욕감을 주거나 혐오, 차별적 표현글</span></label>
                            </span>
                            <span class="td1">
                                <input type="radio" id = "declareNo4" value = "4" v-model = "declareNo" @click="declareNo = 4"/>
                                <label for = "declareNo4">저작권, 명예훼손등의 글입니다.<span class="stext">저작권, 상표권, 기타 권리 침해 및 명예훼손의 내용이 포함된 글</span></label>
                            </span>
                            <span class="td1">
                                <input type="radio" id = "declareNo5" value = "5" v-model = "declareNo" @click="declareNo = 5"/>
                                <label for = "declareNo5">개인정보 노출 게시글 입니다.<span class="stext">법적으로 중요한 타인의 개인정보를 작성한 글</span></label>
                            </span>
                        </div>
                    </div>
                    <div class="btn_area">
                        <button type="button" @click="actReport">신고하기</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      declareNo: ''
    }
  },
  components: {
  },
  name: 'ReportPopup',
  methods: {
    close () {
      this.$store.state.Popup.isOpenReportPopup = false
    },
    actReport () {
      this.$showConfirmReportPopup('신고하기', '회원님의 신고 내용은 트랭글 이용약관 및<br/>정책에 의해서 처리되며, 허위신고 시<br/> 서비스 이용이 제한될 수 있습니다.', () => {
        this.axios.get('/Board/declare.json', {
          params: {
            token: this.$store.state.Member.token,
            board_id: this.$store.state.Popup.reportData.board_id,
            post_no: this.$store.state.Popup.reportData.post_no,
            declare_id: this.$store.state.Popup.reportData.declare_id,
            declare_no: this.declareNo
          }
        })
          .then(data => {
            this.$store.state.Popup.isOpenReportPopup = false
            if (data.data.response.code === '00') {
              this.$showMessagePopup('신고하기', '신고 처리 되었습니다.')
            } else {
              this.$showMessagePopup('', data.data.response.message)
            }
          })
      })
    }
  }
}
</script>
