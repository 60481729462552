<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <div class="popup_box popup_box_xl">
        <div class="popup_top line_bottom"><h2>기록 첨부</h2><a href="#" class="btn_close" @click.prevent="this.$store.commit('Popup/controlAttachCoursePopup')"></a></div>
        <div class="popup_body1">
          <div class="add_record">
            <div class="course_left">
              <div class="section">
                <div class="top_title">나의 코스</div>
              </div>
              <div class="tab_menu">
                <ul>
                  <li :class="{on : this.listTab === 'MY'}"><a href="#" @click.prevent="changeListTab('MY')">내 기록 {{myCourseCount}}개</a></li>
                  <li :class="{on : this.listTab === 'DOWNLOAD'}"><a href="#" @click.prevent="changeListTab('DOWNLOAD')">받은 기록 {{myDownloadCount}}개</a></li>
                </ul>
              </div>
              <div class="title_box">
                <div class="select_box">
                  <select v-model="category" @change="selectList(1)" class="style1">
                    <option value="">종합</option>
                    <option
                      v-for="(name, value) in this.$getTrackCategoryCustom()"
                      :key="value"
                      :value="value">{{name}}</option>
                  </select>
                  <select v-model="year" @change="selectList(1)" class="style1">
                    <option :value="value" v-for="(value, i) in getYearList" :key="i">{{value}}년</option>
                  </select>
                  <select v-model="region" @change="selectList(1)" class="style1">
                    <option value="">전지역</option>
                    <option :value="sido.name" v-for = "(sido,i) in sidoList" :key="i" @click="selectSigunguList(sido.id)">{{sido.name}}</option>
                  </select>
                </div>
                <div class="select_search input_def">
                  <input type="text" v-model.trim="searchKeyword" placeholder="코스명 검색" @keyup.enter="selectList(1)"/>
                  <a href="#" class="i_del" @click.prevent="clearSearchKeyword()" :style="{'display': showKeywordClearButton}"></a>
                  <a href="#" class="i_fin" @click.prevent="selectList(1)"></a>
                </div>
              </div>
              <div class="left_content">
                <div class="list_poi">
                  <span class="bin" v-if="this.completeSearch && courseList.length === 0">
                    <span class="bin_img_result"></span>
                    <span class="text">검색 결과가 없습니다.</span>
                  </span>
                  <ul v-else>
                    <li v-for="(course, i) in courseList" :key="i" :class="{on: i === this.$store.state.Course.selectedCourseIndex}" @click="this.selectedCourse(i)">
                      <span class="td1"><span class="poi_pin">{{i + 1}}</span></span>
                      <span class="td3">
                        <span class="t_icon">
                          <span :class="`i_s ` + this.$categoryImage(course.log_category,9)"></span>
                          <div class="user_file">
                              <span class="i_record_p" v-if = "course.log_waypoint_image_state !== 'NONE'"></span>
                              <span class="i_record_t" v-if = "course.log_image_count !== '0'"></span>
                              <span class="i_record_v" v-if = "course.log_waypoint_voice_state !== 'NONE'"></span>
                              <span class="i_record_w" v-if = "course.log_guide_count !== '0'"></span>
                              <!-- // -->
                          </div>
                        </span>
                        <span class="poi_content">
                          <span class="subject">
                            <!-- <span :class="`icon_exc ` + this.$categoryImage(course.log_category)"></span> -->
                            <span class="text elp">{{course.log_track_name}}</span></span>
                          <span class="data">
                              <span class="text"><span class="t1">전체 거리</span>{{this.$convertKilometerFormat(course.log_distance)}} km</span>
                              <span class="text"><span class="t1">전체 시간</span>{{this.$convertTimeStampFormat(course.log_total_time)}}</span>
                              <span class="text"><span class="t1">평균 속도</span>{{this.$convertSpeedFormat(course.log_aver_speed)}} km/h</span>
                          </span>
                          <div class="user_action">
                            <span class="t_block">
                              <span class="i_like"></span><span class="text">{{this.$addComma(course.log_recommend_count)}}</span>
                            </span>
                            <span class="t_block">
                              <span class="i_view"></span><span class="text">{{this.$addComma(course.log_hit)}}</span>
                            </span>
                            <span class="t_block">
                              <span class="i_down"></span><span class="text">{{this.$addComma(course.log_download_count)}}</span>
                            </span>
                          </div>
                        </span>
                      </span>
                      <span class="td2" v-if="course.image_file_name"><span class="poi_photo"><img :src="course.image_file_name" /></span></span>
                      <span class="poi_date">{{this.convertCourseRegDate(course.log_datetime)}}</span>
                      <a @click="viewCourseInfo(course)" class="btn_detail">코스상세</a>
                    </li>
                  </ul>
                </div><!--/list_poi-->
              </div>
              <Pagination @select-list="selectList" :totalPageCount="this.totalPageCount" :currentPage="this.page" v-if="isOpenPagination" />
            </div><!--/course_left-->
            <div class="content">
              <AttachCourseMap :menu="`list`" ref="map"/>
            </div><!--/content-->
          </div>
        </div>
      </div>
    </div>
  </div><!--/popup-->
</template>

<script>
import City from '@/assets/common/data/city.js'
import AttachCourseMap from '../map/AttachCourseMap.vue'
import Pagination from '../common/CoursePagination.vue'

export default {
  name: 'AttachCourseList',
  data () {
    return {
      courseList: [],
      page: 1,
      showKeywordClearButton: 'none',
      searchKeyword: '',
      totalPageCount: 0,
      viewCount: 50,
      listTab: 'MY',
      myCourseCount: 0,
      myDownloadCount: 0,
      category: '',
      year: new Date().getFullYear(),
      region: '',
      sidoList: City.sidoList,
      completeSearch: false,
      isOpenPagination: false
    }
  },
  components: {
    AttachCourseMap: AttachCourseMap,
    Pagination: Pagination
  },
  mounted () {
    this.getMyCourseCount()
    this.selectList(1)
  },
  computed: {
    getYearList () {
      const yearList = []
      const year = Number(new Date().getFullYear())

      for (let i = year; i > 2010; i--) {
        yearList.push(i)
      }
      return yearList
    }
  },
  methods: {
    getMyCourseCount () {
      const formData = new FormData()
      formData.append('token', this.$store.state.Member.token)
      this.axios.post('/course/getMyCourseCount.json', formData)
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode === '00') {
            this.myCourseCount = data.data.response.content.myCourseCount
            this.myDownloadCount = data.data.response.content.MyDownloadCount
          }
        })
    },
    selectList (page) {
      this.page = page
      this.completeSearch = false
      this.isOpenPagination = false
      this.showKeywordClearButton = this.searchKeyword !== '' ? 'block' : 'none'

      const params = {
        token: this.$store.state.Member.token,
        year: this.year,
        search_val: this.searchKeyword,
        category: this.category,
        region: this.region,
        view_count: this.viewCount,
        page: this.page
      }

      let listApiURl = ''

      if (this.listTab === 'MY') { // 내 기록
        listApiURl = '/course/myCourseList.json'
      } else if (this.listTab === 'DOWNLOAD') { // 다운로드 기록
        listApiURl = '/course/myDownloadList.json'
      }

      this.axios.get(listApiURl, {
        params: params
      })
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode === '00') {
            this.courseList = data.data.response.content.list
            console.log(this.courseList)
            this.$store.commit('Course/setCourseList', this.courseList)
            this.completeSearch = true

            if (this.courseList.length > 0) {
              this.totalPageCount = parseInt(data.data.response.content.last_key)
              this.isOpenPagination = true
            }
          }
        })
    },
    selectedCourse (index) {
      this.$refs.map.selectedCourseMarker(index)
    },
    viewCourseInfo (course) {
      this.$store.state.Popup.tempAttachTrackKey = course.log_track_no
      this.$store.commit('Popup/switchAttachPopup')
    },
    changeListTab (tab) {
      this.listTab = tab
      this.selectList(1)
    },
    clearSearchKeyword () {
      this.searchKeyword = ''
      this.selectList(1)
    },
    convertCourseRegDate (date) {
      return date.length > 10 ? date.substring(0, 10) : date
    }
  }
}
</script>

<style>

</style>
