<template>
  <div class="popup popup_bg">
    <div class="popup_area">
      <div class="popup_box popup_box_s">
        <div class="popup_top popup_top_line" v-if="popupHeaderMessage !== ''">
          <h2 v-html="popupHeaderMessage"></h2>
        </div>
        <div class="popup_body">
          <p v-html="popupBodyMessage"></p>
          <div class="btn_area">
            <button type="button" class="on" @click="confirm">네</button>
            <button type="button" @click="cancel">아니오</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      popupHeaderMessage: this.$store.state.Popup.popupHeaderMessage,
      popupBodyMessage: this.$store.state.Popup.popupBodyMessage,
      callbackMethod: this.$store.state.Popup.callbackMethod,
      callbackCancelMethod: this.$store.state.Popup.callbackCancelMethod
    }
  },
  components: {
  },
  name: 'CommonConfirmPopup',
  methods: {
    confirm () {
      this.$store.commit('Popup/controlConfirmPopup')
      this.callbackMethod()
    },
    cancel () {
      this.$store.commit('Popup/controlConfirmPopup')
      if (this.callbackCancelMethod) {
        this.callbackCancelMethod()
      }
    }
  }
}
</script>

<style>

</style>
