<template>
  <ModifyMyRecordPopup v-if="this.$store.state.Course.isOpenModifyMyRecordPopup"
    :courseInfo="this.courseInfo" @complete="this.getCourseInfo"/>
  <div class="course_left">
    <template v-if="courseInfo.track != null">
      <div class="section">
        <div class="top_title">
          <template v-if="this.$store.state.Common.courseMenuType === 'my'">
            나의 코스 상세
          </template>
          <template v-else>
            코스 상세
          </template>
          <span class="btn_area_l"><a @click.prevent="goList()" href="#" class="btn_back"></a></span>
          <!-- <span class="btn_area_r"><a href="#" class="btn_s btn_org"
            @click="courseCompare(this.compareTrackData)">코스 비교</a></span> -->
        </div>
      </div>
      <div class="section">
        <div class="course_title">
          <span class="subject">
            <span class="icon_exc" :class="`icon_exc ` + this.$categoryImage(courseInfo.track.log_category)"></span>
            <span class="text">{{courseInfo.track.log_track_name}}</span>
            <template v-if="this.$store.state.Common.courseMenuType === 'my'
            && this.$store.state.Member.memberId === courseInfo.member.member_id">
              <!-- <span class="btn_edit" @click="this.$store.commit('Course/controlModifyMyRecordPopup')"></span> -->
            </template>
          </span>
          <template v-if="this.$store.state.Common.courseMenuType === 'my'
            && this.$store.state.Member.memberId === courseInfo.member.member_id">
            <div class="hashtag elp2">
              <a href="#" v-for="(tag, i) in getTagList" :key="i">#{{tag.tag_name}}</a>
            </div>
            <div class="hashtag_more" v-if="getTagList.length > 10"><a href="#" class="btn_more" @click="this.isOpenHashTagPopup = true">더보기</a>
              <div class="hashtag_more_layer" v-show="this.isOpenHashTagPopup">
                <div class="popup_box popup_box_s">
                  <div class="popup_top line_bottom"><h2>#해시태그</h2>
                  <a href="#" class="btn_close" @click="this.isOpenHashTagPopup = false"></a>
                  </div>
                  <div class="popup_body">
                    <div class="hashtag">
                      <a href="#" v-for="(tag, i) in getTagList" :key="i">#{{tag.tag_name}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <span class="date">{{courseInfo.track.log_last_update}}</span>
        </div>
      </div>
      <div class="course_user" v-if="courseInfo.member.member_id">
        <div class="section">
          <div class="user_g">
            <span class="user_profile"><img :src="courseInfo.member.member_photo_small" /></span>
            <span class="user_info">
              <span class="user_id">{{courseInfo.member.member_nickname || courseInfo.member.member_id}}</span>
              <span class="user_level">LV{{courseInfo.member.level}}</span>
              <!-- <span class="user_link">
                <router-link :to="`/mytranggle/friendInfo/` + courseInfo.member.member_id" style="color:#333">작성자 홈</router-link>
                <a :href="`/story/${courseInfo.track.story_post_no}`" v-if="courseInfo.track.story_post_no != null">스토리 본문</a>
              </span> -->
            </span>
          </div>
        </div>
      </div>
      <div class="user_action2">
        <span class="t_block">
          <span :class="[{'i_like':true}, {'on': !this.courseInfo.track.recommendable}]"
            @click.prevent="recommendCourse"></span>
          <span
            :class="[{'i_drop' : courseInfo.track.log_recommend_count > 0}, {'on': this.isOpenRecommendUserList}]"
            @click="changeVisibleRecommendUserList">
            <span class="text">{{this.$addComma(courseInfo.track.log_recommend_count)}}</span>
            <div class="i_like_layer" v-if="courseInfo.track.log_recommend_count > 0">
              {{this.recommendUserList}}
            </div>
          </span>
        </span>
        <span class="t_block">
          <span class="i_view"></span><span class="text">{{this.$addComma(courseInfo.track.log_hit)}}</span>
        </span>
        <!-- <span class="t_block" @click="this.sharePage">
          <span class="i_share_m"></span><span class="text">{{this.$addComma(courseInfo.track.log_share_count)}}</span>
        </span> -->
        <span class="t_block" @click.prevent="downloadTrack">
        <!-- 트랭 차감 기능 붙을때까지 다운로드 막기 -->
        <!-- <span class="t_block"> -->
          <span class="i_down"></span><span class="text">{{this.$addComma(courseInfo.track.log_download_count)}}</span>
        </span>
      </div>
      <div class="tab_menu">
        <ul>
          <router-link :to="recordUrl" custom v-slot="{ href, navigate }" class="block_click">
            <li><a :href="href" @click="navigate" class="block_click">운동기록</a></li>
          </router-link>
          <!-- <router-link :to="infoUrl" custom v-slot="{ href, navigate, isExactActive }">
            <li :class="[isExactActive && 'on']"><a :href="href" @click="navigate">코스 설명</a></li>
          </router-link>
          <router-link :to="badgeUrl" custom v-slot="{ href, navigate, isExactActive }">
            <li :class="[isExactActive && 'on']"><a :href="href" @click="navigate">획득배지({{courseInfo.badge_list.length}})</a></li>
          </router-link>
          <router-link :to="commentUrl" custom v-slot="{ href, navigate, isExactActive }">
            <li :class="[isExactActive && 'on']"><a :href="href" @click="navigate">댓글({{courseInfo.track.comment_total_count}})</a></li>
          </router-link> -->
        </ul>
      </div>
      <router-view :courseInfo="this.courseInfo" :trackKey="this.trackNo"></router-view>
    </template>
  </div><!--/course_left-->
  <div class="content">
    <CourseMap :trackNo="this.trackNo" ref="map" v-if="Object.keys(this.courseInfo).length > 0"/>
  </div><!--/content-->
</template>

<script>
// import axios from 'axios'
import CourseMap from '../map/CourseMap.vue'
import ModifyMyRecordPopup from '../popup/ModifyMyRecordPopup.vue'
import { mapState } from 'vuex'

export default {
  name: 'courseDetail',
  data () {
    const trackNo = this.$route.params.id

    return {
      recordUrl: '/course/' + trackNo,
      infoUrl: '/course/' + trackNo + '/info',
      badgeUrl: '/course/' + trackNo + '/badge',
      commentUrl: '/course/' + trackNo + '/comment',
      trackNo: String(trackNo),
      recommendUserList: '',
      isOpenRecommendUserList: false,
      isOpenHashTagPopup: false,
      logNo: '',
      trackYear: '',
      courseInfo: {},
      compareTrackData: {}
    }
  },
  watch: {
    // $route (to, from) {
    //   if (String(to.path).indexOf('badge') > -1) {
    //     this.$refs.map.showCourseBadgeList()
    //   } else {
    //     this.$refs.map.hideCourseBadgeList()
    //   }
    // }
  },
  props: {
    id: Number
  },
  components: {
    CourseMap: CourseMap,
    ModifyMyRecordPopup: ModifyMyRecordPopup
  },
  async mounted () {
    if (this.trackNo.length < 5) {
      alert('잘못된 접근입니다.')
      this.$router.go(-1)
    }

    this.trackYear = this.trackNo.substring(0, 4)
    this.logNo = this.trackNo.substring(4, this.trackNo.length)

    this.getCourseInfo()
  },
  computed: {
    ...mapState('UserSet', ['memberId', 'memberNick']),
    getTagList () {
      return this.courseInfo.tags
    }
  },
  methods: {
    courseCompare (data) {
      if ((this.$store.state.Common.compareTrack1.year === data.year && this.$store.state.Common.compareTrack1.no === data.no) ||
            (this.$store.state.Common.compareTrack2.year === data.year && this.$store.state.Common.compareTrack2.no === data.no)) {
        this.$showMessagePopup('', '이미 코스 비교에 등록된 코스입니다.')
      } else if (this.$store.state.Common.compareTrack1.name !== '' && this.$store.state.Common.compareTrack2.name !== '') {
        this.$showConfirmPopup('', '이미 2개의 코스가 비교 대상으로<br>등록되어 있습니다.<br>새롭게 코스 비교하시겠습니까?', () => {
          this.$store.state.Common.compareTrack1.name = ''
          this.$store.state.Common.compareTrack1.year = ''
          this.$store.state.Common.compareTrack1.no = ''
          this.$store.state.Common.compareTrack2.name = ''
          this.$store.state.Common.compareTrack2.year = ''
          this.$store.state.Common.compareTrack2.no = ''
          this.$store.commit('Common/setCompareTrack', data)
        })
      } else {
        this.$store.commit('Common/setCompareTrack', data)
      }
    },
    goList () {
      let path = ''
      if (this.$store.state.Common.courseMenuType === '') {
        path = '/course/'
      } else if (this.$store.state.Common.courseMenuType === 'theme') {
        path = '/course/theme/' + this.$store.state.Common.themaCategoryNo
      } else if (this.$store.state.Common.courseMenuType === 'my') {
        path = '/course/my/'
      } else if (this.$store.state.Common.courseMenuType === 'analyze') {
        path = '/course/analyze/'
      }
      this.$refs.map.clearCourseMarker()
      this.$router.push({ path: path, query: this.$route.query })
    },
    async getCourseInfo () {
      if (this.trackYear === null || this.logNo === 0) return
      this.axios.get('/Track/track_detail.json', {
        params: {
          track_key: this.trackNo
        }
      })
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode !== '00') {
            this.$showMessagePopup('', data.data.response.message, '/course')
          } else {
            this.courseInfo = data.data.response.content
            this.$store.state.Course.courseInfo = this.courseInfo
            this.$store.commit('Course/setBadgeList', this.courseInfo.badge_list)
            this.compareTrackData = {
              name: this.courseInfo.track.log_track_name,
              year: this.trackYear,
              no: this.logNo
            }

            // if (this.courseInfo.track.log_recommend_count > 0) {
            this.axios.get('/Track/recommended_user_list.json', {
              params: {
                track_key: this.trackNo
              }
            })
              .then(data2 => {
                const recommendUserList = data2.data.response.content
                for (let i = 0; i < recommendUserList.length; i++) {
                  if (i > 0) {
                    this.recommendUserList += ', '
                  }
                  this.recommendUserList += recommendUserList[i].recommend_member_id
                }
              })
            // }
          }
        })
    },
    copyUrl () {
      const t = document.createElement('textarea')
      document.body.appendChild(t)
      t.value = location.href
      t.select()
      document.execCommand('copy')
      document.body.removeChild(t)
      this.$showMessagePopup('URL 복사', '이 코스의 주소가 복사되었습니다. 메신저 또는 텍스트 입력창에서 Ctrl+V를 눌러보세요.')
    },
    changeVisibleRecommendUserList () {
      this.isOpenRecommendUserList = !this.isOpenRecommendUserList
    },
    downloadTrack () {
      this.$showConfirmPopup('파일 다운로드', 'GPX 파일을 다운로드하겠습니까?', () => {
        location.href = `/v2/track/download?track_key=${this.trackNo}&down_type=mileage`
      })
      // const formData = new FormData()
      // formData.append('token', this.$store.state.Member.token)
      // formData.append('track_key', this.trackNo)
      // formData.append('way', 'trang')

      // this.axios.post('/Track/download.json', formData)
      //   .then(data => {
      //     const responseCode = data.data.response.code

      //     if (responseCode === '00') {
      //       this.$showMessagePopup('', data.data.response.message)
      //       this.courseInfo.track.log_download_count = Number(this.courseInfo.track.log_download_count) + 1
      //     } else {
      //       this.$showMessagePopup('', data.data.response.message)
      //     }
      //   })
    },
    recommendCourse () {
      if (this.courseInfo.track.recommendable) {
        const formData = new FormData()
        formData.append('track_key', this.trackNo)
        this.axios.post('/course/recommend.json', formData)
          .then(data => {
            const responseCode = data.data.response.code
            if (responseCode === '00') {
              this.courseInfo.track.recommendable = false
              this.courseInfo.track.log_recommend_count++
              this.recommendUserList += this.recommendUserList.length === 0 ? this.memberNick || this.memberId : `,${this.memberNick || this.memberId}`
            } else {
              this.$showMessagePopup('코스 추천', data.data.response.message)
            }
          })
      }
    },
    sharePage () {
      this.$store.state.Popup.sendId = this.courseInfo.member.member_id
      this.$store.state.Popup.sendNickName = this.courseInfo.member.member_nickname
      this.$store.state.Popup.sharePageChk = 'course'
      this.$store.state.Popup.sharePostNo = this.logNo
      this.$store.state.Popup.shereYear = this.trackYear
      this.$store.commit('Popup/controlSharePopup')
    }
  }
}
</script>

<style>
</style>
