<template>
  <div class="popup" v-if="courseInfo.track != null">
    <div class="popup_area">
      <div class="popup_box popup_box_xl">
        <div class="popup_top line_bottom"><h2>기록 첨부</h2><a href="#" class="btn_close" @click.prevent="this.$store.commit('Popup/controlAttachCoursePopup')"></a></div>
        <div class="popup_body1">
          <div class="add_record">
            <div class="course_left">
              <div class="section">
                <div class="top_title">나의 코스 상세
                  <span class="btn_area_l"><a href="#" class="btn_back" @click.prevent="this.$store.commit('Popup/switchAttachPopup')"></a></span>
                </div>
              </div>
              <div class="section">
                <div class="course_title">
                  <span class="subject">
                    <span class="icon_exc" :class="`icon_exc ` + this.$categoryImage(courseInfo.track.log_category)"></span>
                    <span class="text">{{courseInfo.track.log_track_name}}</span>
                  </span>
                  <span class="date">{{courseInfo.track.log_last_update}}</span>
                </div>
                <a href="#" class="btn" @click.prevent="attachTrack">기록 첨부하기</a>
              </div>
              <div class="user_action2">
                <span class="t_block">
                  <span class="i_like"></span>
                  <span
                    :class="[{'i_drop' : courseInfo.track.log_recommend_count > 0}, {'on': this.isOpenRecommendUserList}]"
                    @click="changeVisibleRecommendUserList">
                    <span class="text">{{this.$addComma(courseInfo.track.log_recommend_count)}}</span>
                    <div class="i_like_layer" v-if="courseInfo.track.log_recommend_count > 0">
                      {{this.recommendUserList}}
                    </div>
                  </span>
                </span>
                <span class="t_block">
                  <span class="i_view"></span><span class="text">{{this.$addComma(courseInfo.track.log_hit)}}</span>
                </span>
                <span class="t_block" @click.prevent="downloadTrack">
                  <span class="i_down"></span><span class="text">{{this.$addComma(courseInfo.track.log_download_count)}}</span>
                </span>
              </div>
              <div class="tab_menu">
                <ul>
                  <li :class="{on: this.tab === 1}"><a href="#" @click.prevent="this.tab = 1">운동기록</a></li>
                  <li :class="{on: this.tab === 2}"><a href="#" @click.prevent="this.tab = 2">코스 설명</a></li>
                  <li :class="{on: this.tab === 3}"><a href="#" @click.prevent="this.tab = 3">획득배지({{courseInfo.badge_list.length}})</a></li>
                </ul>
              </div>
              <AttachCourseDetailRecord :courseInfo="this.courseInfo" :trackYear="this.trackYear" v-if="this.tab === 1" />
              <AttachCourseDetailInfo :courseInfo="this.courseInfo" :trackYear="this.trackYear" v-if="this.tab === 2" />
              <AttachCourseDetailBadge :courseInfo="this.courseInfo" :trackYear="this.trackYear" v-if="this.tab === 3" />
            </div><!--/course_left-->
            <div class="content">
              <AttachCourseMap :trackNo="this.trackNo" :menu="`detail`" ref="map"/>
            </div><!--/content-->
          </div>
        </div>
      </div>
    </div>
  </div><!--/popup-->
</template>

<script>
import AttachCourseMap from '../map/AttachCourseMap.vue'
import AttachCourseDetailRecord from './AttachCourseDetailRecord.vue'
import AttachCourseDetailInfo from './AttachCourseDetailInfo.vue'
import AttachCourseDetailBadge from './AttachCourseDetailBadge.vue'

export default {
  name: 'AttachCourseDetail',
  data () {
    return {
      recommendUserList: '',
      isOpenRecommendUserList: false,
      isOpenHashTagPopup: false,
      trackNo: this.$store.state.Popup.tempAttachTrackKey,
      logNo: '',
      trackYear: '',
      courseInfo: {},
      tab: 1
    }
  },
  components: {
    AttachCourseMap: AttachCourseMap,
    AttachCourseDetailRecord: AttachCourseDetailRecord,
    AttachCourseDetailInfo: AttachCourseDetailInfo,
    AttachCourseDetailBadge: AttachCourseDetailBadge
  },
  mounted () {
    if (this.trackNo.length < 5) {
      this.$showMessagePopup('기록 첨부', '잘못된 코스 정보 입니다.')
      this.$store.commit('Popup/switchAttachPopup')
    }

    this.trackYear = this.trackNo.substring(0, 4)
    this.logNo = this.trackNo.substring(4, this.trackNo.length)

    this.getCourseInfo()
  },
  computed: {
    getTagList () {
      return this.courseInfo.tags
    }
  },
  methods: {
    goList () {
      if (this.$store.state.Common.courseMenuType === '') {
        this.$router.push('/course/')
      } else if (this.$store.state.Common.courseMenuType === 'theme') {
        this.$router.push('/course/theme/' + this.$store.state.Common.themaCategoryNo)
      } else if (this.$store.state.Common.courseMenuType === 'my') {
        this.$router.push('/course/my/')
      }
    },
    getCourseInfo () {
      if (this.trackYear === null || this.logNo === 0) return
      this.axios.get('/Track/track_detail.json', {
        params: {
          token: this.$store.state.Member.token,
          log_no: this.logNo,
          track_year: this.trackYear
        }
      })
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode !== '00') {
            this.$showMessagePopup('기록 첨부', '잘못된 코스 번호 입니다.')
            this.$store.commit('Popup/switchAttachPopup')
          } else {
            this.courseInfo = data.data.response.content
            this.$store.state.Course.courseInfo = this.courseInfo
            this.compareTrackData = {
              name: this.courseInfo.track.log_track_name,
              year: this.trackYear,
              no: this.logNo
            }

            if (this.courseInfo.track.log_recommend_count > 0) {
              this.axios.get('/Track/recommended_user_list.json', {
                params: {
                  log_no: this.logNo,
                  track_year: this.trackYear
                }
              })
                .then(data2 => {
                  const recommendUserList = data2.data.response.content
                  for (let i = 0; i < recommendUserList.length; i++) {
                    if (i > 0) {
                      this.recommendUserList += ', '
                    }
                    this.recommendUserList += recommendUserList[i].recommend_member_id
                  }
                })
            }
          }
        })
    },
    copyUrl () {
      const t = document.createElement('textarea')
      document.body.appendChild(t)
      t.value = location.href
      t.select()
      document.execCommand('copy')
      document.body.removeChild(t)
      alert('복사되었습니다.')
    },
    changeVisibleRecommendUserList () {
      this.isOpenRecommendUserList = !this.isOpenRecommendUserList
    },
    attachTrack () {
      this.$store.commit('Popup/setAttachTrack', {
        track_key: this.trackNo,
        track_name: this.courseInfo.track.log_track_name,
        track_year: this.trackYear,
        track_no: this.logNo,
        category: this.courseInfo.track.log_category
      })
    }
  }
}
</script>

<style>

</style>
