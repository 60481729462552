import axios from '../../util/axios.js'
import store from '../../store'

export default {
  namespaced: true,
  state: {
    token: '',
    memberId: '',
    createDate: '',
    memberInfo: {},
    updateMemberInfoBool: false,
    updateMemberInfoCode: '',
    updateMemberInfoMsg: '',
    memberConfig: {},
    updateMemberConfigBool: false,
    alarmList: [],
    alarmCnt: 0,
    alarmDelBool: false,
    kakaoJavaScriptKey: 'd3d3b4e0e2bd4def2fafe9d9cc44d952', // 임시적용 운영 전환시 실제 도메인 연계 필요!
    smsAuthBool: '',
    smsAuthMessage: '',
    smsCertifyBool: '',
    smsCertifyCode: '',
    smsCertifyMessage: '',
    emailAuthBool: '',
    emailAuthMessage: '',
    emailCertifyBool: '',
    emailCertifyMessage: '',
    findIdList: [],
    findPwBool: false,
    validateSmsCode: '',
    memberWakeUp: false,
    memberNo: '',
    newPwdBool: false,
    mobileDomestic: 'domestic',
    newPwdPop: false,
    countryList: [],
    pwdDateBool: false
  },
  mutations: {
    setMemberInfo (state, data) {
      state.memberInfo = data
    },
    setTrangInfo (state, data) {
      state.memberInfo.trang.charge = data.CHARGE
      state.memberInfo.trang.save = data.SAVE
      state.memberInfo.trang.point = data.POINT
    },
    setMemberConfig (state, data) {
      state.memberConfig = data
    },
    setUpdateMemberInfo (state, data) {
      // state.updateMemberInfoBool = data
      state.updateMemberInfoCode = data.code
      state.updateMemberInfoMsg = data.message
    },
    setUpdateMemberConfigBool (state, data) {
      state.updateMemberConfigBool = data
    },
    setAlarmList (state, data) {
      state.alarmList = data
      state.alarmCnt = data.length
    },
    setAlarmDelBool (state, data) {
      state.alarmDelBool = data
    },
    setSmsAuthBool (state, data) {
      state.smsAuthBool = data.code === '00' ? 'Y' : 'N'
      state.smsAuthMessage = data.message
    },
    setSmsCertify (state, data) {
      state.smsCertifyCode = data.code
      state.smsCertifyBool = data.code === '00' ? 'Y' : 'N'
      state.smsCertifyMessage = data.message
    },
    setEmailAuthBool (state, data) {
      state.emailAuthBool = data.code === '00' ? 'Y' : 'N'
      state.emailAuthMessage = data.message
    },
    setEmailCertify (state, data) {
      state.emailCertifyBool = data.code === '00' ? 'Y' : 'N'
      state.emailCertifyMessage = data.message
    },
    setFindIdList (state, data) {
      state.findIdList = data.member
    },
    setFindPw (state, data) {
      state.findPwBool = data
    },
    setValidateSmsCode (state, data) {
      state.validateSmsCode = data
    },
    setMemberWakeUp (state, data) {
      state.memberWakeUp = data
    },
    setMemberNo (state, data) {
      state.memberNo = data.member_no
    },
    setNewPassword (state, data) {
      state.newPwdBool = data
    },
    setContryList (state, data) {
      if (state.countryList.length === 0) {
        data.forEach((el) => {
          if (el.country_code !== '82') {
            state.countryList.push({
              name: `${el.country_name_kr}(+${el.country_code})`,
              value: el.country_code
            })
          }
        })
        state.countryList.push({
          name: '목록에 없음',
          value: 'email'
        })
      }
    },
    setPwdDateBool (state, data) {
      state.pwdDateBool = data
    }
  },
  actions: {
    // 회원 정보 조회
    getMemberInfo ({ state, commit }) {
      axios.get('/member/info.json', {})
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMemberInfo', memberResponseData.content)
          }
        })
    },
    // 회원 정보 업데이트
    updateMemberInfo ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/Member/member_data_upt.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setUpdateMemberInfo', memberResponseData)
          } else {
            commit('setUpdateMemberInfo', memberResponseData)
          }
          // console.log(data.data.response.message)
          // this.$showMessagePopup('', data.data.response.message)
        })
    },
    // 회원 설정 정보 조회
    getMemberConfig ({ state, commit }) {
      axios.get('/member/member_config_data.json', {})
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMemberConfig', memberResponseData.content)
          }
        })
    },
    // 회원 설정 정보 업데이트
    updateMemberConfig ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/Member/member_config_data_upt.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setUpdateMemberConfigBool', true)
          } else {
            commit('setUpdateMemberConfigBool', false)
          }
        })
    },
    // 회원 알림 리스트 조회
    readAlarm ({ state, commit }) {
      axios.get('/PushV2/getPushLog.json', {})
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setAlarmList', memberResponseData.content)
          }
        })
    },
    // 회원 알림 리스트 업데이트 (삭제)
    deleteAlarm ({ state, commit, dispatch }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/Member/member_config_data_upt.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setAlarmDelBool', true)
          } else {
            commit('setAlarmDelBool', false)
          }
        })
    },
    // sms 인증번호 발송
    loadSmsAuth ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/vitality/sms_auth.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          commit('setSmsAuthBool', memberResponseData)
        })
    },
    // sms 인증번호 확인
    loadSmsCertify ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/vitality/sms_certify.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          commit('setSmsCertify', memberResponseData)
        })
    },
    // 이메일 인증번호 발송
    loadEmailAuth ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/Member/email_auth_send.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          commit('setEmailAuthBool', memberResponseData)
        })
    },
    // 이메일 인증번호 확인
    loadEmailCertify ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/Member/email_auth_check.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          commit('setEmailCertify', memberResponseData)
        })
    },
    // 아이디 찾기
    loadFindId  ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/Member/find_id.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setFindIdList', memberResponseData.content)
          } else {
            const errorPopObj = {
              popupHeaderMessage: '아이디 찾기',
              popupBodyMessage: memberResponseData.message
            }
            store.commit('Popup/setMessagePopup', errorPopObj)
            store.commit('Popup/controlMessagePopup')
          }
        })
    },
    // 비밀번호 재설정
    loadFindPw ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/Member/find_password.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setFindPw', true)
          } else {
            const errorPopObj = {
              popupHeaderMessage: '비밀번호 재설정',
              popupBodyMessage: memberResponseData.message
            }
            store.commit('Popup/setMessagePopup', errorPopObj)
            store.commit('Popup/controlMessagePopup')
          }
        })
    },
    // 휴면계정 해제
    loadMemberWakeUp ({ state, commit }) {
      axios.post('/Member/wakeup.json')
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMemberWakeUp', true)
          } else {
            const errorPopObj = {
              popupHeaderMessage: '휴면상태 해제',
              popupBodyMessage: memberResponseData.message
            }
            store.commit('Popup/setMessagePopup', errorPopObj)
            store.commit('Popup/controlMessagePopup')
          }
        })
    },
    // 비밀번호 설정 멤버no 조회
    loadMemberNo ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/Member/get_no.json', fd, {
        headers: {
          'content-type': 'application/json'
        }
      })
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMemberNo', memberResponseData.content)
          } else {
            const errorPopObj = {
              popupHeaderMessage: '비밀번호 재설정',
              popupBodyMessage: memberResponseData.message
            }
            store.commit('Popup/setMessagePopup', errorPopObj)
            store.commit('Popup/controlMessagePopup')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 비밀번호찾기 > 비밀번호재설정 api
    updateNewPassword ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/Member/new_pwd_upt.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setNewPassword', true)
          } else {
            const errorPopObj = {
              popupHeaderMessage: '비밀번호 변경',
              popupBodyMessage: memberResponseData.message
            }
            store.commit('Popup/setMessagePopup', errorPopObj)
            store.commit('Popup/controlMessagePopup')
          }
        })
    },
    // 국가 리스트
    getCountryList ({ state, commit }, data) {
      const fd = new FormData()
      if (data) {
        Object.keys(data).forEach(el => {
          fd.append(el, data[el])
        })
      }
      axios.post('/Vitality/sms_country_list.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setContryList', memberResponseData.content)
          } else {
            const errorPopObj = {
              popupBodyMessage: memberResponseData.message
            }
            store.commit('Popup/setMessagePopup', errorPopObj)
            store.commit('Popup/controlMessagePopup')
          }
        })
    },
    // 비밀번호 변경 기간 연장
    setPasswordDate ({ state, commit }, data) {
      const fd = new FormData()
      if (data) {
        Object.keys(data).forEach(el => {
          fd.append(el, data[el])
        })
      }
      axios.post('/Member/setPasswordDate.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setPwdDateBool', true)
          } else {
            const errorPopObj = {
              popupBodyMessage: memberResponseData.message
            }
            store.commit('Popup/setMessagePopup', errorPopObj)
            store.commit('Popup/controlMessagePopup')
          }
        })
    }
  },
  modules: {
  },
  getters: {
    // getTrang (state) {
    //   return Number(state.memberInfo.trang.point) + Number(state.memberInfo.trang.save) + Number(state.memberInfo.trang.charge)
    // }
  }
}
