export default {
  sidoList: [
    { id: 11, name: '서울특별시' },
    { id: 26, name: '부산광역시' },
    { id: 27, name: '대구광역시' },
    { id: 28, name: '인천광역시' },
    { id: 29, name: '광주광역시' },
    { id: 30, name: '대전광역시' },
    { id: 31, name: '울산광역시' },
    { id: 36, name: '세종특별자치시' },
    { id: 41, name: '경기도' },
    { id: 42, name: '강원도' },
    { id: 43, name: '충청북도' },
    { id: 44, name: '충청남도' },
    { id: 45, name: '전라북도' },
    { id: 46, name: '전라남도' },
    { id: 47, name: '경상북도' },
    { id: 48, name: '경상남도' },
    { id: 50, name: '제주특별자치도' }
  ],
  sidoShortList: [
    { id: 11, name: '서울' },
    { id: 26, name: '부산' },
    { id: 27, name: '대구' },
    { id: 28, name: '인천' },
    { id: 29, name: '광주' },
    { id: 30, name: '대전' },
    { id: 31, name: '울산' },
    { id: 36, name: '세종' },
    { id: 41, name: '경기' },
    { id: 42, name: '강원' },
    { id: 43, name: '충북' },
    { id: 44, name: '충남' },
    { id: 45, name: '전북' },
    { id: 46, name: '전남' },
    { id: 47, name: '경북' },
    { id: 48, name: '경남' },
    { id: 50, name: '제주' }
  ],
  sigunguList: [
    { id: 11110, name: '종로구' },
    { id: 11140, name: '중구' },
    { id: 11170, name: '용산구' },
    { id: 11200, name: '성동구' },
    { id: 11215, name: '광진구' },
    { id: 11230, name: '동대문구' },
    { id: 11260, name: '중랑구' },
    { id: 11290, name: '성북구' },
    { id: 11305, name: '강북구' },
    { id: 11320, name: '도봉구' },
    { id: 11350, name: '노원구' },
    { id: 11380, name: '은평구' },
    { id: 11410, name: '서대문구' },
    { id: 11440, name: '마포구' },
    { id: 11470, name: '양천구' },
    { id: 11500, name: '강서구' },
    { id: 11530, name: '구로구' },
    { id: 11545, name: '금천구' },
    { id: 11560, name: '영등포구' },
    { id: 11590, name: '동작구' },
    { id: 11620, name: '관악구' },
    { id: 11650, name: '서초구' },
    { id: 11680, name: '강남구' },
    { id: 11710, name: '송파구' },
    { id: 11740, name: '강동구' },
    { id: 26110, name: '중구' },
    { id: 26140, name: '서구' },
    { id: 26170, name: '동구' },
    { id: 26200, name: '영도구' },
    { id: 26230, name: '부산진구' },
    { id: 26260, name: '동래구' },
    { id: 26290, name: '남구' },
    { id: 26320, name: '북구' },
    { id: 26350, name: '해운대구' },
    { id: 26380, name: '사하구' },
    { id: 26410, name: '금정구' },
    { id: 26440, name: '강서구' },
    { id: 26470, name: '연제구' },
    { id: 26500, name: '수영구' },
    { id: 26530, name: '사상구' },
    { id: 26710, name: '기장군' },
    { id: 27110, name: '중구' },
    { id: 27140, name: '동구' },
    { id: 27170, name: '서구' },
    { id: 27200, name: '남구' },
    { id: 27230, name: '북구' },
    { id: 27260, name: '수성구' },
    { id: 27290, name: '달서구' },
    { id: 27710, name: '달성군' },
    { id: 28110, name: '중구' },
    { id: 28140, name: '동구' },
    { id: 28177, name: '미추홀구' },
    { id: 28185, name: '연수구' },
    { id: 28200, name: '남동구' },
    { id: 28237, name: '부평구' },
    { id: 28245, name: '계양구' },
    { id: 28260, name: '서구' },
    { id: 28710, name: '강화군' },
    { id: 28720, name: '옹진군' },
    { id: 29110, name: '동구' },
    { id: 29140, name: '서구' },
    { id: 29155, name: '남구' },
    { id: 29170, name: '북구' },
    { id: 29200, name: '광산구' },
    { id: 30110, name: '동구' },
    { id: 30140, name: '중구' },
    { id: 30170, name: '서구' },
    { id: 30200, name: '유성구' },
    { id: 30230, name: '대덕구' },
    { id: 31110, name: '중구' },
    { id: 31140, name: '남구' },
    { id: 31170, name: '동구' },
    { id: 31200, name: '북구' },
    { id: 31710, name: '울주군' },
    { id: 36110, name: '세종특별자치시' },
    { id: 41111, name: '수원시 장안구' },
    { id: 41113, name: '수원시 권선구' },
    { id: 41115, name: '수원시 팔달구' },
    { id: 41117, name: '수원시 영통구' },
    { id: 41131, name: '성남시 수정구 ' },
    { id: 41133, name: '성남시 중원구' },
    { id: 41135, name: '성남시 분당구' },
    { id: 41150, name: '의정부시' },
    { id: 41171, name: '안양시 만안구' },
    { id: 41173, name: '안양시 동안구' },
    { id: 41190, name: '부천시' },
    { id: 41210, name: '광명시' },
    { id: 41220, name: '평택시' },
    { id: 41250, name: '동두천시' },
    { id: 41271, name: '안산시 상록구' },
    { id: 41273, name: '안산시 단원구' },
    { id: 41281, name: '고양시 덕양구' },
    { id: 41285, name: '고양시 일산동구' },
    { id: 41287, name: '고양시 일산서구' },
    { id: 41290, name: '과천시' },
    { id: 41310, name: '구리시' },
    { id: 41360, name: '남양주시' },
    { id: 41370, name: '오산시' },
    { id: 41390, name: '시흥시' },
    { id: 41410, name: '군포시' },
    { id: 41430, name: '의왕시' },
    { id: 41450, name: '하남시' },
    { id: 41461, name: '용인시 처인구' },
    { id: 41463, name: '용인시 기흥구' },
    { id: 41465, name: '용인시 수지구' },
    { id: 41480, name: '파주시' },
    { id: 41500, name: '이천시' },
    { id: 41550, name: '안성시' },
    { id: 41570, name: '김포시' },
    { id: 41590, name: '화성시' },
    { id: 41610, name: '광주시' },
    { id: 41630, name: '양주시' },
    { id: 41650, name: '포천시' },
    { id: 41670, name: '여주시' },
    { id: 41800, name: '연천군' },
    { id: 41820, name: '가평군' },
    { id: 41830, name: '양평군' },
    { id: 42110, name: '춘천시' },
    { id: 42130, name: '원주시' },
    { id: 42150, name: '강릉시' },
    { id: 42170, name: '동해시' },
    { id: 42190, name: '태백시' },
    { id: 42210, name: '속초시' },
    { id: 42230, name: '삼척시' },
    { id: 42720, name: '홍천군' },
    { id: 42730, name: '횡성군' },
    { id: 42750, name: '영월군' },
    { id: 42760, name: '평창군' },
    { id: 42770, name: '정선군' },
    { id: 42780, name: '철원군' },
    { id: 42790, name: '화천군' },
    { id: 42800, name: '양구군' },
    { id: 42810, name: '인제군' },
    { id: 42820, name: '고성군' },
    { id: 42830, name: '양양군' },
    { id: 43111, name: '청주시 상당구' },
    { id: 43112, name: '청주시 서원구' },
    { id: 43113, name: '청주시 흥덕구' },
    { id: 43114, name: '청주시 청원구' },
    { id: 43130, name: '충주시' },
    { id: 43150, name: '제천시' },
    { id: 43720, name: '보은군' },
    { id: 43730, name: '옥천군' },
    { id: 43740, name: '영동군' },
    { id: 43745, name: '증평군' },
    { id: 43750, name: '진천군' },
    { id: 43760, name: '괴산군' },
    { id: 43770, name: '음성군' },
    { id: 43800, name: '단양군' },
    { id: 44131, name: '천안시 동남구' },
    { id: 44133, name: '천안시 서북구' },
    { id: 44150, name: '공주시' },
    { id: 44180, name: '보령시' },
    { id: 44200, name: '아산시' },
    { id: 44210, name: '서산시' },
    { id: 44230, name: '논산시' },
    { id: 44250, name: '계룡시' },
    { id: 44270, name: '당진시' },
    { id: 44710, name: '금산군' },
    { id: 44760, name: '부여군' },
    { id: 44770, name: '서천군' },
    { id: 44790, name: '청양군' },
    { id: 44800, name: '홍성군' },
    { id: 44810, name: '예산군' },
    { id: 44825, name: '태안군' },
    { id: 45111, name: '전주시 완산구' },
    { id: 45113, name: '전주시 덕진구' },
    { id: 45130, name: '군산시' },
    { id: 45140, name: '익산시' },
    { id: 45180, name: '정읍시' },
    { id: 45190, name: '남원시' },
    { id: 45210, name: '김제시' },
    { id: 45710, name: '완주군' },
    { id: 45720, name: '진안군' },
    { id: 45730, name: '무주군' },
    { id: 45740, name: '장수군' },
    { id: 45750, name: '임실군' },
    { id: 45770, name: '순창군' },
    { id: 45790, name: '고창군' },
    { id: 45800, name: '부안군' },
    { id: 46110, name: '목포시' },
    { id: 46130, name: '여수시' },
    { id: 46150, name: '순천시' },
    { id: 46170, name: '나주시' },
    { id: 46230, name: '광양시' },
    { id: 46710, name: '담양군' },
    { id: 46720, name: '곡성군' },
    { id: 46730, name: '구례군' },
    { id: 46770, name: '고흥군' },
    { id: 46780, name: '보성군' },
    { id: 46790, name: '화순군' },
    { id: 46800, name: '장흥군' },
    { id: 46810, name: '강진군' },
    { id: 46820, name: '해남군' },
    { id: 46830, name: '영암군' },
    { id: 46840, name: '무안군' },
    { id: 46860, name: '함평군' },
    { id: 46870, name: '영광군' },
    { id: 46880, name: '장성군' },
    { id: 46890, name: '완도군' },
    { id: 46900, name: '진도군' },
    { id: 46910, name: '신안군' },
    { id: 47111, name: '포항시 남구' },
    { id: 47113, name: '포항시 북구' },
    { id: 47130, name: '경주시' },
    { id: 47150, name: '김천시' },
    { id: 47170, name: '안동시' },
    { id: 47190, name: '구미시' },
    { id: 47210, name: '영주시' },
    { id: 47230, name: '영천시' },
    { id: 47250, name: '상주시' },
    { id: 47280, name: '문경시' },
    { id: 47290, name: '경산시' },
    { id: 47720, name: '군위군' },
    { id: 47730, name: '의성군' },
    { id: 47750, name: '청송군' },
    { id: 47760, name: '영양군' },
    { id: 47770, name: '영덕군' },
    { id: 47820, name: '청도군' },
    { id: 47830, name: '고령군' },
    { id: 47840, name: '성주군' },
    { id: 47850, name: '칠곡군' },
    { id: 47900, name: '예천군' },
    { id: 47920, name: '봉화군' },
    { id: 47930, name: '울진군' },
    { id: 47940, name: '울릉군' },
    { id: 48121, name: '창원시 의창구' },
    { id: 48123, name: '창원시 성산구' },
    { id: 48125, name: '창원시 마산합포구' },
    { id: 48127, name: '창원시 마산회원구' },
    { id: 48129, name: '창원시 진해구' },
    { id: 48170, name: '진주시' },
    { id: 48220, name: '통영시' },
    { id: 48240, name: '사천시' },
    { id: 48250, name: '김해시' },
    { id: 48270, name: '밀양시' },
    { id: 48310, name: '거제시' },
    { id: 48330, name: '양산시' },
    { id: 48720, name: '의령군' },
    { id: 48730, name: '함안군' },
    { id: 48740, name: '창녕군' },
    { id: 48820, name: '고성군' },
    { id: 48840, name: '남해군' },
    { id: 48850, name: '하동군' },
    { id: 48860, name: '산청군' },
    { id: 48870, name: '함양군' },
    { id: 48880, name: '거창군' },
    { id: 48890, name: '합천군' },
    { id: 50110, name: '제주시' },
    { id: 50130, name: '서귀포시' }
  ]
}
