<template>
   <!-- 차단 회원 관리 -->
   <div class="right_body mt">
      <div class="auto_h" @scroll="friendListScroll">
        <div class="result_member" >
          <span v-if="friendListArray.length === 0" class="bin_area">
            <span class="icon_box"></span>
            차단된 회원이 없습니다.
          </span>
          <ul v-if="friendListArray.length !== 0">
            <li v-for="(friend, i) in friendListArray" :key="i">
              <!-- <span class="i_lock" v-if="friend.config_is_record_open === 'FALSE'"></span> -->
              <span v-if="friend.config_is_record_open !== ALL" class="i_lock"></span>
              <span v-if="friend.config_is_record_open === 'ALL'" class="i_lock none"></span>
              <span class="profile"><img :src="$photoEmpty(friend.Photo)" @click="mvPage(friend.UserID,friend.config_is_record_open)" /></span>
              <span class="profile_info" @click="mvPage(friend.UserID,friend.config_is_record_open)">
                <span class="info_nick">{{ friend.friend_nickname }}</span>
                <span class="info_id">{{ friend.friend_id }}</span>
                <span class="info_rank">LV.{{ friend.level }}</span>
              </span>
              <a v-if="friend.is_friend === 'Y'" class="i_member_cut" @click="friendCancel(friend.friend_id)" title="친구삭제"></a>
              <a v-if="friend.is_friend === 'N'" class="i_member_add" :class="{'off': friend.friend_status === 'block'}" @click="friendInvite(friend.friend_id, friend.friend_status)" title="친구추가"></a>
              <a href="#" class="i_member_msg" :class="{'off': friend.friend_status === 'block'}" @click="messegePop(friend.NickName,friend.friend_id,friend.friend_status)" title="메세지 보내기"></a>
              <a href="#" class="i_member_block" :class="{'on': friend.friend_status !== 'block'}" :title="friendStatus()" @click="blockUser(friend.friend_id,'Y')"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      // 차단 친구 리스트
      friendListArray: [],
      Row: 0
    }
  },
  mounted () {
    this.searchNext()
  },
  methods: {
    friendListScroll (e) { // 친구 리스트 스크롤
      const { scrollHeight, scrollTop, clientHeight } = e.target
      const isAtTheBottom = scrollHeight === scrollTop + clientHeight
      // 일정 한도 밑으로 내려오면 함수 실행
      if (isAtTheBottom) this.searchNext()
    },
    searchNext () { // 차단 친구
      console.log('on')
      this.Row = this.Row + 10
      this.axios.get('/friend/lists.json', {
        params: {
          token: this.$store.state.Member.token,
          rows: this.Row,
          page: '1',
          type: 'BLOCK'
        }
      })
        .then(data => {
          if (data.data.response.code === '00') {
            this.friendListArray = data.data.response.content.list
          } else {
            this.$showMessagePopup('', data.data.response.message)
          }
          // console.log(this.invitedCount)
          // console.log(this.invitedArray)
        })
    },
    friendCancel (targetId) {
      this.axios.get('/friend/invite_cancel.json', {
        params: {
          // token: '69202CC15E60313F5ED6BC8870C139DE6089135888506F2BFFECC13D24001D9213EDC2AA63FA7401',
          token: this.$store.state.Member.token,
          request_id: targetId
        }
      })
        .then(data => {
          this.$showMessagePopup('', data.data.response.message)
        })
    },
    friendInvite (targetId, status) {
      if (status !== 'block') {
        this.axios.get('/friend/invite.json', {
          params: {
          // token: '69202CC15E60313F5ED6BC8870C139DE6089135888506F2BFFECC13D24001D9213EDC2AA63FA7401',
            token: this.$store.state.Member.token,
            request_id: targetId
          }
        })
          .then(data => {
            this.$showMessagePopup('', data.data.response.message)
          })
      }
    },
    messegePop (nickName, targetId, status) {
      if (status !== 'block') {
        if (nickName === null || nickName === '') {
          this.$store.state.MyTranggle.msgBoxViewNickname = targetId
        } else {
          this.$store.state.MyTranggle.msgBoxViewNickname = nickName
        }

        this.$store.state.MyTranggle.friendIdOne = targetId
        this.msgPopView()
      }
    },
    friendStatus (opt) {
      return '클릭시 차단이 해제됩니다'
    },
    blockUser (targetId, mode) { // 차단하기, 차단 해제하기
      // this.commentReportBox = false
      console.log(mode)
      var herder = ''
      var message = ''
      var status = ''
      if (mode !== 'Y') {
        herder = '차단하기'
        message = '닉네임님이 트랭글에서 작성한 글과<br/>댓글이 보이지 않고,<br/> 알림도 발송되지 않습니다.'
        status = 'BLOCK'
      } else {
        herder = '차단해제'
        message = '차단을 해제하시겠습니까?'
        status = 'BLOCK_CLEAR'
      }
      console.log(this.$store.state.Member.token)
      console.log(targetId)
      console.log(status)
      this.$showConfirmPopup(herder, message, () => {
        this.axios.get('/friend/update.json', {
          params: {
            token: this.$store.state.Member.token,
            friend_id: targetId,
            status: status
          }
        })
          .then(data => {
            if (data.data.response.code === '00') {
              this.$showMessagePopup('', data.data.response.message)
              this.searchNext()
            } else {
              this.$showMessagePopup('', data.data.response.message)
            }
          })
      })
    }
  }
}
</script>
