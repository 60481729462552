<template>
  <JoinTerms v-if="stepAgree" @termBool="termBool" />
  <div v-else class="popup login_bg sns">
    <div class="popup_area">
      <div class="popup_box popup_box_lm sports_pop">
        <div class="popup_top"><h2 class="head1">본인인증</h2></div>
        <div class="popup_body login_body">
          <p class="t_bold">Tranggle에 오신 것을 환영합니다.</p><br>
          <p>트랭글에서는 성별, 연령, 거주지별 랭킹 정보 및 운동분석,<br>
          통계 데이터 분석을 위해 아래 정보들을 수집합니다.</p>
          <br><br>
          <div class="login_area">
            <ul>
              <li>
                <span class="set_text">이름</span>
                <span class="set_input">
                  <input type="text" v-model="name"
                  maxlength="15"
                  placeholder="이름(실명)을 입력하세요" />
                </span>
              </li>
              <li>
                <span class="set_text">생년월일</span>
                <span class="set_input1 input_l">
                  <input type="text" v-model="birthday" maxlength="10"
                  @keydown="birthDayAutoDash($event, birthday)"
                  @blur="checkBirthday"
                  @input="this.value = this.value.replace(/[^0-9]/g, '')"
                  placeholder="YYYY-MM-DD 숫자만 입력해주세요." />
                  <span :class="[{'check_ok': this.validateBirthday === 'Y'}, {'check_no': this.validateBirthday === 'N'}]" v-if="this.validateBirthday !== ''"></span>
                  <span class="stext text_red" v-if="validateBirthday !== ''">{{errorBirthdayMessage}}</span>
                </span>
              </li>
              <li>
                <span class="set_text">성별</span>
                <span class="set_input">
                  <span class="tab1 mb">
                    <span v-bind:class="{'on' : gender == 'MALE'}" @click="gender = 'MALE'">남성</span>
                    <span v-bind:class="{'on' : gender == 'FEMALE'}" @click="gender = 'FEMALE'">여성</span>
                  </span>
                </span>
              </li>
              <li class="stext">본인 명의 휴대폰 번호로 본인확인을 진행해 주세요.</li>
              <SelfAuthentication useType="join" @completeAuth="completeAuth"/>
            </ul>
          </div>
        </div>
        <div class="btn_area btn_bottom2">
          <button type="button" class="btn_f" @click="signUp">인증 완료</button>
        </div>
      </div>
    </div>
    <DuplicateNumber />
  </div><!--/popup-->
</template>

<script>
import SelfAuthentication from './SelfAuthentication.vue'
import JoinTerms from '@/components/member/popup/JoinTerms.vue'
import DuplicateNumber from '@/components/member/popup/DuplicateNumber.vue'
// import dayjs from 'dayjs'
export default {
  name: 'MemberAuthorization',
  components: {
    JoinTerms,
    SelfAuthentication,
    DuplicateNumber
  },
  data () {
    return {
      token: this.$route.query.token,
      gender: '',
      ctyCd: '',
      isDuplicate: '',
      isDomesctic: true,
      name: '',
      birthday: '',
      phone: '',
      email: '',
      smsCode: '',
      validateSmsCode: '',
      validateBirthday: '',
      isSendSms: false,
      stepAgree: true,
      selected: []
    }
  },
  methods: {
    signUp () {
      if (this.name === '') {
        this.$showMessagePopup('회원 가입', '이름을 입력해주세요.')
      } else if (this.validateBirthday !== 'Y') {
        this.$showMessagePopup('회원 가입', '올바른 생년월일을 입력하세요.')
      } else if (this.gender === '') {
        this.$showMessagePopup('회원 가입', '성별을 선택하세요.')
      } else if (this.validateSmsCode !== 'Y') {
        this.$showMessagePopup('회원 가입', '휴대폰 인증을 해주세요.')
      } else {
        const obj = {
          token: this.token,
          name: this.name,
          birthday: this.birthday,
          gender: this.gender,
          mobile: this.phone,
          advertise_agreement: this.selected.includes('termAd') ? 'TRUE' : 'FALSE', // 광고수신 체크
          // custom_ad: this.selected.includes('termAd') ? 'Y' : 'N', // 맞춤형광고 동의
          external: this.selected.includes('termProvision') ? 'Y' : 'N' // 제 3자 제공 동의
        }
        const formData = new FormData()
        Object.keys(obj).forEach(el => {
          formData.append(el, obj[el])
        })
        this.axios.post('/Member/sns_regist.json', formData)
          .then(data => {
            const responseCode = data.data.response.code
            if (responseCode === '00') {
              // 회원가입이 완료되었습니다.
              this.$showMessagePopup('본인인증', data.data.response.message, '/')
              // this.$router.push('/login')
            } else {
              this.$showMessagePopup('본인인증', data.data.response.message)
            }
          })
      }
    },
    checkBirthday () {
      const reg = /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
      const birthStr = this.birthday.replaceAll('-', '')
      if (!this.birthday || birthStr.length !== 8) {
        this.validateBirthday = 'N'
        this.errorBirthdayMessage = '생년월일을 입력하세요.'
        return false
      }
      if (!reg.test(this.birthday)) {
        this.validateBirthday = 'N'
        this.errorBirthdayMessage = '생년월일을 바르게 입력하세요.'
        return false
      }
      // 만 14세 체크
      const userBirth = this.birthday.replaceAll('-', '')
      const current = new Date()
      const y = current.getFullYear()
      const m = current.getMonth() + 1 < 10 ? `0${current.getMonth() + 1}` : current.getMonth() + 1
      const d = current.getDate() < 10 ? `0${current.getDate()}` : current.getDate()
      if (parseInt(`${y}${m}${d}`) - parseInt(userBirth) < 140000) {
        this.validateBirthday = 'N'
        this.errorBirthdayMessage = '만 14세 미만은 가입할 수 없습니다.'
        return false
      }
      this.validateBirthday = 'Y'
      this.errorBirthdayMessage = ''
    },
    checkMaxLength (e) {
      if (e.target.value.length > e.target.maxLength) {
        e.target.value = e.target.value.slice(0, e.target.maxLength)
      }
    },
    completeAuth (phone, email) {
      this.phone = phone
      this.email = email
      this.validateSmsCode = 'Y'
    },
    birthDayAutoDash ($event, str) {
      if ($event.keyCode !== 8) {
        if (str.length === 4 || str.length === 7) {
          this.birthday += '-'
        }
      }
      if (str.length >= 10) {
        this.checkBirthday()
      }
    },
    termBool (val) {
      if (val) {
        this.stepAgree = false
        this.selected = val
      }
    }
  }
}
</script>
