<template>
  <div class="banner_wrap">
    <swiper
      :direction="'vertical'"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
      :modules="modules"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      :loop="true"
      :allow-touch-move="false"
    >
      <swiper-slide
        v-for="(list, i) in slideList"
        :key="i"
        :style="{'background': list.img.includes('color')? getBackgroundImg(list.img) : `url(${getBackgroundImg(list.img)})`}"
        :class="{'color-slide':list.img.includes('color')}"
      >
      </swiper-slide>
    </swiper>
    <div class="text_slide" :class="{'color-slide':slideList[activeIdx].img.includes('color'), 'active': isChange}">
      <div v-html="slideList[activeIdx].text"></div>
    </div>
    <div class="scroll_notice">
      <p>스크롤 해보세요</p>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import 'swiper/swiper-bundle.css'
// import required modules
import { Autoplay, EffectFade } from 'swiper'

export default {
  name: 'Banner',
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        direction: 'vertical'
      },
      slideList: [
        {
          text: '게임 같이<br /><span><strong>등산</strong>을 <br class="m-br" />플레이하다</span>',
          img: 'banner_dummy_hiking.png'
        },
        {
          text: '게임 같이<br /><span><strong>골프</strong>를 <br class="m-br" />플레이하다</span>',
          img: 'banner_dymmy_golf.png'
        },
        // { // TODO: 승마 주석처리 luke 24.02.15
        //   text: '게임 같이<br /><span><strong>승마</strong>를 <br class="m-br" />플레이하다</span>',
        //   img: 'banner_dummy_riding.png'
        // },
        {
          text: '게임 같이<br /><span><strong>수영</strong>을 <br class="m-br" />플레이하다</span>',
          img: 'banner_dummy_swim.png'
        },
        {
          text: '게임 같이<br /><span><strong>패러글라이딩</strong>을 <br class="m-br" />플레이하다</span>',
          img: 'banner_dummy_paragliding.png'
        },
        {
          text: '<em>25가지의 <br class="m-br" />스포츠를 경험하는</em><br /><em>게임 같은 운동앱 <br class="m-br" />트랭글<em>',
          img: 'color|#FF7920'
        }
      ],
      activeIdx: 0,
      isChange: false
    }
  },
  setup () {
    return {
      modules: [Autoplay, EffectFade]
    }
  },
  mounted () {
    setTimeout(() => {
      this.isChange = true
    }, 10)
  },
  methods: {
    getBackgroundImg (url) {
      if (url.includes('color')) {
        return url.split('|')[1]
      } else {
        return require(`@/assets/images/banner/${url}`)
      }
    },
    onSwiper (swiper) {
    },
    onSlideChange (slide) {
      this.activeIdx = slide.realIndex
      this.isChange = false
      setTimeout(() => {
        this.isChange = true
      }, 10)
    }
  }
}
</script>
