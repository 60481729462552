<template>
  <div class="fnb_box">
    <div class="tab_menu">
      <ul>
        <li :class="{'on': this.$store.state.Course.currentLapsTab === 'detail'}"><a href="#" @click.prevent="changeTab('detail')">속도 · 시간 · 고도 상세정보</a></li>
        <li :class="{'on': this.$store.state.Course.currentLapsTab === 'laps'}"><a href="#" @click.prevent="changeTab('laps')">LAPS</a></li>
      </ul>
    </div>
    <div class="section">
      <template v-if="this.$store.state.Course.currentLapsTab === 'detail'">
        <div class="altitude_area" v-if="Object.keys(this.courseLaps).length > 0">
          <div class="play_bar_area">
              <span class="play_bar">
                <Slider
                  v-model="this.$store.state.Course.currentTrackPoint"
                  :tooltips="false"
                  :min="0"
                  :max="this.trackPoints[0].length - 1"
                />
              </span>
              <button :class="{'stop': this.isStarted, 'start': !this.isStarted}" @click="controlTimer"></button>
          </div>
          <div class="svg_area">
            <span class="svg_box">
              <span class="svg_name">시간 (초)</span>
              <span class="svg_graph">
                <VueGauge :refid="'time_guage'"
                  :options="{
                    hasNeedle: false,
                    arcOverEffect: false,
                    chartWidth: 200,
                    arcColors: ['rgb(255, 0, 0)', 'rgb(211, 211, 211)'],
                    arcDelimiters: [this.getTimeDelimiters()],
                    centralLabel: this.getTimeGuageValue(),
                    rangeLabel: [this.$convertTimeStampFormat(this.trackDetailData.time[0]), this.$convertTimeStampFormat(this.courseInfo.track.log_total_time)]
                  }"
                  :key="this.$store.state.Course.currentTrackPoint"
                />
              </span>
            </span>
            <span class="svg_box">
              <span class="svg_name">속도 (km/h)</span>
              <span class="svg_graph">
                <VueGauge :refid="'speed_guage'"
                  :options="{
                    hasNeedle: false,
                    arcOverEffect: false,
                    chartWidth: 200,
                    arcColors: ['rgb(255, 0, 0)', 'rgb(211, 211, 211)'],
                    arcDelimiters: [this.getSpeedDelimiters()],
                    centralLabel: this.getSpeedGuageValue(),
                    rangeLabel: [this.$convertSpeedFormat(this.trackDetailData.speed[0]), this.$convertSpeedFormat(this.courseInfo.track.log_max_speed)]
                  }"
                  :key="this.$store.state.Course.currentTrackPoint"
                />
              </span>
            </span>
            <span class="svg_box">
              <span class="svg_name">거리 (km)</span>
              <span class="svg_graph">
                <VueGauge :refid="'distance_guage'"
                  :options="{
                    hasNeedle: false,
                    arcOverEffect: false,
                    chartWidth: 200,
                    arcColors: ['rgb(255, 0, 0)', 'rgb(211, 211, 211)'],
                    arcDelimiters: [this.getDistanceDelimiters()],
                    centralLabel: this.getDistanceGuageValue(),
                    rangeLabel: [this.$convertKilometerFormat(this.trackDetailData.distance[0]).toString(), this.$convertKilometerFormat(this.courseInfo.track.log_distance).toString()]
                  }"
                  :key="this.$store.state.Course.currentTrackPoint"
                />
              </span>
            </span>
            <span class="svg_box">
              <span class="svg_name">높이 (m)</span>
              <span class="svg_graph">
                <VueGauge :refid="'altitude_guage'"
                  :options="{
                    hasNeedle: false,
                    arcOverEffect: false,
                    chartWidth: 200,
                    arcColors: ['rgb(255, 0, 0)', 'rgb(211, 211, 211)'],
                    arcDelimiters: [this.getAltitudeDelimiters()],
                    centralLabel: this.getAltitudeGuageValue().toString(),
                    rangeLabel: [this.$convertAltitudeFormat(0).toString(), this.$convertAltitudeFormat(this.courseInfo.track.log_max_elevate).toString()]
                  }"
                  :key="this.$store.state.Course.currentTrackPoint"
                />
              </span>
            </span>
          </div>
          <LineChart :type="`course`" />
        </div>
      </template>
      <template v-if="this.$store.state.Course.currentLapsTab === 'laps'">
        <div class="laps_area">
          <div class="laps_section">
            <span class="title">구간 기준</span>
            <ul>
              <li :class="{'on': this.lapsDistance === '1000'}" @click="changeLapsDistance('1000')"
                v-if="Number(this.courseInfo.track.log_distance) >= 1000"><span class="circle"></span>1km</li>
              <li :class="{'on': this.lapsDistance === '500'}" @click="changeLapsDistance('500')"
                v-if="Number(this.courseInfo.track.log_distance) >= 500"><span class="circle"></span>500m</li>
              <li :class="{'on': this.lapsDistance === '300'}" @click="changeLapsDistance('300')"
                v-if="Number(this.courseInfo.track.log_distance) >= 300"><span class="circle"></span>300m</li>
              <li :class="{'on': this.lapsDistance === '100'}" @click="changeLapsDistance('100')"
                v-if="Number(this.courseInfo.track.log_distance) >= 100"><span class="circle"></span>100m</li>
            </ul>
          </div>
          <div class="laps_table">
            <dl>
              <dt>
                <span class="title">구간(km)</span>
                <span class="title">랩타임</span>
                <span class="title">총 시간</span>
                <span class="title">평균 속도(km/h)</span>
                <span class="title">경사도(%)</span>
              </dt>
              <dd>
                <table>
                  <tr v-for="(lap, i) in this.courseLaps[this.lapsDistance]" :key="i">
                    <td>{{lap.gugan / 1000}}</td>
                    <td>{{this.$convertTimeStampFormat(Math.floor((lap.lap_time / 60)) + Number((lap.lap_time % 60) / 100))}}</td>
                    <td>{{this.$convertTimeStampFormat(Math.floor((lap.total_time / 60)) + Number((lap.total_time % 60) / 100))}}</td>
                    <td :class="[`${this.getSpeedClass(lap.speed)}`]">{{lap.speed.toFixed(2)}}</td>
                    <td>{{lap.slope.toFixed(2)}}</td>
                  </tr>
                </table>
              </dd>
            </dl>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css'
import VueGauge from 'vue-gauge'
import LineChart from './chart/LineChart.vue'
import { mapState } from 'vuex'

let timer = null

export default {
  name: 'Laps',
  data () {
    return {
      simpleValue: 50,
      isStarted: false,
      lapsDistance: '100',
      courseLaps: this.$store.state.Course.courseLaps,
      timeGuageOptions: {}
    }
  },
  components: {
    Slider: Slider,
    VueGauge: VueGauge,
    LineChart: LineChart
  },
  computed: {
    ...mapState('Course', {
      courseInfo: 'courseInfo',
      trackPoints: 'trackPoints',
      trackDetailData: 'trackDetailData',
      chartLabel: 'chartLabel'
    }),
    checkCourseLaps () {
      return this.$store.state.Course.courseLaps
    },
    checkIsOpenLaps () {
      return this.$store.state.Course.isOpenLaps
    }
  },
  watch: {
    checkCourseLaps (courseLaps) {
      this.courseLaps = courseLaps
    },
    checkIsOpenLaps (isOpenLaps) {
      if (!isOpenLaps) {
        if (timer != null) {
          window.clearInterval(timer)
        }
      }
    }
  },
  mounted () {
    this.$store.state.Course.currentTrackPoint = 0
    this.isStarted = false
  },
  unmounted () {
    this.stopTimer()
    this.$store.state.Course.isOpenLaps = false
  },
  methods: {
    convertSecondsToTime (data) {
      var hours = parseInt(data / 3600)
      var minutes = parseInt((data % 3600) / 60)
      var seconds = data % 60

      if (hours < 10) { hours = '0' + hours }
      if (minutes < 10) { minutes = '0' + minutes }
      if (seconds < 10) { seconds = '0' + seconds }

      return hours + ':' + minutes + ':' + seconds
    },
    getGuageColor (currentValue, maxValue) {

    },
    getTimeGuageValue () {
      return this.$store.state.Course.currentTrackPoint >= this.trackPoints[0].length - 1
        ? this.$convertTimeStampFormat(this.courseInfo.track.log_total_time)
        : this.convertSecondsToTime(this.trackDetailData.time[this.$store.state.Course.currentTrackPoint])
    },
    getSpeedGuageValue () {
      return this.$convertSpeedFormat(this.trackDetailData.speed[this.$store.state.Course.currentTrackPoint])
    },
    getDistanceGuageValue () {
      return this.$store.state.Course.currentTrackPoint >= this.trackPoints[0].length - 1
        ? this.$convertKilometerFormat(this.courseInfo.track.log_distance)
        : parseFloat(this.trackDetailData.distance[this.$store.state.Course.currentTrackPoint] / 1000).toFixed(3)
    },
    getAltitudeGuageValue () {
      return this.$convertAltitudeFormat(this.trackDetailData.altitude[this.$store.state.Course.currentTrackPoint])
    },
    getTimeDelimiters () {
      let delimiters = (this.$store.state.Course.currentTrackPoint / this.trackPoints[0].length * 100).toFixed(2)
      if (this.$store.state.Course.currentTrackPoint >= this.trackPoints[0].length - 1) {
        delimiters = 99.9999999
      }
      return this.$store.state.Course.currentTrackPoint <= 0 ? 0.000000001 : delimiters
    },
    getSpeedDelimiters () {
      let delimiters = 0.0000000001
      if (this.courseInfo.track.log_max_speed > 0) {
        delimiters = (this.trackDetailData.speed[this.$store.state.Course.currentTrackPoint] / this.courseInfo.track.log_max_speed * 100).toFixed(2)
      }

      if (delimiters <= 0) {
        delimiters = 0.000000001
      } else if (delimiters === '100.00') {
        delimiters = 99.9999999
      }
      return delimiters
    },
    getDistanceDelimiters () {
      let delimiters = (this.$store.state.Course.currentTrackPoint / this.trackPoints[0].length * 100).toFixed(2)
      if (this.$store.state.Course.currentTrackPoint >= this.trackPoints[0].length - 1) {
        delimiters = 99.9999999
      }
      return this.$store.state.Course.currentTrackPoint <= 0 ? 0.000000001 : delimiters
    },
    getAltitudeDelimiters () {
      let delimiters = 0.0000000001
      if (this.courseInfo.track.log_max_elevate > 0) {
        delimiters = (this.trackDetailData.altitude[this.$store.state.Course.currentTrackPoint] / this.courseInfo.track.log_max_elevate * 100).toFixed(2)
      }

      if (delimiters <= 0) {
        delimiters = 0.000000001
      } else if (delimiters === '100.00') {
        delimiters = 99.9999999
      }
      return delimiters
    },
    controlTimer () {
      this.isStarted = !this.isStarted
      if (this.isStarted) {
        console.log(this.trackPoints[0].length)
        timer = window.setInterval(() => {
          if (this.$store.state.Course.currentTrackPoint === this.trackPoints[0].length - 1) {
            this.stopTimer()
          } else {
            const pointCount = (this.trackPoints[0].length < 200) ? 1 : (this.trackPoints[0].length < 500) ? 2 : (this.trackPoints[0].length < 1000) ? 3 : 5
            // this.$store.state.Course.currentTrackPoint++
            this.$store.state.Course.currentTrackPoint += pointCount
          }
        }, 100)
      } else {
        if (timer != null) {
          window.clearInterval(timer)
        }
      }
    },
    stopTimer () {
      this.isStarted = false
      window.clearInterval(timer)
    },
    changeTab (tab) {
      this.$store.state.Course.currentLapsTab = tab
      this.stopTimer()
    },
    changeLapsDistance (distance) {
      this.lapsDistance = distance
    },
    getSpeedClass (speed) {
      // let className = ''
      const speedArray = this.courseLaps[this.lapsDistance].map(value => {
        return value.speed
      })
      speedArray.sort()

      if (speed === speedArray[0]) {
        return 'low'
      } else if (speed === speedArray[speedArray.length - 1]) {
        return 'high'
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
  .laps_table dl dd {max-height: 200px;}
  .altitude_area {text-align: left;}
  .fnb_box {z-index: 100}
  .vue-gauge-item {display: table-cell}
</style>
