<template>
  <div class="right_body mt">
    <div class="setting_area">
      <ul>
        <li>
          <span class="set_text">전체 알림</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="clubGuidanceON" v-model="clubGuidance" value="ON" name="clubGuidance" @click="clubGuidanceControl('ON')"/>
              <label for="clubGuidanceON">ON</label>
              <input type="radio" id="clubGuidanceOFF" v-model="clubGuidance" value="OFF" namve="clubGuidance" @click="clubGuidanceControl('OFF')"/>
              <label for="clubGuidanceOFF">OFF</label>
            </span>
            <span class="stext">클럽 활동 알림 전체 알림이 ON / OFF 됩니다.</span>
          </span>
        </li>
      </ul>
      <div class="stitle">클럽 활동 알림</div>
      <ul>
        <li>
          <span class="set_text">개설 승인</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="openNoticeTrue" v-model="openNotice" value="TRUE" name="openNotice" @change="clubGuidanceChange()"/>
              <label for="openNoticeTrue">ON</label>
              <input type="radio" id="openNoticeFalse" v-model="openNotice" value="FALSE" name="openNotice" @change="clubGuidanceChange()"/>
              <label for="openNoticeFalse">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">공지 등록</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="publicNoticeTrue" v-model="publicNotice" value="TRUE" name="publicNotice" @change="clubGuidanceChange()"/>
              <label for="publicNoticeTrue">ON</label>
              <input type="radio" id="publicNoticeFalse" v-model="publicNotice" value="FALSE" name="publicNotice" @change="clubGuidanceChange()"/>
              <label for="publicNoticeFalse">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">게시글 등록</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="postNoticeTrue" v-model="postNotice" value="TRUE" name="postNotice" @change="clubGuidanceChange()"/>
              <label for="postNoticeTrue">ON</label>
              <input type="radio" id="postNoticeFalse" v-model="postNotice" value="FALSE" name="postNotice" @change="clubGuidanceChange()"/>
              <label for="postNoticeFalse">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">내 게시글 댓글 등록</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="postCommentNoticeTrue" v-model="postCommentNotice" value="TRUE" name="postCommentNotice" @change="clubGuidanceChange()"/>
              <label for="postCommentNoticeTrue">ON</label>
              <input type="radio" id="postCommentNoticeFalse" v-model="postCommentNotice" value="FALSE" name="postCommentNotice" @change="clubGuidanceChange()"/>
              <label for="postCommentNoticeFalse">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">가입승인 / 탈퇴</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="joinApprNoticeTrue" v-model="joinApprNotice" value="TRUE" name="joinApprNotice" @change="clubGuidanceChange()"/>
              <label for="joinApprNoticeTrue">ON</label>
              <input type="radio" id="joinApprNoticeFalse" v-model="joinApprNotice" value="FALSE" name="joinApprNotice" @change="clubGuidanceChange()"/>
              <label for="joinApprNoticeFalse">OFF</label>
            </span>
          </span>
        </li>
        <li>
          <span class="set_text">회원 스탭 등 자격변경</span>
          <span class="set_input">
            <span class="g">
              <input type="radio" id="promotionNoticeTrue" v-model="promotionNotice" value="TRUE" name="promotionNotice" @change="clubGuidanceChange()"/>
              <label for="promotionNoticeTrue">ON</label>
              <input type="radio" id="promotionNoticeFalse" v-model="promotionNotice" value="FALSE" name="promotionNotice" @change="clubGuidanceChange()"/>
              <label for="promotionNoticeFalse">OFF</label>
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      clubGuidance: '',
      openNotice: '',
      publicNotice: '',
      postNotice: '',
      postCommentNotice: '',
      joinApprNotice: '',
      promotionNotice: ''
    }
  },
  methods: {
    ...mapActions('Member', [
      'updateMemberConfig'
    ]),
    clubGuidanceControl (state) {
      this.clubGuidance = state
      console.log(state)
      if (state === 'OFF') {
        this.openNotice = 'FALSE'
        this.publicNotice = 'FALSE'
        this.postNotice = 'FALSE'
        this.postCommentNotice = 'FALSE'
        this.joinApprNotice = 'FALSE'
        this.promotionNotice = 'FALSE'
      }
      if (state === 'ON') {
        this.openNotice = 'TRUE'
        this.publicNotice = 'TRUE'
        this.postNotice = 'TRUE'
        this.postCommentNotice = 'TRUE'
        this.joinApprNotice = 'TRUE'
        this.promotionNotice = 'TRUE'
      }
      this.memberConfigDataUpt()
    },
    clubGuidanceChange () {
      this.clubGuidance = 'OFF'
      this.memberConfigDataUpt()
    },
    memberConfigDataUpt () {
      const data = {
        config_is_club_open_notice: this.openNotice,
        config_is_club_public_notice: this.publicNotice,
        config_is_club_post_notice: this.postNotice,
        config_is_club_post_comment_notice: this.postCommentNotice,
        config_is_club_join_appr_notice: this.joinApprNotice,
        config_is_club_promotion_notice: this.promotionNotice
      }
      this.updateMemberConfig(data)
    }
  }
}
</script>
