<template>
  <div class="left_content type1">
    <div class="section">
      <p v-html="this.$replaceTextareaNewLine(this.courseInfo.track.log_content)" />
      <div class="hashtag" v-if="this.tagList.length > 0">
        <a href="#" v-for="(tag, i) in tagList" :key="i">#{{tag.tag_name}}</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'courseDetailInfo',
  data () {
    return {
      tagList: this.courseInfo.tags
    }
  },
  props: {
    courseInfo: Object,
    trackYear: String
  },
  components: {
  },
  mounted () {
    console.log(this.courseInfo)
  }
}
</script>

<style>

</style>
