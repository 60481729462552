<template>
  <div class="left_content" :style="this.getLeftContentHeight">
    <div class="section">
      <p v-html="this.$replaceTextareaNewLine(this.courseInfo.track.log_content)"></p>
      <div class="hashtag" v-if="this.courseInfo.tags.length > 0">
        <a href="#" v-for="(tag, i) in this.courseInfo.tags" :key="i">#{{tag.tag_name}}</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'courseDetailInfo',
  data () {
    return {
    }
  },
  props: {
    courseInfo: Object,
    trackYear: String
  },
  components: {
  },
  computed: {
    getLeftContentHeight () {
      if (this.$store.state.Common.courseMenuType === 'my' &&
        this.$store.state.Member.memberId === this.courseInfo.member.member_id) {
        return 'height: calc(100vh - 500px);'
      } else {
        return 'height: calc(100vh - 418px);'
      }
    }
  },
  mounted () {
  }
}
</script>

<style>

</style>
