<template>
<div class="course_left">
  <div class="section">
    <div class="top_title">나의 코스
      <span class="btn_area_r">
        <select v-model="recordType" @change="changeRecordType()" class="default select_lm">
          <option value="tranggle">트랭글 기록</option>
          <option value="gpx">외부 기록</option>
        </select>
      </span>
    </div>
  </div>
  <div class="tab_menu">
    <ul>
      <li :class="{on : this.listTab === 'MY'}"><a href="#" @click.prevent="changeListTab('MY')">내 기록</a></li>
      <li :class="{on : this.listTab === 'DOWNLOAD'}"><a href="#" @click.prevent="changeListTab('DOWNLOAD')">받은 기록</a></li>
    </ul>
  </div>
  <div class="title_box">
    <div class="select_box">
      <select v-model="category" @change="selectList(1)" class="style1">
        <option value="">종합</option>
        <option
          v-for="(name, value) in this.$getTrackCategoryCustom()"
          :key="value"
          :value="value">{{name}}</option>
      </select>
      <select v-model="year" @change="selectList(1)" class="style1" v-if="this.listTab === 'MY'">
        <!-- <option value="">전체</option> -->
        <option :value="value" v-for="(value, i) in getYearList" :key="i">{{value}}년</option>
      </select>
      <!-- <select v-model="region" @change="selectList(1)" class="style1">
        <option value="">전지역</option>
        <option :value="sido.name" v-for = "(sido,i) in sidoList" :key="i" @click="selectSigunguList(sido.id)">{{sido.name}}</option>
      </select> -->
    </div>
    <div class="select_search input_def">
      <input type="text" v-model.trim="searchKeyword" placeholder="제목, 코스명 검색" @keyup.enter="selectList(1)"/>
      <a href="#" class="i_del" @click.prevent="clearSearchKeyword()" :style="{'display': showKeywordClearButton}"></a>
      <a href="#" class="i_fin" @click.prevent="selectList(1)"></a>
    </div>
  </div>
  <div class="left_content" style="height: calc(100vh - 267px);">
    <div class="list_poi">
      <span class="bin" v-if="this.completeSearch && courseList.length === 0">
        <span class="bin_img_result"></span>
        <span class="text">검색 결과가 없습니다.</span>
      </span>
      <ul v-else>
        <li v-for="(course, i) in courseList" :key="i" :id="`list_${i}`" :class="{on: i === this.$store.state.Course.selectedCourseIndex}" @click="this.selectedCourse(i)" @mouseover="listOver(i)" @mouseleave="listHover(i)">
          <span class="td1"><span class="poi_pin">{{i + 1}}</span></span>
          <span class="td3">
            <span class="t_icon">
              <span :class="`i_s ` + this.$categoryImage(course.log_category,9)"></span>
              <div class="user_file">
                  <span class="i_record_p" v-if = "course.log_waypoint_image_state !== 'NONE'"></span>
                  <span class="i_record_t" v-if = "course.log_image_count !== '0'"></span>
                  <span class="i_record_v" v-if = "course.log_waypoint_voice_state !== 'NONE'"></span>
                  <span class="i_record_w" v-if = "course.log_guide_count !== '0'"></span>
                  <!-- // -->
              </div>
            </span>
            <span class="poi_content">
              <span class="subject">
                <span class="text elp">{{course.log_track_name}}</span>
              </span>
              <span class="data">
                  <span class="text"><span class="t1">전체 거리</span>{{this.$convertKilometerFormatCustom(course.log_distance)}} km</span>
                  <span class="text"><span class="t1">전체 시간</span>{{this.$convertTimeStampFormat(course.log_total_time)}}</span>
                  <span class="text"><span class="t1">평균 속도</span>{{this.$convertSpeedFormat(course.log_aver_speed)}} km/h</span>
              </span>
              <div class="user_action">
                <span class="t_block">
                  <span class="i_like"></span><span class="text">{{this.$addComma(course.log_recommend_count)}}</span>
                </span>
                <span class="t_block">
                  <span class="i_view"></span><span class="text">{{this.$addComma(course.log_hit)}}</span>
                </span>
                <span class="t_block">
                  <span class="i_down"></span><span class="text">{{this.$addComma(course.log_download_count)}}</span>
                </span>
              </div>
            </span>
          </span>
          <span class="td2" v-if="course.image_file_name"><span class="poi_photo"><img :src="course.image_file_name" /></span></span>
          <span class="poi_date" v-if="this.listTab === 'MY' && course.log_datetime">{{this.convertCourseRegDate(course.log_datetime)}}</span>
          <span class="poi_date" v-else-if="this.listTab === 'DOWNLOAD' && course.down_log_datetime">{{this.convertCourseRegDate(course.down_log_datetime)}}</span>
          <a @click.stop="viewCourseInfo(course)" class="btn_detail">코스상세</a>
        </li>
      </ul>
    </div><!--/list_poi-->
    <Pagination @select-list="selectList" :totalPageCount="this.totalPageCount" :currentPage="Number(this.page)" ref="Pagination" v-if="isOpenPagination" />
  </div>
</div><!--/course_left-->
<div class="content">
    <CourseMap ref="map" />
</div><!--/content-->
</template>

<script>
import City from '@/assets/common/data/city.js'
import CourseMap from './map/CourseMap.vue'
import Pagination from './common/CoursePagination.vue'

export default {
  data () {
    return {
      courseList: [],
      page: 1,
      showKeywordClearButton: 'none',
      searchKeyword: '',
      totalPageCount: 0,
      viewCount: 50,
      listTab: 'MY',
      myCourseCount: 0,
      myDownloadCount: 0,
      category: '',
      year: new Date().getFullYear(), // 해당년도로 표출시
      // year: '',
      region: '',
      sidoList: City.sidoList,
      completeSearch: false,
      recordType: 'tranggle',
      isOpenPagination: false,
      gpxTrackCount: 0
    }
  },
  name: 'CourseMy',
  components: {
    CourseMap: CourseMap,
    Pagination: Pagination
  },
  mounted () {
    // this.getMyCourseCount()
    this.setListParameter()
    this.selectList(this.page)
  },
  computed: {
    getYearList () {
      const yearList = []
      const year = Number(new Date().getFullYear())

      for (let i = year; i > 2010; i--) {
        yearList.push(i)
      }
      return yearList
    }
  },
  methods: {
    listOver (index) {
      // this.$store.state.Course.selectedCourseIndex = index
      this.$refs.map.hoverCourseMarker(index)
      this.$refs.map.grazeCourseMarker(index)
    },
    listHover (index) {
      this.$refs.map.EraseCourseOnMarker(index)
    },
    getMyCourseCount () {
      const formData = new FormData()
      formData.append('token', this.$store.state.Member.token)
      this.axios.post('/course/getMyCourseCount.json', formData)
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode === '00') {
            this.myCourseCount = data.data.response.content.myCourseCount
            this.myDownloadCount = data.data.response.content.MyDownloadCount
          }
        })
    },
    selectList (page) {
      this.$store.commit('Course/setSelectedCourseIndex', '')
      this.courseList = []
      this.page = page
      this.completeSearch = false
      // this.isOpenPagination = false
      this.showKeywordClearButton = this.searchKeyword !== '' ? 'block' : 'none'

      const params = {
        token: this.$store.state.Member.token
      }

      Object.assign(params, this.getListParameter())

      let listApiURl = ''
      if (this.recordType === 'tranggle') {
        if (this.listTab === 'MY') { // 내 기록
          listApiURl = '/course/myCourseList.json'
        } else if (this.listTab === 'DOWNLOAD') { // 다운로드 기록
          listApiURl = '/course/myDownloadList.json'
        }
      }

      this.axios.get(listApiURl, {
        params: params
      })
        .then(data => {
          const responseCode = data.data.response.code
          if (responseCode === '00') {
            this.courseList = data.data.response.content.list
            this.$store.commit('Course/setCourseList', this.courseList)
            this.completeSearch = true

            if (this.courseList.length > 0) {
              this.totalPageCount = parseInt(data.data.response.content.last_page)
              this.isOpenPagination = true
            }
          }
        })
    },
    selectedCourse (index) {
      this.$refs.map.selectedCourseMarker(index)
    },
    viewCourseInfo (course) {
      this.$store.state.Common.courseMenuType = 'my'
      const params = {}
      Object.assign(params, this.getListParameter())

      this.$router.push({ path: '/course/' + course.log_track_no, query: params })
    },
    changeListTab (tab) {
      this.listTab = tab
      this.selectList(1)
    },
    clearSearchKeyword () {
      this.searchKeyword = ''
      this.selectList(1)
    },
    changeRecordType () {
      if (this.recordType === 'tranggle') {
        this.$router.push('/course/my')
      } else if (this.recordType === 'gpx') {
        this.$router.push('/course/my/gpx')
      }
    },
    convertCourseRegDate (date) {
      return date.length > 10 ? date.substring(0, 10) : date
    },
    getListParameter () {
      const params = {}

      Object.assign(params, {
        year: this.year,
        search_val: this.searchKeyword,
        category: this.category,
        region: this.region,
        view_count: this.viewCount,
        page: this.page,
        listTab: this.listTab
      })

      return params
    },
    setListParameter () {
      if (this.$route.query.page != null) {
        this.page = this.$route.query.page
      }
      if (this.$route.query.view_count != null) {
        this.viewCount = this.$route.query.view_count
      }
      if (this.$route.query.search_val != null) {
        this.searchKeyword = this.$route.query.search_val
      }
      if (this.$route.query.category != null) {
        this.category = this.$route.query.category
      }
      if (this.$route.query.region != null) {
        this.region = this.$route.query.region
      }
      if (this.$route.query.listTab != null) {
        this.listTab = this.$route.query.listTab
      }
      if (this.$route.query.year != null) {
        this.year = this.$route.query.year
      }
    }
  }
}
</script>

<style>

</style>
