import axios from '../../util/axios.js'

export default {
  namespaced: true,
  state: {
    popBg: false,
    popArea: false,
    popGalleryArea: false,
    missionCreate: false,
    missionCreateInfo: false,
    missionTerms: false,
    termsChk: 'inactive',
    missionSearchMenu: false,
    missionCurrentId: '',
    missionSearchStatusCategory: [],
    missionSearchTrackCategory: [],
    missionSearchCompetCategory: [],
    missionSearchOpt: false,
    // 미션관리 상세설정
    missionManageSetSearch: false, // 미션 - watch
    missionManageSetRankCategory: 'Top1',
    missionManageSetCompetList: [],
    missionManageSetCompetCategory: [],
    missionManageSetGenderCategory: [],
    missionManageSetAgeCategory: [],
    missionManageSetAreaCategory: [],
    missionLeftMenuState: false, // 미션 - 왼쪽메뉴 상태값
    msgstat: false, // 미션 - 쪽지 팝업
    msgMessage: '', // 미션 - 쪽지 Comp
    msgReceiveId: '',
    msgReceiveParticipant: [],
    // 여기까지 쪽지
    introductionToCompetition: false,
    missionDelete: false,
    missionParticipatePhoneAuth: false,
    missionManagePop: false,
    missionManageSetPop: false,
    missionExerciseInfoPop: false,
    missionPopFraudulentRecordPop: false,
    // 사용자 정보 변경 연락처
    missionMemberPhoneChanged: false,
    missionMemberPhone: '',
    missionMemberEmail: '',
    // 운동 정보 팝업 오픈 여부
    isOpenTrackAiInfoPopup: false,
    isMissionParticipatePwd: false,
    isMissionCreateSurvey: false,
    // 운동 정보 팝업에 쓰이는 트랙 키 (track_year + log_no 또는 track_key)
    trackInfoTrackKey: '',
    // 미인증 국가
    missionOtherAuthPopup: false,
    missionGalleryPopup: false,
    missionGalleryList: [],
    missionTrackViewPopup: false,
    missionTrackViewPopupTrackKey: '',
    searchCommitCnt: 0,
    alarmMissionStaff: null,
    // 미션 진행중 리스트
    missionProgressList: [],
    missionExpectedList: [],
    // 미션 참가중 리스트
    participatedProgressList: [],
    participatedExpectedList: [],
    waitList: [],
    tempList: [],
    // 미션 종료 리스트
    endedList: [],
    // 미션삭제
    missionDelBool: false,
    missionInfo: [],
    missionCateList: [],
    rankingList: [],
    rankTotalCnt: 0,
    userRecord: [],
    clubList: [],
    missSaveBool: false,
    memberList: [],
    memberListCnt: 0,
    memberAuthCancelBool: false,
    memberDetails: [],
    staffList: [],
    memberGradeList: [],
    setStaffBool: '',
    missStatusList: []
  },
  mutations: {
    openAiTrackInfoPopup (state, data) {
      state.popBg = true
      state.popArea = true
      state.isOpenTrackAiInfoPopup = true
      state.trackInfoTrackKey = data
    },
    closeAiTrackInfoPopup (state) {
      state.popBg = false
      state.popArea = false
      state.isOpenTrackAiInfoPopup = false
    },
    setAttachTrack (state, data) {
      state.attachTrack = {
        track_name: data == null ? '' : data.track_name,
        track_key: data == null ? '' : data.track_key,
        track_year: data == null ? '' : data.track_year,
        track_no: data == null ? '' : data.track_no,
        category: data == null ? '' : data.category
      }
    },
    setMissionOn (state, data) {
      state.missionProgressList = data.progress
      state.missionExpectedList = data.expected
    },
    setMissionParticipate (state, data) {
      state.participatedProgressList = data.participate
      state.participatedExpectedList = data.expected
      state.waitList = data.wait
      state.tempList = data.temp
    },
    setMissionEnd (state, data) {
      state.endedList = data
    },
    setMissionDel (state, data) {
      state.missDelBool = data
    },
    setMissInfo (state, data) {
      state.missionInfo = data.info
      state.missionCateList = data.compet
    },
    setRankingDetail (state, data) {
      state.rankingList = data.rankingList
      state.rankTotalCnt = data.rank_total_count
    },
    setUserRecord (state, data) {
      state.userRecord = data
    },
    setClubList (state, data) {
      state.clubList = data
    },
    setMissSave (state, data) {
      state.missSaveBool = data
    },
    setMemberLists (state, data) {
      state.memberList = data.list
      state.memberListCnt = data.total_count
    },
    setMemberAuthCancel (state, data) {
      state.memberAuthCancelBool = data
    },
    setMemberDetails (state, data) {
      state.memberDetails = data
    },
    setStaffList (state, data) {
      state.staffList = data.list
    },
    setMemberGradeList (state, data) {
      state.memberGradeList = data
    },
    setStaffGrade (state, data) {
      state.setStaffBool = data
    },
    setMissStatus (state, data) {
      state.missStatusList = data.list
    }
  },
  getters: {
    // 팝업 전체 닫기
    missionCloseAll (state) {
      state.missionCreate = !state.missionCreate
      state.termsChk = 'on'
    },
    // 팝업 배경
    missionBgOn (state) {
      state.popBg = true
      state.popArea = true
    },
    missionBgOff (state) {
      state.popBg = false
      state.popArea = false
    },
    // 미션 개설 팝업
    missionNewOpen (state, getters) {
      // console.log('missionNewOpen')
      // state.popBg = true
      // state.popArea = true
      state.termsChk = 'inactive'
      state.missionTerms = false
      state.missionCreate = true
      return getters.missionBgOn
    },
    // 미션 개설 이용약관
    missionNewOpenHide (state, getters) {
      // console.log('미션닫기')
      // state.popBg = false
      // state.popArea = false
      // state.termsChk = 'inactive'
      // state.missionTerms = false
      state.missionCreate = false
      return getters.missionBgOff
    },
    // 미션 개설 팝업
    missionCreateInfoShow (state, getters) {
      // console.log('missionNewOpen')
      // state.popBg = true
      // state.popArea = true
      state.missionCreateInfo = true
      return getters.missionBgOn
    },
    // 미션 개설 이용약관
    missionCreateInfoHide (state, getters) {
      // console.log('미션닫기')
      // state.popBg = false
      // state.popArea = false
      // state.termsChk = 'inactive'
      // state.missionTerms = false
      state.missionCreateInfo = false
      return getters.missionBgOff
    },
    // missionTermsOn (state) {
    //   state.missionCreate = !state.missionCreate
    //   state.missionTerms = !state.missionTerms
    //   state.termsChk = 'on'
    // },
    missionTermsShow (state, getters) {
      // console.log('missionTermsShow')
      state.termsChk = 'on'
      state.missionTerms = true
      state.missionCreate = false
      return getters.missionBgOn
    },
    missionTermsHide (state, getters) {
      // console.log('missionTermsHide')
      state.termsChk = 'inactive'
      state.missionTerms = false
      state.missionCreate = false
      return getters.missionBgOff
    },
    // 미션 검색 메뉴
    // searchMenu (state) {
    //   state.popBg = !state.popBg
    //   state.popArea = !state.popArea
    //   state.missionSearchMenu = !state.missionSearchMenu
    //   state.missionSearchOpt = false
    // },
    // searchMenuPush (state) {
    //   state.popBg = !state.popBg
    //   state.popArea = !state.popArea
    //   state.missionSearchMenu = !state.missionSearchMenu
    //   state.missionSearchOpt = !state.missionSearchOpt
    // },
    // 미션 - 검색옵션
    searchMenuShow (state, getters) {
      state.missionSearchMenu = true
      state.missionSearchOpt = false
      return getters.missionBgOn
    },
    searchMenuHide (state, getters) {
      state.missionSearchMenu = false
      state.missionSearchOpt = false
      return getters.missionBgOff
    },
    searchMenuPush (state, getters) {
      state.missionSearchMenu = false
      state.missionSearchOpt = true
      state.searchCommitCnt += 1
      return getters.missionBgOff
    },
    // 미션 개설하기 - 경쟁 부분
    introSwith (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.introductionToCompetition = !state.introductionToCompetition
    },
    introSwithShow (state, getters) {
      // state.popBg = !state.popBg
      // state.popArea = !state.popArea
      state.introductionToCompetition = true
      return getters.missionBgOn
    },
    introSwithHide (state, getters) {
      // state.popBg = !state.popBg
      // state.popArea = !state.popArea
      state.introductionToCompetition = false
      return getters.missionBgOff
    },
    missionDelete (state) {
      state.popBg = !state.popBg
      state.popArea = !state.popArea
      state.missionDelete = !state.missionDelete
    },
    // 미션 참가신청 폰 인증
    missionParticipatePhoneAuthShow (state, getters) {
      state.missionParticipatePhoneAuth = true
      return getters.missionBgOn
    },
    missionParticipatePhoneAuthHide (state, getters) {
      console.log('missionParticipatePhoneAuthHide')
      state.missionParticipatePhoneAuth = false
      return getters.missionBgOff
    },
    // 미션관리 - 팝업
    missionManagePopShow (state, getters) {
      state.missionManagePop = true
      return getters.missionBgOn
    },
    // 미션 - 운동정보
    missionExerciseInfoShow (state, getters) {
      state.missionExerciseInfoPop = true
      state.missionExerciseInfoAiPop = false // 미션 - 운동정보 AI 닫기
      return getters.missionBgOn
    },
    missionExerciseInfoHide (state, getters) {
      state.missionExerciseInfoPop = false
      return getters.missionBgOff
    },
    // 미션 - 운동정보 Ai
    missionExerciseInfoAiShow (state, getters) {
      state.missionExerciseInfoPop = false // 미션 - 운동정보 AI 닫기
      state.missionExerciseInfoAiPop = true
      return getters.missionBgOn
    },
    missionExerciseInfoAiHide (state, getters) {
      state.missionExerciseInfoAiPop = false
      return getters.missionBgOff
    },
    // 미션관리 - 설정에 따른 현황 확인 지도 - 설정
    missionManageSetPopShow (state, getters) {
      state.missionManageSetPop = true
      return getters.missionBgOn
    },
    // 미션관리 - 설정에 따른 현황 확인 지도 - 설정
    missionManageSetPopHide (state, getters) {
      state.missionManageSetPop = false
      return getters.missionBgOff
    },
    missionManageSetPopPush (state, getters) {
      state.missionManageSetPop = false
      state.missionManageSetSearch = !state.missionManageSetSearch
      return getters.missionBgOff
    },
    missionManagePopHide (state, getters) {
      state.missionManagePop = false
      return getters.missionBgOff
    },
    missionManagePopPush (state, getters) {
      state.missionManagePop = false
      state.missionManageSetSearch = !state.missionManageSetSearch
      return getters.missionBgOff
    },
    // 미션 - 부정 기록 팝업
    missionPopFraudulentRecordPopShow (state, getters) {
      state.missionPopFraudulentRecordPop = true
      return getters.missionBgOn
    },
    missionPopFraudulentRecordPopHide (state, getters) {
      state.missionPopFraudulentRecordPop = false
      return getters.missionBgOff
    },
    missionParticipatePwdPopShow (state, getters) {
      state.isMissionParticipatePwd = true
      return getters.missionBgOn
    },
    missionParticipatePwdPopHide (state, getters) {
      state.isMissionParticipatePwd = false
      return getters.missionBgOff
    },
    missionCreateSurveyPopShow (state, getters) {
      state.isMissionCreateSurvey = true
      return getters.missionBgOn
    },
    missionCreateSurveyPopHide (state, getters) {
      state.isMissionCreateSurvey = false
      return getters.missionBgOff
    },
    // 미션 - 미인증 국가
    missionOtherAuthPopShow (state, getters) {
      state.missionOtherAuthPopup = true
      return getters.missionBgOn
    },
    missionOtherAuthPopHide (state, getters) {
      state.missionOtherAuthPopup = false
      return getters.missionBgOff
    },
    missionGalleryPopupShow (state, getters) {
      state.missionGalleryPopup = true
      state.popBg = true
    },
    missionGalleryPopupHide (state, getters) {
      state.missionGalleryPopup = false
      state.popBg = false
    },
    missionTrackViewPopupShow (state, getters) {
      state.missionTrackViewPopup = true
      return getters.missionBgOn
    },
    missionTrackViewPopupHide (state, getters) {
      state.missionTrackViewPopup = false
      return getters.missionBgOff
    },
    // 미션 - 쪽지
    msgSendComp (state) {
      state.msgstat = !state.msgstat
      state.MessageSendEnd = !state.MessageSendEnd
    },
    msgPopShow (state, getters) {
      console.log('msgPopShow')
      state.msgstat = true
      return getters.missionBgOn
    },
    msgPopHide (state, getters) {
      state.msgstat = false
      return getters.missionBgOff
    },
    updateAlarmMissionStaff (state, data) {
      state.alarmMissionStaff = data
    }
  },
  actions: {
    // 알림에서 관리자 승낙/거절 업데이트
    setAlarmMissionStaff ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/PushV2/setMissionStaff.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('updateAlarmMissionStaff', data.log_no)
          } else {
            commit('updateAlarmMissionStaff', false)
          }
        })
    },
    // 미션 진행중 리스트
    getListOn ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getListOn.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMissionOn', memberResponseData.content)
          }
        })
    },
    // 미션 참가중 리스트
    getListParticiPating ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getListParticiPating.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMissionParticipate', memberResponseData.content)
          }
        })
    },
    // 미션 종료 리스트
    getListFinish ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getListFinish.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMissionEnd', memberResponseData.content)
          }
        })
    },
    // 미션 삭제
    loadDeleteMission ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/setTempMissDelete.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMissionDel', true)
          } else {
            commit('setMissionDel', false)
          }
        })
    },
    // 미션 기본 정보 조회
    getMissInfo ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getMissInfo.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMissInfo', memberResponseData.content)
          }
        })
    },
    // 미션 랭킹상세
    getRankingDetail ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getRankingDetail.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setRankingDetail', memberResponseData.content)
          }
        })
    },
    // 참가자 기록 조회
    getUserRecord ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getUserRecord.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setUserRecord', memberResponseData.content[data.miss_compet])
          }
        })
    },
    // 클럽 리스트 조회
    getClubList ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getClubList.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setClubList', memberResponseData.content)
          }
        })
    },
    // 미션 저장
    saveMission ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/setMissSave.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMissSave', true)
          } else {
            commit('setMissSave', false)
          }
        })
    },
    // 미션 참가자 리스트
    getMemberLists ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getMemberLists.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMemberLists', memberResponseData.content)
          }
        })
    },
    // 미션 참가 승인 거절
    memberAuthCancel ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/setMemberAuthCancel.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMemberAuthCancel', true)
          } else {
            commit('setMemberAuthCancel', false)
          }
        })
    },
    // 참가자 상세 정보
    getMemberDetails ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getMemberDetails.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMemberDetails', memberResponseData.content)
          }
        })
    },
    // 미션 스태프 리스트
    getStaffList ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getStaffList.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setStaffList', memberResponseData.content)
          }
        })
    },
    // 미션 관리자 검색
    getMemberGrade ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getMemberGrade.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMemberGradeList', memberResponseData.content)
          }
        })
    },
    setStaffGrade ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/setStaffGrade.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setStaffGrade', 'Y')
          } else {
            commit('setStaffGrade', 'N')
          }
        })
    },
    loadMissStatus ({ state, commit }, data) {
      const fd = new FormData()
      Object.keys(data).forEach(el => {
        fd.append(el, data[el])
      })
      axios.post('/mission/getMissStatus.json', fd)
        .then(data => {
          const memberResponseData = data.data.response
          const memberResponseCode = memberResponseData.code
          if (memberResponseCode === '00') {
            commit('setMissStatus', memberResponseData.content)
          }
        })
    }
  },
  modules: {
  }
}
