<template>
  <div class="course_left">
    <div class="section">
      <div class="top_title">코스 검색</div>
      <div class="search">
          <span class="search_input input_def">
            <input type="text" v-model.trim="searchKeyword" placeholder="제목, 코스명 검색" @keyup.enter="searchCourse()"/>
            <a href="#" class="i_del" @click.prevent="clearSearchKeyword()" :style="{'display': showKeywordClearButton}"></a>
            <a href="#" class="i_fin" @click.prevent="searchCourse()" :style="{'display': showKeywordSearchButton}"></a>
          </span>
      </div>
      <div class="option_tag">
          <!-- <span class="option_btn">
            <a href="#" @click.prevent="this.$store.commit('Course/controlSearchOptionPopup', 'course')" class="btn_option">검색 옵션</a>
          </span> -->
          <span class="option_choice" v-if="!this.$store.state.Course.courseSearchOption">
            현재 선택된 검색 옵션이 없습니다.
          </span>
          <span class="option_choice" v-if="this.$store.state.Course.courseSearchOption">
            <a href="#">
                <span class="text" v-if="!this.$store.state.Course.selectArea.includes('ALL')">지역</span>
                <span class="text" v-if="!this.$store.state.Course.courseSearchAllPeriod">기간</span>
                <span class="text" v-if="!this.$store.state.Course.courseSearchTrackCategory.includes('ALL')">운동종목</span>
                <span class="text" v-if="!this.$store.state.Course.courseSearchDistance.includes('ALL')">운동거리</span>
                <span class="text" v-if="!this.$store.state.Course.courseSearchTime.includes('ALL')">운동시간</span>
                <span class="text" v-if="!this.$store.state.Course.courseSearchBadge.includes('ALL')">배지</span>
            </a>
          </span>
      </div>
    </div>
    <div class="tab_menu" v-if="this.listStatus === 'search'">
      <ul>
        <li><a href="#" @click.prevent="changeListTab('TRACK')" class="block_click">코스 검색 결과 <span v-if="this.searchTotalCount && this.listTab === 'TRACK'">{{this.$addComma(this.searchTotalCount)}}개</span></a></li>
        <!-- <li :class="{on : this.listTab === 'BADGE'}"><a href="#" @click.prevent="changeListTab('BADGE')">배지명 검색 결과 <span v-if="this.searchTotalCount && this.listTab === 'BADGE'">{{this.$addComma(this.searchTotalCount)}}개</span></a></li> -->
      </ul>
    </div>
    <!-- <div class="title" v-if="this.listStatus === 'recommend'">코스
      <span class="area_right">
          <div class="select_box">
              <select name="" id="" class="style1" v-model="this.$store.state.Course.searchOrder" @change="selectSearchCourseList(1)">
                  <option value="recommend">추천순</option>
                  <option value="new">최신순</option>
                  <option value="popular">인기순</option>
              </select>
          </div>
      </span>
    </div> -->
    <div class="tab_menu" v-if="this.listStatus === 'recommend'">
      <ul>
        <li :class="{on : this.searchOrder === 'new'}"><a href="#" @click.prevent="changeListCs('new')">최신 기록</a></li>
        <li :class="{on : this.searchOrder === 'popular'}"><a href="#" @click.prevent="changeListCs('popular')">인기 기록</a></li>
      </ul>
    </div>
    <div class="left_content" ref="left_content" :style="this.getLeftContentHeight">
      <div class="list_poi">
        <span class="bin" v-if="this.completeSearch && courseList.length === 0">
          <span class="bin_img_result"></span>
          <span class="text">검색 결과가 없습니다.</span>
        </span>
        <ul v-else>
          <div v-if="courseList.length" class="check_new" :class="{open : checkNew}">실시간 코스가 업로드 되었습니다.</div>
          <li v-for="(course, i) in courseList" :key="i" :id="`list_${i}`" :class="{on: i === this.$store.state.Course.selectedCourseIndex}" @click="this.selectedCourse(i)" @mouseover="listOver(i)" @mouseleave="listHover(i)">
            <span class="td1"><span class="poi_pin">{{i + 1}}</span></span>
            <span class="td3">
              <span class="t_icon">
                <span :class="`i_s ` + this.$categoryImage(course.log_category,9)"></span>
                <div class="user_file">
                    <span class="i_record_p" v-if = "course.log_waypoint_image_state !== 'NONE'"></span>
                    <span class="i_record_t" v-if = "course.log_image_count !== '0'"></span>
                    <span class="i_record_v" v-if = "course.log_waypoint_voice_state !== 'NONE'"></span>
                    <span class="i_record_w" v-if = "course.log_guide_count !== '0'"></span>
                    <!-- // -->
                </div>
              </span>
              <span class="poi_content">
                <span class="subject">
                  <!-- <span :class="`icon_exc ` + this.$categoryImage(course.log_category)"></span> -->
                  <span class="text elp">{{course.log_track_name}}</span></span>
                <span class="data">
                  <span class="text"><span class="t1">전체 거리</span>{{this.$convertKilometerFormatCustom(course.log_distance)}} km</span>
                  <span class="text"><span class="t1">전체 시간</span>{{this.$convertTimeStampFormat(course.log_total_time)}}</span>
                  <span class="text"><span class="t1">평균 속도</span>{{this.$convertSpeedFormat(course.log_aver_speed)}} km/h</span>
                </span>
                <div class="user_action">
                  <span class="t_block">
                    <span class="i_like"></span><span class="text">{{this.$addComma(course.log_recommend_count)}}</span>
                  </span>
                  <span class="t_block">
                    <span class="i_view"></span><span class="text">{{this.$addComma(course.log_hit)}}</span>
                  </span>
                  <span class="t_block">
                    <span class="i_down"></span><span class="text">{{this.$addComma(course.log_download_count)}}</span>
                  </span>
                </div>
              </span>
            </span>
            <span class="td2" v-if="course.image_file_name"><span class="poi_photo"><img :src="course.image_file_name" /></span></span>
            <span class="poi_date">{{this.convertCourseRegDate(course.log_datetime)}}</span>
            <a href="#" @click.stop="viewCourseInfo(course)" class="btn_detail">코스상세</a>
          </li>
        </ul>
      </div><!--/list_poi-->
      <Pagination @select-list="selectList" :totalPageCount="this.totalPageCount" :currentPage="Number(this.page)" ref="Pagination"/>
    </div>
  </div><!--/course_left-->
  <div class="content">
      <CourseMap ref="map" @select-list-by-coords="selectListByCoords" :searchStatus="searchChk" :searchKeyword="searchKeyword" :cSearch="cSearch" />
  </div><!--/content-->
</template>

<script>

import CourseMap from '../map/CourseMap.vue'
import Pagination from '../common/CoursePagination.vue'
import { mapState } from 'vuex'

export default {
  name: 'courseBookList',
  components: {
    CourseMap: CourseMap,
    Pagination: Pagination
  },
  data () {
    return {
      courseList: [],
      page: 1,
      showKeywordClearButton: 'none',
      showKeywordSearchButton: 'block',
      searchKeyword: '',
      totalPageCount: 0,
      viewCount: 50,
      listStatus: 'recommend',
      listTab: 'TRACK',
      listCs: 'new',
      isOpenPagination: false,
      completeSearch: false,
      searchResultCount: {
        track_result_count: 0,
        badge_result_count: 0
      },
      searchTotalCount: 0,
      latitude: null,
      longitude: null,
      radius: 1,
      // searchOrder: 'recommend',
      searchChk: false,
      cSearch: '',
      checkNew: false
    }
  },
  mounted () {
    if (this.$store.state.Course.searchKeyword) {
      this.searchChk = true
    }
    this.$store.state.Course.selectedCourseIndex = ''
    this.setListParameter()
    this.selectList(this.page)
    if (this.page > 1) {
      this.selectSearchCourseCount()
    }
  },
  methods: {
    listOver (index) {
      this.$refs.map.hoverCourseMarker(index)
      this.$refs.map.grazeCourseMarker(index)
    },
    listHover (index) {
      this.$refs.map.EraseCourseOnMarker(index)
    },
    searchCourse () {
      if (this.searchKeyword) {
        this.clearSearchCoords()
        this.searchChk = true
        this.selectList(1)
      } else {
        this.$showMessagePopup('', '검색어를 입력해주세요.')
      }
    },
    selectList (page) {
      this.$store.commit('Course/setSelectedCourseIndex', '')
      this.$refs.map.deleteCourseMarker()

      this.courseList = []
      this.completeSearch = false
      this.page = page

      this.showKeywordClearButton = this.searchKeyword !== '' ? 'block' : 'none'
      this.showKeywordSearchButton = this.searchKeyword !== '' ? 'none' : 'block'

      if (this.searchChk) {
        if (this.listStatus !== 'search') {
          this.isOpenPagination = false
        }
        this.listStatus = 'search'
        this.$store.state.Course.searchKeyword = this.searchKeyword
      } else {
        if (this.listStatus !== 'recommend') {
          this.isOpenPagination = false
        }
        this.listStatus = 'recommend'
      }

      if (this.listStatus === 'search') {
        if (Number(page) === 1 && this.searchResultCount.track_result_count === 0) {
          this.selectSearchCourseCount()
        }
        this.selectSearchCourseList(page)
      } else if (this.listStatus === 'recommend') {
        this.selectSearchCourseList(page)
      }
      this.$refs.Pagination.createPagination()
    },
    selectSearchCourseCount () {
      const params = {
        token: this.$store.state.Member.token,
        search_val: this.searchKeyword
      }

      if (this.latitude != null && this.longitude != null) {
        params.latitude = this.latitude
        params.longitude = this.longitude
        params.radius = this.radius
      }

      const searchOption = this.$store.getters['Course/getSearchOption']
      Object.assign(params, searchOption)

      this.searchResultCount = {
        track_result_count: 0,
        badge_result_count: 0
      }

      // this.axios.get('/course/get_search_result_count.json', {
      //   params: params
      // })
      //   .then(data => {
      //     const responseCode = data.data.response.code
      //     if (responseCode === '00') {
      //       this.searchResultCount = data.data.response.content
      //     }
      //   })
    },
    selectSearchCourseList (page, cSearch) {
      this.cSearch = cSearch || ''
      this.$store.state.Course.selectedCourseIndex = ''
      this.selectSearchCourseCount()
      if (this.$store.state.Course.searchOrder === 'recommend') {
        this.selectRecommendCourseList(page)
      } else {
        this.page = page

        const params = {
          token: this.$store.state.Member.token,
          order: this.$store.state.Course.searchOrder
        }

        Object.assign(params, this.getListParameter())

        const searchOption = this.$store.getters['Course/getSearchOption']
        Object.assign(params, searchOption)

        this.axios.get('/course/search.json', {
          params: params
        })
          .then(data => {
            this.$refs.left_content.scrollTop = 0
            this.courseList = data.data.response.content.list || []
            const courseInfo = data.data.response.content
            this.searchTotalCount = 0
            // 코스 업데이트시 가장 최신 기록 쿠키 저장 / 문구 2초후 사라지기
            if (params.order === 'new' && Number(params.page) === 1 && params.search_val === '' && this.courseList.length) {
              if (this.$cookies.get('check_new_list') && String(this.courseList[0].log_track_no) !== this.$cookies.get('check_new_list')) {
                this.checkNew = true
                setTimeout(() => {
                  this.checkNew = false
                }, 2000)
              }
              this.$cookies.set('check_new_list', this.courseList[0].log_track_no)
            }
            if (this.courseList.length > 0) {
              this.totalPageCount = parseInt(courseInfo.last_page)
            } else {
              this.totalPageCount = 0
            }
            // if (params.search_val) {
            //   this.searchTotalCount = courseInfo.total_count
            // }
            this.$store.commit('Course/setCourseList', this.courseList)
            this.isOpenPagination = true
            this.completeSearch = true
          })
      }
    },
    selectRecommendCourseList (page) {
      this.axios.get('/course/lists.json', {
        params: {
          token: this.$store.state.Member.token
        }
      })
        .then(data => {
          this.courseList = data.data.response.content.list

          if (this.courseList.length > 0) {
            this.totalPageCount = parseInt(data.data.response.content.totalCount)
          } else {
            this.totalPageCount = 0
          }

          this.$store.commit('Course/setCourseList', this.courseList)
          this.isOpenPagination = true
          this.completeSearch = true
          this.$refs.Pagination.createPagination()
        })
    },
    closeSearchOptionPopup () {
      this.isOpenSearchOptionPopup = false
    },
    selectedCourse (index) {
      this.$refs.map.selectedCourseMarker(index)
    },
    changeListTab (tab) {
      this.listTab = tab
      this.selectSearchCourseList(1)
    },
    changeListCs (tab) {
      this.listCs = tab
      this.$store.state.Course.searchOrder = tab
      this.selectSearchCourseList(1)
    },
    viewCourseInfo (course) {
      this.$store.state.Common.courseMenuType = ''
      const params = {}
      const searchOption = this.$store.getters['Course/getSearchOption']
      Object.assign(params, searchOption)
      Object.assign(params, this.getListParameter())

      this.$router.push({ path: '/course/' + course.log_track_no, query: (params.search_val) ? { search: params.search_val } : {} })
    },
    clearSearchKeyword () {
      this.searchKeyword = ''
      this.selectList(1)
    },
    convertCourseRegDate (date) {
      return date.length > 10 ? date.substring(0, 10) : date
    },
    clearSearchCoords () {
      this.latitude = null
      this.longitude = null
    },
    selectListByCoords (latitude, longitude) {
      this.latitude = latitude
      this.longitude = longitude
      this.completeSearch = false
      this.selectSearchCourseCount()
      this.selectSearchCourseList(1, 'search')
    },
    getListParameter () {
      const params = {}

      Object.assign(params, {
        page: this.page,
        rows: this.viewCount,
        search_val: this.searchKeyword,
        by: this.listTab
      })

      if (this.latitude != null && this.longitude != null) {
        params.latitude = this.latitude
        params.longitude = this.longitude
        params.radius = this.radius
      }

      return params
    },
    setListParameter () {
      if (this.$route.query.page != null) {
        this.page = this.$route.query.page
      }
      if (this.$route.query.rows != null) {
        this.viewCount = this.$route.query.rows
      }
      if (this.$route.query.search != null) {
        this.searchKeyword = this.$route.query.search
      }
      if (this.$route.query.by != null) {
        this.listTab = this.$route.query.by
      }
      if (this.$route.query.latitude != null) {
        this.latitude = this.$route.query.latitude
      }
      if (this.$route.query.longitude != null) {
        this.longitude = this.$route.query.longitude
      }
      if (this.$route.query.radius != null) {
        this.radius = this.$route.query.radius
      }
      if (this.$route.query.start_date != null) {
        this.$store.state.Course.courseSearchStartDate = this.$route.query.start_date
        this.$store.state.Course.courseSearchAllPeriod = false
      }
      if (this.$route.query.end_date != null) {
        this.$store.state.Course.courseSearchEndDate = this.$route.query.end_date
        this.$store.state.Course.courseSearchAllPeriod = false
      }

      let tempArray = ''
      if (this.$route.query.region != null && this.$route.query.region !== '') {
        tempArray = this.$route.query.region.split('|')
      } else {
        tempArray = ['ALL']
      }
      this.$store.state.Course.selectArea = tempArray

      if (this.$route.query.category != null && this.$route.query.category !== '') {
        tempArray = this.$route.query.category.split('|')
      } else {
        tempArray = ['ALL']
      }
      this.$store.state.Course.courseSearchTrackCategory = tempArray

      if (this.$route.query.distance != null && this.$route.query.distance !== '') {
        tempArray = this.$route.query.distance.split('|')
      } else {
        tempArray = ['ALL']
      }
      this.$store.state.Course.courseSearchDistance = tempArray

      if (this.$route.query.pass_time != null && this.$route.query.pass_time !== '') {
        tempArray = this.$route.query.pass_time.split('|')
      } else {
        tempArray = ['ALL']
      }
      this.$store.state.Course.courseSearchTime = tempArray

      if (this.$route.query.badge_cnt != null && this.$route.query.badge_cnt !== '') {
        tempArray = this.$route.query.badge_cnt.split('|')
      } else {
        tempArray = ['ALL']
      }
      this.$store.state.Course.courseSearchBadge = tempArray

      if (!this.$store.state.Course.courseSearchTrackCategory.includes('ALL') ||
        !this.$store.state.Course.selectArea.includes('ALL') ||
        this.$store.state.Course.courseSearchStartDate !== '' ||
        this.$store.state.Course.courseSearchEndDate !== '' ||
        !this.$store.state.Course.courseSearchDistance.includes('ALL') ||
        !this.$store.state.Course.courseSearchTime.includes('ALL') ||
        !this.$store.state.Course.courseSearchBadge.includes('ALL')) {
        this.$store.state.Course.courseSearchOption = true
      } else {
        this.$store.state.Course.courseSearchOption = true
      }
    }
  },
  computed: {
    ...mapState('Course', ['searchOrder']),
    getLeftContentHeight () {
      if (this.listStatus === 'recommend') {
        return 'height: calc(100vh - 245px);'
      } else {
        return 'height: calc(100vh - 245px - 30px);'
      }
    },
    serachTap () {
      return this.$store.state.Course.searchCommitCnt
    }
  },
  watch: {
    serachTap () {
      this.searchCourse()
    }
  }
}
</script>

<style>

</style>
