<template>
  <div class="popup popup_bg" v-if="Object.keys(this.$store.state.Course.courseBookInfo).length > 0">
    <div class="popup_area">
      <div class="popup_box popup_box_m" v-if="!isRequiredCharge">
        <div class="popup_top line_bottom"><h2>코스북 구매</h2>
        </div>
        <div class="popup_body">
          <p class="title">코스북을 지금 구매하면?</p>
          <div class="article">
            <ul>
              <li>나만의 코스북을 만들 수 있어요.(활동사진, 글 편집가능)</li>
              <li>구매시점에 따라 최대 30%할인혜택이 있어요.</li>
              <li>도전/성공 코스북을 다른 친구들에게 자랑할 수있어요.</li>
              <li>성공 시 완주배지를 받을 수 있어요.</li>
              <li class="pay">공인 완주인증서 신청을 할 수 있어요.</li>
              <li class="pay">코스북 앨범제작을 할 수 있어요.</li>
              <li>나만의 코스북이 자동으로 저장/관리되고 재도전 할 수 있어요.</li>
            </ul>
          </div>
          <div class="cbook_cover">
            <div class="g">
              <span class="cbook_img"><img :src="this.$photoEmpty(this.$store.state.Course.courseBookInfo.view.course_image)" @error="replaceByDefault" /></span>
              <span class="gc">
                <span class="subject">
                  <span class="icon_exc" :class="`icon_exc ` + this.$categoryImage(this.$store.state.Course.courseBookInfo.view.course_category_e_name)"></span>
                  <span class="subject_text">{{this.$store.state.Course.courseBookInfo.view.course_name}}</span>
                </span>
                <span class="data">
                  <span class="text"><span class="t1">코스 길이</span>{{this.$addComma(Math.floor(this.$store.state.Course.courseBookInfo.view.course_track_distance))}}km</span>
                  <span class="text"><span class="t1">누적 고도</span>{{this.$addComma(Math.floor(this.$store.state.Course.courseBookInfo.view.course_track_accum_elevate))}}m</span>
                  <span class="text"><span class="t1">목표 기간</span>{{this.$store.state.Course.courseBookInfo.view.course_target_date}}일</span>
                </span>
              </span>
            </div>
          </div>
          <div class="cbook_buy">
            <ul>
              <li class="dis">
                <span class="td1"><span class="text">내 보유트랭</span></span>
                <span class="td2"><span class="trang text_org">{{this.$addComma(this.$store.getters['Member/getTrang'])}}</span></span>
                <a href="#" class="btn_charge" @click.prevent="isRequiredCharge = true">충전</a>
              </li>
              <li class="line">
                <span class="td1"><span class="text">총 구매금액</span></span>
                <span class="td2"><span class="trang">{{this.$addComma(this.$store.state.Course.courseBookInfo.view.course_price)}}</span></span>
              </li>
            </ul>
          </div>
          <div class="btn_area">
            <button type="button" @click="this.$store.commit('Course/controlCourseBookBuyPopup')">취소</button>
            <button type="button" class="on" @click="buyCourseBook">구매하기</button>
          </div>
        </div>
      </div>
      <div class="popup_box popup_box_m" v-if="isRequiredCharge">
        <div class="popup_top line_bottom"><h2>트랭 충전</h2>
        </div>
        <div class="popup_body">
          <p class="t_center">트랭은 아이템 구매 및 클럽 개설, 트랭글톡, 코스북 구매등<br>
          트랭글 사용시에 이용되는 <span class="text_org">트랭글 머니</span>입니다.
          </p>

          <div class="cbook_buy">
            <ul>
              <li :class="{'on':trangSelect == 100}" @click="trangSelect = 100, paySelect = 1200">
                <span class="trang">100</span><span class="won">1,200 원</span>
              </li>
              <li :class="{'on':trangSelect == 300}" @click="trangSelect = 300, paySelect = 3900">
                <span class="trang">300</span><span class="won">3,900 원</span>
              </li>
              <li :class="{'on':trangSelect == 500}" @click="trangSelect = 500, paySelect = 5900">
                <span class="trang">500</span><span class="won">5,900 원</span>
              </li>
              <li :class="{'on':trangSelect == 1000}" @click="trangSelect = 1000, paySelect = 12000">
                <span class="trang">1,000</span><span class="won">12,000 원</span>
              </li>
              <li :class="{'on':trangSelect == 3000}" @click="trangSelect = 3000, paySelect = 37000">
                <span class="trang">3,000</span><span class="won">37,000 원</span>
              </li>
              <li :class="{'on':trangSelect == 5000}" @click="trangSelect = 5000, paySelect = 65000">
                <span class="trang">5,000</span><span class="won">65,000 원</span>
              </li>
            </ul>
          </div>
          {{trangErrorMessege}}
          <div class="btn_area">
            <button type="button" @click="isRequiredCharge = false">취소</button>
            <button type="button" class="on" @click="TrangOrderNo (trangSelect, paySelect)">구매하기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  data () {
    return {
      isRequiredCharge: false,
      trangSelect: '',
      paySelect: 0,
      trangErrorMessege: ''
    }
  },
  name: 'CourseBookBuyPopup',
  mounted () {
  },
  methods: {
    TrangOrderNo (code, pay) {
      var orderCode = ''
      this.axios.get('/Trang/order_no.json', {
        params: {
          token: this.$store.state.Member.token,
          app_store_id: 'googleplaystore',
          app_product_code: 'tranggle_trang_' + code
        }
      })
        .then(data => {
          if (data.data.response.code === '00') {
            orderCode = data.data.response.content.order_code
            this.trangPayment(code, pay, orderCode)
          } else {
            this.trangErrorMessege = data.data.response.message
          }
        })
    },
    trangPayment (code, pay, orderCode) {
      var tempCode = 'GPA.' + dayjs().format('YYYY-mmmm-DDDD-HHmmss')
      this.axios.get('/Trang/payment.json', {
        params: {
          token: this.$store.state.Member.token,
          app_store_id: 'googleplaystore',
          app_product_code: 'tranggle_trang_' + code,
          order_code: orderCode,
          payment_price: pay,
          payment_price_type: 'won',
          payment_date: dayjs().format('YYYY-MM-DD HH:mm:ss'), // 2021-03-09 14:22:56
          order_appr_code: tempCode // 발급받는 코드를 일단 임의로 작성 차후 결제모듈이랑 연계 필요
        }
      })
        .then(data => {
          if (data.data.response.code === '00') {
            this.$showMessagePopup('트랭 충전', '충전이 완료되었습니다.')
            this.$store.commit('Member/setTrangInfo', data.data.response.content)
            this.isRequiredCharge = false
          } else {
            this.$showMessagePopup('트랭 충전', data.data.response.message)
          }
        })
    },
    buyCourseBook () {
      const myTrang = this.$store.getters['Member/getTrang']
      const price = this.$store.state.Course.courseBookInfo.view.course_price

      if (myTrang < price) {
        this.$showMessagePopup('코스북 구매', '보유환 트랭이 상품 가격보다 적습니다<br/>충전 후 구매해주세요.')
      } else {
        this.$showConfirmPopup('코스북 구매', '구매하시겠습니까?', () => {
          const productCode = this.$store.state.Course.courseBookInfo.view.course_product_no

          const formData = new FormData()
          formData.append('token', this.$store.state.Member.token)
          formData.append('app_store_id', 'googleplaystore')
          formData.append('product_code', productCode)
          formData.append('buy_code', productCode + dayjs().format('YYYY-mmmm-DDDD-HHmmss'))
          this.axios.post('/product/buy_info_v2.json', formData)
            .then(data => {
              if (data.data.response.code === '00') {
                this.$store.commit('Member/setTrangInfo', data.data.response.content)
                if (this.$store.state.Course.courseBookInfo.view.user_course_no != null) {
                  this.$showCallBackMessagePopup('코스북 구매', '구매가 완료되었습니다.', () => {
                    formData.append('user_course_no', this.$store.state.Course.courseBookInfo.view.user_course_no)
                    formData.append('is_buy', 'Y')
                    this.axios.post('/coursebook/set_user_course_isBuy.json', formData)
                      .then(data2 => {
                        window.location.reload()
                      })
                  })
                } else {
                  formData.append('course_no', this.$store.state.Course.courseBookInfo.view.course_no)
                  formData.append('status', 'CHALLENGE')
                  formData.append('is_buy', 'Y')

                  this.axios.post('/coursebook/challengeToCouse.json', formData)
                    .then(data => {
                      if (data.data.response.content.user_course_no != null) {
                        this.$showMessagePopup('코스북 구매', '구매가 완료되었습니다.', '/course/book/user/' + data.data.response.content.user_course_no)
                      }
                    })
                }
              } else {
                this.$showMessagePopup('코스북 구매', data.data.response.message)
              }
            })
        })
      }
    },
    replaceByDefault (e) {
      // 코스북 이미지 없을 시 기본 이미지
      e.target.src = require('@/assets/images/sample/photo.png')
    }
  }
}
</script>

<style>

</style>
