// import router from '../../router'
import axios from '../../util/axios.js'
import dayjs from 'dayjs'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    page: 'misson',
    LargeCategory: '',
    MiddleCategory: '',
    searchSdate: '',
    searchEdate: '',
    searchScnt: 0,
    searchEcnt: 0,

    pageRow: 1,
    row: 50,
    sortOrder: '',
    sort: 'asc',

    totalCount: 0,

    // popup
    MissionListPopup: false,
    isThemaRecommendedCoursePopup: false,

    // data
    missionlist: [],
    courseList: [],
    themaList: [],
    searchList: [],
    themacourse: [],
    orderChangedata: {},
    sido: '서울특별시',
    sigungu: '',
    url: '',

    // 코스 목록 조회시 사용
    category: '',
    region1: '',
    region2: '',
    distance1: '',
    distance2: '',
    pass_time1: '',
    pass_time2: '',
    reg_date1: '',
    reg_date2: '',
    badge_count1: '',
    badge_count2: '',
    avg_speed1: '',
    avg_speed2: '',
    year1: 0,
    year2: 0,
    themaCategoryNo: '',
    searchCnt: 0,
    courseNo: '',
    courseNm: '',

    // list order
    mvObjectIndex: 0,
    mvChangeIndex: 0,

    // messegePopup
    // 네/아니오 버튼 팝업 표시 여부
    isOpenConfirmPopup: false,
    // 메시지 버튼 팝업 표시 여부
    isOpenMessagePopup: false,
    // 콜백 메소드 메시지 버튼 팝업 표시 여부
    isOpenCallBackMessagePopup: false,
    // 팝업 헤더 메시지
    popupHeaderMessage: '',
    // 팝업 바디 메시지
    popupBodyMessage: '',
    // 링크
    link: null,
    // 네 버튼 클릭시 실행할 메소드
    callbackMethod: null
  },
  mutations: {
    setMissionList (state, data) {
      if (data.data.response.code === '00') {
        state.missionlist = data.data.response.content.list
        state.totalCount = data.data.response.content.total_count
        console.log(state.totalCount)
        console.log(state.missionlist)
      } else {
        state.missionlist = []
        state.totalCount = 0
      }
    },
    setCourseList (state, data) {
      if (data.data.response.code === '00') {
        state.courseList = data.data.response.content.list
        state.totalCount = data.data.response.content.total_count
        console.log(state.courseList)
      } else {
        state.courseList = []
        state.totalCount = 0
      }
    },
    setThemaList (state, data) {
      if (data.data.response.code === '00') {
        state.themaList = data.data.response.content.list
        state.totalCount = data.data.response.content.total_count
        console.log(state.themaList)
      } else {
        state.themaList = []
        state.totalCount = 0
      }
    },
    setSearchList (state, data) {
      if (data.data.response.code === '00') {
        state.searchList = []
        state.searchCnt = 0
        state.searchList = data.data.response.content.list
        state.searchCnt = data.data.response.content.total_count
        for (const i in state.searchList) {
          if (state.searchList[i].is_in_theme === 'Y') {
            state.searchList[i].selected = true
          } else {
            state.searchList[i].selected = false
          }
        }
        console.log(state.searchList)
      } else {
        state.searchList = []
        state.searchCnt = 0
      }
    },
    clearData (state) {
      state.LargeCategory = ''
      state.MiddleCategory = ''
      state.searchSdate = ''
      state.searchEdate = ''
      state.searchScnt = 0
      state.searchEcnt = 0

      state.pageRow = 1
      state.row = 50
      state.sortOrder = ''
      state.sort = 'asc'
      state.sido = '서울특별시'
      state.sigungu = ''
      state.url = ''

      state.totalCount = 0

      state.mvObjectIndex = 0
      state.mvChangeIndex = 0

      state.missionlist = []
      state.orderChangedata = {}
      state.themaCategoryNo = ''
    },
    // 네/아니오 팝업 세팅
    setConfirmPopup (state, data) {
      console.log(data)
      state.popupHeaderMessage = data.popupHeaderMessage
      state.popupBodyMessage = data.popupBodyMessage
      state.callbackMethod = data.callbackMethod
      state.callbackCancelMethod = data.callbackCancelMethod
      console.log(state.popupHeaderMessage)
      console.log(state.popupBodyMessage)
      console.log(state.callbackMethod)
      console.log(state.callbackCancelMethod)
    },
    // 메시지 팝업 세팅
    setMessagePopup (state, data) {
      state.popupHeaderMessage = data.popupHeaderMessage
      state.popupBodyMessage = data.popupBodyMessage
      state.link = data.link
    },
    // 메시지 팝업 세팅
    setCallBackMessagePopup (state, data) {
      state.popupHeaderMessage = data.popupHeaderMessage
      state.popupBodyMessage = data.popupBodyMessage
      state.callbackMethod = data.callbackMethod
    },
    // 네/아니오 버튼 팝업 제어
    controlConfirmPopup (state) {
      console.log(state.isOpenConfirmPopup)
      state.isOpenConfirmPopup = !state.isOpenConfirmPopup
    },
    // 메시지 버튼 팝업 제어
    controlMessagePopup (state) {
      state.isOpenMessagePopup = !state.isOpenMessagePopup
      if (state.link != null && !state.isOpenMessagePopup) { // 팝업 확인 버튼 클릭 및 링크 파라미터 있을 경우
        if (state.link.includes('http')) {
          window.location.href = state.link
        } else {
          router.replace(state.link)
        }
      }
    }

  },
  actions: {
    clear ({ state, commit }) {
      commit('clearData')
    },
    getThemeList ({ state, commit }) {
      var var1 = ''
      var var2 = ''
      if (state.LargeCategory === 'reg_date') {
        if (state.searchSdate !== null)var1 = dayjs(state.searchSdate).format('YYYY-MM-DD HH:mm:ss')
        if (state.searchEdate !== null)var2 = dayjs(state.searchEdate).format('YYYY-MM-DD HH:mm:ss')
      }
      if (state.LargeCategory === 'thema_category_order' || state.LargeCategory === 'thema_category_name') {
        var1 = state.MiddleCategory
      }
      if (state.LargeCategory === 'theme_count') {
        var1 = state.searchScnt
        var2 = state.searchEcnt
      }
      axios.get('/admin/Course/getThemeList.json', {
        params: {
          search_type: '',
          search_val1: var1,
          search_val2: var2,
          order: state.sortOrder,
          sort: 'desc',
          page: state.pageRow,
          rows: state.row
        }
      })
        .then(data => {
          commit('setThemaList', data)
          // this.commit('setTrackCategoryCustom', data)
        })
    },
    getMissionList ({ state, commit }) {
      var var1 = ''
      var var2 = ''
      if (state.LargeCategory === 'miss_sdate' || state.LargeCategory === 'miss_edate' || state.LargeCategory === 'miss_reg_date') {
        if (state.searchSdate !== null)var1 = dayjs(state.searchSdate).format('YYYY-MM-DD HH:mm:ss')
        if (state.searchEdate !== null)var2 = dayjs(state.searchEdate).format('YYYY-MM-DD HH:mm:ss')
      }
      if (state.LargeCategory === 'miss_title' || state.LargeCategory === 'member_id' || state.LargeCategory === 'miss_grade' || state.LargeCategory === 'miss_status' || state.LargeCategory === '') {
        var1 = state.MiddleCategory
      }
      if (state.LargeCategory === 'miss_auth_person' || state.LargeCategory === 'category_count') {
        var1 = state.searchScnt
        var2 = state.searchEcnt
      }
      axios.get('/admin/Mission/getMissionList.json', {
        params: {
          search_type: state.LargeCategory,
          search_val1: var1,
          search_val2: var2,
          order: state.sortOrder,
          sort: state.sort,
          page: state.pageRow,
          rows: state.row
        }
      })
        .then(data => {
          commit('setMissionList', data)
          // this.commit('setTrackCategoryCustom', data)
        })
    },
    getMainMissionList ({ state, commit }) {
      console.log(state.LargeCategory)
      var var1 = ''
      var var2 = ''
      if (state.LargeCategory === 'miss_sdate' || state.LargeCategory === 'miss_edate' || state.LargeCategory === 'miss_reg_date') {
        console.log(state.searchEdate)
        if (state.searchSdate !== null)var1 = dayjs(state.searchSdate).format('YYYY-MM-DD HH:mm:ss')
        if (state.searchEdate !== null)var2 = dayjs(state.searchEdate).format('YYYY-MM-DD HH:mm:ss')
      }
      if (state.LargeCategory === 'miss_title' || state.LargeCategory === 'member_id' || state.LargeCategory === 'miss_grade' || state.LargeCategory === 'miss_status' || state.LargeCategory === '') {
        var1 = state.MiddleCategory
      }
      if (state.LargeCategory === 'miss_auth_person' || state.LargeCategory === 'category_count') {
        var1 = state.searchScnt
        var2 = state.searchEcnt
      }
      console.log(var2)
      axios.get('/admin/Mission/getMainMissionList.json', {
        params: {
          search_type: state.LargeCategory,
          search_val1: var1,
          search_val2: var2,
          order: state.sortOrder,
          sort: 'desc',
          page: state.pageRow,
          rows: state.row
        }
      })
        .then(data => {
          commit('setMissionList', data)
          // this.commit('setTrackCategoryCustom', data)
        })
    },
    getCoursebookList ({ state, commit }) {
      var var1 = ''
      var var2 = ''
      if (state.LargeCategory === 'course_reg_date') {
        if (state.searchSdate !== null)var1 = dayjs(state.searchSdate).format('YYYY-MM-DD HH:mm:ss')
        if (state.searchEdate !== null)var2 = dayjs(state.searchEdate).format('YYYY-MM-DD HH:mm:ss')
      }
      if (state.LargeCategory === 'course_name' || state.LargeCategory === 'course_no' || state.LargeCategory === 'course_category_e_name' || state.LargeCategory === '') {
        var1 = state.MiddleCategory
      }
      if (state.LargeCategory === 'course_track_distance' || state.LargeCategory === 'course_track_accum_elevate' || state.LargeCategory === 'course_target_date' || state.LargeCategory === 'course_badge_count' || state.LargeCategory === 'course_price') {
        var1 = state.searchScnt
        var2 = state.searchEcnt
      }
      if (state.LargeCategory === 'course_text_indexer') {
        if (state.sigungu === '') {
          var1 = state.sido
        } else {
          var1 = state.sido + ' ' + state.sigungu
        }
      }
      axios.get('/admin/Coursebook/getCoursebookList.json', {
        params: {
          search_type: state.LargeCategory,
          search_val1: var1,
          search_val2: var2,
          order: state.sortOrder,
          sort: state.sort,
          page: state.pageRow,
          rows: state.row
        }
      })
        .then(data => {
          commit('setCourseList', data)
          // this.commit('setTrackCategoryCustom', data)
        })
    },
    getRecommendCoursebookList ({ state, commit }) {
      var var1 = ''
      var var2 = ''
      if (state.LargeCategory === 'course_reg_date') {
        if (state.searchSdate !== null)var1 = dayjs(state.searchSdate).format('YYYY-MM-DD HH:mm:ss')
        if (state.searchEdate !== null)var2 = dayjs(state.searchEdate).format('YYYY-MM-DD HH:mm:ss')
      }
      if (state.LargeCategory === 'course_name' || state.LargeCategory === 'course_no' || state.LargeCategory === 'course_category_e_name' || state.LargeCategory === '') {
        var1 = state.MiddleCategory
      }
      if (state.LargeCategory === 'course_track_distance' || state.LargeCategory === 'course_track_accum_elevate' || state.LargeCategory === 'course_target_date' || state.LargeCategory === 'course_badge_count' || state.LargeCategory === 'course_price') {
        var1 = state.searchScnt
        var2 = state.searchEcnt
      }
      if (state.LargeCategory === 'course_text_indexer') {
        if (state.sigungu === '') {
          var1 = state.sido
        } else {
          var1 = state.sido + ' ' + state.sigungu
        }
      }
      axios.get('/admin/Coursebook/getRecommendCoursebookList.json', {
        params: {
          search_type: state.LargeCategory,
          search_val1: var1,
          search_val2: var2,
          order: state.sortOrder,
          sort: 'desc',
          page: state.pageRow,
          rows: state.row
        }
      })
        .then(data => {
          commit('setCourseList', data)
          // this.commit('setTrackCategoryCustom', data)
        })
    },
    getCourseList ({ state, commit }) { // 코스목록조회
      var distance = ''
      var passTime = ''
      var regDate = ''
      var badgeCount = ''
      var avgSpeed = ''
      if (state.distance1 === '' && state.distance2 !== '') {
        distance = '0|' + state.distance2
      } else if (state.distance1 !== '' && state.distance2 === '') {
        distance = state.distance1
      } else if (state.distance1 !== '' && state.distance2 !== '') {
        distance = state.distance1 + '|' + state.distance2
      }

      if (state.pass_time1 === '' && state.pass_time2 !== '') {
        passTime = '0|' + state.pass_time2
      } else if (state.pass_time1 !== '' && state.pass_time2 === '') {
        passTime = state.pass_time1
      } else if (state.pass_time1 !== '' && state.pass_time2 !== '') {
        passTime = state.pass_time1 + '|' + state.pass_time2
      }

      if (state.reg_date1 === '' && state.reg_date2 !== '') {
        regDate = '0|' + state.reg_date2
      } else if (state.reg_date1 !== '' && state.reg_date2 === '') {
        regDate = state.reg_date1
      } else if (state.reg_date1 !== '' && state.reg_date2 !== '') {
        regDate = state.reg_date1 + '|' + state.reg_date2
      }

      if (state.reg_date1 === '' && state.reg_date2 !== '') {
        badgeCount = '0|' + state.reg_date2
      } else if (state.reg_date1 !== '' && state.reg_date2 === '') {
        badgeCount = state.reg_date1
      } else if (state.reg_date1 !== '' && state.reg_date2 !== '') {
        badgeCount = state.reg_date1 + '|' + state.reg_date2
      }

      if (state.avg_speed1 === '' && state.avg_speed2 !== '') {
        avgSpeed = '0|' + state.avg_speed2
      } else if (state.avg_speed1 !== '' && state.avg_speed2 === '') {
        avgSpeed = state.avg_speed1
      } else if (state.avg_speed1 !== '' && state.avg_speed2 !== '') {
        avgSpeed = state.avg_speed1 + '|' + state.avg_speed2
      }

      axios.get('/admin/Course/getCourseList.json', {
        params: {
          track_year: state.year2,
          thema_category_no: state.themaCategoryNo,
          search_type: 'conditional',
          log_no: state.courseNo,
          log_track_name: state.courseNm,
          category: state.category,
          region: state.region1 + ' ' + state.region2,
          distance: distance,
          pass_time: passTime,
          avg_speed: avgSpeed,
          badge_count: badgeCount,
          reg_date: regDate,
          order: state.sortOrder,
          sort: state.sort,
          page: state.pageRow,
          rows: state.row
        }
      })
        .then(data => {
          commit('setSearchList', data)
          // this.commit('setTrackCategoryCustom', data)
        })
    }

  },
  modules: {
  },
  getters: {

  }
}
