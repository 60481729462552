<template>
  <div class="right_area">
    <div class="right_header">
      <div class="main_top">
        <div class="title_top">
          <span class="area_center">회원 정보</span>
          <!-- <span class="area_left"><router-link to="/mytranggle/myinfo" class="btn_back"></router-link></span> -->
        </div>
        <div class="tab_menu">
          <ul>
            <li
              v-for="(list, idx) in setTabMenu"
              :key="idx"
              :class="list.active ? 'on' : 'off'"
              @click="setPage(idx)"
            >
              <a href="#">{{ list.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 회원정보 -->
    <Info v-if="tabType === 'info'" @changeInfo="detectChange" />
    <!-- 공개 설정 -->
    <Open v-if="tabType === 'open'" />
    <!-- 운동 설정 -->
    <Excercise v-if="tabType === 'excercise'" />
    <!-- 배지 보기 -->
    <Badge v-if="tabType === 'badge'" />
    <!-- 음성 안내 -->
    <Audio v-if="tabType === 'audio'" />
    <!-- 일반 알림 -->
    <Alarm v-if="tabType === 'alarm'" />
    <!-- 클럽 알림 -->
    <ClubAlarm v-if="tabType === 'clubAlarm'" />
    <!-- 계정 -->
    <Account v-if="tabType === 'account'" />
    <!-- 차단 회원 관리 -->
    <Block v-if="tabType === 'block'" />
  </div>
</template>

<script>
// import { mapMutations } from 'vuex'
import Account from "@/components/mytranggle/mytrangconf/Account.vue";
import Excercise from "@/components/mytranggle/mytrangconf/Excercise.vue";
import Alarm from "@/components/mytranggle/mytrangconf/Alarm.vue";
import Audio from "@/components/mytranggle/mytrangconf/Audio.vue";
import Badge from "@/components/mytranggle/mytrangconf/Badge.vue";
import Block from "@/components/mytranggle/mytrangconf/Block.vue";
import ClubAlarm from "@/components/mytranggle/mytrangconf/ClubAlarm.vue";
import Open from "@/components/mytranggle/mytrangconf/Open.vue";
import Info from "@/components/mytranggle/mytrangconf/Info.vue";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      setTabMenu: [
        {
          name: "회원정보",
          value: "info",
          active: true,
        },
        // {
        //   name: '공개 설정',
        //   value: 'open',
        //   active: false
        // },
        // {
        //   name: '차단 회원 관리',
        //   value: 'block',
        //   active: false
        // },
        // // {
        // //   name: '운동 설정',
        // //   value: 'excercise',
        // //   active: false
        // // },
        // {
        //   name: '배지 보기',
        //   value: 'badge',
        //   active: false
        // },
        // // {
        // //   name: '음성 안내',
        // //   value: 'audio',
        // //   active: false
        // // },
        // {
        //   name: '일반 알림',
        //   value: 'alarm',
        //   active: false
        // },
        // {
        //   name: '클럽 알림',
        //   value: 'clubAlarm',
        //   active: false
        // },
        {
          name: "계정",
          value: "account",
          active: false,
        },
      ],
      tabType: "info",
      // 계정
      memberNo: "",
      changeInfoChk: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.changeInfoChk) {
      this.$showConfirmPopup(
        "설정취소",
        "현재 변경된 사항을 취소하고 페이지를 이동하시겠습니까?",
        next
      );
    } else {
      return next();
    }
  },
  components: {
    Account,
    Excercise,
    Alarm,
    Audio,
    Badge,
    Block,
    ClubAlarm,
    Open,
    Info,
  },
  created() {
    // 앱에서 접속하면 계정탭으로
    if (this.$store.state.UserSet.from === "appstore") {
      this.setPage(1);
      this.$store.state.UserSet.from = "";
    }
  },
  methods: {
    ...mapMutations("MyTranggle", [
      "confChgIt",
      "confMemberWith",
      "interestExercise",
      "addrSearchView",
      "mapSearchView",
    ]),
    detectChange(val) {
      this.changeInfoChk = val;
    },
    // memberConfigDataUpt () {
    //   // console.log(this.revelation)
    //   const formData = new FormData()
    //   formData.append('token', this.$store.state.Member.token)
    //   formData.append('record_open', this.revelation)
    //   formData.append('message_receive_type', this.messageSend)
    //   formData.append('friends_receive_type', this.friendRequest)
    //   formData.append('config_cert_celluar', this.configCertCelluar)
    //   formData.append('config_climb_down_time', this.configClimbDownTime)
    //   formData.append('config_deviate_distnace', this.configDeviateDistnace)
    //   formData.append('config_device_sleep_mode', this.configDeviceSleepMode)
    //   formData.append('config_friends_receive_type', this.configFriendsReceiveType)
    //   formData.append('config_heartbeat_open_type', this.configHeartbeatOpenType)
    //   formData.append('config_is_ad_notice', this.configIsAdNotice)
    //   formData.append('config_is_address_open', this.configIsAddressOpen)
    //   formData.append('config_is_app_update_notice', this.configIsAppUpdateNotice)
    //   formData.append('config_is_badge_guide', this.configIsBadgeGuide)
    //   formData.append('config_is_badge_popup_notice', this.configIsBadgePopupNotice)
    //   formData.append('config_is_bicycle_cert_badge_see', this.configIsBicycleCertBadgeSee)
    //   formData.append('config_is_break_mode_auto_use', this.configIsBreakModeAutoUse)
    //   formData.append('config_is_club_join_appr_notice', this.configIsClubJoinApprNotice)
    //   formData.append('config_is_club_open_notice', this.configIsClubOpenNotice)
    //   formData.append('config_is_club_post_comment_notice', this.configIsClubPostCommentNotice)
    //   formData.append('config_is_club_post_notice', this.configIsClubPostNotice)
    //   formData.append('config_is_club_promotion_notice', this.configIsClubPromotionNotice)
    //   formData.append('config_is_club_public_notice', this.configIsClubPublicNotice)
    //   formData.append('config_is_comment_notice', this.configIsCommentNotice)
    //   formData.append('config_is_coursebook_regist_notice', this.configisCoursebookRegistNotice)
    //   formData.append('config_is_crossroad_guide', this.configIsCrossroadGuide)
    //   formData.append('config_is_deviate_guide', this.configIsDeviateGuide)
    //   formData.append('config_is_event_badge_see', this.configIsEventBadgeSee)
    //   formData.append('config_is_event_notice', this.configIsEventNotice)
    //   formData.append('config_is_friend_invite_notice', this.configIsFriendInviteNotice)
    //   formData.append('config_is_friend_record_notice', this.configIsFriendRecordNotice)
    //   formData.append('config_is_gps_condition_guide', this.configIsGpsConditionGuide)
    //   formData.append('config_is_limit_speed_guide', this.configIsLimitSpeedGuide)
    //   formData.append('config_is_message_notice', this.configIsMessageNotice)
    //   formData.append('config_is_mountaintop_badge_see', this.configIsMountaintopBadgeSee)
    //   formData.append('config_is_name_open', this.configIsNameOpen)
    //   formData.append('config_is_pace_counters_use', this.configIsPaceCountersUse)
    //   formData.append('config_is_park_badge_see', this.configIsParkBadgeSee)
    //   formData.append('config_is_phone_open', this.configIsPhoneOpen)
    //   formData.append('config_is_photo_open', this.configIsPhotoOpen)
    //   formData.append('config_is_position_open', this.configIsPositionOpen)
    //   formData.append('config_is_public_notice', this.configIsPublicNotice)
    //   formData.append('config_is_record_open', this.configIsRecordOpen)
    //   formData.append('config_is_start_end_guide', this.configIsStartEndGuide)
    //   formData.append('config_is_traggle_start_guide', this.configIsTraggleStartGuide)
    //   formData.append('config_member_id', this.configMemberId)
    //   formData.append('config_member_no', this.configMemberNo)
    //   formData.append('config_message_receive_type', this.configMessageReceiveType)
    //   formData.append('config_no', this.configNo)
    //   formData.append('config_position_check_term', this.configPositionCheckTerm)
    //   formData.append('config_prefer_category', this.configPreferCategory)
    //   formData.append('config_profile_open_type', this.configProfileOpenType)
    //   formData.append('config_target_auto_break', this.configTargetAutoBreak)
    //   formData.append('config_target_distance', this.configTargetDistance)
    //   formData.append('config_target_kcal', this.configTargetKcal)
    //   formData.append('config_target_time', this.configTargetTime)
    //   formData.append('config_target_walk', this.configTargetWalk)
    //   formData.append('config_exercise_type', this.configExerciseType)
    //   formData.append('config_exercise_guide_distnace', this.configExerciseGuideDistnace)
    //   formData.append('config_exercise_guide_time', this.configExerciseGuideTime)

    //   this.axios.post('/Member/member_config_data_upt.json', formData)
    //     .then(data => {
    //       // console.log(data.data.response.message)
    //       // this.$showMessagePopup('', data.data.response.message)
    //     })
    // },
    memberConfigData() {
      // console.log(this.$store.state.token)
      this.axios
        .get("/Member/member_config_data.json", {
          params: {
            // 임시
            token: this.$store.state.Member.token,
            // token: '69202CC15E60313F5ED6BC8870C139DE6089135888506F2BFFECC13D24001D9213EDC2AA63FA7401'
          },
        })
        .then((data) => {
          // console.log(data.data.response.content)
          this.configCertCelluar =
            data.data.response.content.config_cert_celluar;
          this.configClimbDownTime =
            data.data.response.content.config_climb_down_time;
          this.configDeviateDistnace =
            data.data.response.content.config_deviate_distnace;
          this.configDeviceSleepMode =
            data.data.response.content.config_device_sleep_mode;
          this.configFriendsReceiveType =
            data.data.response.content.config_friends_receive_type;
          this.configHeartbeatOpenType =
            data.data.response.content.config_heartbeat_open_type;
          this.configIsAdNotice =
            data.data.response.content.config_is_ad_notice;
          this.configIsAddressOpen =
            data.data.response.content.config_is_address_open;
          this.configIsAppUpdateNotice =
            data.data.response.content.config_is_app_update_notice;
          this.configIsBadgeGuide =
            data.data.response.content.config_is_badge_guide;
          this.configIsBadgePopupNotice =
            data.data.response.content.config_is_badge_popup_notice;
          this.configIsBicycleCertBadgeSee =
            data.data.response.content.config_is_bicycle_cert_badge_see;
          this.configIsBreakModeAutoUse =
            data.data.response.content.config_is_break_mode_auto_use;
          this.configIsClubJoinApprNotice =
            data.data.response.content.config_is_club_join_appr_notice;
          this.configIsClubOpenNotice =
            data.data.response.content.config_is_club_open_notice;
          this.configIsClubPostCommentNotice =
            data.data.response.content.config_is_club_post_comment_notice;
          this.configIsClubPostNotice =
            data.data.response.content.config_is_club_post_notice;
          this.configIsClubPromotionNotice =
            data.data.response.content.config_is_club_promotion_notice;
          this.configIsClubPublicNotice =
            data.data.response.content.config_is_club_public_notice;
          this.configIsCommentNotice =
            data.data.response.content.config_is_comment_notice;
          this.configisCoursebookRegistNotice =
            data.data.response.content.config_is_coursebook_regist_notice;
          this.configIsCrossroadGuide =
            data.data.response.content.config_is_crossroad_guide;
          this.configIsDeviateGuide =
            data.data.response.content.config_is_deviate_guide;
          this.configIsEventBadgeSee =
            data.data.response.content.config_is_event_badge_see;
          this.configIsEventNotice =
            data.data.response.content.config_is_event_notice;
          this.configIsFriendInviteNotice =
            data.data.response.content.config_is_friend_invite_notice;
          this.configIsFriendRecordNotice =
            data.data.response.content.config_is_friend_record_notice;
          this.configIsGpsConditionGuide =
            data.data.response.content.config_is_gps_condition_guide;
          this.configIsLimitSpeedGuide =
            data.data.response.content.config_is_limit_speed_guide;
          this.configIsMessageNotice =
            data.data.response.content.config_is_message_notice;
          this.configIsMountaintopBadgeSee =
            data.data.response.content.config_is_mountaintop_badge_see;
          this.configIsNameOpen =
            data.data.response.content.config_is_name_open;
          this.configIsPaceCountersUse =
            data.data.response.content.config_is_pace_counters_use;
          this.configIsParkBadgeSee =
            data.data.response.content.config_is_park_badge_see;
          this.configIsPhoneOpen =
            data.data.response.content.config_is_phone_open;
          this.configIsPhotoOpen =
            data.data.response.content.config_is_photo_open;
          this.configIsPositionOpen =
            data.data.response.content.config_is_position_open;
          this.configIsPublicNotice =
            data.data.response.content.config_is_public_notice;
          this.configIsRecordOpen =
            data.data.response.content.config_is_record_open;
          this.configIsStartEndGuide =
            data.data.response.content.config_is_start_end_guide;
          this.configIsTraggleStartGuide =
            data.data.response.content.config_is_traggle_start_guide;
          this.configMemberId = data.data.response.content.config_member_id;
          this.configMemberNo = data.data.response.content.config_member_no;
          this.configMessageReceiveType =
            data.data.response.content.config_message_receive_type;
          this.configNo = data.data.response.content.config_no;
          this.configPositionCheckTerm =
            data.data.response.content.config_position_check_term;
          this.configPreferCategory =
            data.data.response.content.config_prefer_category;
          this.configProfileOpenType =
            data.data.response.content.config_profile_open_type;
          this.configTargetAutoBreak =
            data.data.response.content.config_target_auto_break;
          this.configTargetDistance =
            data.data.response.content.config_target_distance;
          this.configTargetKcal = data.data.response.content.config_target_kcal;
          this.configTargetTime = data.data.response.content.config_target_time;
          this.configTargetWalk = data.data.response.content.config_target_walk;
          this.configExerciseType =
            data.data.response.content.config_exercise_type;
          this.configExerciseGuideDistnace =
            data.data.response.content.config_exercise_guide_distnace;
          this.configExerciseGuideTime =
            data.data.response.content.config_exercise_guide_time;
          if (this.configClimbDownTime === "0") {
            this.configClimbDownTimeChk = "FALSE";
          } else {
            this.configClimbDownTimeChk = "TRUE";
          }
        });
    },
    // configIsPaceCountersUseChange (bool) {
    //   // this.configIsPaceCountersUse = bool
    //   // console.log(bool)
    //   if (bool === '') {
    //     this.configIsPaceCountersUse = 'ON'
    //   } else {
    //     this.configIsPaceCountersUse = bool
    //   }
    //   // this.configIsPaceCountersUse = bool
    //   this.memberConfigDataUpt()
    // },
    setPage(idx) {
      if (this.changeInfoChk && this.tabType === "info") {
        this.$showConfirmPopup(
          "설정취소",
          "현재 변경된 사항을 취소하고 페이지를 이동하시겠습니까?",
          () => {
            this.changeInfoChk = false;
            this.setTabMenu.forEach((el) => {
              el.active = false;
            });
            this.setTabMenu[idx].active = true;
            this.tabType = this.setTabMenu.filter(
              (el, idx) => el.active === true
            )[0].value;
          }
        );
      } else {
        this.changeInfoChk = false;
        this.setTabMenu.forEach((el) => {
          el.active = false;
        });
        this.setTabMenu[idx].active = true;
        this.tabType = this.setTabMenu.filter(
          (el, idx) => el.active === true
        )[0].value;
      }
    },
  },
  async mounted() {
    // if (this.$store.state.UserSet.isLogin !== "Y") {
    //   this.$router.replace("/login");
    // }
    window.addEventListener("beforeunload", this.unLoadEvent);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.unLoadEvent);
  },
  // watch: {
  //   userStature (val) {
  //     const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/

  //     // 한글, 영문 체크
  //     // if (reg.exec(val) !== null) this.userStature = val.replace(/[^0-9]/g, '')

  //     // // .... 만 입력하게 될 경우 체크
  //     // if (isNaN(parseFloat(val))) this.userStature = ''
  //     if (reg.exec(val) !== null) {
  //       this.userStature = this.userStature.slice(0, -1)
  //     }
  //     this.userStature = this.userStature.replace(/[^0-9]/g, '')
  //   },
  //   userWeight (val) {
  //     const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/

  //     // 한글, 영문 체크
  //     // if (reg.exec(val) !== null) this.userStature = val.replace(/[^0-9]/g, '')

  //     // // .... 만 입력하게 될 경우 체크
  //     // if (isNaN(parseFloat(val))) this.userStature = ''
  //     if (reg.exec(val) !== null) {
  //       this.userWeight = this.userWeight.slice(0, -1)
  //     }
  //     this.userWeight = this.userWeight.replace(/[^0-9]/g, '')
  //   }
  // }
};
</script>
