<template>
  <div class="">
    <div class="header_wrap">
      <TopMenu currentMenu="course"/>
    </div>
  </div>
  <div class="container">
    <div class="course">
      <div class="course_nav">

        <ul>
            <router-link to="/course/" custom v-slot="{ href, isActive }">
              <li :class="[(checkUrl('') && isActive) && 'on']">
                <a @click="goUrl(href)"><span class="nav01"></span><span class="text">코스 검색</span></a>
              </li>
            </router-link>
            <!-- <router-link to="/course/book"
            custom v-slot="{ href, isActive}">
              <li :class="[isActive && 'on']"><a @click="goUrl(href)"><span class="nav02"></span><span class="text">코스북</span></a></li>
            </router-link> -->
            <!-- <router-link to="/course/theme"
            custom v-slot="{ href, isActive }">
              <li :class="[(checkUrl('theme') || isActive) && 'on']">
                <a @click="goUrl(href)"><span class="nav03"></span><span class="text">테마별<br>코스</span></a>
              </li>
            </router-link> -->
            <router-link to="/course/my" custom v-slot="{ href, isActive }">
              <li :class="[(checkUrl('my') || isActive) && 'on']">
                <a @click="goUrl(href)"><span class="nav04"></span><span class="text">나의 코스</span></a>
              </li>
            </router-link>
            <!-- <router-link to="/course/analyze"
            custom v-slot="{ href, isExactActive }">
              <li :class="[isExactActive && 'on']"><a @click="goUrl(href)"><span class="nav05"></span><span class="text">분석 제안</span></a></li>
            </router-link> -->
            <!-- <router-link to="/course/history"
            custom v-slot="{ href, isExactActive }">
              <li :class="[isExactActive && 'on']"><a @click="goUrl(href)"><span class="nav06"></span><span class="text">코스 비교<br>히스토리</span></a></li>
            </router-link> -->
            <li>
              <a href="#" @click.prevent="gpxPopup"><span class="nav07"></span><span class="text">GPX<br>업로드</span></a>
              <!-- <router-link to="/course/gpx" @click.prevent="this.$store.commit('Course/controlUploadGPXFilePopup')"><span class="nav07"></span><span class="text">GPX<br>업로드</span></router-link> -->
            </li>
        </ul>
        <div v-if="$route.href.indexOf('/course/book') >= 0" class="course_termes">
          <a href="#" @click.prevent="this.$store.commit('Course/controlCourseBookAgreePopup')">코스북<br>이용약관</a>
          <a href="#" @click.prevent="this.$store.commit('Course/controlCourseBookAgreePopup')">코스북<br>이용방법</a>
        </div>
      </div><!--/course_nav-->
      <router-view></router-view>
    </div>
  </div><!--/container-->
  <CoursePopup />
</template>

<script>

import TopMenu from '../components/common/TopMenu.vue'
import CoursePopup from '../components/course/popup/CoursePopup.vue'

export default {
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    goUrl (href) {
      this.$store.state.Course.domestic = true
      this.$store.state.Common.courseMenuType = ''
      // 메뉴 재클릭시 refresh
      if (this.$route.path === href) {
        this.$router.go()
      } else {
        this.$router.push(href)
      }
    },
    checkUrl (menuType) {
      let checkUrl = true
      if (this.$route.path.indexOf('/course/' + this.$route.params.id) > -1) {
        if (this.$store.state.Common.courseMenuType !== menuType) {
          checkUrl = false
        }
      } else {
        if (this.$route.path === '/course/my/gpx' && menuType === 'my') {
          checkUrl = true
        } else if (this.$route.path.indexOf('/course') > -1 && menuType !== '') {
          checkUrl = false
        }
      }
      return checkUrl
    },
    gpxPopup () {
      this.$store.commit('Course/controlUploadGPXFilePopup')
    }
  },
  watch: {
    $route (to, from) {
      if (to.path !== from.path) {
        this.$store.commit('Course/clearSearchOption')
        // this.$store.state.Course.isOpenCompareTrackPopup = false
      }
    }
  },
  mounted () {
    if (this.$store.state.UserSet.isLogin !== 'Y') {
      this.$router.push('/login')
    }
    // console.log(this.$store.state.Common.compareTrack1)
    // console.log(this.$store.state.Common.compareTrack2)
    // console.log(this.$store.state.Common.compareTrack1.no)
    // if (this.$store.state.Common.compareTrack1.no !== null || this.$store.state.Common.compareTrack2.no !== null) {
    //   console.log('in')
    //   this.$store.state.Common.isOpenCompareTrackPopup = true
    //   this.$store.state.Course.isOpenCompareTrackPopup = true
    //   console.log(this.$store.state.Common.isOpenCompareTrackPopup)
    // }
    // this.$store.state.Course.searchOrder = 'recommend'
  },
  components: {
    TopMenu: TopMenu,
    CoursePopup: CoursePopup
  }

}
</script>

<style>

</style>
