<template>
    <div class="popup popup_bg" v-if = "this.$store.state.MyTranggle.popBg">
        <div class="popup_area" v-if = "this.$store.state.MyTranggle.popArea">

            <!-- 설정 화면 팝업 -->
            <div class="popup_box popup_box_lm" v-if = "this.$store.state.MyTranggle.MyTrangConfPopUpStep1">
                <div class="popup_top popup_top_line"><h2>트랭글 통합 계정 회원 탈퇴</h2><a href="#" class="btn_close" @click="confMemberWith"></a>
                </div>
                <div class="popup_body">
                    <div class="popup_terms">
                      회원탈퇴 약관<br><br>

                      트랭글 통합계정 회원탈퇴 전 안내사항을 확인하시고 탈퇴를 진행해 주시기 바랍니다.<br><br>

                      - 회원탈퇴시 주식회사 비글(이하 “회사”라 합니다.)이 제공하는 트랭글 서비스 및 관련 제반 서비스(올댓스탬프, 아이나비트랭글, 스탬프투어 서비스 등. 이하 “서비스”에서 모두 탈퇴하게 됩니다.<br><br>

                      - 회원탈퇴로 삭제된 계정은 복구할 수 없으며 서비스 이용 중 취득한 운동기록, 경험치, 트랭, 배지, 스탬프, 포인트, 선물, 보상 등도 모두 삭제됩니다.
                      단, “회원”이 작성한 “게시물” 중 본인이 탈퇴 전 삭제하지 않은 “게시물”은 타인에 의해 댓글, 다운로드, 담기, 스크랩 등이 되어 재 게시될 수 있으므로 삭제되지 않습니다.<br><br>

                      - 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에 한하여 보유 및 이용됩니다. 따라서 회원탈퇴시 회사가 보유한 개인정보가 삭제되는 것을 원칙으로 합니다.<br><br>
                      단, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률, 신용정보의 이용 및 보호에 관한 법률 등 관계법령에 따라 보존할 필요가 있는 경우 회사는 관계법령에서 정한 기간 동안 회원의 개인정보를 보유하며, 그 기간은 다음과 같습니다.<br><br>
                      <table>
                        <colgroup>
                          <col width="40%" />
                          <col width="40%" />
                          <col width="20%" />
                        </colgroup>
                        <tr>
                          <th>보존 근거</th>
                          <th>보존 대상</th>
                          <th>보존 기간</th>
                        </tr>
                        <tr>
                          <td rowspan="2">통신비밀보호법</td>
                          <td>이동전화번호, 통신사, 휴대폰 단말기 정보</td>
                          <td>12개월</td>
                        </tr>
                        <tr>
                          <td>서비스 이용에 따른 위치정보, 서비스 이용기록, 접속 기록</td>
                          <td>3개월</td>
                        </tr>
                        <tr>
                          <td rowspan="2">전자상거래등에서의 소비자 보호에 관한 법률</td>
                          <td>표시/광고에 관한 기록</td>
                          <td>6개월</td>
                        </tr>
                        <tr>
                          <td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
                          <td>3년</td>
                        </tr>
                        <tr>
                          <td>신용정보의 이용 및 보호에 관한 법률</td>
                          <td>신용정보의 수집/처리 및 이용 등에 관한 기록</td>
                          <td>3년</td>
                        </tr>
                      </table>
                    </div>
                    <br>
                    <p>회원 탈퇴 및 계정 삭제를 진행하시겠습니까?</p>
                    <div class="btn_area">
                        <button type="button" class="btn_m" @click="confMemberWithNext">탈퇴하기</button>
                    </div>
                </div>
            </div>

            <div class="popup_box popup_box_s" v-if = "this.$store.state.MyTranggle.MyTrangConfPopUpStep2">
                <div class="popup_top popup_top_line"><h2>회원 탈퇴</h2>
                </div>
                <div class="popup_body">
                    <p>정말 회원탈퇴를 진행하시겠습니까?<br>
                    삭제된 계정은 복구할 수 없습니다.<br>
                    신중한 결정 부탁드립니다.
                    </p>
                    <div class="btn_area">
                        <button type="button" class="" @click="confClosePopChg">취소</button>
                        <button type="button" class="" @click="withdrawalCheck">확인</button>
                    </div>
                </div>
            </div>

            <div class="popup_box popup_box_s" v-if = "this.$store.state.MyTranggle.MyTrangConfPopUpStep3">
                <div class="popup_top popup_top_line"><h2>설정 취소</h2>
                </div>
                <div class="popup_body">
                    <p>현재 변경된 사항을 취소하고 페이지를 이동하시겠습니까?
                    </p>
                    <div class="btn_area">
                        <button type="button" class="" @click="confClosePopChg">취소</button>
                        <button type="button" class="">확인</button>
                    </div>
                </div>
            </div>

            <!-- <div class="popup_box popup_box_m" v-if = "this.$store.state.MyTranggle.MyTrangConfPopUpStep4">
                <div class="popup_top popup_top_line"><h2>비밀번호 변경</h2><a href="#" class="btn_close" @click="close"></a></div>
                <div class="popup_body">
                    <div class="title_img"><img src="../../assets/images/common/img_password.png" /></div>
                    <p class="t_bold">회원님의 비밀번호를 변경해주세요</p><br>
                    <p>개인정보보호를 위해 6개월 마다 비밀번호 변경을 권장하고 있사오니<br />비밀번호를 변경해 주시기 바랍니다.</p>
                    <br>
                    <div class="login_area">
                        <ul>
                            <li>
                                <span class="set_text">비밀번호</span>
                                <span class="set_input"><input type="password" v-model="oldPass" placeholder="현재 비밀번호를 입력해주세요" /></span>
                            </li>
                            <li>
                                <span class="set_text">새 비밀번호</span>
                                <span class="set_input"><input type="password" v-model="newPass" placeholder="새 비밀번호를 입력해주세요" @blur="checkPassword"/>
                                  <span class="check_ok" v-if="this.validatePassword === 'Y'"></span>
                                  <span class="check_no" v-if="this.validatePassword === 'N'"></span>
                                  <span class="stext text_red" v-if="this.validatePassword === 'N'">8자 이상 영문 대소문자, 숫자, 특수문자 조합</span>
                                </span>
                            </li>
                            <li>
                                <span class="set_text">새 비밀번호 확인</span>
                                <span class="set_input"><input type="password" v-model="reNewPass" placeholder="새 비밀번호를 다시 한번 입력해 주세요" @blur="checkConfirmPassword"/>
                                  <span class="check_ok" v-if="comparePassword && this.reNewPass !== ''"></span>
                                  <span class="check_no" v-if="!comparePassword && this.reNewPass !== ''"></span>
                                  <span class="stext text_red" v-if="!comparePassword && this.reNewPass !== ''">일치하지 않습니다.<br/></span>
                                  <span class="stext text_red" v-if="this.oldPass === this.reNewPass && reNewPass !== ''">현재 비밀번호와 동일합니다</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <br>
                    <span>{{passErrorText}}</span>
                    <br>
                    <p class="t_bold">비밀번호 안전규칙</p>
                    <div class="box_gray mt"><span class="section">· 반드시 8자 이상 영문 대소문자, 숫자, 특수문자 3개의 조합 필수<br>
                    · 특수문자는 ~!@#$%^()_-={[}]’:; >,.?/ 사용가능<br>
                    · 생년월일, 휴대전화와 동일한 번호는 사용 자제</span>
                    </div>
                    <br>
                    <div class="btn_area">
                        <button type="button" class="btn_m on" @click="passChgAPI">변경하기</button>
                        <button type="button" class="btn_m" @click="close">닫기</button>
                    </div>
                </div>
            </div> -->
            <div class="popup_box popup_box_m" v-if = "this.$store.state.MyTranggle.MyTrangConfPopUpStep6">
                <div class="popup_top popup_top_line"><h2>회원탈퇴</h2>
                </div>
                <div class="popup_body">
                    <p>보안을 위해 회원님 정보 및<br>계정의 비밀번호를 확인합니다.
                    </p><br>
                    <div class="login_area">
                        <ul>
                            <li>
                                <span class="set_text">이름</span>
                                <span class="set_input"><input type="text" v-model ="nameChk" /></span>
                            </li>
                            <li>
                                <span class="set_text">휴대전화번호</span>
                                <span class="set_input"><input type="text" v-model="phoneNumChk" /></span>
                            </li>
                            <li>
                                <span class="set_text">비밀번호 입력</span>
                                <span class="set_input"><input type="password" v-model="passChk" /></span>
                            </li>
                        </ul>
                        <span>{{exitsErrorText}}</span>
                    </div><br>
                    <div class="btn_area">
                        <button type="button" class="" @click="close">취소</button>
                        <button type="button" class="" @click ="exitsAPI">확인</button>
                    </div>
                </div>
            </div>
            <!-- <div class="popup_box popup_box_s" v-if = "this.$store.state.MyTranggle.MyTrangConfPopUpStep5">
                <div class="popup_top popup_top_line"><h2>비밀번호 변경 완료</h2><a href="#" class="btn_close" @click="close"></a>
                </div>
                <div class="popup_body">
                    <p>비밀번호가 변경되었습니다.
                    </p>
                </div>
            </div> -->
            <div class="popup_box popup_box_l" v-if = "this.$store.state.MyTranggle.MyTrangBadgePopupStep1">
                <div class="popup_top popup_top_line"><h2>배지 검색 옵션</h2>
                    <span class="top_left"><a href="#" class="btn" @click="badgeSearch">취소</a></span>
                    <span class="top_right">
                        <a href="#" class="btn_refresh">옵션 초기화</a>
                        <a href="#" class="btn_default" @click="badgeSelect">완료</a>
                    </span>
                </div>
                <div class="popup_body">
                    <div class="option_area">
                        <ul>
                            <li>
                                <span class="t1">지역</span>
                                <span class="t2">
                                    <a href="#" class="btn_all">ALL</a>
                                    <span class="t2_inner">
                                        <span class="option_select">지역 선택
                                            <!--<span class="select_city_layer">
                                                <ul class="city">
                                                    <li>전국</li>
                                                    <li class="on">서울</li>
                                                    <li>부산</li>
                                                    <li>대구</li>
                                                    <li>인천</li>
                                                    <li>광주</li>
                                                </ul>
                                                <ul class="town">
                                                    <li>전체</li>
                                                    <li>강남구</li>
                                                    <li>강동구</li>
                                                    <li>강북구</li>
                                                    <li>강서구</li>
                                                    <li>관악구</li>
                                                    <li>광진구</li>
                                                    <li>영등포구</li>
                                                </ul>
                                            </span>-->
                                        </span>
                                        <span class="option_select_text">
                                            <span>서울시/강남구<a href="#" class="text_del"></a></span>
                                            <span>경기도/전체<a href="#" class="text_del"></a></span>
                                            <span>부산시/해운대구<a href="#" class="text_del"></a></span>
                                        </span>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span class="t1">운동 종목</span>
                                <span class="t2">
                                    <a href="#" class="btn_all">ALL</a>
                                    <span class="t2_inner">
                                        <span class="op_tag on">걷기</span>
                                        <span class="op_tag">골프</span>
                                        <span class="op_tag">등산</span>
                                        <span class="op_tag">러닝</span>
                                        <span class="op_tag">럭비</span>
                                        <span class="op_tag on">런닝머신</span>
                                        <span class="op_tag">서핑</span>
                                        <span class="op_tag">수영</span>
                                        <span class="op_tag">스카이다이빙</span>
                                        <span class="op_tag">스키/보드</span>
                                        <span class="op_tag">스탭퍼</span>
                                        <span class="op_tag">승마</span>
                                        <span class="op_tag">요트</span>
                                        <span class="op_tag">윈드서핑</span>
                                        <span class="op_tag on">인라인</span>
                                        <span class="op_tag">자전거</span>
                                        <span class="op_tag">모터사이클</span>
                                        <span class="op_tag">조정</span>
                                        <span class="op_tag">줄넘기</span>
                                        <span class="op_tag">축구</span>
                                        <span class="op_tag">카누</span>
                                        <span class="op_tag">카약</span>
                                        <span class="op_tag">트레일러닝</span>
                                        <span class="op_tag">트렘벌린</span>
                                        <span class="op_tag">패러글라이딩</span>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span class="t1">배지 유형</span>
                                <span class="t2">
                                    <a href="#" class="btn_all">ALL</a>
                                    <span class="t2_inner">
                                        <span class="op_tag on">공원</span>
                                        <span class="op_tag">만보기</span>
                                        <span class="op_tag">여행지</span>
                                        <span class="op_tag">이벤트</span>
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div><!-- 설정 화면 팝업 -->

            <!-- 배지 화면 -->
            <div class="popup_box popup_box_l" v-if = "this.$store.state.MyTranggle.MyTrangBadgeSearchPagePopup">
                <div class="popup_top popup_top_line"><h2>배지 검색 옵션</h2>
                    <span class="top_left"><a href="#" class="btn" @click="badgeSearch">취소</a></span>
                    <span class="top_right">
                        <a href="#" class="btn_refresh">옵션 초기화</a>
                        <a href="#" class="btn_default" @click="badgeSelect">완료</a>
                    </span>
                </div>
                <div class="popup_body">
                    <div class="option_area">
                        <ul>
                            <li>
                                <span class="t1">배지 종목</span>
                                <span class="t2">
                                    <a href="#" class="btn_all">ALL</a>
                                    <span class="t2_inner">
                                        <span class="op_tag on">걷기</span>
                                        <span class="op_tag">골프</span>
                                        <span class="op_tag">등산</span>
                                        <span class="op_tag">러닝</span>
                                        <span class="op_tag">럭비</span>
                                        <span class="op_tag on">런닝머신</span>
                                        <span class="op_tag">서핑</span>
                                        <span class="op_tag">수영</span>
                                        <span class="op_tag">스카이다이빙</span>
                                        <span class="op_tag">스키/보드</span>
                                        <span class="op_tag">스탭퍼</span>
                                        <span class="op_tag">승마</span>
                                        <span class="op_tag">요트</span>
                                        <span class="op_tag">윈드서핑</span>
                                        <span class="op_tag on">인라인</span>
                                        <span class="op_tag">자전거</span>
                                        <span class="op_tag">모터사이클</span>
                                        <span class="op_tag">조정</span>
                                        <span class="op_tag">줄넘기</span>
                                        <span class="op_tag">축구</span>
                                        <span class="op_tag">카누</span>
                                        <span class="op_tag">카약</span>
                                        <span class="op_tag">트레일러닝</span>
                                        <span class="op_tag">트렘벌린</span>
                                        <span class="op_tag">패러글라이딩</span>
                                        <span class="op_tag">공원</span>
                                        <span class="op_tag">만보기</span>
                                        <span class="op_tag">여행지</span>
                                        <span class="op_tag">이벤트</span>
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- 회원 검색 -->
            <div class="popup_box popup_box_l" v-if = "this.$store.state.MyTranggle.MyTrangFriendSearchPopUp">
                <div class="popup_top popup_top_line"><h2>회원 검색 옵션</h2>
                    <span class="top_left"><a class="btn" @click="friendSearch">취소</a></span>
                    <span class="top_right">
                        <a class="btn_refresh" >옵션 초기화</a>
                        <a class="btn_default" @click="friendSelect">완료</a>
                    </span>
                </div>
                <div class="popup_body">
                    <div class="option_area">
                        <ul>
                            <li>
                                <span class="t1">지역</span>
                                <span class="t2">
                                    <a href="#" class="btn_all">ALL</a>
                                    <span class="t2_inner">
                                        <span class="option_select">지역 선택
                                            <!--<span class="select_city_layer">
                                                <ul class="city">
                                                    <li>전국</li>
                                                    <li class="on">서울</li>
                                                    <li>부산</li>
                                                    <li>대구</li>
                                                    <li>인천</li>
                                                    <li>광주</li>
                                                </ul>
                                                <ul class="town">
                                                    <li>전체</li>
                                                    <li>강남구</li>
                                                    <li>강동구</li>
                                                    <li>강북구</li>
                                                    <li>강서구</li>
                                                    <li>관악구</li>
                                                    <li>광진구</li>
                                                    <li>영등포구</li>
                                                </ul>
                                            </span>-->
                                        </span>
                                        <span class="option_select_text">
                                            <span>서울시/강남구<a href="#" class="text_del"></a></span>
                                            <span>경기도/전체<a href="#" class="text_del"></a></span>
                                            <span>부산시/해운대구<a href="#" class="text_del"></a></span>
                                        </span>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span class="t1">운동 종목</span>
                                <span class="t2">
                                    <a href="#" class="btn_all">ALL</a>
                                    <span class="t2_inner">
                                        <span class="op_tag on">걷기</span>
                                        <span class="op_tag">골프</span>
                                        <span class="op_tag">등산</span>
                                        <span class="op_tag">러닝</span>
                                        <span class="op_tag">럭비</span>
                                        <span class="op_tag on">런닝머신</span>
                                        <span class="op_tag">서핑</span>
                                        <span class="op_tag">수영</span>
                                        <span class="op_tag">스카이다이빙</span>
                                        <span class="op_tag">스키/보드</span>
                                        <span class="op_tag">스탭퍼</span>
                                        <span class="op_tag">승마</span>
                                        <span class="op_tag">요트</span>
                                        <span class="op_tag">윈드서핑</span>
                                        <span class="op_tag on">인라인</span>
                                        <span class="op_tag">자전거</span>
                                        <span class="op_tag">모터사이클</span>
                                        <span class="op_tag">조정</span>
                                        <span class="op_tag">줄넘기</span>
                                        <span class="op_tag">축구</span>
                                        <span class="op_tag">카누</span>
                                        <span class="op_tag">카약</span>
                                        <span class="op_tag">트레일러닝</span>
                                        <span class="op_tag">트렘벌린</span>
                                        <span class="op_tag">패러글라이딩</span>
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        <!-- 설정화면 관심운동 종목 선택 -->
            <div class="popup_box popup_box_lm sports_pop" v-if = "$store.state.MyTranggle.Exercise">
              <div class="popup_top popup_top_line"><h2>관심운동종목 선택</h2>
              </div>
              <div class="popup_body">
                <p>자주하는 운동 혹은 관심 운동을 선택해주세요 (최대 5개)<br /><br />
                미설정 시 개별 종목별 비교, 랭킹, 코스 제안 등의<br />
                맞춤 서비스 제공에 한계가 있습니다.</p>
                <br /><br />
                <div class="to_sports">
                  <span
                    v-for="(name, value) in $getTrackCategoryCustom()"
                    :key="value"
                    :class="{ 'on': $store.state.MyTranggle.categoryPreferArray.includes(value.toLowerCase()) }"
                    :data-value="value"
                    @click="toggleTrackCategory(value, name)">
                    <span :class="$categoryImage(value, 9) + ' i_s'"></span>
                    <span class="to_text">{{name}}</span>
                  </span>
                </div>

              </div>
              <div class="btn_area btn_bottom2">
                <button type="button" @click="interestExercise">취소</button>
                <button type="button" class="on" @click="pushTrackCategory">확인</button>
              </div>
            </div>

            <div class="popup_box popup_box_l" v-if = "this.$store.state.MyTranggle.trangTerms">
                <div class="popup_top popup_top_line"><h2>트랭 이용약관</h2>
                    <span class="top_right">
                        <a href="#" class="btn_default" @click = "trangTermsPage">확인</a>
                    </span>
                </div>
                <div class="popup_body">
                    <div class="popup_terms lg">
                        <h3>서비스 이용약관 (시행일자 2022.03.01)</h3>
                        <h3>제 1 조 (목적)</h3>
                        본 약관은 이용자가 (주)비글(이하 ‘회사’라 합니다)에서 제공하는 인터넷 서비스 상에서 트랭을 이용함에 있어 회사와 이용자 간의 권리/의무 및 기타 책임 사항을 규정함을 그 목적으로 합니다.
                        <br><br>
                        <h3>제 2 조 (용어의 정의)</h3>

                        ① 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. '이용자'란 본 약관에 동의하고 회사가 제공하는 트랭을 이용하는 회원을 말합니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. '트랭'이란 이용자가 회사의 인터넷 서비스 상에서 제공되는 컨텐츠 사용, 디지털 아이템 구매 등 유료 서비스의 이용 대금을 결제할 수 있는 사이버머니를 말하며, 충전트랭,<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;적립트랭, 포인트트랭으로 구분됩니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. '충전'이란 회사가 지정한 방법으로 납입하였거나 납입할 금액(이하 '충전금액'이라 합니다)에 상응하는 트랭을 이용자의 계정에 적립하여 주는 것을 말합니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. ‘충전트랭’은 회원이 회사가 제공하는 결제수단을 이용하여 충전하고 서비스 내에서 현금처럼 사용하는 트랭입니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. ‘적립트랭’은 회원이 회사가 제공하는 오픈마켓을 이용하여 판매하고 서비스 내에서 현금처럼 사용하거나 일정 수수료를 제한 후 출금할 수 있는 트랭입니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. ‘포인트트랭’은 회사가 서비스의 원활한 이용을 위하여 회원에게 부여하는 재산적 가치 및 환가성이 없는 트랭입니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7. '사용취소’란 트랭으로 유료 서비스를 결제한 회원이 유료 서비스를 이용하려고 하였으나 회사의 귀책사유로 인해 정상적으로 서비스를 제공받지 못한 경우 사용한 트랭을<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;회사가 환원해주는 것을 말합니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8. '환불'이란 회원이 보유한 충전트랭을 수수료를 차감한 후 현금으로 돌려받거나, 당해 금액에 대해 승인취소를 하는 것을 말합니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9. ‘환급’이란 회원이 획득한 적립트랭을 수수료를 차감한 후 현금으로 돌려받는 것을 말합니다.<br>
                        ② 제1항에서 정의한 용어 이외의 사항은 관계 법령 및 상관례에서 정하는 바에 따릅니다.
                        <br><br>

                        <h3>제 3 조 (이용 약관의 효력 발생 및 변경)</h3>

                        ① 본 약관은 인터넷 서비스 화면에 게시하거나 기타의 방법으로 이용자에게 통보함으로써 효력이 발생합니다.<br>
                        ② 본 약관에 명시되지 않은 사항에 대해서는 상법, 전기통신사업법 등 관계 법령 및 회사의 인터넷 서비스 화면에 게시하여 공지한 유료 서비스 세부 이용 지침 등의 규정에 따릅니다.<br>
                        ③ 회사는 이 약관을 개정할 경우 그 개정 이유 및 적용일자를 명시하여 현행 약관과 함께 적용일자 7일 전부터 적용일 전일까지 제1항의 방법으로 공지합니다. 다만, 회원의 권리 또는 의무에 관한 중요한 규정의 변경은 최소한 30일 전에 공지하고 개정 약관을 회원이 등록한 전자우편 등으로 통지합니다.<br>
                        ④ 이용자는 변경된 약관에 동의하지 않을 경우 유료 서비스의 이용을 중단하고 이용 계약을 해지할 수 있습니다. 이 경우 회사는 회원이 가입한 유료 서비스의 이용 기간이 남아 있는 경우 이용 계약 해지 시점을 기준으로 환불을 합니다.<br>
                        ⑤ 회사가 제4항에 따라 개정 약관을 공지 또는 통지하면서 회원에게 공지일 또는 통지일로부터 30일 이내에 거부 의사를 표시하지 않으면 의사 표시가 표명된 것으로 본다는 뜻을 공지 또는 통지하였음에도 불구하고 회원이 명시적으로 거부 의사를 표시하지 아니하는 경우 회원이 개정 약관에 동의한 것으로 봅니다.<br>
                        ⑥ 이용자는 약관의 변경에 대하여 주의 의무를 다하여야 하며, 변경된 약관의 부지로 인한 회원의 피해는 회사가 책임지지 않습니다.
                        <br><br>
                        <h3>제 4 조 (유료 서비스 이용 계약 성립)</h3>

                        유료 서비스 이용 계약은 제3조 제1항의 절차에 따라서 이용자가 본 약관에 대하여 “동의합니다”를 선택하고 회사가 이를 승낙함으로써 성립합니다.
                        <br><br>
                        <h3>제 5 조 (트랭 사용 방법)</h3>

                        ① 회사는 제 6 조에 따라 이용자에게 트랭을 충전하여 주고, 회사가 정한 방법에 따라 이용자가 충전 받은 트랭을 사용하여 회사의 유료 서비스의 이용 요금을 결제하면 해당 금액을 이용자의 트랭에서 차감합니다.  트랭을 이용하여 유료 서비스를 결제할 당시 이용자 자신이 직접 충전한 트랭과 제9조 제3항 각 호에 따라서 취득한 트랭을 이용자가 함께 보유하고 있을 경우, 회사는 이용자가 직접 충전한 트랭을 먼저 차감하여 결제하고 잔액이 부족한 경우에는 제9조 제3항 각 호에 따라서 취득한 트랭을 차감함을 원칙으로 합니다.<br>
                        ② 회사는 이용자가 본조 제1항에 따라 트랭을 충전하거나 사용한 경우 이용자가 트랭 잔액을 확인할 수 있도록 하며, 이용자는 트랭을 충전하거나 사용한 경우 즉시 트랭 잔액을 확인하고 이상이 있는 경우에는 회사에 해당 사실을 통지하여야 합니다. 이 경우 회사는 해당 이용자의 트랭 충전 또는 사용 내역을 확인하여 필요한 조치를 취합니다.<br>
                        ③ 트랭을 이용할 수 있는 인터넷 서비스와 트랭을 사용하여 결제할 수 있는 유료 서비스는 회사에서 정한 바에 따르며 회사의 정책에 따라 추가, 변경, 삭제될 수 있습니다.<br>
                        ④ 이용자가 충전 금액의 실제 납부 이전에 트랭을 발급받아 사용하는 경우에는 충전금액을 일정한 기간 내에 회사에 납부하지 아니하면, 회사는 잔여 트랭의 사용을 제한할 수 있습니다.<br>
                        ⑤ 회사는 이용자에 대하여 청소년보호법에 따른 이용 등급 및 연령 준수를 위해 인터넷 서비스에 대하여 이용 제한이나 등급별 연령 제한을 할 수 있습니다.
                        <br><br>
                        <h3>제 6 조 (트랭 획득 등)</h3>

                        ① 이용자는 회사가 정한 방법(계좌이체, 신용카드, 핸드폰 기타 회사가 정하는 결제 수단)으로 충전 금액을 회사에 납부하여 충전트랭을 획득하거나, 회사의 인터넷 서비스 내에서의 활동으로 포인트트랭을 획득할 수 있습니다.<br>
                        ② 이용자는 회사가 제공하는 오픈마켓을 통해 전자 콘텐츠 또는 현물을 판매하여 적립트랭을 획득할 수 있습니다.<br>
                        ③ 회사는 금일십원(10원) 당 트랭 1개를 충전합니다.<br>
                        ④ 충전은 1000원, 3000원, 5000원, 10000원, 30000원, 50000원 단위로 거래할 수 있으며, 1일 최대 한도는 50000원이다.
                        <br><br>
                        <table>
                          <colgroup>
                            <col width="50%" />
                            <col width="50%" />
                          </colgroup>
                          <tr>
                            <th>충전금액 (원)</th>
                            <th>충전트랭</th>
                          </tr>
                          <tr>
                            <td>1,000</td>
                            <td>100</td>
                          </tr>
                          <tr>
                            <td>3,000</td>
                            <td>300</td>
                          </tr>
                          <tr>
                            <td>5,000</td>
                            <td>500</td>
                          </tr>
                          <tr>
                            <td>10,000</td>
                            <td>1,000</td>
                          </tr>
                          <tr>
                            <td>30,000</td>
                            <td>3,000</td>
                          </tr>
                          <tr>
                            <td>50,000</td>
                            <td>5,000</td>
                          </tr>
                        </table>
                        <br><br>

                        ⑤ 트랭을 구매하는 이용자는 반드시 실명 확인을 거쳐 트랭을 충전해야 합니다.<br>
                        ⑥ 미성년자가 트랭을 충전할 경우 친권자 등 법정대리인의 사전 동의를 받아야 하며, 친권자 등 법정대리인의 사전 동의는 트랭 재충전 및 충전한 트랭으로 각 유료서비스 이용 및 상품 구매에 대한 동의를 포함합니다. 친권자 등 법정대리인의 동의 없이 미성년자의 이름으로 트랭을 충전할 경우 친권자 등 법정대리인은 회사에 의사 표시를 하고 트랭 충전 및 유료서비스 이용을 취소할 수 있습니다.<br>
                        ⑦ 미성년자가 타인의 주민등록번호 등 개인 정보 또는 결제 정보를 부정한 방법으로 사용하여 트랭을 충전하거나 해당 트랭으로 유료서비스를 사용한 경우 친권자 등 법정대리인은 이를 취소할 수 없습니다.<br>
                        ⑧ 이용자가 제1항에 따라 충전한 금액을 정상적으로 지불하지 않을 경우, 회사는 트랭 서비스의 사용을 제한하거나 중지시킬 수 있습니다.
                        <br><br>
                        <h3>제 7 조 (트랭 유효기간)</h3>

                        ① 이용자가 트랭을 사용하여 트랭이 차감 처리된 이후에는 트랭의 사용 취소를 할 수 없습니다.<br>
                        ② 제1항에도 불구하고 회사의 귀책 사유로 인하여 정상적인 유료 서비스를 이용자에게 제공하지 못하였을 경우, 회사는 차감된 트랭을 환원 처리합니다.
                        <br><br>
                        <h3>제 9 조 (트랭 환불)</h3>

                        ① 이용자가 충전한 트랭 중 사용하지 아니한 잔액은 환불받을 수 있으며, 회사는 환불 시 환불 금액의 10%에 해당하는 환불수수료를 공제한 나머지 금액을 이용자에게 환불합니다.<br>
                        ② 회사는 본 조에 따른 트랭의 환불 절차와 관련하여 트랭 잔액 중 회사로 입금이 확인된 트랭에 대한 환불은 현금으로 지급함을 원칙으로 합니다. 단, 결제승인취소가 가능할 경우 결제승인취소로 환불을 대신하고 동일 결제수단으로 결제 금액을 재환원합니다.<br>
                        ③ 회사는 제1항에도 불구하고 아래 각 호에 해당하는 경우에는 환불을 하지 않습니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. 다른 이용자로부터 선물 또는 양도 받은 트랭<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. 이벤트 당첨 또는 참여로 적립 받은 트랭<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. 인터넷 서비스 내에서 활동 등으로 회사로부터 적립 받은 트랭<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. 기타 이용자가 직접 충전하지 않은 트랭<br>
                        ④ 이용자가 외부 마일리지나 포인트로부터 전환된 트랭을 환불 요청하는 경우, 회사는 경험치와 포인트로 전환하며, 현금 환불은 하지 않습니다.<br>
                        ⑤ 회사는 이용자로부터 환불처리에 필요한 본인 신분증 사본, 본인 통장 사본 등을 수령하고 이용자가 충전을 위하여 입금을 완료한 사실을 확인한 후 매주 은행 첫 영업일 이내에 이용자가 지정한 계좌로 해당 환불금액을 입금합니다. 핸드폰, 신용카드, 일반전화 등을 이용한 결제의 경우에는 입금 확인에 일정 기간이 소요되기 때문에 환불 처리가 지연될 수 있습니다.<br>
                        ⑥ 회사가 이용자에게 환불하는 트랭 잔액이 금일천원(1,000원) 미만일 경우에는 환불이 제한될 수 있습니다.
                        <br><br>
                        <h3>제 10 조 (트랭 선물)</h3>

                        ① 이용자는 회사가 정하는 방법 및 절차에 의하여 다른 이용자에게 트랭의 전부 또는 일부를 선물할 수 있습니다. 단, 회사는 이용자가 트랭을 선물하는 것에 대하여 회사의 이용정책에 따라 제한할 수 있습니다.<br>
                        ② 회사로부터 트랭 또는 유료서비스를 선물 받은 이용자가 1개월 이상 선물의 제공을 수락하지 아니할 경우에 회사는 선물의 제공을 취소할 수 있습니다.<br>
                        ③ 다른 이용자로부터 트랭 또는 유료서비스를 선물 받은 이용자가 1개월 이상 선물의 제공을 수락하지 아니할 경우에 회사는 선물을 제공한 이용자에게 트랭을 재충전하거나 선물을 반환하고 선물의 제공을 취소할 수 있습니다.<br>
                        ④ 트랭 선물은 충전트랭으로만 선물할 수 있고, 선물을 받는 이용자에게는 포인트 트랭으로 제공됩니다.<br>
                        ⑤ 트랭 선물 시 30%의 양도수수료가 선물 트랭에서 차감됩니다.
                        <br><br>
                        <h3>제 11 조 (회사의 의무)</h3>

                        ① 회사는 본 약관이 정하는 바에 따라 지속적이고 안정된 트랭 서비스를 제공하기 위하여 노력합니다.<br>
                        ② 회사는 트랭 서비스와 관련하여 이용자로부터 제기되는 불만 사항 등이 정당하다고 판단하는 경우 그 문제를 신속하게 해결하도록 노력하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 이용자에게 통지합니다.<br>
                        ③ 회사는 트랭 서비스의 설비에 장애가 발생한 경우 최대한 신속하게 그 설비를 수리하거나 복구합니다.
                        <br><br>
                        <h3>제 12 조 (이용자 권리 및 의무)</h3>

                        ① 이용자는 회사가 제공하는 트랭 서비스 이용 시 본 약관, 세부이용지침 및 관련 법령을 준수하여야 하여야 하며, 기타 회사의 명예를 훼손하거나 트랭 서비스 운영 기타 업무수행에 지장을 초래하는 행위를 해서는 아니 됩니다.<br>
                        ② 이용자는 타인의 명의, 이용자ID, 비밀번호, 휴대폰번호, 계좌번호, 신용카드번호 등 개인정보나 금융정보를 도용하거나 부정 사용하여서는 아니 됩니다.<br>
                        ③ 이용자는 회사가 사전에 동의한 경우를 제외하고는 트랭 서비스를 이용하여 영업활동을 하거나 타인에게 서비스를 제공하여서는 아니 됩니다.<br>
                        ④ 이용자는 트랭 서비스 이용과정에서 선량한 풍속 기타 사회질서에 반하는 행위를 하여서는 아니 됩니다.<br>
                        ⑤ 이용자는 트랭 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 이외의 목적으로 사용하여서는 아니 됩니다.<br>
                        ⑥ 이용자ID 및 비밀번호, 휴대폰번호, 계좌번호, 신용카드번호 등 개인정보나 금융정보는 이용자 본인이 직접 관리하여야 하며, 본인이 아닌 타인이 사용하게 하여서는 아니 됩니다. 이용자의 개인정보를 타인이 사용하여 트랭 서비스를 이용함으로써 발생한 결과에 대한 책임은 이용자 본인에게 있습니다.
                        <br><br>
                        <h3>제 13 조 (개인정보 수집 및 보호)</h3>

                        ① 회사는 트랭 서비스 제공을 위한 목적으로 이용자의 동의를 얻어 수집한 개인정보를 본인의 승낙 없이 타인에게 누설할 수 없으며, 트랭 서비스 제공 목적 이외의 용도로 사용하지 않습니다. 다만, 관련 법령에 의한 수사상의 목적 등으로 관계 기관으로부터 요구 받은 경우나 방송통신심의위원회의 요청이 있는 경우 등 법령에 따른 적법한 절차에 의한 경우에는 그러하지 아니합니다.<br>
                        ② 회사는 필요한 경우 이용자의 동의를 얻어 이용자의 개인정보를 이용하거나 제3자에게 제공할 수 있습니다. 이 경우 그 개인정보의 이용 목적, 제공받는 자, 제공하는 개인정보 항목, 제공 목적, 제공 시기 등에 대해 개별적으로 이용자의 동의를 받습니다.<br>
                        ③ 회사는 이용자의 개인정보 보호와 관련하여 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법 등 관련 법령을 준수합니다.<br>
                        ④ 이용자의 개인정보 열람 요청은 관련 법령에 따라 수사기관의 수사 자료제공 절차에 의해서 가능하며 이용자는 자신의 개인정보 도용 등을 이유로 타인의 개인정보를 열람할 수 없습니다.<br>
                        ⑤ 본 조에서 정한 사항 이외의 이용자의 개인정보보호에 관한 사항은 회사가 제정한 '개인정보취급방침'에서 정한 바에 의합니다.
                        <br><br>
                        <h3>제 14 조 (트랭 이용 제한)</h3>

                        ① 이용자는 다음 각호에 해당하는 행위를 하여서는 아니 되며, 이를 위반할 경우 회사는 당해 이용자에 대하여 트랭 이용을 정지시킬 수 있습니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. 타인의 이용ID 및 비밀번호를 부정하게 사용하는 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. 타인의 명의나 개인정보를 도용하여 서비스를 이용하는 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. 타인의 결제정보 등을 도용하거나 부정한 행위로 거래를 하는 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. 이용자의 트랭을 매매하거나 회사가 허용하지 아니한 곳에서 양도하는 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. 이용자가 결제수단을 제공하는 사업자가 지정한 방식으로 이용요금을 납부하지 않고 장기 연체하는 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. 다른 이용자의 트랭 사용을 저해하거나, 트랭 서비스 제공에 지장을 초래하는 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7. 본 약관에 위반하는 방법으로 트랭 서비스를 이용하는 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8. 트랭이 사용되는 회사의 인터넷 서비스 이용약관에 위반하는 방법으로 서비스를 이용하는 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9. 기타 법령에 위배되는 행위를 하는 경우<br>
                        ② 회사는 트랭 이용을 제한할 경우 이용 제한 내용과 소명 절차를 이용자가 지정한 전자우편 또는 서비스 내 쪽지 등으로 통지하여 이용자가 소명할 수 있는 기회를 부여합니다.
                      <br><br>
                      <h3>제 15 조 (트랭 제공 중단)</h3>

                        ① 회사는 다음 각 호의 1에 해당하는 사유가 발생한 경우에는 트랭 서비스의 전부 또는 일부의 제공을 중단할 수 있습니다. 이 경우 회사는 가능한 한 그 중단 사유, 중단 기간 등을 이용자에게 사전 공지합니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. 회사가 긴급한 시스템 점검, 증설, 교체, 시설의 보수 또는 공사를 하기 위하여 부득이한 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. 새로운 트랭 서비스 시스템으로의 교체 등을 위하여 회사가 필요하다고 판단하는 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. 시스템 또는 기타 서비스 설비의 장애, 유무선 Network 장애 또는 트랭 서비스 이용의 폭주 등으로 정상적인 트랭 서비스 제공이 불가능할 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. 천재지변, 국가비상사태, 정전 등 회사가 통제할 수 없는 불가항력적 사유로 인한 경우<br>
                        ② 회사는 전항 각 호의 사유 발생시 부득이할 경우 예고 없이 트랭 서비스의 전부 또는 일부의 제공을 일시 중지할 수 있습니다. 이 경우 그 사유 및 중지 기간 등을 이용자에게 지체없이 사후 공지합니다.
                        <br><br>
                        <h3>제 16 조 (손해배상)</h3>

                        ① 회사의 트랭 서비스와 관련하여 회사의 귀책사유로 이용자에게 손해가 발생한 경우, 이용자에게 발생한 손해를 배상합니다.<br>
                        ② 이용자가 본 약관상의 의무를 위반함으로 인하여 회사에 손해가 발생한 경우 또는 이용자가 트랭 서비스를 이용함에 있어 회사에 손해를 입힌 경우, 이용자는 회사에 그 손해를 배상하여야 합니다.
                        <br><br>
                        <h3>제 17 조 (면책)</h3>

                        ① 회사는 다음 각 호의 1에 해당하는 사유로 인하여 이용자 또는 제3자에게 발생한 손해에 대하여는 그 책임을 지지 아니합니다.<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. 천재지변 또는 이에 준하는 불가항력으로 인해 서비스를 제공할 수 없는 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. 이용자가 자신의 이용자ID 또는 비밀번호 등의 관리를 소홀히 하는 등 타인의 부정사용을 방치한 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. 이용자가 제3자의 이용자ID, 비밀번호, 휴대폰번호, 계좌번호, 신용카드번호 등 개인정보를 도용하여 제3자에게 손해를 발생시킨 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. 회사의 관리영역이 아닌 공중통신선로의 장애로 서비스이용이 불가능한 경우<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. 기타 회사의 귀책사유가 없는 통신서비스 등의 장애로 인한 경우<br>
                        ② 회사는 이용자가 트랭 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않습니다.
                        ③ 회사는 이용자 상호간 또는 이용자와 제3자 상호간에 트랭 서비스와 관련하여 발생한 분쟁에 대하여 개입할 의무가 없으며, 회사에 귀책사유가 없는 한 이로 인하여 발생한 손해를 배상할 책임이 없습니다.
                        <br><br>
                        <h3>제 18 조 (분쟁의 해결)</h3>

                        ① 유료 서비스 이용과 관련하여 회사와 이용자 간에 분쟁이 발생한 경우 본 약관, 유료 서비스 세부 이용 지침, 관계 법령 및 상관례에 의거하여 상호 합의하에 분쟁을 해결합니다.<br>
          ② 전 항에 따라 쌍방간에 합의가 이루어지지 않는 경우 대한민국의 민사소송법에 따른 법원을 관할법원으로 하여 해결합니다.
                        <br><br>
                        <h3>개정이력 및 내용</h3>
                        2022년 03월 01일 : 오타 및 띄어쓰기 수정<br><br>

                        공시일자 : 2022.02.21<br>
                        시행일자 : 2022.03.01

                    </div>
                </div>
            </div>

            <div class="popup_box popup_box_m" v-if = "this.$store.state.MyTranggle.paymentPage">
                <div class="popup_top line_bottom"><h2>트랭 충전</h2>
                </div>
                <div class="popup_body">
                    <p class="t_center">트랭은 아이템 구매 및 클럽 개설, 트랭글톡, 코스북 구매등<br>
                    트랭글 사용시에 이용되는 <span class="text_org">트랭글 머니</span>입니다.
                    </p>

                    <div class="cbook_buy">
                        <ul>
                            <li :class="{'on':trangSelect == 100}" @click="trangSelect = 100, paySelect = 1200">
                                <span class="trang">100</span><span class="won">1,200 원</span>
                            </li>
                            <li :class="{'on':trangSelect == 300}" @click="trangSelect = 300, paySelect = 3900">
                                <span class="trang">300</span><span class="won">3,900 원</span>
                            </li>
                            <li :class="{'on':trangSelect == 500}" @click="trangSelect = 500, paySelect = 5900">
                                <span class="trang">500</span><span class="won">5,900 원</span>
                            </li>
                            <li :class="{'on':trangSelect == 1000}" @click="trangSelect = 1000, paySelect = 12000">
                                <span class="trang">1,000</span><span class="won">12,000 원</span>
                            </li>
                            <li :class="{'on':trangSelect == 3000}" @click="trangSelect = 3000, paySelect = 37000">
                                <span class="trang">3,000</span><span class="won">37,000 원</span>
                            </li>
                            <li :class="{'on':trangSelect == 5000}" @click="trangSelect = 5000, paySelect = 65000">
                                <span class="trang">5,000</span><span class="won">65,000 원</span>
                            </li>
                        </ul>
                    </div>
                    {{trangErrorMessege}}
                    <div class="btn_area">
                        <button type="button" @click = "trangAdd">취소</button>
                        <button type="button" class="on" @click="TrangOrderNo (trangSelect, paySelect)">구매하기</button>
                    </div>
                </div>
            </div>

            <div class="popup_box popup_box_s" v-if ="this.$store.state.MyTranggle.MessageSendEnd">
                <div class="popup_top popup_top_line"><h2>메시지 전송 완료</h2><a href="#" class="btn_close" @click="closeMsgEndPop"></a>
                </div>
                <div class="popup_body">
                    <p>메시지가 전송되었습니다.</p>
                </div>
            </div>

            <div class="popup_box popup_box_m" v-if ="this.$store.state.MyTranggle.msgstat">
                <div class="popup_top popup_top_line"><h2>메시지 보내기</h2>
                </div>
                <div class="popup_body">
                    <p class="to">To. {{this.$store.state.MyTranggle.msgBoxViewNickname}}</p>
                    <textarea placeholder="메시지를 입력하세요.(최대 800자)" v-model = "this.$store.state.MyTranggle.msgContent" maxlength='800' ></textarea>
                    <div class="btn_area">
                        <button type="button" class="" @click="msgPopTap('')">취소</button>
                        <button type="button" class="on" @click="msgSend">전송</button>
                    </div>
                </div>
            </div>

            <div class="popup_box popup_box_ms" v-if ="this.$store.state.MyTranggle.addrSearch">
                <div class="popup_top popup_top_line"><h2>주소 찾기</h2><a href="#" class="btn_close" @click="addrSearchView"></a>
                </div>
                <div class="address_area">
                    카카오 등 사이트에서 주로 사용하는 주소 검색 라이브러리 적용
                    -주소 입력시, 주소 직접 입력시 팝업
                </div>
            </div>

            <div class="popup_box popup_box_l" v-if ="this.$store.state.MyTranggle.mapSearch">
                <div class="popup_top popup_top_line"><h2>지도에서 선택</h2><a href="#" class="btn_close" @click="mapSearchView"></a></div>
                <div class="map_area">
                    <Map ref="map"/>
                    <!-- <div class="map_rnb">
                        <div class="map_btn_zoom">
                            <a href="#"><span class="btn_in">확대</span></a>
                            <a href="#"><span class="btn_out">축소</span></a>
                        </div>
                    </div> -->

                    <!-- <div class="map_pin_g" style="top:270px; left:435px;">
                        <div class="map_pin default"></div>
                    </div> -->
                    <div class="btn_area">
                        <button type="button" class="on" @click ="locationBlockConfigUpt">위치 공유 제한 지역으로 지정</button>
                    </div>
                </div>
            </div>

            <div class="popup_box popup_box_m" v-if ="this.$store.state.MyTranggle.addVlog">
                <div class="popup_top popup_top_line"><h2>브이로그 등록</h2>
                </div>
                <div class="popup_body">
                    <p>해당 장소에서 촬영한 동영상만 등록해 주시기 바라며,<br>
                    동영상 등록 후 승인이 완료되면 트랭을 적립해 드립니다.
                    </p>
                    <div class="input_area">
                        <ul>
                            <li>
                                <span class="title">제목</span>
                                <span class="input_text"><input type="text" placeholder="제목을 입력해주세요" v-model="title"/></span>
                            </li>
                            <li>
                                <span class="title">URL</span>
                                <span class="input_text"><input type="text" placeholder="등록하고자 하는 동영상 URL 주소를 입력해주세요" v-model="link"/></span>

                            </li>
                        </ul>
                    </div>
                    <div class="link_area">
                        <a href="https://www.youtube.com/"><img src="../../assets/images/common/img_youtube.png" /></a>
                    </div>
                    <div class="btn_area">
                        <button type="button" class="" @click="vlogAddPage">취소</button>
                        <button type="button" class="on" @click="gear360VideoRegist">등록</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import Map from '@/components/mytranggle/configMap.vue'
import dayjs from 'dayjs'
// import { mapGetters, mapMutations } from 'vuex'
// let mapInstance = null
export default {
  data () {
    return {
      oldPass: '',
      newPass: '',
      reNewPass: '',
      passErrorText: '',
      nameChk: '',
      phoneNumChk: '',
      passChk: '',
      exitsErrorText: '',

      trangSelect: '',
      paySelect: 0,
      trangErrorMessege: '',

      item0: '',
      item1: '',
      item2: '',
      item3: '',
      item4: '',

      validatePassword: '',
      comparePassword: null

      // category: []
    }
  },
  methods: {
    //   this.$store.commit('MyTranggle/confChgComp')
    //   this.$store.dispatch('')
    //   this.$store.getters()
    ...mapMutations('MyTranggle', [
      'confChgComp',
      'confClosePopChg',
      'badgeSearch',
      'badgeSelect',
      'friendSearch',
      'friendSelect',
      'badgeSearchPage',
      'badgeSearchSelect',
      'interestExercise',
      'confMemberWith',
      'confMemberWithNext',
      'confMemberwithdrawLastChk',
      'trangTermsPage',
      'trangAdd',
      'msgSendComp',
      'msgPopView',
      'closeMsgEndPop',
      'addrSearchView',
      'mapSearchView',
      'vlogAddPage'
    ]),
    ...mapActions('MyTranggle', [
      'locationBlockConfig'
    ]),
    ...mapActions('UserSet', [
      'loadLogout'
    ]),
    close () {
      this.oldPass = ''
      this.newPass = ''
      this.reNewPass = ''
      this.nameChk = ''
      this.phoneNumChk = ''
      this.passChk = ''
      this.validatePassword = ''
      this.comparePassword = null
      this.confClosePopChg()
    },
    gear360VideoRegist () {
      this.axios.get('/Badge/gear360_video_regist.json', {
        params: {
          token: this.$store.state.Member.token,
          title: this.title,
          // 임시
          badge_id: this.$route.params.badgeCode,
          // badge_id: '12000108',
          link: this.link
        }
      })
        .then(data => {
        //   console.log(data)
          //   if (data.data.response.code === '00') {
          this.title = ''
          this.link = ''
          this.vlogAddPage()
          this.$route.go()
          //   }

        // console.log(this.gear360VideoListArray[0])
        })
    },
    checkConfirmPassword () {
      this.comparePassword = this.newPass === this.reNewPass
    },
    checkPassword () {
      const numRegType = this.newPass.search(/[0-9]/g)
      const engRegType = this.newPass.search(/[A-Za-z]/ig)
      const spcRegType = this.newPass.search(/[!?@#$%^&*():;+-=~{}<>₩₩₩'₩";:₩/?]/gi)

      if (!(this.newPass.length >= 8 && this.newPass.length <= 20) ||
        !((numRegType > -1 && engRegType > -1) || (engRegType > -1 && spcRegType > -1) || (spcRegType > -1 && numRegType > -1))) {
        this.validatePassword = 'N'
      } else {
        this.validatePassword = 'Y'
      }
    },
    pushTrackCategory () {
      // console.log(this.$store.state.MyTranggle.categoryPrefer)
      // console.log(this.$store.state.MyTranggle.categoryPreferArray)
      var text = ''
      var textKO = ''
      for (const i in this.$store.state.MyTranggle.categoryPreferArray) {
        // console.log(this.$changeSportsLanguage(array[i].toUpperCase()))
        if (i === '0') {
          text = this.$store.state.MyTranggle.categoryPreferArray[i]
          textKO = this.$changeSportsLanguage(this.$store.state.MyTranggle.categoryPreferArray[i].toUpperCase())
        } else {
          text = text + '|' + this.$store.state.MyTranggle.categoryPreferArray[i]
          textKO = textKO + ', ' + this.$changeSportsLanguage(this.$store.state.MyTranggle.categoryPreferArray[i].toUpperCase())
        }
      }
      if (this.$store.state.MyTranggle.categoryPreferArray[0] === 'ALL') {
        this.$store.state.MyTranggle.categoryPrefer = ''
        this.$store.state.MyTranggle.categoryPreferText = ''
      } else {
        this.$store.state.MyTranggle.categoryPrefer = text
        this.$store.state.MyTranggle.categoryPreferText = textKO
      }
      // if ()

      // console.log(this.$store.state.MyTranggle.categoryPrefer)
      this.interestExercise()
    },
    toggleTrackCategory (e) {
      // console.log(this.$store.state.MyTranggle.categoryPreferArray.length)
      if (e === 'ALL') {
        this.$store.state.MyTranggle.categoryPreferArray = []
        this.$store.state.MyTranggle.categoryPreferArray.push(e.toLowerCase())
      } else if (!this.$store.state.MyTranggle.categoryPreferArray.includes(e.toLowerCase())) {
        if (this.$store.state.MyTranggle.categoryPreferArray.length < 5) {
          this.$store.state.MyTranggle.categoryPreferArray.push(e.toLowerCase())
        }
      } else {
        this.$store.state.MyTranggle.categoryPreferArray.splice(this.$store.state.MyTranggle.categoryPreferArray.indexOf(e.toLowerCase()), 1)
      }
      if (this.$store.state.MyTranggle.categoryPreferArray.includes('ALL') && this.$store.state.MyTranggle.categoryPreferArray.length > 1) {
        this.$store.state.MyTranggle.categoryPreferArray.splice(this.$store.state.MyTranggle.categoryPreferArray.indexOf('ALL'), 1)
      } else if (this.$store.state.MyTranggle.categoryPreferArray.length === 0) {
        this.$store.state.MyTranggle.categoryPreferArray.push('ALL')
      }
    },
    locationBlockConfigUpt () {
      if (this.$store.state.MyTranggle.configLon !== null) {
        const formData = new FormData()
        formData.append('token', this.$store.state.Member.token)
        formData.append('location_longitude', this.$store.state.MyTranggle.configLon)
        formData.append('location_latitude', this.$store.state.MyTranggle.configLat)
        formData.append('location_around', '2000')
        this.axios.post('/Member/member_location_block_config_upt.json', formData)
          .then(data => {
          // console.log(data.data.response.message)
            // this.$showMessagePopup('', data.data.response.message, '')
            this.locationBlockConfig()
            this.mapSearchView()
          })
      } else {
        this.$showMessagePopup('', '제한할 지역을 클릭해주세요')
      }
    },
    // vuex의 state 값 변경 테스트
    // confChgComp2 () {
    //   this.$store.state.MyTranggle.oldPassword = this.oldPass
    //   this.$store.state.MyTranggle.newPassword = this.newPass
    //   this.$store.state.MyTranggle.newPasswordRe = this.reNewPass
    // },
    msgSend () {
      // console.log('chk1')
      this.msgTargetId()
      this.msgSendComp()
    },
    msgTargetId () {
      // console.log('chk1')
      // console.log(this.$store.state.MyTranggle.selectFriend.length)
      if (this.$store.state.MyTranggle.selectFriend.length !== 0) {
        for (const i in this.$store.state.MyTranggle.selectFriend) {
          console.log(this.$store.state.MyTranggle.selectFriend[i].friend_id)
          if (this.$store.state.MyTranggle.selectFriend[i].friend_id !== undefined) {
            this.messageSend(this.$store.state.MyTranggle.selectFriend[i].friend_id)
          } else if (this.$store.state.MyTranggle.selectFriend[i].UserID !== undefined) {
            this.messageSend(this.$store.state.MyTranggle.selectFriend[i].UserID)
          }
        }
      } else {
        this.messageSend(this.$store.state.MyTranggle.friendIdOne)
      }
    },
    messageSend (targetId) { // 친구 목록
      // console.log(this.$store.state.Member.token)
      // console.log(targetId)
      if (targetId !== null || targetId !== undefined || targetId !== '') {
        this.axios.get('/message/send.json', {
          params: {
          // token: '69202CC15E60313F5ED6BC8870C139DE6089135888506F2BFFECC13D24001D9213EDC2AA63FA7401',
            token: this.$store.state.Member.token,
            receive_id: targetId,
            message: this.$store.state.MyTranggle.msgContent
          }
        })
          .then(data => {
          // this.listCount = data.data.response.content.list_count
          // this.listArray = data.data.response.content.list
          // console.log(this.listArray)
            this.$store.state.MyTranggle.friendIdOne = ''
            this.$store.state.MyTranggle.selectFriend = []
            this.$store.state.MyTranggle.msgBoxViewNickname = ''
            this.$store.state.MyTranggle.msgContent = ''
            console.log(data)
          })
      } else {
        this.$showMessagePopup('', '대상 ID정보가 비어있습니다.')
      }
    },
    msgPopTap () {
      this.msgPopView()
    },
    passChgAPI () {
      // console.log(this.$store.state.token)
      this.axios.get('/Member/change_password.json', {
        params: {
          token: this.$store.state.Member.token,
          password: this.oldPass,
          new_password: this.newPass,
          new_password_re: this.reNewPass
        }
      })
        .then(data => {
          // if구성 00이면 페이지 이동 아니면 텍스트 표출
          if (data.data.response.code === '00') {
            this.confChgComp()
          } else {
            // this.passErrorText = data.data.response.message
            this.$showMessagePopup('', data.data.response.message)
          }
        })
    },
    exitsAPI () {
      // console.log(this.$store.state.token)
      this.axios.get('/Member/exits.json', {
        params: {
          token: this.$store.state.Member.token,
          name: this.nameChk,
          phone: this.phoneNumChk,
          password: this.passChk

        }
      })
        .then(data => {
          // if구성 00이면 페이지 이동 아니면 텍스트 표출
          // this.confChgComp()
          if (data.data.response.code === '00') {
            // this.exitsErrorText = '로그인 페이지 생성후 이동 시킬예정'
            // this.$router.push('/')
            
            // 탈퇴 후 회원 데이터 스토어 초기화
            // this.$store.state.UserSet.isLogin = "";
            // this.$store.state.UserSet.memberId = "";
            // this.$store.state.UserSet.memberNick = "";
            // this.$store.state.UserSet.isOpenDormantPopup = false;
            // this.$store.state.UserSet.isOpenthirdPopup = false;

            this.$showMessagePopup('', data.data.response.message, '/')
            this.loadLogout();
          } else {
            // this.exitsErrorText = data.data.response.message
            this.$showMessagePopup('', data.data.response.message)
          }
        })
    },
    PopType1Call () {
      this.popOn = !this.popOn
      this.MyTrangConfPop2 = !this.MyTrangConfPop2
    },
    defaultDataRead () {
      // console.log(this.$store.state.token)
      this.axios.get('/member/info.json', {
        params: {
          token: this.$store.state.Member.token,
          member_id: this.$store.state.Member.memberId
          // member_id: 'vlenddl'
        }
      })
        .then(data => {
          // this.category = data.data.response.content.response.content.list.response.content.categoryPrefer
          this.item0 = data.data.response.content.categoryPrefer.split('|')[0]
          this.item1 = data.data.response.content.categoryPrefer.split('|')[1]
          this.item2 = data.data.response.content.categoryPrefer.split('|')[2]
          this.item3 = data.data.response.content.categoryPrefer.split('|')[3]
          this.item4 = data.data.response.content.categoryPrefer.split('|')[4]
          if (this.item0 != null) {

          }
          // console.log(this.item3)
        })
    },
    TrangOrderNo (code, pay) {
      var orderCode = ''
      this.axios.get('/Trang/order_no.json', {
        params: {
          token: this.$store.state.Member.token,
          // token: '35F171EF3EA8E438EBE3ECD06AD0C3B3BEE0BBBEB2AF28565FCA0094AE19E7D1',
          app_store_id: 'googleplaystore',
          app_product_code: 'tranggle_trang_' + code
        }
      })
        .then(data => {
          if (data.data.response.code === '00') {
            orderCode = data.data.response.content.order_code
            this.trangPayment(code, pay, orderCode)
          } else {
            this.trangErrorMessege = data.data.response.message
          }
        })
    },
    trangPayment (code, pay, orderCode) {
      // console.log(this.$store.state.token)
      var tempCode = 'GPA.' + dayjs().format('YYYY-mmmm-DDDD-HHmmss')
      console.log(tempCode)
      this.axios.get('/Trang/payment.json', {
        params: {
          token: this.$store.state.Member.token,
          // token: '35F171EF3EA8E438EBE3ECD06AD0C3B3BEE0BBBEB2AF28565FCA0094AE19E7D1',
          app_store_id: 'googleplaystore',
          app_product_code: 'tranggle_trang_' + code,
          order_code: orderCode,
          payment_price: pay,
          payment_price_type: 'won',
          payment_date: dayjs().format('YYYY-MM-DD HH:mm:ss'), // 2021-03-09 14:22:56
          // order_appr_code: 'GPA.3368-4715-6042-26142'
          // 임시
          order_appr_code: tempCode // 발급받는 코드를 일단 임의로 작성 차후 결제모듈이랑 연계 필요
        }
      })
        .then(data => {
          console.log(data)
          if (data.data.response.code === '00') {
            this.trangAdd()
            this.$router.go()
          } else {
            this.$showMessagePopup('', data.data.response.message)
          }
        })
    },
    category (data) {
      this.data = !this.data
    },
    withdrawalCheck () {
      
      if (this.$store.state.Member.memberInfo.sns_account) {
        // sns 탈퇴
        this.passChk = this.$store.state.Member.memberInfo.sns_account
        this.exitsAPI()
      } else {
        this.confMemberwithdrawLastChk()
      }
    }

  },
  computed: {
    // ...mapState({ items: state => state.items, }),

    changedParams: function () {
      return {
        popOn: this.$store.state.MyTranggle.MyTrangConfPopUpStat,
        MyTrangConfPop1: this.$store.state.MyTranggle.MyTrangConfPop1,
        MyTrangConfPop2: this.$store.state.MyTranggle.MyTrangConfPop2
      }
    }
  },
  watch: {
  },
  mounted () {
    this.defaultDataRead()
  },
  components: {
    Map: Map
  }
}

</script>
